<template>
    <div class="intro-y">
        <div v-if="this.$store.state.postDrop.pendingBatches.isReady && this.$store.state.postDrop.pendingTable.isReady">
            <uploaded-files-table>
            </uploaded-files-table>
        </div>
    </div>

</template>

<script>
import UploadedFilesTable from "./uploadedFiles/UploadedFilesTable"
import i18n from "../../i18n";
export default {
    name: "UploadedFiles",
    components : {UploadedFilesTable},
    mounted(){
        this.$store.dispatch("updateToken");
        this.$store.commit("setPendingBatchesIsReady" , false)
        this.$store.dispatch("getPendingBatches");
        this.$store.commit("setPendingTableDataIsReady" , false);
        this.$store.dispatch("setPendingTableData");
        this.$store.commit("setActiveComponent", "uploadedFiles")
    },
    beforeUnmount(){
        this.$store.commit("setActiveComponent", "")
    },
    data() {
        return {
            i18n : i18n,
        }
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
