<template>
    <pop-up-window :show="this.$store.state.postDrop.displayCreateDepartment"
                   :showClose=false
                   class="doc-statuses-popup"
                   positionFixed=true
                   :top0=true
                   fixed
                   >
        <template #title>
            <h1 class="text-2xl">
                {{ $t('postDrop.createDepartmentTitle') }}
            </h1>
        </template>
        <template #content>
            <div class="one-line">
                <h2 class="base-text header-text">{{ $t('postDrop.departmentCode') }}:</h2>
                <input
                      v-uppercase
                      id="code"
                      type="text"
                      class="form-control text-popup input-flex"
                      v-model="code"
                      @click = "setErrors('code', false)"
                >
            </div>
            <div v-if="errors.codeFormat">
                <p class="error error-styling"

                >
                    {{ $t('errors.invalidDepartmentCodeFormat') }}
                </p>
            </div>
            <div v-if="errors.code">
                <p class="error error-styling">
                    {{ $t('errors.emptyDepartmentCode') }}
                </p>
            </div>
            <div class="one-line">
                <h2 class="base-text header-text">{{ $t('postDrop.departmentName') }}:</h2>
                <input
                       id="name"
                       type="text"
                       class="form-control text-popup input-flex"
                       v-model="name"
                       @click = "setErrors('name', false)"
                >
            </div>

            <div v-if="errors.name">
                <p class="error error-styling">
                    {{ $t('errors.emptyDepartmentName') }}
                </p>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6 grey-line">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
                        {{$t('buttons.cancel')}}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="create">
                        {{$t('buttons.create')}}
                    </button>
                </div>

            </div>

        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow";

export default {
    name: "CreateDepartment",
    components: {PopUpWindow},
    data(){
        return{
            name: '',
            code: '',
            errors: {
                code: false,
                name: false,
                codeFormat : false
            }
        }
    },
    directives: {
        uppercase(el){
            el.value = el.value.toUpperCase();
        }
    },
    methods:{
        codeIsValid(code){
            let codeValid = true;
            if (code.includes(' ') || !(code.match("^[A-Z0-9]+$")) || code.length>8){
                codeValid = false
            }
            if (!codeValid){
                this.errors.codeFormat = true;
                return false
            }
            return true
        },
        create(){
            this.code = this.code.trim();
            if ( this.code === ''){
                this.errors.code = true
                return
            }
            if (!(this.codeIsValid(this.code))){
                this.errors.codeFormat = true;
                return
            }
            if (this.name === ''){
                this.errors.name = true
                return
            }
            let formData = {
                name : this.name,
                code : this.code
            }
            this.$store.dispatch("department/createDepartment", formData);
            this.close();
        },
        close(){
            this.name = '';
            this.code= "";
            this.setErrors('code' ,false);
            this.setErrors('name' ,false);
            this.$store.commit("setCreateDepartment" , false);
        },
        setErrors(field, value){
            if (field === "code"){
                this.errors.codeFormat  = value;
                this.errors.code  = value;
            }
            this.errors[field]= value
        }
    }
}
</script>

<style scoped>
.one-line{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 160px auto;
    align-items: center;
    margin-top: 10px;

}
</style>
