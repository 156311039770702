import axios from "axios"

export default {
	setExtendedSearchUserRole(context, payload) {
		axios.get(context.rootState.domainName + context.rootState.API.getUsers + "?group=" + payload.selectedRole, {
			headers: {
				'Authorization': "bearer " + context.rootState.token
			}
		})
			.then((response) => {
				context.commit("setDataManagmentExtendedSearch", response.data.data.users, {root: true})
				context.rootState.userDataUpdated = true
			})
			.catch(err => console.error(err))
	}
}