<template>
    <pop-up-window :show="this.$store.state.displayAskPopUp"
                   :showClose=false
                   class="doc-statuses-popup"
                   :top0=true
                   fixed>
        <template #title>
            <h1 class="text-2xl">
             {{header}}
            </h1>
        </template>
        <template #content>
            <div class="text-center">
                {{description}}
            </div>
            <div class="btn-content mt-2">
                <div>
					<button class="btn custom-button shadow-md mr-2 text-popup" @click="openWindow">
						{{ $t('buttons.yes') }}
					</button>
                    <button class="btn custom-button shadow-md mr-2 text-popup" @click="close(false)">
                        {{ $t('buttons.no') }}
                    </button>

                </div>
            </div>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUpWindow.vue";
import i18n from '../../i18n'
export default {
    name: "AskPopUp",
    components: {PopUpWindow},
    props:{
        simpleConfirmation:{
            default: false,
            type: Boolean,
            required: false
        }
    },
    data(){
      return {
          i18n: i18n,
      }
    },
    computed:{
        header(){
            let text = '';
            if (this.$store.state.askPopUpAction === 'resendDocument' ){
                text = i18n.t('documentDetail.resendDocumentHeader')
            }
            if (this.$store.state.askPopUpAction === 'resendDocumentMail' ){
                text = i18n.t('documentDetail.resendDocumentMailHeader')
            }
            if ( this.$store.state.askPopUpAction === 'checkOnPeppol'){
                text = i18n.t('documentDetail.resendPeppolHeader')
            }
            if (this.$store.state.askPopUpAction === 'deleteEmail'){
                text = i18n.t('reports.deleteEmailHeader')
            }
          return   text
        },
        description(){
            let text = '';
            if (this.$store.state.askPopUpAction === 'resendDocument'){
                text = i18n.t('documentDetail.resendDocumentDescription')
            }
            if (this.$store.state.askPopUpAction === 'resendDocumentMail'){
                text = i18n.t('documentDetail.resendDocumentMailDescription')
            }
            if (this.$store.state.askPopUpAction === 'checkOnPeppol'){
                text = i18n.t('documentDetail.resendDocumentByEdiDescription')
            }
            if (this.$store.state.askPopUpAction === 'deleteEmail'){
                text = i18n.t('reports.deleteEmailDescription')
            }
            return text;

        }
    },
    methods:{
        openWindow(){
            if (this.simpleConfirmation){
                this.$emit('confirmed')
            } else if (this.$store.state.askPopUpAction ===  "resendDocumentMail") {
              //Do nothing
            } else {
                this.$store.commit('resendAfterAddAttach', true)
                this.$store.commit('displayResendDocument', true);
            }
            this.close(true);
        },
        close(ignoreActions){
            if(ignoreActions) {
              this.$store.commit("displayAskPopUp", {display : false, action: ''})
            } else if(this.$store.state.askPopUpAction ===  "checkOnPeppol"){
                this.$store.commit("displayAskPopUp", {display : false, action: this.$store.state.askPopUpAction})
            }
            else if (this.$store.state.askPopUpAction ===  "resendDocumentMail") {
                var checkBox = document.getElementById("addAttachCheck");
                this.$store.commit("displayAskPopUp", {display : false, action: checkBox.checked = false})
            }
            else {
                this.$store.commit("displayAskPopUp", {display : false, action: ''})
            }
            
        }
    }
}
</script>

<style scoped>

</style>
