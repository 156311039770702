<template>
    <div>
        <error-notification>
        </error-notification>

        <cancel-mandate>
        </cancel-mandate>

        <edit-mandate
			@updated-mandate="mandateUpdated"
		>
        </edit-mandate>

        <bank-account>
        </bank-account>

        <!-- BEGIN: HTML Table Data -->
        <div class="" id="tabulatorOuter">
            <div class="tabulator-buttons-container">
                <div class="quickSearch">
                    <input id='mandateId'
                           type='text'
                           v-model="filter.value"
                           @keyup="quickSearch"
                           :placeholder="$t('placeholders.search')"
                           class="form-control text-popup"
                    >
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="cleanQuickSearch">
                        {{ $t('buttons.reset') }}
                    </button>

                </div>
                <div class="flex mt-5 sm:mt-0">

                </div>
            </div>

            <div class="totalNumberDocuments">{{ $t('table.totalNumberMandates') }}: {{ totalDocuments }}</div>
            <div class="overflow-x-auto scrollbar-hidden">
                <div
                      id="tabulator"
                      ref="tableRef"
                      class="mt-5 table-report table-report--tabulator">

                </div>
            </div>

        </div>
        <!-- END: HTML Table Data -->
    </div>
</template>

<script>
import {defineComponent, ref, reactive, onMounted, watch, computed} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import i18n from "../../../i18n";
import ErrorNotification from "../../UI/ErrorNotification";
import cash from "cash-dom";
import moment from "moment";
import userGroups from "../../../userGoups";
import CancelMandate from "./CancelMandate";
import en from "../../../locales/en.json";
import nl from "../../../locales/nl.json";
import de from "../../../locales/de.json";
import fr from "../../../locales/fr.json";
import sk from "../../../locales/sk.json";
import es from "../../../locales/es.json";
import axios from 'axios'
import qs from "qs";
import EditMandate from "./EditMandate";
import BankAccount from "./BankAccount";
const env = window.environment;

export default defineComponent({
    components: {BankAccount, EditMandate, CancelMandate, ErrorNotification},
    emits: ['update:modelValue'],
    props: {
        token: String,
        supplierGroupId: String,
        requestUrl: String,
        modelValue: String,
    },
    setup(props) {
        const store = useStore();
        const requestUrl = ref(props.requestUrl);
        const supplierGroupId = ref(props.supplierGroupId);
        const totalDocuments = ref('');
        const searchTableValue = store.state.tableSearchValueMandates;
        const token = ref(props.token);
        const tableRef = ref()
        const tabulator = ref();
		const tableIsLoading = ref(false);
        const pageSize = ref(10);
        const filter = reactive({
            value: searchTableValue
        });

        let statusOptions = ref([
            {
                label: i18n.t('payments.dropdown.allStatuses'),
                value: '',
            },
            {
                label: i18n.t('payments.dropdown.signed'),
                value: 'SIGNED',
            },
            {
                label: i18n.t('payments.dropdown.canceled'),
                value: 'CANCELLED',
            },
        ]);
        let status = ref(statusOptions.value[0]);
        let mandateId = ref('');
        let docTypeError = ref(false);
        let documentType = ref('');
        let tableItems = ref(0);
        let tableColumns = computed(() => {
            let columns = [
                {
                    title: 'MANDATE ID',
                    field: 'mandateId',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 200,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
                                     <div  id = ${cell.getData().mandateId}>${cell.getData().mandateId}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'LEGAL ENTITY',
                    field: 'legalEntityName',
                    cssClass: "text-centered",
                    headerSort: false,
                    minWidth: 260,
                    formatter(cell) {
                        let firstLeName = '';
                        let displayClass = ''
                        if (cell.getData().leNames.length === 1) {
                            firstLeName = cell.getData().leNames[0]
                            displayClass = "display-none";
                        } else {
                            firstLeName = cell.getData().leNames[0] + ' ...'
                        }
                        let length = cell.getData().leNames.length;
                        let firstPart = [];
                        let secondPart = [];
                        if (length > 3) {
                            let half = Math.round(length / 2);
                            for (let i = 0; i < half; i++) {
                                firstPart.push(cell.getData().leNames[i]);
                            }
                            for (let i = half; i <= length - 1; i++) {

                                secondPart.push(cell.getData().leNames[i]);
                            }
                        } else {
                            firstPart = cell.getData()
                        }
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common customer-name-outer firstLeName">
                                      <span>${firstLeName}</span>
                                      <div class="${displayClass}">
                                            <div class="tooltip mandate-le-tooltip tooltip-receiver">
                                                <div>${firstPart}</div>
                                                <div>${secondPart}</div>
                                            </div>
                                      </div>
                             </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'email',
                    field: 'email',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 260,
                    formatter(cell) {
                        let email = '';
                        if (cell.getData().receiverEmail) {
                            email = cell.getData().receiverEmail
                        }
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          <div>
                          ${email}
                        </div>
                    </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'STATUS',
                    field: 'status',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 180,
                    formatter(cell) {
                        return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing general">
                          ${cell.getData().status}
                    </div>`
                    }
                },
                {
                    title: 'UPDATED ON',
                    field: 'updatedon',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 200,
                    formatter(cell) {
                        let date = (moment(cell.getData().updatedOn).format("DD/MM/YYYY"));
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          <div>
                          ${date}
                        </div>
                    </div>`
                        )
                        return a[0];
                    }

                },
                {
                    title: 'BANK ACCOUNT',
                    field: 'bankAccount',
                    width: "60",
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                        if (cell.getData().bankAccount !== "") {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">

                                             <div class="tooltip-outer">
                                                 <i class="icon-eye  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner bank-account">${i18n.t('tooltips.bankAccount')}</span>
                                            </div>
                                    </div> `);
                            cash(a).on('click', function () {
								store.commit('setPageYScrollPosition', window.pageYOffset)
                                store.commit('setMandateData', cell.getData().allData)
                                store.commit('displayBankAccount', true);
                            })
                            return a[0];
                        } else {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-eye  icon-color-disable icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner no-bank-account">${i18n.t('tooltips.noBankAccount')}</span>
                                            </div>
                                    </div> `);
                            return a[0];
                        }
                    }
                },
                {
                    title: 'EDIT MANDATE',
                    field: 'editMandate',
                    width: "60",
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                        if (cell.getData().status === "SIGNED") {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-pencil  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner edit-mandate">${i18n.t('tooltips.editMandate')}</span>
                                            </div>
                                    </div> `);
                            cash(a).on('click', function () {
                                store.dispatch("updateToken");
								store.commit('setPageYScrollPosition', window.pageYOffset)
                                store.commit('setMandateData', cell.getData().allData)
                                store.commit('displayEditMandate', true);
                            })
                            return a[0];
                        } else {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-pencil  icon-color-disable icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner edit-mandate-disabled">${i18n.t('tooltips.editMandateDisabled')}</span>
                                            </div>
                                    </div> `);
                            return a[0];
                        }
                    }
                },
                {
                    title: 'CANCEL MANDATE',
                    field: 'cancelMandate',
                    width: "60",
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                        if (cell.getData().status === "SIGNED") {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-bin  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner cancel-mandate">${i18n.t('tooltips.cancelMandate')}</span>
                                            </div>
                                    </div> `);
                            cash(a).on('click', function () {
                                store.dispatch("updateToken");
                                store.commit('setMandateData', cell.getData().allData)
                                store.commit('displayCancelMandate', true);

                            })
                            return a[0];
                        } else {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-bin  icon-color-disable icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner edit-mandate-disabled">${i18n.t('tooltips.editMandateDisabled')}</span>
                                            </div>
                                    </div> `);
                            return a[0];
                        }
                    }
                },
                {
                    title: 'DOWNLOAD MANDATE',
                    field: 'downloadMandate',
                    width: "60",
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                        const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-arrow-down  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner download-mandate">${i18n.t('tooltips.downloadMandate')}</span>
                                            </div>
                                    </div> `);
                        cash(a).on('click', function () {
                            store.dispatch("updateToken");
                            store.dispatch('downloadMandate', cell.getData().allData)
                        })
                        return a[0];
                    }
                },
            ];
            if (store.state.supplierGroup.data.sgType !== "SALARY") {
                let item = {
                    title: 'CUSTOMER NAME',
                    field: 'customerName',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 260,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common customer-name-outer">
                          <span>${cell.getData().receiverName}</span>
                          <span class="tooltip tooltip-receiver">${cell.getData().receiverId}</span>
                    </div>`
                        )
                        return a[0]
                    }
                }
                columns.splice(1, 0, item)
            } else {
                let item = {
                    title: 'EMPLOYEE NAME',
                    field: 'employee',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 260,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common customer-name-outer">
                          <span>${cell.getData().receiverName}</span>
                          <span class="tooltip tooltip-receiver">${cell.getData().receiverId}</span>
                    </div>`
                        )
                        return a[0]
                    }
                }
                columns.splice(1, 0, item)
            }
            return columns
        });

        watch(() => store.state.getLocale, () => {

        });
        watch(() => store.state.mandateCanceled, (newVal) => {
            if (newVal) {
                setTimeout(() => {
                    onFilter();
                    store.commit('setMandateCanceled', false);
                }, 1000);
            }
        })
        watch(() => props.token, (newVal) => {
            token.value = newVal;
        });
        watch(() => store.getters.getLocale, function () {
            tabulator.value.setLocale(store.getters.getLocale);
        });

        const refreshToken = () => {
        }
        const setInitialTableSorters = () => {
            store.commit('setTableSorters', [{}])
        }
		const mandateUpdated = () => {
			setTimeout(() => initTabulator(), 2000)
		}
        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value);
            let params = {
                supplierGroupId: supplierGroupId.value,
                pageNo: 1,
                pageSize: pageSize.value,
                quick: filter.value,
            }
            if (store.state.tableSorters[0].order) {
                params.sortOn = store.state.tableSorters[0];
            }
            if (filter.value === '') {
                delete params.quick
            }
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: requestUrl.value,
                ajaxConfig: {
                    method: "POST",
                    headers: {
                        'Authorization': 'bearer ' + store.state.token,
                        'Accept': 'application/json',
                    },
                },
                ajaxParams: params,
                ajaxResponse: function (url, params, response) {
                    let data = [];
                    const res = response.data.items;

                    totalDocuments.value = response.data.numResults;
                    tableItems.value = response.data.items.length;
                    for (let item of res) {
                        let tempItem = {
                            mandateId: item.mandateId,
                            legalEntityIds: item.legalEntityIds,
                            receiverId: item.receiverId,
                            status: item.status,
                            updatedOn: item.updatedOn,
                            receiverEmail: item.receiverEmail,
                            receiverName: '-',
                            leNames: [],
                            legalEntityId: item.legalEntityIds[0],
                            allData: {},
                            bankAccount : item.bankAccount ? item.bankAccount : '',
                            bicCode : item.BICCode ? item.BICCode : ''
                        }
                        if (item.receiverName) {
                            tempItem.receiverName = item.receiverName
                        } else {
                            if (item.name){
                                tempItem.receiverName = item.name
                            }
                        }
                        item.legalEntityIds.forEach(leId => {
                            for (const legalEntity in store.state.supplierGroup.data.supplierLegalEntities) {
                                if (leId === legalEntity) {
                                    if (store.state.supplierGroup.data.supplierLegalEntities[legalEntity].internalName
                                          && !userGroups.receiverNames.includes(store.state.currentUserData.data.group)) {
                                        tempItem.leNames.push(store.state.supplierGroup.data.supplierLegalEntities[legalEntity].internalName)
                                    } else {
                                        tempItem.leNames.push(store.state.supplierGroup.data.supplierLegalEntities[legalEntity].name)
                                    }
                                }
                            }
                        });
                        tempItem.allData = {
                            mandateId: item.mandateId,
                            legalEntityIds: item.legalEntityIds,
                            receiverId: item.receiverId,
                            status: item.status,
                            updatedOn: item.updatedOn,
                            receiverEmail: item.receiverEmail,
                            receiverName: item.receiverName ? item.receiverName : '',
                            bankAccount : item.bankAccount ? item.bankAccount : '',
                            bicCode : item.BICCode ? item.BICCode : ''
                        }
                        data.push(tempItem);
                    }

                    let last_page = Math.ceil(response.data.numResults / pageSize.value);
                    let formatedData = {
                        last_page: last_page,
                        data: data,
                    }
                    store.commit("setTablePage", {field: "mandates", pageNo: tabulator.value.getPage()})
                    return formatedData;
                },
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
					filter: "quick"
				},
                ajaxURLGenerator: function (url, config, params) {
                   updateToken();
                    if (params.sort?.length > 0) {
                        let sortFields = {
                            email: 'EMAIL',
                            mandateId: 'MANDATE_ID',
                            status: 'STATUS',
                            updatedon: 'UPDATED_ON',
                            customerName: 'CLIENT_NAME'
                        };
                        store.commit('setTableSorters',
                              {
								  place: 'mandates',
                                  order: params.sort[0].dir.toUpperCase(),
                                  sortField: sortFields[params.sort[0].field].toUpperCase()
                              });
                        params.sortOn = {
                            order: params.sort[0].dir.toUpperCase(),
                            sortField: sortFields[params.sort[0].field]
                        }
                    }
                    return url;
                },
                headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
                ajaxContentType: "json",
                ajaxFiltering: true,
                printAsHtml: true,
                printStyled: true,
				pagination: true,
				sortMode:"remote",
				paginationMode: 'remote',
                paginationSize: pageSize.value,
                paginationInitialPage: store.state.tableInitialPage.mandates,
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: i18n.t('placeholders.noMatchingFound'),
                locale: true,
                langs: {
                    "en": en,
                    "nl": nl,
                    "de": de,
                    "fr": fr,
                    "sk": sk,
                    "es": es,
                },
                columns: tableColumns.value,
            })
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', ()=> {
				tableIsLoading.value = true
			})
        }
		watch(tableIsLoading, () => {
			if(tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
        const tokenIsExpired =() =>{
            let exp;
            if (JSON.parse(atob(store.state.token.split('.')[1])).exp){
                exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
            }
            let now = parseInt(new Date().getTime()/ 1000 );

            if (exp - now <= 0){
                return true
            } else {
                return false
            }
        }
        const updateToken = () => {
            if (tokenIsExpired()){
                let payload = {
                    client_id : env.keycloakClient,
                    grant_type : "refresh_token" ,
                    refresh_token : store.state.refreshToken
                }

                let body = qs.stringify(payload);
                axios.post(store.state.API.keycloakToken,
                      body,
                      {
                          headers: {
                              'Content-Type': "application/x-www-form-urlencoded",
                          }
                      }
                )
                      .then (res =>{
                          store.commit('setToken', res.data.access_token);
                          store.commit('setRefreshToken', res.data.refresh_token);
                          initTabulator();

                      })
                      .catch (()=>{
                          store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
                          store.commit('displayNotification', true);
                      })
            }
        }
        const resetSearch = () => {
            status.value = {
                label: i18n.t('payments.dropdown.allStatuses'),
                value: '',
            };
            mandateId.value = '';
            onFilter();
        }
        const cleanQuickSearch = () => {
            filter.value = '';
            store.commit("setTablePage", {field: "mandates", pageNo: 1})
            setInitialTableSorters();
            onFilter();
        }
        const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
        }
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw();
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }
        const quickSearch = () => {
            setInitialTableSorters();
            store.commit("setMandateTableSearch", filter.value)
            store.commit("setTablePage", {field: "mandates", pageNo: 1})
            onFilter();
        }
        const onFilter = () => {
            initTabulator();

        }
        const onResetFilter = () => {
            filter.value = ''
            onFilter();
        }
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }
        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }
        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }
        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }


        onMounted(() => {
            store.commit("setTablePage", {field: "mandates", pageNo: 1})
            store.commit('setTableSorters', [{}])
            refreshToken();
            initTabulator();
            reInitOnResizeWindow();
            setLocale();
        })

        return {
            tableRef,
            filter,
			mandateUpdated,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            totalDocuments,
            quickSearch,
            tabulator,
            resetSearch,
            docTypeError,
            documentType,
            statusOptions,
            status,
            mandateId,
            cleanQuickSearch,
            tableItems,
        }
    }
})
</script>

<style scoped>
.tabulator-arrow {
    display: none;
}
.tabulator-col-title {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}
.leg-entity-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-columns: 80px auto;
    grid-auto-flow: column;
}
.leg-entity-container img {
    padding-right: var(--generalPaddings);
}
.leg-entity-container .table-report img {
    border: none;
}

.leg-entity-container .table-report img, .table-image {
    box-shadow: none !important;
}
.legEntityColumnHeader {
    padding-left: 40px
}
#tabulator-html-filter-value {
    width: 400px;
}
.totalNumberDocuments {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: end;
    padding-top: var(--generalPaddings);
}
#selectAllDocuments {
    justify-self: center;
}
.buttons-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    grid-column-gap: var(--generalPaddings);
}
.search {
    display: none !important;
}
.tabulator-buttons-container {
    display: grid;
    grid-template-columns: 90% 10%;
    grid-auto-flow: column;
    align-items: center;
}
.tabulator-search-container {
    display: grid;
    grid-template-columns: 400px repeat(5, auto);
    grid-template-rows: 100%;
    grid-auto-flow: column;
    grid-column-gap: 5px;
}
.download {
    justify-self: end;
}

.buttons-container {
    align-self: center;
    justify-self: start;
}

.col-header {
    text-align: center;
}


.arrow-up:after {
    content: ' \2191';
}

.arrow-down:after {
    content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
    display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
    display: inline-block;
    color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
    display: inline-block;
    color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
    display: inline-block;
    color: var(--theme-primary-100);
}

.download-outer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-auto-flow: column;
    justify-items: end;

}

.download-outer button {
    margin-left: 5px;
}

.icon-coin-euro {
    font-size: 24px;
}

.icon-coin-euro:hover {
    cursor: pointer;
    color: var(--infoColor);

}

.download-buttons {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: auto auto;
}

.download-buttons .btn {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding: 0;
}

.button-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.download-buttons .btn span {
    grid-column: 1/2;
    grid-row: 1/2;
}

.download-buttons .btn:disabled:hover .tooltip {

    display: block;

}

.tooltip {
    display: none;
    position: relative;
    top: 20px;
    background-color: var(--theme-primary-500);
    color: white;
    border-radius: 5px;
    font-size: 12px;
}
.extended-search {
    margin-top: 10px;
    width: 40%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 10px;

}

.extendedLe {
    align-self: start;
}

.extended-left {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    grid-row-gap: 10px;
}

.display-none {
    display: none;
}

.extended-search-check-box {
    margin-top: 10px;
    line-height: 1.5em;

}

.search-button {
    grid-column: 1/3;
    justify-items: center;
    display: grid;
}

.extended-search-check-box span {
    margin-right: 10px;
    position: relative;
    top: -5px;
}

.margin-right {
    margin-right: 10px;
}

@media only screen and (max-width: 800px) and (max-width: 670px) {
    #tabulator-html-filter-value {
        width: 50vw;
    }

    .tabulator-search-container {
        grid-template-columns: 50vw repeat(5, auto);
    }

}

@media only screen and (max-width: 669px) {
    #tabulator-html-filter-value {
        width: 40vw;
    }

    .tabulator-search-container {
        grid-template-columns: 40vw repeat(5, auto);
    }

}

@media only screen and (max-width: 850px) {
    .download {
        height: 38px;
    }
}

.findBy {
    display: grid;
    grid-template-columns: 240px 240px auto;
    grid-template-rows: 100%;
    grid-column-gap: 20px;
    grid-auto-flow: row;
    justify-items: start;
}

.findByStatus {
    width: 240px;
}

.button-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    align-items: center;
}

.quickSearch {
    display: grid;
    grid-template-columns: 400px 100px;
    grid-template-rows: 100%;
    grid-column-gap: 10px;
}
@media only screen and (max-width: 560px) {
    .quickSearch {

        grid-template-columns: 80% 20%;
    }
}

</style>

