<template>
    <div class="document-info">
        <div class="status-title">
            <i class="icon-bell info-color"></i>
            <h1 class="text-2xl">{{ $t('postDrop.documentDetail.title') }}</h1>
        </div>
        <div>
            <div class="divider"
                 v-if="statuses.postalAddress">
                <h1 class="text-xl">{{ $t('postDrop.documentDetail.postalAddress') }}</h1>
                <div class="margin-left">
                    <i class="icon-envelop info-color"></i> {{ statuses.postalAddress.companyName }}
                    <div class="address">
                        <p>{{ statuses.postalAddress.streetName }}</p>
                        <p>{{ statuses.postalAddress.zip }}, {{ statuses.postalAddress.city }} </p>
                        <p>{{ statuses.postalAddress.countryName }}</p>
                    </div>
                </div>
            </div>
            <div
                  class="divider"
                  v-if="printMode"
            >
                <h1 class="text-xl">
                    {{ $t('postDrop.documentDetail.printMode') }}
                </h1>
                <div class="margin-left">
                    <i class="icon-printer info"></i> {{ printMode }}
                </div>
            </div>
            <div class="divider"
                 v-if="deliveryMethod">
                <h1 class="text-xl">
                    {{ $t('postDrop.documentDetail.deliveryMethod') }}
                </h1>
                <div class="margin-left">
                    <i class="icon-truck info"></i> {{ deliveryMethod }}
                </div>
            </div>
            <div class="divider" v-if="statuses.bPostLink">
                <h1 class="text-xl">{{ $t('documentDetail.documentStatusesInfo.registeredMail.trackMail') }}</h1>
                <div class="margin-left">
                    <document-info-extended
                          :prinInfo="true"
                          :printCSS=true
                    >
                        <template #print>
                            <i class="info-color icon-earth">
                                <a target="_blank" :href='statuses.bPostLink' class="description margin-left-4">https://track.bpost.cloud...</a>
                            </i>
                        </template>
                    </document-info-extended>
                </div>
            </div>
            <div class="divider"
                 v-if=statuses.printStatuses.length
            >
                <h1 class="text-xl">{{ $t('postDrop.documentDetail.printStatuses') }}</h1>
                <div class="margin-left"
                     v-for="(status, index) in statuses.printStatuses"
                     :key=index
                >
                    <status-item :item=status
                                 itemType='print'
                    >
                    </status-item>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import StatusItem from "../UI/StatusItem";
import i18n from "../../i18n";
import DocumentInfoExtended from "../documentDetail/ui/DocumentInfoExtended.vue"


export default {
    name: "ProcessedDocumentStatuses",
    components: {StatusItem,  DocumentInfoExtended},
    props: {
        docStatuses: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            i18n: i18n,
            statuses: this.docStatuses,
        }
    },
    computed: {
        deliveryMethod() {
            let deliveryMethod = '';
            if (this.statuses.deliveryMethod) {
                let key = 'postDrop.postDropTypeList.' + this.statuses.deliveryMethod;
                deliveryMethod = i18n.t(key)
            }
            return deliveryMethod

        },
        printMode() {
            let printMode = '';
            if (this.statuses.printMode) {
                let key = 'postDrop.printMode.' + this.statuses.printMode.replaceAll("_", '').toLowerCase();
                printMode = i18n.t(key);
            }
            return printMode
        },
    }


}
</script>

<style scoped>
.document-info {
    padding-left: var(--generalPaddings);
}

.status-title {
    display: grid;
    grid-template-columns: 25px auto;
    grid-template-rows: 100%;
    grid-auto-flow: row;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
}

.status-title i {
    font-size: 20px;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    align-items: center;

}

.divider {
    margin-top: calc(1 * var(--generalPaddings));
}

.margin-left {
    margin-left: calc(1 * var(--generalPaddings));
}

.address {
    margin-left: 20px;
}
.margin-left-4{
    margin-left: 4px;
}

@media only screen and (max-width: 1100px) and (min-width: 1000px) {
    .status-title h1 {
        font-size: 1.3rem;
    }
}

</style>
