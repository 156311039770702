<template>
	<div class="flex flex-row items-center" :style="{'margin-left' : marginLeft + 'px' }">
		<span
			:class="{'material-icons-outlined': outlined, 'material-icons': !outlined}"
			:style="{'font-size': fontSizeIcon + 'px','color': iconColor}"
		>{{ iconName }}</span>
		<span class="description" :style="{'font-size' : fontSizeLabel + 'px'}">{{ label }}</span>
	</div>
</template>

<script>
export default {
	props: {
		iconName: String,
		label: String,
		iconSeverityColor:{
			type: String,
			Required: false,
			default: 'info'
		},
		marginLeft: {
			type: Number,
			required: false,
			default: 0
		},
		outlined: {
			type: Boolean,
			required: false,
			default: true
		},
		fontSizeIcon: {
			type: Number,
			required: false,
			default: 18
		},
		fontSizeLabel: {
			type: Number,
			required: false,
			default: 14
		}
	},
	computed: {
		iconColor() {
			switch (this.iconSeverityColor) {
				case "info":
					return "#215893"
				case "warning":
					return "#ffc107"
				case "error":
					return "#a23e3e"
				case "success":
					return "#095709"
			}
			return this.iconSeverityColor
		}
	}
}
</script>

<style scoped>
.description {
	color: var(--theme-primary-500);
	padding-left: 5px;
}

</style>