<template>
	<div>
		<error-notification>
		</error-notification>
		<edit-receiver
			:channels="channelOptions"
			:ediChannelsAvailable="ediChannels"
			:companyIdentifierNumberTypes="identifierNumberTypeMatch"
		>
		</edit-receiver>
		<add-receiver
			:leData="leData"
			:channels="channelOptions"
			:ediChannelsAvailable="ediChannels"
			:companyIdentifierNumberTypes="identifierNumberTypeMatch"
		>
		</add-receiver>

		<!-- BEGIN: HTML Table Data -->
		<div class="" id="tabulatorOuter">
			<h1 v-if="displayInfoView" class="portalInfo">
				<i class="icon-notification info"></i>
				{{ $t('general.displayOnPortal') }} <strong>{{ displayOnPortal }}</strong>
			</h1>
			<h1 v-if="displayInfoEdit" class="portalInfo">
				<i class="icon-notification info"></i>
				{{ $t('general.editOnPortal') }} <strong>{{ editOnPortal }}</strong>
			</h1>
			<div class="tabulator-buttons-container">
				<div class="extended-search-box add-margin-small-screen" style="width: 400px">
					<input
						type='text'
						v-model="filter.value"
						@keyup="quickSearch"
						:placeholder="$t('placeholders.search')"
						class="form-control"
						style="margin-right: 10px; height: 37px"
					>
					<div class="flex flex-row">
						<extended-search-filter
							current-page="clients"
							:margin-left-small-screen="8"
							@search="onFilter"
							@reset-filter="onFilter"
							class="mr-2"
						/>
						<button class="btn custom-button shadow-md" @click="cleanQuickSearch">
							{{ $t('buttons.reset') }}
						</button>
					</div>

				</div>
				<div class="addNewChoice">
					<button v-if="displayAddButton"
							class="btn custom-button shadow-md mr-2 showStatuses text-popup"
							@click="addNewReceiver">
						{{ $t('buttons.addReceiver') }}
					</button>
				</div>
			</div>
			<filter-label @remove-label="onFilter"/>
			<div class="totalNumberDocuments">{{ $t('table.totalNumberReceivers') }}: {{ totalDocuments }}</div>

			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				></div>
			</div>

		</div>
		<!-- END: HTML Table Data -->
	</div>
</template>

<script>
import {defineComponent, ref, reactive, onMounted, watch, computed} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import i18n from "../../../i18n";
import ErrorNotification from "../../UI/ErrorNotification";
import cash from "cash-dom";
import moment from "moment";
import EditReceiver from "./EditReceiver";
import AddReceiver from "./AddReceiver";
import userGroups from "../../../userGoups";
import axios from 'axios'
import qs from "qs";
import ExtendedSearchFilter from "@/components/extendedSearch/ExtendedSearchFilter.vue";
import FilterLabel from "@/components/extendedSearch/FilterLabel.vue";

const env = window.environment;

export default defineComponent({
	components: {FilterLabel, ExtendedSearchFilter, EditReceiver, ErrorNotification, AddReceiver},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		requestUrl: String,
		modelValue: String,
		leIds: Array,
		leData: Array,
	},
	setup(props) {
		const en = require('../../../locales/en.json')
		const nl = require('../../../locales/nl.json')
		const fr = require('../../../locales/fr.json')
		const de = require('../../../locales/de.json')
		const es = require('../../../locales/es.json')
		const sk = require('../../../locales/sk.json')
		const store = useStore();

		const requestUrl = ref(props.requestUrl);
		const supplierGroupId = ref(props.supplierGroupId);
		const leData = ref(props.leData);
		const totalDocuments = ref('');
		const searchTableValue = store.state.tableSearchValueReceivers;
		const token = ref(props.token);
		const tableRef = ref()
		const tabulator = ref()
		const showReleaseColumn = ref(store.state.supplierGroup.data.holdRelease)
		const showChannelChoiceColumn = ref( store.state.supplierGroup.data.channelsConfig.docImage.channelChoice?.modifyChannel ? store.state.supplierGroup.data.channelsConfig.docImage.channelChoice.modifyChannel : false)
		const tableIsLoading = ref(false);

		const filter = reactive({
			value: searchTableValue
		});
		const identifierNumberTypeMatch = ref([
			{
				names: ['Mercurius', 'OpenPeppol', 'Peppol', 'TradeShift', 'Basware', 'Arco'],
				identifierNumberTypes: ["KBO", "KVK", "GLN"],
			},
			{
				names: ['Eks'],
				identifierNumberTypes: ["GLN"],
			},
			{
				names: ['Ariba'],
				identifierNumberTypes: ["AN"],
			},
			{
				names: ['Tungsten'],
				identifierNumberTypes: ["AAA"],
			},
			{
				names: ['ChorusPro'],
				identifierNumberTypes: ['SIRET']
			}
		]);

		let docTypeError = ref(false);
		let documentType = ref('');
		let showExtendedSearch = ref(false);
		let mandateId = ref('');
		let tableItems = ref(0)

		watch(() => props.token, (newVal) => {
			token.value = newVal;
		});
		watch(() => store.state.receiverDataUpdated, function (newVal) {
			if (newVal) {
				initTabulator(tabulator.value.getPage());
				store.commit('receiverDataUpdated', false);
			}
		});
		watch(() => store.getters.getLocale, function () {
			tabulator.value.setLocale(store.getters.getLocale);
		});

		const displayAddButton = computed(() => {
			if (userGroups.optipostNames.includes(store.state.currentUserData.data.group)) {
				return true
			}
			if (userGroups.supplierNames.includes(store.state.currentUserData.data.group) && editOnPortal.value) {
				return true
			}
			return false
		})

		const ediChannels = computed(() => {
			let ediChannels = [];
			let channelConfig = store.state.supplierGroup.data.channelsConfig;
			if (channelConfig.edi) {
				if (channelConfig.edi.ediChannelProviders) {
					for (let provider in channelConfig.edi.ediChannelProviders) {
						ediChannels.push(provider)
					}
				}
			}
			ediChannels.sort();
			return ediChannels
		})
		const displayInfoView = computed(() => {
			return userGroups.optipostNames.includes(store.state.currentUserData.data.group)
		})
		const displayInfoEdit = computed(() => {
			return userGroups.optipostNames.includes(store.state.currentUserData.data.group)
		})
		const displayOnPortal = computed(() => {
			if (store.state.supplierGroup.data.channelsConfig?.docImage?.channelChoice?.displayOnPortal !== undefined) {
				return store.state.supplierGroup.data.channelsConfig.docImage.channelChoice.displayOnPortal
			}
			return false
		})
		const editOnPortal = computed(() => {
			if (store.state.supplierGroup.data.channelsConfig?.docImage?.channelChoice?.editOnPortal !== undefined) {
				return store.state.supplierGroup.data.channelsConfig.docImage.channelChoice.editOnPortal
			}
			return false
		})

		const channelOptions = computed(() => {
			let options = [];
			let channelConfig = store.state.supplierGroup.data.channelsConfig;
			if (channelConfig.edi) {
				let ediProviders = []
				if (channelConfig.edi.ediChannelProviders) {
					for (let provider in channelConfig.edi.ediChannelProviders) {
						ediProviders.push(provider)
					}
				}
				ediProviders.sort().forEach(provider => {
					options.push({label: "EDI: " + provider, value: provider})
				})
			}
			if (channelConfig.attachEmail) {
				options.push({label: i18n.t('management.channelChoice.attachemail'), value: 'ATTACH_EMAIL'})
			}
			if (channelConfig.linkEmail) {
				options.push({label: i18n.t('management.channelChoice.linkemail'), value: 'LINK_EMAIL'})
			}
			if (channelConfig.print) {
				options.push({label: i18n.t('management.channelChoice.print'), value: 'PRINT'})
			}
			return options;
		})
		let tableColumns = computed(() => {
			let columns = [
				{
					title: '',
					field: 'logo',
					minWidth: 100,
					maxWidth: 130,
					headerSort: false,

					formatter(cell) {

						return `<div  class="font-medium whitespace-nowrap logo logo-receivers ">
                                    <img src="${cell.getData().logo}" class="${cell.getData().logo}">
                              </div>`
					}
				},
				{
					title: 'LEGAL ENTITY',
					field: 'legalEntityName',
					width: 170,
					cssClass: 'text-start',
					headerSort: true,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap">
                                   ${cell.getData().leName}
                              </div>`
					}
				},
				{
					title: 'RECEIVER NAME',
					field: 'receiverName',
					minWidth: 450,
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: true,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                   ${cell.getData().receiverName}
                              </div>`
					}
				},
				{
					title: 'RECEIVER ID',
					field: 'receiverId',
					minWidth: 100,
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: true,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                   ${cell.getData().receiverId}
                              </div>`
					}
				},
				{
					title: 'HOLD RELEASE',
					field: 'holdRelease',
					cssClass: "center",
					minWidth: 100,
					visible: showReleaseColumn.value,
					headerSort: false,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                   ${cell.getData().holdRelease}
                              </div>`
					}
				},
				{
					title: 'CHANNEL CHOICE',
					field: 'channelChoice',
					minWidth: "100",
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: true,
					visible: showChannelChoiceColumn.value,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                   ${cell.getData().channelChoices}
                              </div>`
					}
				},
				{
					title: 'CHANNEL UPDATE METHOD',
					field: 'channelUpdateMethod',
					minWidth: "100",
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: true,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                   ${cell.getData().channelUpdateMethod}
                              </div>`
					}
				},
				{
					title: 'UPDATED ON',
					field: 'updatedon',
					minWidth: "100",
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: true,
					formatter(cell) {
						return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                   ${cell.getData().updatedOn}
                              </div>`
					}
				},
			];
			return columns
		});

		if (store.state.supplierGroup.data.channelsConfig.docImage?.channelChoice?.generateOnboardingAccessCode) {
			tableColumns.value.splice(tableColumns.value.length, 0,
				{
					title: 'ACCESS CODE',
					field: 'accessCode',
					width: "100",
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: false,
					formatter(cell) {
						if (cell.getData().accessCode !== '-') {
							const a = cash(
								`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                            <div class="tooltip-outer">
                                              ${cell.getData().accessCode}
                                              <span class="tooltip-inner click-to-copy">${i18n.t('tooltips.clickToCopy')}</span>
                                            </div>

                                      </div>`
							)
							cash(a).on('click', function () {
								let link = store.state.domainName + '/' + store.state.locale + '/channel-choice/' + cell.getData().accessCode
								navigator.clipboard.writeText(link);
								cell.getData().linkCopied = true
							})
							return a[0];
						} else {
							const a = cash(
								`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                            <div>
                                              ${cell.getData().accessCode}
                                            </div>
                                      </div>`
							)
							return a[0];
						}
					}
				},
			)
		}
		if (userGroups.optipostNames.includes(store.state.currentUserData.data.group)) {
			tableColumns.value.splice(tableColumns.value.length + 1, 0,
				{
					title: 'VIEW RECEIVER',
					field: 'viewReceiver',
					width: "80",
					cssClass: "text-centered color-grey",
					visible: showReleaseColumn.value || showChannelChoiceColumn.value,
					headerSort: false,
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-eye  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner view-receiver-choice">${i18n.t('tooltips.viewReceiverChoice')}</span>
                                            </div>
                                    </div> `);
						cash(a).on('click', function () {
							store.commit('setPageYScrollPosition', window.pageYOffset)
							store.commit('setReceiverData', cell.getData().allData);
							store.commit('displayEditReceiverViewMode', true);
							store.commit('displayEditReceiver', true);
							store.commit('setTableSearchValueReceivers', filter.value)
						})
						return a[0];
					}
				},
			)
			tableColumns.value.splice(tableColumns.value.length + 2, 0,
				{
					title: 'EDIT RECEIVER',
					field: 'editReceiver',
					width: "80",
					cssClass: "text-centered color-grey",
					headerSort: false,
					visible: showReleaseColumn.value || showChannelChoiceColumn.value,
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-pencil  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner edit-receiver-choice">${i18n.t('tooltips.editReceiverChoice')}</span>
                                            </div>
                                    </div> `);
						cash(a).on('click', function () {
							store.dispatch("updateToken");
							store.commit('setPageYScrollPosition', window.pageYOffset)
							store.commit('setReceiverData', cell.getData().allData);
							store.commit('displayEditReceiverViewMode', false);
							store.commit('displayEditReceiver', true);
							store.commit('setTableSearchValueReceivers', filter.value)
						})
						return a[0];
					}
				},
			)
		}

		if (userGroups.supplierNames.includes(store.state.currentUserData.data.group)) {
			if (editOnPortal.value) {
				tableColumns.value.splice(tableColumns.value.length + 1, 0,
					{
						title: '',
						field: 'editReceiver',
						width: "60",
						cssClass: "text-centered",
						visible: showReleaseColumn.value || showChannelChoiceColumn.value,
						headerSort: false,
						formatter(cell) {
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-pencil  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner edit-receiver-choice">${i18n.t('tooltips.editReceiverChoice')}</span>
                                            </div>
                                    </div> `);
							cash(a).on('click', function () {
								store.dispatch("updateToken");
								store.commit('setPageYScrollPosition', window.pageYOffset)
								store.commit('setReceiverData', cell.getData().allData);
								store.commit('displayEditReceiverViewMode', false);
								store.commit('displayEditReceiver', true);
								store.commit('setTableSearchValueReceivers', filter.value)
							})
							return a[0];
						}
					},
				)
			} else {
				tableColumns.value.splice(tableColumns.value.length + 1, 0,
					{
						title: '',
						field: 'viewReceiver',
						width: "100",
						cssClass: "text-centered",
						visible: showReleaseColumn.value || showChannelChoiceColumn.value,
						headerSort: false,
						formatter(cell) {
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-eye  icon-color ">
                                                  </i>
                                                  <span class="tooltip-inner view-receiver-choice">${i18n.t('tooltips.viewReceiverChoice')}</span>
                                            </div>
                                    </div> `);
							cash(a).on('click', function () {
								store.commit('setPageYScrollPosition', window.pageYOffset)
								store.commit('setReceiverData', cell.getData().allData);
								store.commit('displayEditReceiverViewMode', true);
								store.commit('displayEditReceiver', true);
								store.commit('setTableSearchValueReceivers', filter.value)
							})
							return a[0];
						}
					},
				)

			}
		}

		const addNewReceiver = () => {
			store.dispatch("updateToken");
			store.commit("setDisplayAddReceiver", true)
		}
		const resetSearch = () => {
			mandateId.value = '';
			onFilter();
		}
		const pageSize = ref(10);
		const initTabulator = () => {
			let le = props.leIds;
			if (store.state.extendedSearchValue.extendedSearch?.legalEntityNumber?.value) {
				le = [store.state.extendedSearchValue.extendedSearch.legalEntityNumber.value]
			}

			let params = {
				supplierGroupId: supplierGroupId.value,
				pageNo: 1,
				pageSize: pageSize.value,
				quick: filter.value.trim(),
				legalEntityIds: le,
				sortOn: null,
				filter: {
					hold: store.state.extendedSearchValue.extendedSearch.clientDocsOnHold
				}
			}
			if (params.filter.hold === false) delete params.filter.hold
			if (store.state.tableSorters[0].order) {
				params.sortOn = store.state.tableSorters[0];
			}
			let selectedChannels = store.state.extendedSearchValue.extendedSearch.selectedChannels

			if(selectedChannels?.length) {
				if (!(selectedChannels.length === 1 && selectedChannels.value === 'NO_CHANNEL')) {
					let selectedChannelArray = [];
					for (let channel in selectedChannels) {

						if (selectedChannels[channel].value !== 'NO_CHANNEL') {
							selectedChannelArray.push(selectedChannels[channel].value);
						}
					}
					params.filter.channelChoice = selectedChannelArray;
				}
			}



			if (filter.value === '') {
				delete params.quick
			}
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "POST",
					headers: {
						'Authorization': 'bearer ' + store.state.token,
						'Accept': 'application/json',
					},
				},
				ajaxParams: params,
				ajaxResponse: function (url, params, response) {
					let data = [];
					const res = response.data.items
					totalDocuments.value = response.data.numResults;
					tableItems.value = response.data.items.length;

					for (let item of res) {
						let tempItem = {
							leId: item.legalEntityId,
							leName: '',
							receiverId: item.receiverId,
							receiverName: item.info.companyName ? item.info.companyName : '-',
							channelChoices: ['-'],
							holdRelease: '-',
							updatedOn: '-',
							logo: 'no-logo',
							allData: {
								legalEntityId: item.legalEntityId,
								receiverId: item.receiverId,
								supplierGroupId: item.supplierGroupId,
								receiverName: item.info.companyName,
							},
							channelUpdateMethod: '-',
							accessCode: "-",
							linkCopied: false
						}
						if (item.channelInfo?.holdRelease) {
							tempItem.holdRelease = `<span class="material-icons">check</span>`
						}
						if (item.channelChoiceCodes) {
							if (item.channelChoiceCodes.length === 1) {
								tempItem.accessCode = item.channelChoiceCodes[0].accessCode
							} else {
								let hasTimeStamp = false;
								item.channelChoiceCodes.forEach(channelChoice => {
									if (channelChoice.generatedOn) {
										hasTimeStamp = true
									}
								})
								let tempAccessCode = item.channelChoiceCodes[0].accessCode
								if (hasTimeStamp) {
									let timeStamp = item.channelChoiceCodes[0].generatedOn;
									for (let i = 1; i <= item.channelChoiceCodes.length - 1; i++) {
										if (item.channelChoiceCodes[i].generatedOn) {
											if (Date.parse(item.channelChoiceCodes[i].generatedOn) > Date.parse(timeStamp)) {
												tempAccessCode = item.channelChoiceCodes[i].accessCode
											}
										}
									}
									tempItem.accessCode = tempAccessCode;
								} else {
									tempItem.accessCode = tempAccessCode;
								}
							}
						}

						if (store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].internalName) {
							tempItem.leName = store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].internalName
						} else {
							tempItem.leName = store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].name
						}
						if (item.channelInfo) {
							tempItem.allData.channelInfo = item.channelInfo;
							let choices = []
							item.channelInfo.channelChoice.forEach(choice => {
								choices.push(" " + choice.replace("_", " ").toLowerCase());
							})
							tempItem.channelChoices = choices;
							if (item.channelInfo.updateMethod) {
								tempItem.channelUpdateMethod = item.channelInfo.updateMethod
							}
						}
						if (item.channelChoice) {
							tempItem.channelChoice = item.channelChoice;
						}
						if (item.channelInfo && item.channelInfo.updatedOn) {
							tempItem.updatedOn = moment(item.channelInfo.updatedOn).format('DD/MM/YYYY')
						}
						leData.value.forEach(le => {
							if (item.legalEntityId === le.leId && le.internalName) {
								tempItem.leName = le.internalName;
							}
							if (item.legalEntityId === le.leId && le.logo) {
								tempItem.logo = le.logo;
							}
						})
						data.push(tempItem);
					}


					let last_page = Math.ceil(response.data.numResults / pageSize.value);
					let formattedData = {
						last_page: last_page,
						data: data,
					}

					store.commit("setTablePage", {field: "managementReceivers", pageNo: tabulator.value.getPage()})
					return formattedData;
				},
				dataSendParams: {
					page: "pageNo",
					size: "page_size"
				},
				ajaxURLGenerator: function (url, config, params) {
					updateToken();
					if (store.state.tableSorters.length > 0 && store.state.tableSorters[0].order && params.sort?.length === 0
						&& store.state.tableSorters[0].place === 'receivers' || store.state.tableSorters[0].place === ''){
						params.sortOn = {
							order: store.state.tableSorters[0].order.toUpperCase(),
							sortField: store.state.tableSorters[0].sortField
						}
					}
					 else if (params.sort?.length > 0) {
						let sortFields = {
							receiverId: 'RECEIVER_ID',
							updatedon: 'CHANNEL_UPDATED_ON',
							channelChoice: 'CHANNEL_CHOICE',
							channelUpdateMethod: 'UPDATE_METHOD',
							receiverName: 'RECEIVER_NAME',
							legalEntityName: 'LE_NAME',
						};
						params.sortOn = {
							order: params.sort[0].dir.toUpperCase(),
							sortField: sortFields[params.sort[0].field]
						}
						store.commit('setTableSorters',
							{
								place: 'receivers',
								order: params.sort[0].dir.toUpperCase(),
								sortField: sortFields[params.sort[0].field].toUpperCase()
							});
					}

					return url;
				},
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				sortMode:"remote",
				paginationMode: 'remote',
				paginationSize: pageSize.value,
				paginationInitialPage: store.state.tableInitialPage.managementReceivers,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: tableColumns.value,
			})
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', ()=> {
				tableIsLoading.value = true
			})

		}
		watch(tableIsLoading, () => {
			if(tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const tokenIsExpired = () => {
			let exp;
			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
			}
			let now = parseInt(new Date().getTime() / 1000);
			if (exp - now <= 0) {
				return true
			} else {
				return false
			}
		}
		const updateToken = () => {
			if (tokenIsExpired()) {
				let payload = {
					client_id: env.keycloakClient,
					grant_type: "refresh_token",
					refresh_token: store.state.refreshToken
				}
				let body = qs.stringify(payload);
				axios.post(store.state.API.keycloakToken,
					body,
					{
						headers: {
							'Content-Type': "application/x-www-form-urlencoded",
						}
					}
				)
					.then(res => {
						store.commit('setToken', res.data.access_token);
						store.commit('setRefreshToken', res.data.refresh_token);
						initTabulator();

					})
					.catch(() => {
						store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
						store.commit('displayNotification', true);
					})
			}

		}
		const setExtendedSearchValues = () => {
			if (!showExtendedSearch.value) {
				onFilter();

			}
		}
		const cleanQuickSearch = () => {
			filter.value = ''
			store.state.extendedSearchValue.extendedSearch = {}
			store.state.extendedSearchValue.extendedSearch.legalEntityNumber = {label: 'general.allLegalEntity', value: null}
			onFilter();
		}

		const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
		}
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw();
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}

		const quickSearch = () => {
			onFilter();
		}
		const onFilter = () => {
			initTabulator();
			tabulator.value.setPage("first")
		}
		const onResetFilter = () => {
			filter.value = ''
			onFilter();
		}
		const onExportCsv = () => {
			tabulator.value.download('csv', 'data.csv')
		}
		const onExportJson = () => {
			tabulator.value.download('json', 'data.json')
		}
		const onExportXlsx = () => {
			const win = window
			win.XLSX = xlsx
			tabulator.value.download('xlsx', 'data.xlsx', {
				sheetName: 'Products'
			})
		}
		const onExportHtml = () => {
			tabulator.value.download('html', 'data.html', {
				style: true
			})
		}
		onMounted(() => {
			store.commit("setTablePage", {field: "managementReceivers", pageNo: 1})
			store.commit('setTableSorters', [{}])
			initTabulator();
			reInitOnResizeWindow();
			setLocale();
		})


		return {
			tableRef,
			filter,
			onFilter,
			onResetFilter,
			onExportCsv,
			onExportJson,
			onExportXlsx,
			onExportHtml,
			totalDocuments,
			quickSearch,
			tabulator,
			resetSearch,
			docTypeError,
			documentType,
			mandateId,
			cleanQuickSearch,
			tableItems,
			channelOptions,
			ediChannels,
			showExtendedSearch,
			setExtendedSearchValues,
			addNewReceiver,
			displayInfoView,
			displayInfoEdit,
			displayOnPortal,
			editOnPortal,
			identifierNumberTypeMatch,
			displayAddButton
		}
	}
})
</script>

<style scoped>

.extended-search-box {
	display: flex;
	flex-direction: row;
}
@media only screen and (max-width: 739px) {
	.extended-search-box {
		display: flex;
		flex-direction: column;
	}
	.add-margin-small-screen {
		margin-top: 5px
	}
}
.tabulator-arrow {
	display: none;
}

.tabulator-col-title {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
}

.leg-entity-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-columns: 80px auto;
	grid-auto-flow: column;
}

.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
}

.leg-entity-container .table-report img, .table-image {
	box-shadow: none !important;
}

.legEntityColumnHeader {
	padding-left: 40px
}

#tabulator-html-filter-value {
	width: 400px;

}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	padding-top: var(--generalPaddings);
}

#selectAllDocuments {
	justify-self: center;
}

.buttons-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: repeat(2, auto);
	grid-auto-flow: column;
	grid-column-gap: var(--generalPaddings);
}

.search {
	display: none !important;
}

.tabulator-buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

}


.addNewChoice {
	justify-self: end;
}

.tabulator-search-container {
	display: grid;
	grid-template-columns: 400px repeat(5, auto);
	grid-template-rows: 100%;
	grid-auto-flow: column;
	grid-column-gap: 5px;
}

.download {
	justify-self: end;
}

.buttons-container {
	align-self: center;
	justify-self: start;
}

.col-header {
	text-align: center;
}


.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.download-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: column;
	justify-items: end;

}

.download-outer button {
	margin-left: 5px;
}

.icon-coin-euro {
	font-size: 24px;
}

.icon-coin-euro:hover {
	cursor: pointer;
	color: var(--infoColor);

}

.download-buttons {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: auto auto;
}

.download-buttons .btn {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	padding: 0;
}

.button-text {
	padding: 0.5rem 0.75rem;
}

.download-buttons .btn span {
	grid-column: 1/2;
	grid-row: 1/2;
}

.download-buttons .btn:disabled:hover .tooltip {

	display: block;

}

.tooltip {
	display: none;
	position: relative;
	top: 20px;
	background-color: var(--theme-primary-500);
	color: white;
	border-radius: 5px;
	font-size: 12px;

}

.extended-search {
	margin-top: 10px;
	width: 740px;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto auto;
	grid-gap: 10px;
}

.extendedLe {
	align-self: start;
}

.extended-left {
	display: grid;
	grid-template-rows: auto auto auto;
	grid-template-columns: 100%;
	grid-row-gap: 10px;
}

.display-none {
	display: none;
}

.extended-search-check-box {
	margin-top: 10px;
	line-height: 1.5em;
}

.search-button {
	grid-column: 1/3;
	justify-items: center;
	display: grid;
}

.extended-search-check-box span {
	margin-right: 10px;
	position: relative;
	top: -5px;
}

.margin-right {
	margin-right: 10px;
}

@media only screen and (max-width: 800px) and (max-width: 670px) {
	#tabulator-html-filter-value {
		width: 50vw;
	}

	.tabulator-search-container {
		grid-template-columns: 50vw repeat(5, auto);
	}

}

@media only screen and (max-width: 669px) {
	#tabulator-html-filter-value {
		width: 40vw;
	}

	.tabulator-search-container {
		grid-template-columns: 40vw repeat(5, auto);
	}

}

@media only screen and (max-width: 850px) {
	.download {
		height: 38px;
	}
}

.findBy {
	display: grid;
	grid-template-columns: 240px 240px auto;
	grid-template-rows: 100%;
	grid-column-gap: 20px;
	grid-auto-flow: row;
	justify-items: start;
}

.findByStatus {
	width: 240px;
}

.button-container {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-row: 100%;
	align-items: center;
}

.quickSearch {
	display: grid;
	grid-template-columns: 400px 100px;
	grid-template-rows: 100%;
	grid-column-gap: 10px;
}

.filter-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto;
	grid-auto-flow: row;
}

.button-container {
	align-self: center;
}

.search-button {
	grid-column: 1/3;
	justify-items: center;
	display: grid;
}

.portalInfo {
	text-align: end;
	margin-bottom: 10px;
}

@media only screen and (max-width: 740px) {
	.extended-search {
		width: 400px;

	}

	.tabulator-buttons-container {
		grid-template-columns: 100% !important;
		grid-template-rows: 50% 50%;
		grid-row-gap: 10px;
	}

	.addNewChoice {

		grid-column: 1/2;
		grid-row: 1/2;
	}

	.addNewChoice button {
		margin: 0;
	}

	.quickSearch {
		grid-column: 1/2;
		grid-row: 2/3;
		grid-template-columns: calc(100% - 100px) 100px;
	}
}

@media only screen and (max-width: 440px) {
	.extended-search {
		width: 95%;
	}
}

@media only screen and (max-width: 720px) {
	.extended-search {

		grid-template-columns: 100%;
		grid-template-rows: auto auto auto auto
	}

	.search-button {
		grid-column: 1/2;
	}
}
</style>

