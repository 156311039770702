import axios from "axios"

export default {
	reportSettingsLeLevel(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.updateReportConfigLe.replace(':legalEntityNumber', payload.legalEntityNumber).replace(':supplierGroupId', context.rootState.supplierGroup.data.supplierGroupId)

		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
				context.commit('displayNotification', true, {root: true})
				context.commit('updateSupplierLegalEntities', res.data.data, {root: true})
			}
		).catch(() => {
			context.rootState.errorNotification.display = true
		})
	},

	reportSettingsSgLevel(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.updateReportConfigSg.replace(':supplierGroupId', context.rootState.supplierGroup.data.supplierGroupId)
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
				context.commit('displayNotification', true, {root: true})
				if (res.data.data.reportingConfig) {
					context.commit('updateSGReportConfig', res.data.data.reportingConfig, {root: true})
				}
			}
		).catch(() => {
				context.rootState.errorNotification.display = true
			})
	},
	downloadReport(context, payload) {
		let generateXslx = false
		if (payload.reportType === '.xlsx') generateXslx = true
		let url = context.rootState.domainName + context.rootState.API.downloadReport.replace(':supplierGroupId', context.rootState.supplierGroup.data.supplierGroupId) + payload.type
		let reportName = context.rootState.supplierGroup.data.brandingId ? context.rootState.supplierGroup.data.brandingId : context.rootState.supplierGroup.data.supplierGroupId

		switch (payload.type) {
			case "MAIL_ERRORS":
				reportName += '-delivery'
				break
			case "CHANNEL_DETAIL":
				reportName += '-channel-details'
				break
			case "CHANNEL_ARCHIVED_DETAIL":
				reportName += '-channel-details-archived'
				break
			case "TRANSACTIONS":
				reportName += '-transactions'
				break
			case "MANDATES_CHOICES":
				reportName += '-mandate-choices'
				break
			case "MANDATES_OVERVIEW":
				reportName += '-mandate-overview'
				break
			case "CHANNEL_CHOICE":
				reportName += '-channel-choice'
				break
			case "POST_DROP":
				reportName += '-post-drop'
				break
			case "SG_SPECIFIC_ERRORS" :
				reportName += '-sg-specific-errors'
				break
			case "UBL_STATUS_REPORT" :
				reportName += '-ubl-status'
				break
			case "DOCUMENTS_HELD":
				reportName += '-documents-held'
				break
		}
		reportName += payload.reportType

		return axios.post(url,
			{
				from: payload.formData.from,
				to: payload.formData.to,
				legalEntities: payload.formData.legalEntities,
				generateXslx: generateXslx,
			},
			{
				responseType: 'blob',
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		)
			.then((response) => {
				context.commit('displayLoadingNotification', {display: false, type: ''}, {root: true})

				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', reportName) //or any other extension
				document.body.appendChild(link)
				link.click()
			})
			.catch(() => {
				context.commit('displayLoadingNotification', {display: false, type: ''}, {root: true})
				context.rootState.errorNotification.type = "REPORT_FAILED"
				context.rootState.errorNotification.display = true
			})
	},
}