<template>
	<pop-up-window :show="this.$store.state.userManagement.displayManageUser"
				   :showClose=false
				   :width60=true
				   @close="closeManageUser"
				   class="doc-statuses-popup"
				   additional-info=""
				   :top10=true
				   :stickToScrollPosition=true
				   fixed>
		<template #title>
			<h1 class="text-2xl">{{ $t('management.manageUser.title') }}</h1>
			<h1>{{ showEmailOrUsername }}</h1>
		</template>
		<template #content>
			<div class="user-info ">
				<div class="divider">
					<div>
						<h2 class="base-text header-text">{{ $t('general.legalEntities') }}</h2>
						<div class="legal-entity-container">
							<div v-for="legalEntity in legalEntitiesData" :key="legalEntity.entityNumber">
								<div class="input-container">
									<input class="customCheckbox"
										   type="checkbox"
										   :id=legalEntity.entityNumber
										   :checked=isChecked(legalEntity.entityNumber)
										   @change="setSelectedLegalEntities">
									<label
										class="base-text"
										:for="legalEntity.entityNumber"
									>
										{{ legalEntity.name }}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p class="error" v-if="errors.legalEntities">{{ $t('errors.choseLegalEntity') }}</p>
				<p class="error" v-if="errors.canNotCreateSsuWithLe">{{ $t('errors.canNotCreateSsuWithLe') }}</p>

				<div class="divider find-customer" v-if="showReceivers">
					<h2 class="base-text header-text">
						{{ findReceiver }}
					</h2>
					<div class="select-outer">
						<v-select @search="fetchOptions"
								  v-model="selectedReceiver"
								  :options=this.$store.state.receivers.options
						>
							<template #no-options="{search}">
								{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
							</template>
						</v-select>
					</div>
				</div>
				<div class="divider receivers-list" v-if="showReceivers">
					<h2 class="base-text header-text">{{ $t('management.manageUser.authorizationOverview') }}</h2>
					<div class="receiversList">
						<div
							class="box"
							style="padding: 10px; margin: 5px"
							v-for="receiver in userReceivers"
							:key=receiver.receiverId
						>
							<div style="display: flex; flex-direction: row; justify-content: space-between">
								<div>
									<h2 v-if="usedReceiverIds === false" class="base-text">
										<span v-if=receiver.info.receiverName>{{ receiver.info.receiverName }}</span>
										{{ receiver.info.companyName }} - {{ receiver.receiverId }}
									</h2>
									<h2 v-else>
										{{ receiver.receiverId }}
									</h2>
									<h3 class="base-text color-grey">{{ $t('general.legalEntity') }}:
										<strong>{{ legalEntityName(receiver) }}</strong>
									</h3>
								</div>
								<i class="icon-bin" @click="removeReceiver(receiver)"></i>
							</div>
						</div>
					</div>
				</div>
				<p class="error" v-if="errors.receivers">{{ choseReceiver }}</p>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="cancel">
						{{ $t('buttons.cancel') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="manageUser">
						{{ $t('buttons.save') }}
					</button>
				</div>
			</div>
			<confirmation-pop-up @approveAction="sendManageUserWithoutLe" type="editUser"></confirmation-pop-up>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../UI/PopUpWindow";
import ConfirmationPopUp from "../UI/ConfirmationPopUp.vue"
import i18n from '../../i18n'
import userGroups from "../../userGoups";

export default {
	name: "ManageUser",
	components: {PopUpWindow, ConfirmationPopUp},
	emits: ['userManaged'],
	mounted() {
		this.setPortalData();
		this.errors.receivers = false;
	},
	data() {
		return {
			userData: {},
			legalEntities: this.$store.state.legalEntitiesData.data,
			userLegalEntitiesData: [],
			selectedLegalEntities: [],
			i18n: i18n,
			selectedReceiver: null,
			form: {
				group: this.$store.state.userManagement.userData.group,
				language: this.$store.state.userManagement.userData.language,
				firstName: '',
				lastName: '',
				legalEntities: [],
			},
			receivers: this.$store.state.userManagement.userReceivers,
			errors: {
				receivers: false,
				legalEntities: false,
				canNotCreateSsuWithLe: false,
			},
			postDrop: false,
			billing: false,
			peppol: false,

			usedReceiverIds: false
		}
	},
	computed: {
		findReceiver() {
			if (this.$store.state.supplierGroup.data.sgType === "SALARY") {
				return i18n.t('general.findEmployee');
			}
			return i18n.t('general.findReceiver');
		},
		choseReceiver() {
			if (this.$store.state.docType === "SALARY") {
				return i18n.t('errors.choseEmployee')
			}
			return i18n.t('errors.choseReceiver')
		},
		showReceivers() {
			return !(this.$store.state.userManagement.userData.group === 'SupplierSubAdmin'
				|| this.$store.state.userManagement.userData.group === 'SupplierSubUser'
				|| this.$store.state.userManagement.userData.group === 'SupplierLimitedSubUser');
		},
		legalEntitiesStoreData() {
			return this.$store.state.legalEntitiesData.data;
		},
		userStoreData() {
			return this.$store.state.userManagement.userData;
		},
		userReceivers() {
			if (this.$store.state.userManagement.userReceivers.length === 0) {
				let receivers = []

				this.$store.state.userManagement.userData.legalEntities.forEach(le => {
					le.receiverIds.forEach(id => {
						receivers.push({
							legalEntityId: le.legalEntityId,
							receiverId: id
						})
					})
				})
				this.usingReceiverId()
				return receivers
			} else {
				this.notUsingReceiverId()
				return this.$store.state.userManagement.userReceivers;
			}
		},
		showEmailOrUsername() {
			let email = this.$store.state.userManagement.userData.email;
			let username = this.$store.state.userManagement.userData.username;

			if (email !== '' && email !== undefined) return email
			else return username
		},
		legalEntitiesData() {
			let legalEntities = [];
			for (let legEntity in this.legalEntities.legalEntities) {
				let name = '';
				if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
					name = this.legalEntities.legalEntities[legEntity].internalName ? this.legalEntities.legalEntities[legEntity].internalName : this.legalEntities.legalEntities[legEntity].name
				} else {
					name = this.legalEntities.legalEntities[legEntity].name
				}
				let tempItem = {
					entityNumber: this.legalEntities.legalEntities[legEntity].entityNumber,
					name: name
				};
				legalEntities.push(tempItem)
			}
			legalEntities.sort(function (a, b) {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			})
			return legalEntities
		},
	},
	watch: {
		selectedReceiver(newVal) {
			if (newVal !== null) {
				this.form.legalEntities.forEach(legEntity => {
					if (legEntity.legalEntityId === newVal.value.legalEntityId) {
						legEntity.add.push(newVal.value.receiverId);
						legEntity.receiverIds.push(newVal.value.receiverId);
						this.userReceivers.push({
							receiverId: newVal.value.receiverId,
							legalEntityId: newVal.value.legalEntityId,
							legalEntityName: newVal.value.legalEntityName,
							info: {
								companyName: newVal.value.companyName,
								receiverName: newVal.value.receiverName
							}
						})
					}
				});
			}
		},
		userStoreData(newVal) {
			let userLE = [];
			if (newVal.legalEntities) {
				newVal.legalEntities.forEach(le => {
					userLE.push(le.legalEntityId)
				})
			}
			this.$store.dispatch('users/fetchReceivers', {
				token: this.$root.token,
				formData: {
					supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
					legalEntityIds: userLE,
					quick: ''
				}
			})
			this.selectedLegalEntities = [];
			this.form.legalEntities = [];
			this.$store.state.userManagement.userReceivers = [];
			this.userData = newVal;
			this.userLegalEntitiesData = newVal.legalEntities;
			if (newVal.legalEntities
				&& ((this.$store.state.userManagement.userData.group === 'SupplierSubAdmin'
					|| this.$store.state.userManagement.userData.group === 'SupplierSubUser'
					|| this.$store.state.userManagement.userData.group === 'SupplierLimitedSubUser'))) {
				newVal.legalEntities.forEach((legEntity) => {
					this.selectedLegalEntities.push(legEntity.legalEntityId)
				});
			}
			if (this.userData.legalEntities) {
				if (!((this.userData.legalEntities.length === 1) && (this.userData.legalEntities[0].legalEntityId.length === 0))) {
					this.userData.legalEntities.forEach((legEntity) => {
						let userReceiverFormData = {};
						userReceiverFormData = {
							legalEntityNumber: legEntity.legalEntityId,
							pageNo: 1,
							pageSize: legEntity.receiverIds.length,
							receiverIds: legEntity.receiverIds,
							supplierGroupId: this.userData.supplierGroupId,
							quick: null,
							receiverId: null
						};
						this.$store.dispatch('users/fetchUserReceiversData', {
							token: this.$root.token,
							formData: userReceiverFormData
						})
					})
				}
			}
			this.form.group = newVal.group;
			this.form.language = newVal.language;
			for (const legalEntity in this.legalEntities.legalEntities) {
				this.form.legalEntities.push({
					legalEntityId: this.legalEntities.legalEntities[legalEntity].entityNumber,
					add: [],
					remove: [],
					receiverIds: []
				});
			}
			if (newVal.legalEntities) {
				newVal.legalEntities.forEach(userLegEntity => {
					this.form.legalEntities.forEach(legEntity => {
						if (userLegEntity.legalEntityId === legEntity.legalEntityId) {
							legEntity.receiverIds = userLegEntity.receiverIds;
						}
					})
				})
			}
		},
	},
	methods: {
		usingReceiverId() {
			this.usedReceiverIds = true
		},
		notUsingReceiverId() {
			this.usedReceiverIds = false
		},
		setPortalData() {
			if (
				this.$store.state.supplierGroup.data.channelsConfig.docImage &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			) {
				this.postDrop = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			}
			if (
				this.$store.state.supplierGroup.data.channelsConfig.docImage &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
			) {
				this.billing = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
			}
			if (
				this.$store.state.supplierGroup.data.channelsConfig.docImage &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig &&
				this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
			) {
				this.peppol = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
			}
		},
		legalEntityName(receiver) {
			let leName = '';
			if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
				|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
				leName = this.$store.state.supplierGroup.data.supplierLegalEntities[receiver.legalEntityId].internalName
					? this.$store.state.supplierGroup.data.supplierLegalEntities[receiver.legalEntityId].internalName
					: this.$store.state.supplierGroup.data.supplierLegalEntities[receiver.legalEntityId].name
			} else {
				leName = this.$store.state.supplierGroup.data.supplierLegalEntities[receiver.legalEntityId].name
			}
			return leName
		},
		removeReceiver(rec) {
			this.form.legalEntities.forEach(legEntity => {
				if (legEntity.legalEntityId === rec.legalEntityId) {                     //search at initial receiversIds
					const removeIndex = legEntity.receiverIds.indexOf(rec.receiverId);
					const removeIndexAddArray = legEntity.add.indexOf(rec.receiverId);
					if (removeIndex > -1 && removeIndexAddArray <= -1) {
						legEntity.remove.push(rec.receiverId);
						legEntity.receiverIds.splice(removeIndex, 1);
					}
					if (removeIndexAddArray > -1) {
						legEntity.add.splice(removeIndexAddArray, 1);
						legEntity.receiverIds.splice(removeIndex, 1);
					}
					this.userReceivers.forEach(userRec => {
						if (userRec.legalEntityId === rec.legalEntityId && userRec.receiverId === rec.receiverId) {
							const removeIndex = this.userReceivers.indexOf(userRec);
							if (removeIndex > -1) {
								this.userReceivers.splice(removeIndex, 1);
							}
						}
					})
				}
			})
		},
		fetchOptions(search) {
			this.$store.dispatch('users/fetchReceivers', {
				token: this.$root.token,
				formData: {
					supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
					legalEntityIds: this.selectedLegalEntities,
					quick: search
				}
			})
		},
		setSelectedLegalEntities(event) {
			this.errors.legalEntities = false;
			this.errors.canNotCreateSsuWithLe = false;
			const index1 = this.selectedLegalEntities.indexOf('');
			if (index1 > -1) {
				this.selectedLegalEntities.splice(index1, 1);
			}
			if (event.target.checked) {
				this.selectedLegalEntities.push(event.target.id)
			} else {
				const index = this.selectedLegalEntities.indexOf(event.target.id);
				if (index > -1) {
					this.selectedLegalEntities.splice(index, 1);
				}
			}
			if (this.$store.state.legalEntitiesData.data.legalEntities) {
				this.$store.dispatch('users/fetchReceivers', {
					token: this.$root.token,
					formData: {
						supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
						legalEntityIds: this.selectedLegalEntities,
						quick: ''
					}
				})
			}
		},
		isChecked(entityNumber) {
			if (this.$store.state.userManagement.userData.group === 'SupplierSubAdmin'
				|| this.$store.state.userManagement.userData.group === 'SupplierLimitedSubUser'
				|| this.$store.state.userManagement.userData.group === 'SupplierSubUser') {
				return !!this.userLegalEntitiesData.find(legEntity => legEntity.legalEntityId === entityNumber);
			}
		},
		cancel() {
			this.errors.receivers = false;
			this.errors.legalEntities = false;
			this.closeManageUser();
			this.$emit("canceled");
		},
		closeManageUser() {
			this.errors.receivers = false;
			this.errors.legalEntities = false;
			this.selectedReceiver = null;
			this.$store.commit('displayManageUser', false);
		},
		manageUser() {
			if (this.$store.state.userManagement.userData.group === 'ReceiverAdmin' || this.$store.state.userManagement.userData.group === 'ReceiverUser') {
				let filteredLegEntities = this.form.legalEntities.filter(legEntity => (legEntity.add.length > 0 || legEntity.remove.length > 0 || legEntity.receiverIds.length > 0));
				this.form.legalEntities = filteredLegEntities;
				let chosenReceiversIsEmpty = true;
				filteredLegEntities.forEach(filteredLegEntity => {
					if (filteredLegEntity.receiverIds.length > 0) chosenReceiversIsEmpty = false
				})
				if (chosenReceiversIsEmpty) {
					this.errors.receivers = true;
					return
				}
			}

			if ((!this.billing
				&& this.postDrop
				&& !this.peppol
				&& this.$store.state.userManagement.userData.group === 'SupplierSubUser'
				&& this.selectedLegalEntities.length > 0)) {
				this.errors.canNotCreateSsuWithLe = true;
				return
			}
			if (this.$store.state.userManagement.userData.group === 'SupplierSubAdmin'
				|| this.$store.state.userManagement.userData.group === 'SupplierSubUser'
				|| this.$store.state.userManagement.userData.group === 'SupplierLimitedSubUser') {
				let selectedLE = []
				let UserLEInitial = []
				this.$store.state.userManagement.userData.legalEntities.forEach(userLE => {
					UserLEInitial.push(userLE.legalEntityId)
				});
				let allLE = [];
				for (let key in this.$store.state.legalEntitiesData.data.legalEntities) {
					allLE.push(key)
				}
				allLE.forEach(le => {
					if ((this.selectedLegalEntities.includes(le) && UserLEInitial.includes(le)) || (this.selectedLegalEntities.includes(le) && !(UserLEInitial.includes(le)))) {
						let tempItem = {
							add: [],
							legalEntityId: le,
							remove: []
						}
						selectedLE.push(tempItem)
					}
					if (UserLEInitial.includes(le) && !(this.selectedLegalEntities.includes(le))) {
						let tempItem = {
							add: [],
							legalEntityId: le,
							remove: [],
							removeLegalEntity: true
						}
						selectedLE.push(tempItem)
					}
				})
				this.form.legalEntities = selectedLE;
			}

			if (this.$store.state.userManagement.userData.group === 'SupplierSubAdmin'
				|| this.$store.state.userManagement.userData.group === 'SupplierSubUser'
				|| this.$store.state.userManagement.userData.group === 'SupplierLimitedSubUser') {
				if (this.selectedLegalEntities.length === 0) {
					if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig
						&& this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost) {
						if (this.$store.state.userManagement.userData.group === 'SupplierSubAdmin') {
							this.errors.legalEntities = true;
						} else {
							this.$store.commit('setDisplayConfirmation', true);
						}
					} else {
						this.errors.legalEntities = true;
					}
				} else {
					this.sendManageUserRequest();
				}
			} else {
				this.sendManageUserRequest();
			}
		},
		sendManageUserWithoutLe() {
			this.sendManageUserRequest()
		},
		sendManageUserRequest() {
			this.$store.dispatch('users/manageUser', {
				formData: this.form,
				id: this.$store.state.userManagement.userData.id,
			});
			this.closeManageUser();
			this.$emit("userManaged");
			this.errors.legalEntities = false;
		}
	}
}
</script>

<style scoped>
.receiversListItem i {
	cursor: pointer;
}

.legal-entity-container div {
	font-size: 20px;
	margin-bottom: 5px;
	margin-left: 20px;
}

.legal-entity-container label {
	padding-left: 5px;
}

.divider {
	padding-bottom: calc(0.5 * var(--generalPaddings));
	border-bottom: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}

.input-container {
	display: grid;
	grid-template-columns: 20px auto;
	grid-template-rows: 100%;
	grid-auto-flow: column;
	align-items: center;
}

.input-container label {
	padding-top: 5px;
}

.select-outer {
	margin-bottom: 10px;
	margin-top: 20px;
}

.find-customer {
	display: grid;
	grid-template-columns: 140px auto;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	align-items: center;
}
</style>
