<template>
    <pop-up-window :show="this.$store.state.postDrop.displayDeleteDepartment"
                   :showClose=false
                   class="doc-statuses-popup"
                   additional-info=""
                   :positionFixed="true"
                   :top0="true"
                   :stickToScrollPosition = "true"
                   :bottomDiv = "false"
    >
        <template #title >
            <h1 class="text-2xl">
                {{ $t('postDrop.deleteDepartmentTitle') }}
            </h1>
        </template>
        <template #content >
            <div>
                <h2 class="text-lg text-center">
                    <i class="icon-notification warning"></i> {{ $t('postDrop.deleteDepartmentDescription') }}
                    <strong>{{ name }}</strong>
                </h2>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6 grey-line">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
                        {{ $t('buttons.cancel') }}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="deleteDepartment">
                        {{ $t('buttons.delete') }}
                    </button>
                </div>
            </div>
            <confirmation-pop-up
                  @approveAction="sendDeleteDepartmentRequest"
                  type = "deleteDepartmentWithUsers"
            >
            </confirmation-pop-up>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow";
import ConfirmationPopUp from "../../UI/ConfirmationPopUp.vue"

export default {
    name: "DeleteDepartment",
    components: {PopUpWindow, ConfirmationPopUp},
    data() {
        return {
            departmentId: '',
            name: '',
        }
    },
    computed: {
        departmentData() {
            return this.$store.state.postDrop.departmentData
        },
        confirmationIsOpen(){
            if(this.$store.state.postDrop.displayDeleteDepartment && this.$store.state.displayConfirmation){
                return true
            }
            return false
        }
    },
    watch: {
        departmentData(newVal) {
            this.setDepartmentData(newVal)
        }
    },

    methods: {
        sendDeleteDepartmentRequest(){
            this.$store.dispatch("department/deleteDepartment", this.departmentId);
            this.close();
        },
        deleteDepartment() {
            if (!this.departmentData.users.length){
                this.sendDeleteDepartmentRequest()
            } else {
                this.$store.commit('setDisplayConfirmation', true);
            }

        },
        setDepartmentData(data) {
            if (data.departmentId) {
                this.departmentId = data.departmentId;
                this.name = data.departmentName;
            }
        },
        close() {
            this.$store.commit('displayDeleteDepartment', false);
            this.$store.commit('setDepartmentData', {})
        }
    }
}
</script>

<style scoped>
.opacity0{
    opacity:0;
}

</style>
