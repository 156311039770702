<template>
    <div class="intro-y statuses-table">
       <div class="">
           <div>
               <statuses-table
                     :supplierGroupId="this.$store.state.supplierGroup.data.supplierGroupId"
                     :requestUrl="this.requestUrl">
               </statuses-table>
           </div>
       </div>
    </div>

</template>

<script>
import StatusesTable from "./statuses/StatusesTable";
import printStatuses from  '../../locales/en.json';
import i18n from '../../i18n'
export default {
    components: {
        StatusesTable
    },
    mounted(){
        this.$store.dispatch("updateToken");
        this.setPrintStatusesList()
    },
    data(){
        return {
            requestUrl: this.$store.state.domainName + this.$store.state.API.postalService,
            printStatuses : printStatuses.dropdowns.printStatuses,
            statusesList : [],
            i18n : i18n,
        }
    },
    watch:{
        locale(){
            this.setTranslations();
        }
    },
    computed:{
        locale(){
            return this.$store.state.locale
        }
    },
    methods:{
        setPrintStatusesList(){
            for (let item in this.printStatuses){
                this.statusesList.push(item)
            }
        },
        setTranslations(){
            this.statusesList.forEach(item =>{
                let cellClass = '.' + item;
                let cells = document.querySelectorAll(cellClass);
               let key = 'dropdowns.printStatuses.' + item;
                cells.forEach(cell=>{
                    cell.innerHTML = i18n.t(key)
                })
            })
        }
    },
}
</script>

<style scoped>


</style>
