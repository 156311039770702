<template>
    <div class="intro-y">
        <loader-page></loader-page>
        <div class="page-container mt-6">
            <div class="page-container-inner mt-6">
                <div class="description">
                    <h2 class="text-lg text-center ">
                        {{ $t('ubl.ublPageTitle') }}
                    </h2>
                </div>

                <div class="upload-pdf">
                    <div class="one-item mt-6">
                        <div>
                            <h3 class="font-bold">{{ $t('postDrop.validateFile') }}</h3>
                        </div>
                        <div>
                            <input type="file"
                                   ref="fileInput"
                                   v-if="renderComponent"
                                   accept=".xml"
                                   class="form-control text-popup "
                                   @change="uploadFile"
                                   @click="cleanErrors()"

                            >
                        </div>
                    </div>

                    <div class="mt-2">
                        <p class="error"
                           v-if="errors.fileLength">
                            {{ $t('errors.attachFileIsEmpty') }}
                        </p>
                        <p class="error"
                           v-if="errors.fileType">
                            {{ $t('errors.validateFileType') }}
                        </p>
                    </div>
                    <div class="one-item mt-6">
                        <div>
                            <h3 class="font-bold">{{ $t('ubl.ublSelectDocType') }}</h3>
                        </div>
                        <v-select
                              v-model="documentType"
                              :options='dropdownOptions'
                              :clearable='false'
                        >
                            <template #no-options="{search}">
                                {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                            </template>
                        </v-select>
                    </div>
                    <div class="btn-content mt-8 grey-top-border">
                        <div>
                            <button class="btn custom-button shadow-md mr-2 text-popup"
                                    @click="validate"
                            >
                                {{ $t('buttons.validate') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <validate-pop-up></validate-pop-up>
    </div>

</template>

<script>
import ValidatePopUp from "./ValidatePopUp.vue";
import i18n from "../../i18n";
import LoaderPage from "../UI/LoadingPage.vue"

export default {
    name: "ValidateFile",
    components: {LoaderPage, ValidatePopUp},
    mounted(){
        this.$store.dispatch("updateToken");
    },
    beforeUnmount(){
        this.cleanErrors();
    },

    data() {
        return {
            renderComponent: true,
            documentData: {},
            i18n: i18n,
            file: null,
            selectedFile: null,
            documentType: 'BIS_BILLING_INVOICE_V3',
            errors :{
                fileLength: false,
                fileType : false
            },
        }
    },
    computed: {
        locale() {
            return this.$store.state.locale;
        },
    },
    props: {
        dropdownOptions: {
            type: Array,
            required: true,
    },
    },
    methods: {
        forceRerender() {
          this.renderComponent = false;
          this.$nextTick(() => {
            this.renderComponent = true;
          });
        },
        cleanErrors(){
            this.setError("fileLength", false);
            this.setError("fileType", false);
        },
        setError(field, value) {
            this.errors[field] = value
        },
        uploadFile(event) {
         this.selectedFile = event.target.files[0];
        },
        clearFileInput() {
          const fileInput = this.$refs.fileInput;
          if (fileInput) {
            fileInput.value = '';
          }
        },
        validate() {
            if (!this.selectedFile) {
                this.setError("fileLength", true);
                return;
            }

            if (this.selectedFile.type !== "application/xml" && this.selectedFile.type !== "text/xml") {
                this.setError("fileType", true);
                return;
            }

            const formData = new FormData();
            formData.append("document", this.selectedFile);
            formData.append("document-type", this.documentType);

            this.$store.dispatch("peppol/validateUBLDocument", formData)
                .then(() => {
                const validationResponse = this.$store.state.validationResponse;
                if (validationResponse && validationResponse.success === false) {
                    this.$store.commit('setValidatePopUp', true, validationResponse.errors);
                } else {

                    this.$store.commit('setValidatePopUp', true);
                }
                  this.selectedFile = null;
                  this.clearFileInput();
                })
                .catch((error) => {
                console.log(error);
                });
        }

    }
}
</script>

<style scoped>

.page-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
}
.page-container-inner{
    width: 60%;
}
.description{
    margin-bottom: 20px;
}

.upload-pdf{
    width: 600px;
    margin: 0 auto;
}

.one-item {
    display: grid;
    grid-template-columns: 28% 72%;
    grid-template-rows: 100%;
    grid-auto-flow: row;
    align-items: center;

}
@media only screen and (max-width: 1100px) and (min-width: 850px) {
    .page-container-inner {
        width: 80%;
    }
}
@media only screen and (max-width: 849px)  {
    .page-container-inner {
        width: 100%;
    }
}
@media only screen and (max-width: 650px) {
    .one-item {
        grid-template-rows: auto auto;
        grid-template-columns: 100%;
    }
    .upload-pdf {
        width: 100%;
    }
}

</style>
