import {createApp, h} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index'
import i18n from "./i18n";
import VueSelect from 'vue3-select'
import Keycloak from 'keycloak-js';

// SASS Theme
import './assets/sass/app.scss'

const newLoginPage = window.newLoginPage;
const proxyToken = localStorage.getItem("proxyToken")
const env = window.environment;
//import globalComponents from './global-components'
//import utils from './utils'

let keycloak;

if (newLoginPage || proxyToken) {
    let initOptions = {
        url: env.keycloakUrl,
        realm: env.keycloakRealm,
        clientId: env.keycloakClient,
        onLoad: 'check-sso'  //onLoad: 'login-required'
    }
    let keycloak = new Keycloak(initOptions);
    keycloak.init({onLoad: initOptions.onLoad}).then(() => {
    const app =
          createApp({
              data: () => ({
                    keycloak: keycloak,
                    token: keycloak.token,
                  isLoading: false,
                  environment: window.environment,
                  //   userIsAuthenticated: auth,
                  // cookiesWarning : undefined

              }),
              render: () => (
                    h(App, {
                        //   props: {keycloak: keycloak}
                    })
              )
          })
              .use(store)
              .use(router)
              .use(i18n)
              .component('v-select', VueSelect)
              .mount("#app");
    router.app = app;
    });
}
else {
    let initOptions = {
        url: env.keycloakUrl,
        realm: env.keycloakRealm,
        clientId: env.keycloakClient,
        //'ssl-required': 'external',
        //'public-client': true,
       // idpHint: ' ',
        onLoad: 'check-sso'  //onLoad: 'login-required'
    }
    let keycloak = new Keycloak(initOptions);

    keycloak.init({onLoad: initOptions.onLoad}).then(() => {
        router.app = createApp({
            data: () => ({
                keycloak: keycloak,
                token: keycloak.token,
                isLoading: false,
                environment: window.environment,

            }),
            render: () => (
                h(App, {
                    props: {keycloak: keycloak}
                })

            )
        })
            .use(store)
            .use(router)
            .use(i18n)
            .component('v-select', VueSelect)
            .mount("#app");
    });
}
export default keycloak;



