<template>
    <transition name="dialog">
        <div>
            <page-container class="page-content">
                <top-banner>
                    <div class="w-9/12">
                        <h1 class="text-3xl text-center">{{ $t('termsConditions.title') }}</h1>
                    </div>
                </top-banner>
                <div class="box tos-items" v-if="displayTos">
                    <div class="button-tos-overview"></div>
                    <div>
                        <div v-for="(value, key) in tos" v-bind:key ="key">
                            <div class="tos-item">
                                <h2 class="text"><span class="numeration">{{key}}</span>. {{value}}</h2>
                            </div>
                        </div>

                        <div class="button-container">
                            <div class="button-container-inner">
                                <button class="btn custom-button" @click="acceptTos" v-if="showAcceptTos">
                                    {{$t('buttons.acceptTos')}}
                                </button>
                                <button class="btn custom-button" @click="declineTos" v-if="showAcceptTos">
                                    {{$t('buttons.declineTos')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </page-container>
        </div>
    </transition>
</template>

<script>
import PageContainer from "../components/PageContainer"
import TopBanner from "../components/TopBanner"
import textEN from "../locales/en.json"
import textNL from "../locales/nl.json"
import textFR from "../locales/fr.json"
import textDE from "../locales/de.json"
import routerData from "../routerData"
import userGroups from "@/userGoups"
const newLoginPage = window.newLoginPage
export default {
    components: { PageContainer, TopBanner},
    mounted() {
		if (this.$store.getters.getLocale === '') this.$store.getter.getLocale = 'EN'
        this.checkDocType()
        this.getTosItemsKeys()
        this.getTos()
    },
    data(){
        return{
            tosItems: {
                en: textEN.termsConditions.items,
                fr: textFR.termsConditions.items,
                nl: textNL.termsConditions.items,
                de: textDE.termsConditions.items,
            },
            tos: textEN.termsConditions.items,
            tosKeys: {}
        }
    },
    watch: {
        locale(newVal){
            let tosText = this.tosItems[newVal]
            let SGName = this.$store.state.supplierGroup.data.name
            let supportEmail = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.termsOfServiceEmail

            for (const tosItem in tosText){
                tosText[tosItem]= tosText[tosItem].replaceAll("INSERT_SG_NAME", SGName)
                tosText[tosItem]= tosText[tosItem].replaceAll("INSERT_SUPPORT_EMAIL", supportEmail)
            }
            this.tos = tosText
        },
        lastLogin(newVal){
            if(newVal){
                this.$store.state.displayResetOwnPassword = false
            }
        }
    },
    computed:{
        lastLogin(){
             return this.$store.state.currentUserData.data.lastLogin
        },
        currentUser(){
           return this.$store.state.currentUserData.data
        },
        displayTos(){
            if  (newLoginPage &&
                  ((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
                  || (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))) {
                return false
            }
            return true
        },
        locale(){
            return this.$store.getters.getLocale
        },

        showAcceptTos(){
			if(userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) {
				return !(this.$store.state.currentUserData.data.termsAndConditionsAcceptedOn || !(this.$store.state.currentUserData.isReady));
			} else return false
        }
    },
    methods:{
        checkResetPassword(){
            if (newLoginPage &&
                  (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
                  || (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)
            ) {
                return false
            }
            return true
        },
        checkDocType(){
            if (this.$store.state.docType === 'SALARY' || this.$store.state.docType === 'GENERAL' ){
                this.$router.push({ path: routerData.notFound.link })
            }
        },
        getTos(){
            let locale = this.$store.getters.getLocale
            let tosText = this.tosItems[locale]
            let SGName = this.$store.state.supplierGroup.data.name
            let supportEmail = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.termsOfServiceEmail


            for (const tosItem in tosText){
                tosText[tosItem]= tosText[tosItem].replaceAll("INSERT_SG_NAME", SGName)
                tosText[tosItem]= tosText[tosItem].replaceAll("INSERT_SUPPORT_EMAIL", supportEmail)
            }

            this.tos = tosText
        },
        getTosItemsKeys(){
            for (let item in this.tosItems){
                this.tosKeys[item] = false
            }
        },
        declineTos(){
            if (newLoginPage){
                this.$router.push('/login');
                this.$root.keycloak.logout()
                this.$store.dispatch('logout' )
            } else {
                this.$router.push('/overview');
                setTimeout(()=> {
                    this.$root.keycloak.logout()
                }, 0)
            }
        },
        acceptTos(){
            this.$store.dispatch('acceptTos')
        }
    }
}
</script>

<style scoped>
.tos-items{
    margin-top: var(--generalPaddings);
    padding: calc(3*var(--generalPaddings));
    min-height:  calc(100vh - var(--headerheight) - var(--footerHeight) - var(--generalPaddings) - 100px);
}
.tos-item{
    margin-bottom: var(--generalPaddings);
    padding-bottom: var(--generalPaddings);
    border-bottom: 1px solid var(--theme-primary-50);
    text-align: justify;
}
.numeration{
    font-weight: bold;
}

.button-container, .button-tos-overview{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    grid-auto-flow: row;
}
.button-container{
    justify-items: center;
}

.button-tos-overview{
   margin-bottom: var(--generalPaddings);
    justify-items: end;
}

.button-container-inner .btn{
    margin: 5px;
}
.text{
    font-size: 1.25rem;
    line-height: 1.75rem;
}

@media only screen and (min-width: 651px) and (max-width: 950px){
    .text{
        font-size: 1.1rem;
        line-height: 1.75rem;
    }
    .tos-items{
        padding: calc(2*var(--generalPaddings));
    }
}
@media only screen and (min-width: 450px) and (max-width: 650px){
    .text{
        font-size: 1rem;
        line-height: 1.55rem;
    }
    .tos-items{
        padding: calc(1*var(--generalPaddings));
    }
}
@media only screen and (min-width: 0px) and (max-width: 451px){
    .text{
        font-size: 0.8rem;
        line-height: 1.25rem;
    }
    .tos-items{
        padding: calc(0.5*var(--generalPaddings));
    }
}

</style>
