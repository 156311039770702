<template>
	<div>
		<delete-user @userDeleted="refreshTable">
		</delete-user>

		<edit-user @userUpdated="refreshTable">
		</edit-user>

		<manage-user
			@userManaged="refreshTable"
			@canceled="refreshAfterCancel">
		</manage-user>

		<invite-user @userInvited="refreshTable">
		</invite-user>

		<error-notification>
		</error-notification>

		<find-user></find-user>

		<!-- BEGIN: HTML Table Data -->
		<div>
			<div>
				<div class="filter-container" >
					<div class="extended-search">
						<input
							id="tabulator-html-filter-value"
							v-model="filter.value"
							style="margin-right: 10px; height: 37px"
							type="text"
							class="form-control"
							:placeholder="$t('placeholders.search')"
							@keyup="onFilter"
						/>
						<div class="flex flex-row add-margin-small-screen">
							<extended-search-filter
								current-page="users"
								:two-columns="false"
								@search="refreshTableForSearch"
								@reset-filter="refreshTable"
								:margin-left-small-screen="8"
								class="mr-2"
							/>
							<button
								class="btn custom-button shadow-md"
								@click="onResetFilter"
							>
								{{ $t('buttons.reset') }}
							</button>
						</div>

					</div>

					<div class="right-buttons-outer">
						<button
							v-if="displayFindUser"
							@click="openFindUser"
							class="btn custom-button w-full shadow-md add-new-user">
							{{ $t('buttons.findUser') }}
						</button>
						<button
							id="tabulator-export-csv"
							class="btn custom-button w-full shadow-md"
							@click="exportUsers"
						>
							{{ $t('buttons.export') }}
						</button>
						<button
							@click="openInviteUser"
							class="btn custom-button w-full shadow-md add-new-user">
							{{ $t('buttons.addNewUser') }}
						</button>
					</div>
				</div>
				<div class="flex mt-5 sm:mt-0">
				</div>
			</div>
			<filter-label @remove-label="refreshTableForSearch" style="margin-top: 8px !important;"/>

			<div class="totalNumberDocuments">{{ $t('table.totalUserNumber') }}: {{ totalDocuments }}</div>
			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"

				></div>
			</div>

		</div>
		<!-- END: HTML Table Data -->
	</div>
</template>

<script>
import {defineComponent, ref, reactive, onMounted, watch, computed} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import cash from 'cash-dom';
import {useStore} from 'vuex';
import moment from "moment";
import DeleteUser from "./DeleteUser.vue";
import EditUser from "./EditUser.vue";
import ManageUser from "../ManageUser.vue";
import InviteUser from "./InviteUser.vue";
import ErrorNotification from "../../UI/ErrorNotification.vue";
import i18n from "../../../i18n";
import FindUser from "./FindUser.vue";
import userGroups from "../../../userGoups";
import qs from "qs";
import axios from "axios";
import ExtendedSearchFilter from "@/components/extendedSearch/ExtendedSearchFilter.vue";
import FilterLabel from "@/components/extendedSearch/FilterLabel.vue";

const env = window.environment;

export default defineComponent({
	components: {
		FilterLabel,
		ExtendedSearchFilter,
		FindUser, ErrorNotification, InviteUser, ManageUser, DeleteUser, EditUser,
	},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		general: Boolean,
		requestUrl: String,
		modelValue: String,
	},
	setup(props) {
		const en = require('../../../locales/en.json')
		const nl = require('../../../locales/nl.json')
		const fr = require('../../../locales/fr.json')
		const de = require('../../../locales/de.json')
		const es = require('../../../locales/es.json')
		const sk = require('../../../locales/sk.json')

		const requestUrl = ref(props.requestUrl);
		const token = ref(props.token);
		const store = useStore();
		const totalDocuments = ref('');
		const searchTableValue = store.state.tableSearchValueManagement;
		const tableRef = ref()
		const tabulator = ref()
		const filter = reactive({
			value: searchTableValue
		})
		const pageSize = ref(10);
		const tableIsLoading = ref(false);
		let displayFindUser = computed(() => {
			if ((userGroups[store.state.currentUserData.data.group].accessType === "SUPPLIER" && !(userGroups.allUsersNames.includes(store.state.currentUserData.data.group)))
				|| userGroups.optipostNames.includes(store.state.currentUserData.data.group)) {
				return true
			}

			return false
		})

		watch(() => props.token, (newVal) => {
			token.value = newVal;
		});
		watch(() => store.getters.getLocale, function () {
			tabulator.value.setLocale(store.getters.getLocale);
		});

		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value, {

				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "GET",
					headers: {
						'Authorization': 'bearer ' + store.state.token,
						'Accept': 'application/json',
					},
				},
				ajaxResponse: function (url, params, response) {
					let data = [];

					let res = response.data.users;
					totalDocuments.value = response.data.total

					for (let item of res) {
						let lastLogin = '-';
						if (item.lastLogin !== undefined) {
							lastLogin = moment(item.lastLogin).format("DD/MM/YYYY");
						}
						data.push({
							email: item.email,
							role: item.group,
							createdAt: item.createdOn,
							lastLogin: lastLogin,
							userData: item,
							viewUserTooltip: i18n.t('tooltips.viewUser'),
							editUserTooltip: i18n.t('tooltips.editUser'),
							noEmailTooltip: i18n.t('tooltips.noEmail'),
							onlyNymdropTooltip: i18n.t('tooltips.onlyNymdrop'),
							manageAuthorizationTooltip: i18n.t('tooltips.manageAuthorization'),
							resetPasswordTooltip: i18n.t('tooltips.resetPassword'),
							deleteUserTooltip: i18n.t('tooltips.deleteUser'),
							notAvailableTooltip: i18n.t('tooltips.notAvailable'),
						})
					}

					let last_page = Math.ceil(response.data.total / pageSize.value);
					let formattedData = {
						last_page: last_page,
						data: data,
					}
					store.commit("setTablePage", {field: "managementUsers", pageNo: tabulator.value.getPage()})
					return formattedData; //return the tableData property of a response json object
				},
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
					filter: "quick"
				},
				ajaxURLGenerator: function (url, config, params) {
					updateToken();
					let filters = this.getFilters();
					let search = store.state.tableSearchValueManagement;
					if (filters.length > 0) {
						params.quick = filters[0]["value"];
						search = store.state.tableSearchValueManagement;
					}
					let sortField = '';
					let order = '';
					if (params.sort?.length > 0) {
						let sortFields = {
							email: 'EMAIL',
							role: 'GROUP',
							createdAt: 'CREATED_ON',
							lastLogin: 'LAST_LOGIN',
						};
						params.sortOn = {
							order: params.sort[0].dir.toUpperCase(),
							sortField: sortFields[params.sort[0].field]
						}
						store.commit('setTableSorters',
							{
								place: 'users',
								order: params.sort[0].dir.toUpperCase(),
								sortField: sortFields[params.sort[0].field].toUpperCase()
							});

						if (store.state.tableSorters[0].order) {
							sortField = store.state.tableSorters[0].sortField;
							order = store.state.tableSorters[0].order;
						}
					}

					let proceededSearch = search.replace(/\+/g, '%2B');
					let role = store.state.extendedSearchValue.extendedSearch.role
					if (role === undefined) role = ''
					return url + "?size=" + pageSize.value + "&from=" + (params.pageNo - 1) * 10 + "&filter=" + proceededSearch + "&field=" + sortField + "&order=" + order + "&group=" + role;

				},
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				sortMode: "remote",
				paginationMode: 'remote',
				paginationInitialPage: store.state.tableInitialPage.mandates,
				paginationSize: pageSize.value,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: [
					// For HTML table
					{
						title: 'E-MAIL',
						field: 'email',

						formatter(cell) {
							let email = cell.getData().email ? cell.getData().email : cell.getData().userData.username;
							return `<div  class="font-medium whitespace-nowrap cell-font cell-style">
                          				${email}
                    				</div>`
						}
					},
					{
						title: 'ROLE',
						field: 'role',
						cssClass: "text-centered",
						hozAlign: "center",
						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">

                             ${cell.getData().role}
                        </div>
                    </div>`
						}
					},
					{
						title: 'CREATED AT',
						field: 'createdAt',
						cssClass: "text-centered",
						hozAlign: "center",
						formatter(cell) {
							let date = (moment(cell.getData().createdAt).format("DD/MM/YYYY"));
							const a = cash(
								`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          <div>
                          ${date}
                        </div>
                    </div>`
							)
							return a[0];
						}
					},
					{
						title: 'LAST LOGIN',
						field: 'lastLogin',
						cssClass: "text-centered",
						formatter(cell) {
							let date = cell.getData().lastLogin;
							return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          <div>
                               ${date}
                          </div>
                    </div>`
						}
					},


					{
						title: 'EDIT USER',
						field: 'editUser',

						width: 70,
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {

							if (cell.getData().email !== store.state.currentUserData.data.email && cell.getData().email !== "") {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                           <div class="tooltip-outer">
                                                 <i class="icon-pencil icon-color">

                                                  </i>
                                                  <span class="tooltip-inner edit-user">${cell.getData().editUserTooltip}</span>

                                            </div>


                                    </div> `);
								cash(a).on('click', function () {
									store.dispatch("updateToken");
									store.commit('setUserData', cell.getData().userData);
									store.commit('setPageYScrollPosition', window.pageYOffset)
									store.commit('displayEditUser', true);
									store.commit('setTableSearchValueManagement', filter.value);

								})
								return a[0];
							} else {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                           <div class="tooltip-outer">
                                                 <i class="icon-pencil icon-color-disable">

                                                  </i>
                                                  <span class="tooltip-inner no-email">${cell.getData().noEmailTooltip}</span>
                                            </div>


                                    </div> `);

								return a[0];
							}


						}
					},
					{
						title: 'MANAGE USER',
						field: 'manageUser',
						width: 70,
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							if (store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig
								&& !store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
								&& !store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
								&& store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
							) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center" >

                                             <div class="tooltip-outer">
                                                 <i class="icon-cog  icon-color-disable">

                                                  </i>
                                                  <span class="tooltip-inner only-nymdrop">${cell.getData().onlyNymdropTooltip}</span>

                                            </div>


                                    </div> `);

								return a[0];
							}
							if (cell.getData().role === 'ReceiverUser'
								|| cell.getData().role === 'ReceiverAdmin'
								|| cell.getData().role === 'SupplierSubAdmin'
								|| cell.getData().role === 'SupplierSubUser'
								|| cell.getData().role === 'SupplierLimitedSubUser'

							) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">

                                             <div class="tooltip-outer">
                                                 <i class="icon-cog  icon-color icon-color">

                                                  </i>
                                                  <span class="tooltip-inner manage-authorization">${cell.getData().manageAuthorizationTooltip}</span>

                                            </div>


                                    </div> `);
								cash(a).on('click', function () {
									store.dispatch("updateToken");
									store.commit('setUserData', cell.getData().userData);
									store.commit('setPageYScrollPosition', window.pageYOffset)
									store.commit('displayManageUser', true);
									store.commit('setTableSearchValueManagement', filter.value);

								})
								return a[0];
							} else {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center" >

                                             <div class="tooltip-outer">
                                                 <i class="icon-cog  icon-color-disable">

                                                  </i>
                                                  <span class="tooltip-inner not-available">${cell.getData().notAvailableTooltip}</span>

                                            </div>


                                    </div> `);

								return a[0];
							}

						}
					},
					{
						title: 'RESET PASSWORD',
						field: 'resetPassword',
						width: 70,
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							if (cell.getData().email !== store.state.currentUserData.data.email && cell.getData().email) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">

                                             <div class="tooltip-outer">
                                                 <i class="icon-key icon-color ">

                                                  </i>
                                                  <span class="tooltip-inner reset-password">${cell.getData().resetPasswordTooltip}</span>

                                            </div>

                                    </div> `);
								cash(a).on('click', function () {
									store.dispatch("updateToken");
									store.commit('resetLoggedInUserPassword', false);
									store.commit('setUserData', cell.getData().userData)
									store.commit('displayResetPassword', true);
									store.commit('setTableSearchValueManagement', filter.value)
								})
								return a[0];
							} else {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">

                                             <div class="tooltip-outer">
                                                 <i class="icon-key icon-color-disable">

                                                  </i>
                                                  
                                                    <span class="tooltip-inner no-email">${cell.getData().noEmailTooltip}</span>
                                                    
                                            </div>

                                    </div> `);

								return a[0];
							}

						}
					},
					{
						title: 'DELETE USER',
						field: 'deleteUser',
						width: 70,
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							if (cell.getData().email !== store.state.currentUserData.data.email) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                           <div class="tooltip-outer">
                                                 <i class="icon-bin icon-color ">

                                                  </i>
                                                  <span class="tooltip-inner delete-user">${cell.getData().deleteUserTooltip}</span>

                                            </div>


                                    </div> `);
								cash(a).on('click', function () {
									store.dispatch("updateToken");
									store.commit('setUserData', cell.getData().userData);
									store.commit('setPageYScrollPosition', window.pageYOffset);
									store.commit('displayDeleteUser', true);
									store.commit('setTableSearchValueManagement', filter.value)
								})
								return a[0];
							} else {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                           <div class="tooltip-outer">
                                                 <i class="icon-bin icon-color-disable">

                                                  </i>
                                                  <!--
                                                  <span class="tooltip-inner delete-user">${cell.getData().deleteUserTooltip}</span>
                                                  -->

                                            </div>


                                    </div> `);

								return a[0];
							}

						}
					},
				],
			})
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})

		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const tokenIsExpired = () => {
			let exp;
			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
			}
			let now = parseInt(new Date().getTime() / 1000);
			if (exp - now <= 0) {
				return true
			} else {
				return false
			}
		}
		const updateToken = () => {
			if (tokenIsExpired()) {
				let payload = {
					client_id: env.keycloakClient,
					grant_type: "refresh_token",
					refresh_token: store.state.refreshToken
				}
				let body = qs.stringify(payload);
				axios.post(store.state.API.keycloakToken,
					body,
					{
						headers: {
							'Content-Type': "application/x-www-form-urlencoded",
						}
					}
				)
					.then(res => {
						store.commit('setToken', res.data.access_token);
						store.commit('setRefreshToken', res.data.refresh_token);
						initTabulator();

					})
					.catch(() => {
						store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
						store.commit('displayNotification', true);
					})
			}


		}
		const refreshAfterCancel = () => {
			initTabulator(tabulator.value.getPage());
		}
		const refreshTableForSearch = () => {
			initTabulator()
		}
		const refreshTable = () => {
			if (!store.state.userDataUpdated) {
				setTimeout(() => {
					refreshTable()
				}, 100)
				return
			}
			initTabulator(tabulator.value.getPage());
			store.state.userDataUpdated = false;
		}
		const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
		}
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw()
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}
		const onFilter = () => {
			tabulator.value.setLocale(store.getters.getLocale);
			store.commit('setTableSorters', [{}]);
			store.commit("setTablePage", {field: "mandates", pageNo: 1})
			store.commit('setTableSearchValueManagement', filter.value);
			tabulator.value.setFilter(store.state.tableSearchValueManagement);
			initTabulator()
		}
		const onResetFilter = () => {
			filter.value = ''
			store.state.tableSearchValueManagement = ''
			store.state.extendedSearchValue.extendedSearch.role = ''
			initTabulator()
		}
		const openFindUser = () => {
			store.dispatch("updateToken");
			store.commit('displayFindUser', true);
		}
		const exportUsers = () => {
			store.dispatch("updateToken");
			store.dispatch('users/exportUsers', {token: token});

		}
		const openInviteUser = () => {
			store.dispatch("updateToken");
			store.commit('displayInviteUser', true);
		}

		onMounted(() => {
			store.commit("setTablePage", {field: "mandates", pageNo: 1})
			store.commit('setTableSorters', [{}])
			initTabulator();
			reInitOnResizeWindow();
			setLocale();
		})

		watch(() => store.state.dataManagementExtendedSearch, () => {
			refreshTable()
		})
		return {
			tableRef,
			filter,
			onFilter,
			onResetFilter,
			totalDocuments,
			tabulator,
			openInviteUser,
			exportUsers,
			openFindUser,
			refreshTable,
			refreshTableForSearch,
			refreshAfterCancel,
			displayFindUser,
		}
	}
})
</script>

<style scoped>

.table-report img {
	border: none;
}

.table-report img {
	box-shadow: none !important;
}

#tabulator-html-filter-value {
	width: 400px;
}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	padding-top: var(--generalPaddings);
}

.add-new-user {
	justify-self: end;
}

.right-buttons-outer {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 100%;
	grid-auto-flow: column;
	grid-column-gap: 10px;
	justify-content: end;
}

.add-new-user {
	justify-self: end;
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-50);
	font-size: 16px;
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-50);
	font-size: 16px;
}

.tooltip-inner {
	display: none;
	position: fixed;
	z-index: 420;
	padding: 4px;
	margin-left: -50px;
	border-radius: 3px;
	color: var(--primaryBgColor);
	background-color: var(--fontColor);
}

.tooltip-outer:hover .tooltip-inner {
	display: block;
}

.tabulator-col[tabulator-field=viewUser] .tabulator-col-title,
.tabulator-col[tabulator-field=editUser] .tabulator-col-title,
.tabulator-col[tabulator-field=manageUser] .tabulator-col-title,
.tabulator-col[tabulator-field=resetPassword] .tabulator-col-title,
.tabulator-col[tabulator-field=deleteUser] .tabulator-col-title {
	display: none;
}

@media only screen and (min-width: 0px) and (max-width: 650px) {
	.cell-style {
		font-size: 14px !important;
	}
}

@media only screen and (min-width: 501px) and (max-width: 925px) {
	.right-buttons-outer, .right-buttons-outer button {
		height: 38px;
	}
}

@media only screen and (min-width: 670px) and (max-width: 925px) {
	#tabulator-html-filter-value {
		width: 50vw;
	}
}

@media only screen and (max-width: 669px) {
	#tabulator-html-filter-value {
		width: 40vw;
	}
}

@media only screen and (max-width: 925px) {
	.right-buttons-outer {
		grid-column: 1/3;
		grid-row: 2/3;
		justify-self: end;
		align-content: start;
		height: 38px;
		margin-top: 10px;
		margin-right: 10px;
	}
}

.filter-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.extended-search {
	display: flex;
	flex-direction: row;
	width: 400px
}

@media only screen and (max-width: 1030px) {
	.filter-container {
		display: flex;
		flex-direction: column;
	}

	.right-buttons-outer {
		margin-top: 8px;
		justify-content: start;
	}
}
@media only screen and (max-width: 739px) {
	.extended-search {
		display: flex;
		flex-direction: column;
	}
	.add-margin-small-screen {
		margin-top: 5px
	}
}
</style>