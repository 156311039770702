<template>
    <transition name="dialog">
        <page-container class="page-content onboarding">
            <div>
                <top-banner>
                    <div class="w-9/12">
                        <h1 class="text-3xl text-center">{{ $t('onboarding.header.title') }}</h1>
                    </div>
                </top-banner>
                <div class="box page-content intro-y box p-5 mt-5">
                    <div class="content-container">
                        <h1 class="text-center">{{this.successfulText1}}</h1>
                    </div>
                </div>
            </div>
        </page-container>
    </transition>
</template>

<script>
import TopBanner from "../components/TopBanner";
import PageContainer from "../components/PageContainer";
import i18n from '../i18n'
import textEN from "../locales/en.json";
import textFR from "../locales/fr.json";
import textNL from "../locales/nl.json";
import textDE from "../locales/de.json";

export default {
    name: "OnboardingSuccessful",
    components: {TopBanner, PageContainer},
    mounted() {
        this.setUserChoiceTranslation();
    },
    data() {
        return {
            i18n : i18n,
            userChoice: '',
            descriptionText : '',
            successfulTexts:{
                en: textEN.onboarding.texts,
                fr: textFR.onboarding.texts,
                nl: textNL.onboarding.texts,
                de: textDE.onboarding.texts,
            },
            successfulText1 : ''
        }
    },
    computed: {
        locale() {
            return this.$store.state.locale
        },
        successfulData(){
            return this.$store.state.onboarding.successfulData
        }
    },
    watch: {
        locale() {
            this.setUserChoiceTranslation();
        },
        successfulData(){
            this.setUserChoiceTranslation();
        }

    },

    methods: {
        setUserChoiceTranslation(){
            if (this.$store.state.locale !== ''){
                let successfulText = this.successfulTexts[this.$store.state.locale][this.$store.state.onboarding.successfulTextField];
                let userChoice = '';
                let amount = '';
                if (this.$store.state.onboarding.successfulData.mandateOnboardingInfo){
                    if (this.$store.state.onboarding.successfulData.mandateOnboardingInfo.choice === "YEARLY"){

                        userChoice = this.userChoice = i18n.t('onboarding.choiceType.yearly');
                        amount = this.$store.state.onboarding.successfulData.mandateOnboardingInfo.mandateOnboardingData.yearlyAmount;
                    } else {
                        userChoice = this.userChoice = i18n.t('onboarding.choiceType.monthly');
                        amount = this.$store.state.onboarding.successfulData.mandateOnboardingInfo.mandateOnboardingData.monthlyAmount;
                    }
                    successfulText = successfulText.replaceAll("INSERT_USER_NAME", this.$store.state.onboarding.successfulData.info.companyName).replaceAll('INSERT_USER_CHOICE', userChoice).replaceAll('INSERT_AMOUNT' ,amount);
                    this.successfulText1 = successfulText;
                }
            }
        }
    }
}
</script>

<style scoped>
.content-container{
    width: 60%;
    margin: 60px 20%;
}
.text-center{
    text-align: center;
}
.content-container h1{
    line-height: 2em;
    font-size: 20px;
}
@media only screen and (min-width: 600px) and (max-width: 900px){
    .content-container{
        width: 80%;
        margin: 40px 10%;
    }
    .content-container h1{
        line-height: 1.8em;
        font-size: 18px;
    }
}
@media only screen and (min-width: 00px) and (max-width: 599px){
    .content-container{
        width: 90%;
        margin: 20px 5%;
    }
    .content-container h1{
        line-height: 1.6em;
        font-size: 16px;
    }
}
</style>
