<template>
    <pop-up-window :show="this.$store.state.mandates.displayBankAccount"
                   :showClose=false
				   :stick-to-scroll-position="true"
	>
        <template #title>
            <h1 class="text-2xl">
                 {{ $t('management.mandates.bankAccountTitle') }}
            </h1>
        </template>
        <template #content>
            <div class="info-container">
                <div>
                    <div class="one-item">
                        <div>{{ $t('management.mandates.bankAccountInfo.bankAccountNumber') }}</div>
                        <div>{{ this.$store.state.mandates.mandateData.bankAccount }}</div>
                    </div>
                    <div class="one-item">
                        <div>{{ $t('management.mandates.bankAccountInfo.bic') }}</div>
                        <div>{{ this.$store.state.mandates.mandateData.bicCode }}</div>
                    </div>
                </div>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6 grey-top-border">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
                        {{ $t('buttons.cancel') }}
                    </button>
                </div>

            </div>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow";

export default {
    name: "BankAccount",
    components: {PopUpWindow},
    methods: {
        close() {
            this.$store.commit('displayBankAccount', false);
            this.$store.commit('setMandateData', {})
        },
    }
}
</script>

<style scoped>
.one-item {
    display: grid;
    grid-template-columns: 200px auto;
    grid-template-rows: auto;
}
.info-container{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
}
.info-container>div{
    width: 80%;
}
</style>
