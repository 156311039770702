<template>
    <transition name='dialog'>
        <div class="loading-container"
             v-if=this.$store.state.loadingNotification.display
        >
            <div class="loading-container-inner">
                <h2>{{ notificationText }}</h2>
                <div>
                    <the-spinner
                          :height40=true
                    >
                    </the-spinner>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import TheSpinner from "./TheSpinner";
import i18n from "../../i18n";

export default {
    name: "LoadingNotification",
    components: {TheSpinner},
    data() {
        return {
            i18n: i18n,
        }
    },
    computed: {
        notificationText() {
            if (this.$store.state.loadingNotification.type === 'mailErrors') {
                return i18n.t('general.loadingEmailReport');
            }
            if (this.$store.state.loadingNotification.type === 'channelDetails') {
                return i18n.t('general.loadingChannelDetailsReport');
            }
            return i18n.t('general.loading');
        },
    }
}
</script>

<style scoped>
.loading-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: end;
}
.loading-container-inner {
    display: grid;
    grid-template-rows: 40px;
    grid-template-columns: auto 40px;
    grid-auto-flow: row;
    align-items: center;
}

h2 {
    margin-right: 10px
}


.dialog-enter-active {
    transition: all 0.3s ease-out;
}

.dialog-leave-active {
    transition: all .3s ease-in;
}

.dialog-enter-from, .dialog-leave-to {
    transform: scale(1);
    opacity: 0;
}

</style>
