<template>
    <pop-up-window :show="this.$store.state.postDrop.displayEditDepartment"
                   :showClose=false
                   class="doc-statuses-popup"
                   additional-info=""
                   :positionFixed="true"
                   :top10="true"
                   :stickToScrollPosition="true"
                   :bottomDiv="false"
    >
        <template #title>
            <h1 class="text-2xl">
                {{ $t('postDrop.editDepartmentTitle') }}
            </h1>

        </template>
        <template #content>
            <div class="one-line">
                <h2 class="base-text header-text">{{ $t('postDrop.departmentName') }}:</h2>
                <input
                      id="name"
                      type="text"
                      class="form-control text-popup input-flex"
                      v-model="name"
                      @click="setErrors(false)"
                >
            </div>
            <div v-if="errors">
                <p class="error error-styling">
                    {{ $t('errors.emptyUser') }}
                </p>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6 grey-line">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
                        {{ $t('buttons.cancel') }}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="edit">
                        {{ $t('buttons.edit') }}
                    </button>
                </div>

            </div>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow";

export default {
    name: "EditDepartment",
    components: {PopUpWindow},

    data() {
        return {
            departmentId: '',
            name: '',
            errors: false,
        }
    },
    computed: {
        departmentData() {
            return this.$store.state.postDrop.departmentData
        }
    },
    watch: {
        departmentData(newVal) {
            this.setDepartmentData(newVal)
        }
    },
    methods: {
        edit() {
            if (this.name === '') {
                this.setErrors(true);
                return
            }
            let formData = {
                name: this.name,
                departmentId: this.departmentId
            }
            this.$store.dispatch("department/editDepartment", formData);
            this.close()
        },
        setErrors(value) {
            this.errors = value
        },
        setDepartmentData(data) {
            if (data.departmentId) {
                this.departmentId = data.departmentId;
                this.name = data.departmentName;
            }
        },
        close() {
            this.$store.commit('displayEditDepartment', false);
            this.$store.commit('setDepartmentData', {})
        }
    }
}
</script>

<style scoped>
.one-line {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 160px auto;
    align-items: center;
    margin-top: 10px;

}

@media only screen and (max-width: 550px) {
    .one-line {
        grid-template-columns: 140px auto;
    }
}

</style>
