const getters = {
	getLocale(state){
		return state.locale
	},
	getPrimaryColor: state => {
		return state.branding.primaryColor
	},

	getBannerData: state => {
		return state.bannerData
	},

	getDocumentDetailData: state => {
		return Object.assign({}, state.documentDetailData)
	}
}

export default getters
