const routerData = {
	home: {
		link: '/', //root folder for project, defines at vue.config.js
	},
	auth: {
		link: '/auth'
	},
	login: {
		link: '/login'
	},

	overview: {
		link: '/overview',
	},
	oldPortalMain: {
		link: '/:lang/documents',
	},
	proxyLogin: {
		link: '/:lang/proxy-login',
	},
	oldPortalTerms: {
		link: '/:lang/terms'
	},

	documentDetail: {
		link: '/overview/:docId',
	},
	processedDocument : {
		link : '/postDrop/:dodId'
	},
	documentDetailPublic: {
		link: '/:lang/documents/public/:docId'
	},
	onboarding: {
		link: '/:lang/onboarding/:accessCode'
	},
	postDrop: {
		link: '/postDrop'
	},
	resetPassword: {
		link: '/resetPassword'
	},
	saml: {
		link: '/saml'
	},
	channelChoice: {
		link: '/:lang/channel-choice/:accessCode'
	},
	onboardingAC: {
		link: '/:lang/onboardingAC'
	},
	channelChoiceAC: {
		link: '/:lang/channelChoiceAC'
	},
	createMandateOk:{
		link: '/:lang/mandate/ok/:code'
	},

	mandateOk:{
		link: '/:lang/mandate/ok/'
	},
	cancelMandateSuccessful : {
		link: '/cancelMandateSuccessful'
	},
	cancelMandateFail : {
		link: '/cancelMandateFail'
	},

	onboardingSuccessful: {
		link: '/onboardingSuccessful'
	},
	onboardingUnsuccessful: {
		link: '/onboardingUnsuccessful'
	},
	cancelMandateCreate: {
		link : '/:lang/:accessCode/cancel'
	},
	management: {
		link: '/management'
	},
	ingestion: {
		link: '/ingestion'
	},
	reports: {
		link: '/reports'
	},
	payments:{
		link: '/payments'
	},
	ubl:{
		link: '/ubl'
	},
	ublDetail: {
		link: '/ubl/:docId'
	},

	tos: {
		link: '/tos'
	},
	sgTos: {
		link: '/sgtos'
	},
	terms:{
		link:'/terms'
	},
	helpLang:{
		link: '/:lang/help'
	},
	help: {
		link: '/help'
	},
	errorPage: {
		link: '/errorPage'
	},
	noAccess: {
		link: '/noAccess'
	},
	notFound: {
		link: '/notFound(.*)'
	}
}
export default routerData;
