<template>
    <pop-up-window :show="this.$store.state.displayConfirmation"
                   :showClose=false
                   class="confirmation"
                   :positionFixed=true
                   :top0=true
                   fixed>
        <template #title>
            <h1 class="text-2xl ">
                {{ $t('confirmation.header') }}
            </h1>
        </template>
        <template #content>
            <h1 class="text-center">
                <i class="icon-notification info-color pr-2"></i>{{ description }}
            </h1>
            <div class="btn-content mt-2 mb-10 grey-line">
                <div>
					<button class="btn custom-button shadow-md mr-2 text-popup" @click="approveAction">
						{{ $t('buttons.yes') }}
					</button>
                    <button class="btn custom-button shadow-md mr-2 text-popup" @click="cancelAction">
                        {{ $t('buttons.no') }}
                    </button>
                </div>
            </div>
        </template>
    </pop-up-window>

</template>

<script>
import PopUpWindow from "./PopUpWindow";
import i18n from "../../i18n"

export default {
    name: "ConfirmationPopUp",
    components: {PopUpWindow},
    props: {
        type: {
            type: String,
            required: false,
            default: 'general'
        }
    },
    data() {
        return {
            i18n: i18n,
            description: '',
        }
    },
    computed: {
        locale(){
          return this.$store.state.locale
        },
        displayConfirmation() {
            return this.$store.state.displayConfirmation
        }
    },
    watch: {
        locale(){
            this.setDescription();
        },
        displayConfirmation(newVal) {
            if (newVal) {
                this.setDescription()
            }
        }
    },
    methods: {
        setDescription() {
            let key = 'confirmation.description.' + this.type
            this.description = i18n.t(key);
        },
        approveAction() {
            this.$emit('approveAction');
            this.$store.commit('setDisplayConfirmation', false);
        },
        cancelAction() {
            this.$store.commit('displayDeleteDepartment', false);
            this.$store.commit('setDepartmentData', {})
            this.$store.commit('setDisplayConfirmation', false);
        },
    }
}
</script>

<style scoped>
.confirmation {
    z-index: 300;
    position: fixed;
}

</style>
