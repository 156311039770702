<template>
    <pop-up-window :show="this.$store.state.postDrop.displayDeleteDepartmentUser"
                   :showClose=false
                   class="doc-statuses-popup"
                   additional-info=""
                   :positionFixed="true"
                   :top10 = "true"
                   :stickToScrollPosition = "true"
                   :bottomDiv = "false"
    >
        <template #title>
            <h1 class="text-2xl">
                {{ $t('postDrop.deleteDepartmentUserTitle') }}
            </h1>
            <h1>
                {{this.name}}
            </h1>
        </template>
        <template #content>
            <div class="one-line">
                <h2 class="base-text header-text">{{ $t('postDrop.selectUser') }}:</h2>
                <v-select
                      @click="setErrors(false)"
                      v-model="deleteUser"
                      :options=this.options
                      :clearable="false"
                >
                    <template #no-options="{search}">
                        {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                    </template>


                </v-select>
            </div>

            <div v-if="errors">
                <p class="error error-styling">
                    {{ $t('errors.emptyUser') }}
                </p>
            </div>

            <div class="grid grid-rows-1 justify-items-center my-6 grey-line">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
                        {{ $t('buttons.cancel') }}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="deleteUsers">
                        {{ $t('buttons.delete') }}
                    </button>
                </div>
            </div>
        </template>
    </pop-up-window>


</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow";
import i18n from "../../../i18n"

export default {
    name: "DeleteDepartmentUser",
    components: {PopUpWindow},
    data() {
        return {
            i18n : i18n,
            departmentId: '',
            name: "",
            departmentUsers: [],
            errors: false,
            deleteUser : {label: "", value : ""}
        }
    },

    computed: {
        departmentData() {
            return this.$store.state.postDrop.departmentData
        },
        options(){
            let options = [];
            this.departmentUsers.forEach(user=>{
                let tempItem = {
                    label: user.email,
                    value : user.id
                }
                options.push(tempItem)
            })

            return options
        },
    },
    watch: {
        departmentData(newVal) {
            this.setDepartmentData(newVal)
        }
    },

    methods: {
        setErrors(value) {
            this.errors = value
        },
        setDepartmentData(data) {
            if (data.departmentId) {
                this.departmentId = data.departmentId;
                this.name = data.departmentName;
                this.departmentUsers = data.users
            }
        },
        deleteUsers() {
            if (this.deleteUser.value === '') {
                this.setErrors(true);
                return
            }
                let formData = {
                    userId: this.deleteUser.value,
                    departmentId: this.departmentId
                };
                this.$store.dispatch("department/deleteDepartmentUser", formData);
            this.close();
        },
        close() {
            this.$store.commit('displayDeleteDepartmentUser', false);
            this.departmentId = '';
            this.departmentUsers = [];
            this.deleteUser = {label: "", value : ""};
            this.setErrors(false);
            this.$store.commit('setDepartmentData', {})
            this.$store.commit("setUsersDataForPostDrop", [])
        }
    }

}
</script>

<style scoped>
.one-line {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100px auto;
    align-items: center;
    margin-top: 10px;

}

</style>
