
<template>
	<vue-select
		v-model="extendedSearch.userId"
		:options="this.$store.state.supplierUsersList"
		:clearable="false"
		class="mb-2.5 mt-3.5"
	>
		<template #no-options="{search}">
			{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
		</template>
		<template #selected-option="option">
			{{option.value === '' ? $t(option.label) : option.label}}
		</template>
	</vue-select>
</template>

<script>
import VueSelect from "vue3-select";

export default {
	components: {VueSelect},
	data() {
		return {
			extendedSearch: {
				userId: {label: 'postDrop.selectUser', value: ''}
			}
		}
	},
	created() {
		if(this.$store.state.extendedSearchValue.extendedSearch.userId) this.extendedSearch.userId = this.$store.state.extendedSearchValue.extendedSearch.userId
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch.userId": {
			handler(newValue) {
				this.extendedSearch.userId = newValue
			},
			deep: true
		}
	},
}
</script>

<style scoped>

</style>