<template>
	<v-select
		v-model="extendedSearch.selectedRole"
		:options="rolesUsers"
		:clearable=false
		class="mb-2.5 mt-3.5"
		:placeholder="$t('extendedSearch.management.users.placeholderRoleDropdown')"
	>
	</v-select>
</template>
<script>

import userGroups from "@/userGoups";

export default {
	data() {
		return {
			extendedSearch: {
				selectedRole: ''
			}
		}
	},
	created() {
		this.extendedSearch.selectedRole = this.$store.state.extendedSearchValue.extendedSearch.role
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch.role": {
			handler(newValue){
				this.extendedSearch.selectedRole = newValue
			}
		}
	},
	computed: {
		rolesUsers() {
			let rolesUsers = []
			userGroups.supplierNames.forEach(name => {
				if (name === "SupplierLimitedSubUser") {
					if (this.$store.state.supplierGroup.data.supplierGroupId === "g4sbelgium"
						|| this.$store.state.supplierGroup.data.supplierGroupId === "g4sluxemburg") {
						rolesUsers.push(name)
					}
				} else {
					rolesUsers.push(name)
				}
			})
			userGroups.receiverNames.forEach(name => rolesUsers.push(name))
			userGroups.optipostNames.forEach(name => {
				if (this.$store.state.currentUserData.data.group !== "SupplierGroupSuperAdmin" && this.$store.state.currentUserData.data.group !== "OpHelpdesk") {
					rolesUsers.push(name)
				}
			})

			rolesUsers.sort((roleA, roleB) => {
				let firstRole = roleA.toLowerCase(), secondRole = roleB.toLowerCase();
				if (firstRole < secondRole) {
					return -1
				}
				if (firstRole > secondRole) {
					return 1
				}
				return 0
			})

			return rolesUsers
		}
	}
}
</script>