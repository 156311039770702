<template>
	<pop-up-window
		:show="this.$store.state.receiverManagement.displayAddReceiver"
		:showClose=false
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed=true
		:top10=true
		fixed
	>
		<template #title>
			<h1>{{ $t('management.addReceiver.title') }}</h1>
		</template>
		<template #content>
			<div>
				<div class="one-item">
					<h1>{{ $t('management.addReceiver.receiverId') }}</h1>
					<input
						type='text'
						v-model="formData.receiverId"
						@click='setErrors("receiverId", false)'
						class="form-control text-popup"
					>
				</div>
				<p v-if="errors.receiverId" class="error">{{ $t('errors.receiverId') }}</p>
				<div class="one-item">
					<h1>{{ $t('management.addReceiver.receiverName') }}</h1>
					<input
						type='text'
						v-model="formData.receiverName"
						class="form-control text-popup"
						@click='setErrors("receiverName", false)'
					>
					<p v-if="errors.receiverName" class="error">{{ $t('errors.receiverName') }}</p>
				</div>

				<div class="one-item">
					<h1>{{ $t('management.addReceiver.legalEntity') }}</h1>
					<v-select
						v-model="legalEntityId"
						:options='leOptions'
						@click='setErrors("legalEntity", false)'
					>
						<template #no-options="{search}">
							{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
						</template>
					</v-select>
				</div>
				<p v-if="errors.legalEntity" class="error">{{ $t('errors.choseLegalEntity') }}</p>

				<div v-if="showReleaseToggle" class="one-item">
					<h1>Hold release:</h1>
					<toggle-slide
						@update-value="updateHoldRelease"
						class="w-min"
						:bool="formData.holdRelease"
					>

					</toggle-slide>
				</div>
				<div v-if="this.showOverrideChannel" class="one-item">
					<h1>{{ $t('management.selectChannel') }}</h1>
					<v-select
						v-model="selectedChannels"
						:options='channelOptions'
						multiple=true
						@option:selected=setChannelOptions()
						@option:deselected=setChannelOptions()
					>
						<template #no-options="{search}">
							{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
						</template>
					</v-select>
				</div>
				<div class="ediInfo" v-if="displayEdiInfo">
					<div>
						<div class="one-item">
							<h1>{{ $t('management.ediInfo.companyIdentifierNumberType') }}</h1>
							<v-select
								v-model="companyIdentifierNumberType"
								:options='identifierNumberType'
								@click='setErrors("companyIdentifierNumberType", false)'
							>
								<template #no-options="{search}">
									{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
								</template>
							</v-select>
						</div>
						<p v-if="errors.companyIdentifierNumberType" class="error">
							{{ $t('errors.companyIdentifierNumberType') }}
						</p>
					</div>
					<div>
						<div class="one-item">
							<h1>{{ $t('management.ediInfo.companyIdentifierNumber') }}</h1>
							<input
								type='text'
								v-model.trim="companyIdentifierNumber"
								@click='setErrors("companyIdentifierNumber", false)'
								class="form-control text-popup"
							>
						</div>

						<p v-if="errors.companyIdentifierNumber" class="error">
							{{ $t('errors.companyIdentifierNumber') }}
						</p>
						<p v-if="errors.companyIdentifierNumberGeneral" class="error">
							{{ $t('errors.companyIdentifierNumberGeneral') }}
						</p>
					</div>
				</div>
				<div v-if="displayReceiverEmails">
					<email-language-form
						:input-existing-contacts="[]"
						:error-given-input="errors.givenMailInput"
						:languages="languages"
						ref="newEmails"
					></email-language-form>
					<p v-if="errors.atLeastOneEmail" class="error">{{ $t('errors.atLeastOneEmail') }}</p>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6 grey-line">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeAddReceiver">
						{{ $t('buttons.cancel') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="addReceiver">
						{{ $t('buttons.add') }}
					</button>
				</div>
			</div>
			<confirmation-with-props
				:description="descriptionConfirmDialog"
				:show-dialog="showConfirmDialog"
				@cancel-action="showConfirmDialog = false"
				@approve-action="confirmAddReceiver"
			></confirmation-with-props>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow"
import ToggleSlide from "@/components/UI/ToggleSlider.vue"
import EmailLanguageForm from "@/components/UI/EmailLanguageForm.vue"
import ConfirmationWithProps from "@/components/UI/ConfirmationWithProps.vue";
import i18n from "@/i18n";

export default {
	name: "AddReceiver",
	components: {ConfirmationWithProps, EmailLanguageForm, ToggleSlide, PopUpWindow},
	props: {
		leData: Array,
		channels: Array,
		ediChannelsAvailable: Array,
		companyIdentifierNumberTypes: Array
	},
	mounted() {
		this.setLeOptions()
		this.errors.givenMailInput.push(false)
	},
	data() {
		return {
			re: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),

			formData: {
				receiverId: '',
				supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
				legalEntityId: '',
				holdRelease: false,
				channelChoice: [],
				emails: {},
				ediInfo: {},
				receiverName: '',
			},
			legalEntityId: '',
			companyIdentifierNumberType: '',
			companyIdentifierNumber: '',
			selectedChannels: [],
			errors: {
				receiverId: false,
				legalEntity: false,
				companyIdentifierNumberType: false,
				companyIdentifierNumber: false,
				companyIdentifierNumberGeneral: false,
				atLeastOneEmail: false,
				receiverName: false,
				givenMailInput: []
			},
			showReleaseToggle: this.$store.state.supplierGroup.data.holdRelease,
			showOverrideChannel:  this.$store.state.supplierGroup.data.channelsConfig.docImage.channelChoice?.modifyChannel ? this.$store.state.supplierGroup.data.channelsConfig.docImage.channelChoice.modifyChannel: false,
			showConfirmDialog: false,
			confirm: {
				alreadyConfirmed: false,
			},
			displayReceiverEmails: false,
			newReceiverEmails: [],
			leOptions: [],
			ediChannels: this.ediChannelsAvailable,
			channelOptions: JSON.parse(JSON.stringify(this.channels)),
			channelOptionsAvailable: this.channels,
			identifierNumberType: [],
			identifierNumberTypeMatch: this.companyIdentifierNumberTypes,
		}
	},
	computed: {
		displayEdiInfo() {
			let isEdi = false
			this.selectedChannels.forEach(channel => {
				if (this.ediChannels.includes(channel.value)) {
					if (channel.value === 'Mercurius'
						|| channel.value === 'OpenPeppol'
						|| channel.value === 'Peppol'
						|| channel.value === 'Tradeshift'
						|| channel.value === 'Basware'
					) {
						this.identifierNumberType = [
							{label: 'KBO', value: "KBO"},
							{label: 'KVK', value: "KVK"},
							{label: 'GLN', value: "GLN"},
						]

						if (channel.value === 'OpenPeppol'
							|| channel.value === 'Peppol'
							|| channel.value === 'Basware'
						) {
							this.identifierNumberType.unshift({label: 'DUNS', value: "DUNS"})
							this.identifierNumberType.unshift({label: 'BE 9925', value: "BE_VAT"})
						}
					}
					if (channel.value === 'OpenPeppol' || channel.value === 'Peppol') {
						this.identifierNumberType.unshift({label: 'GENERAL', value: "GENERAL"})
					}
					if (channel.value === 'Eks') {
						this.identifierNumberType = [
							{label: 'GLN', value: "GLN"}
						]
					}
					if (channel.value === 'Ariba') {
						this.identifierNumberType = [
							{label: 'AN', value: "AN"}
						]
					}
					if (channel.value === 'Tungsten') {
						this.identifierNumberType = [
							{label: 'AAA', value: "AAA"}
						]
					}
					if (channel.value === 'ChorusPro') {
						this.identifierNumberType = [
							{label: 'SIRET', value: "SIRET"}
						]
					}
					isEdi = true
				}
			})
			return isEdi
		},
		descriptionConfirmDialog(){
			if (this.showReleaseToggle && this.showOverrideChannel === false && this.formData.holdRelease === false) {
				return i18n.t('confirmation.description.addNewClient.confirmHoldReleaseEmpty')
			}
			else if (this.showReleaseToggle === false && this.showOverrideChannel && this.formData.channelChoice <= 0) {
				return i18n.t('confirmation.description.addNewClient.confirmChannelChoseEmpty')
			}
			else {
				if (this.formData.holdRelease === false && this.formData.channelChoice.length <=0){
					return i18n.t('confirmation.description.addNewClient.confirmHoldReleaseAndChannelChoseEmpty')
				}
			}
			return ''
		},
		languages() {
			let languages = this.$store.state.supplierGroup.data.languages
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities
			if (legalEntities[this.legalEntityId.value]?.templates?.mail?.emailLanguages && legalEntities[this.legalEntityId.value]?.templates?.mail?.emailLanguages.length > 0) {
				languages = legalEntities[this.legalEntityId.value]?.templates?.mail?.emailLanguages && legalEntities[this.legalEntityId.value]?.templates?.mail?.emailLanguages
			} else if (this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages && this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages.length > 0) {
				languages = this.$store.state.supplierGroup.data.templates?.mail?.emailLanguages
			}
			languages.sort()
			return languages
		},
	},
	methods: {
		updateHoldRelease(data) {
			this.formData.holdRelease = data
		},
		openConfirmDialog(){
			if (this.formData.holdRelease === true || this.selectedChannels.length >= 1) {
				this.showConfirmDialog = false
				return
			}
			if (this.showReleaseToggle === false && this.showOverrideChannel === false ){
				this.showConfirmDialog = false
				return
			}
			if (this.showReleaseToggle && this.formData.holdRelease === false)this.showConfirmDialog = true
			else if (this.showOverrideChannel && this.selectedChannels.length <= 0) this.showConfirmDialog = true
			else if (this.formData.holdRelease === false && this.selectedChannels.length <=0){
					this.showConfirmDialog = true
			}
		},
		confirmAddReceiver(){
			this.confirm.alreadyConfirmed = true
			this.addReceiver()
		},
		addReceiver() {
			let errors = false
			if (this.formData.receiverId === "") {
				this.setErrors('receiverId', true)
				errors = true
			}
			if (this.formData.receiverName === "") {
				this.setErrors('receiverName', true)
				errors = true
			}
			if (this.legalEntityId === '') {
				this.setErrors('legalEntity', true)
				errors = true
			} else {
				this.formData.legalEntityId = this.legalEntityId.value
			}
			if (errors) return
			let selectedChannelChoices = []

			if (this.confirm.alreadyConfirmed === false){
				this.openConfirmDialog()
				if (this.showConfirmDialog){
					return
				}
			}

			this.selectedChannels.forEach(channel => {
				selectedChannelChoices.push(channel.value)
			})

			this.formData.channelChoice = selectedChannelChoices

			if (this.$refs.newEmails?.newContacts){
				this.$refs.newEmails.newContacts.forEach((_, index) => this.errors.givenMailInput[index] = false)
			}

			if (selectedChannelChoices.length > 0) {
				if (selectedChannelChoices.includes("ATTACH_EMAIL") || selectedChannelChoices.includes("LINK_EMAIL")) {
					let emailsInvalid = false
					if (!this.$refs.newEmails?.newContacts && this.$refs.newEmails.newContacts.length > 0) emailsInvalid = true
					else {
						this.$refs.newEmails.newContacts.forEach((receiver, index) => {
							let validEmail = this.re.test(receiver.email)
							if (receiver.email === '' || receiver.language === '' || !validEmail) {
								this.errors.givenMailInput[index] = true
								emailsInvalid = true
							}
						})

						this.$refs.newEmails.newContacts.forEach(receiver => {
							if (this.formData.emails[receiver.language]) {
								this.formData.emails[receiver.language].push(receiver.email)
							} else {
								if (receiver.language !== '') {
									this.formData.emails[receiver.language] = []
									this.formData.emails[receiver.language].push(receiver.email)
								}
							}
						})
					}
					if (emailsInvalid) {
						return
					}
				}
				let isEdiChannel = false
				let ediIndex = -1
				let channelProvider = ''
				selectedChannelChoices.forEach((channel, index) => {
					if (this.ediChannelsAvailable.includes(channel)) {
						isEdiChannel = true
						ediIndex = index
						channelProvider = channel
					}
				})

				if (isEdiChannel) {
					let errors = false
					if (this.companyIdentifierNumberType.value === '') {
						this.setErrors('companyIdentifierNumberType', true)
						errors = true
					}
					if (this.companyIdentifierNumber === '') {
						this.setErrors('companyIdentifierNumber', true)
						errors = true
					}
					if (errors) return
					if (this.companyIdentifierNumberType.value === "GENERAL") {
						if (this.companyIdentifierNumber !== '') {
							let checkErrors = this.companyIdentifierNumber.split(':')
							if (checkErrors[0].length !== 4) {
								this.setErrors('companyIdentifierNumberGeneral', true)
								return
							}
							if (checkErrors[1].length === 0) {
								this.setErrors('companyIdentifierNumberGeneral', true)
								return
							}
						}
					}

					this.formData.ediInfo = {
						companyIdentifierNumber: this.companyIdentifierNumber,
						companyIdentifierNumberType: this.companyIdentifierNumberType.value,
						channelProvider: channelProvider
					}
					this.formData.channelChoice[ediIndex] = "EDI"
				}
				if (!selectedChannelChoices.includes("EDI")) {
					delete this.formData.ediInfo
				}
				if (!(selectedChannelChoices.includes("ATTACH_EMAIL") || selectedChannelChoices.includes("LINK_EMAIL"))) {
					delete this.formData.emails
				}
			}
			//TODO: uncomment when Raymond made this field optional
			// if (this.formData.channelChoice.length === 0){
			// 	delete this.formData.channelChoice
			// }
			this.$store.dispatch('users/addReceiver', {
				formData: this.formData,
			})
			this.closeAddReceiver()
		},
		setChannelOptions() {
			let selectedChannels = []
			this.selectedChannels.forEach(channel => {
				selectedChannels.push(channel.value)
			})
			this.setChannelOptionsList(selectedChannels)
			this.setDisplayReceiverEmails(selectedChannels)
		},
		setChannelOptionsList(selectedChannels) {
			this.channelOptions = JSON.parse(JSON.stringify(this.channelOptionsAvailable))
			let removeIndexes = []

			if (selectedChannels.includes("ATTACH_EMAIL") || selectedChannels.includes("LINK_EMAIL")) {
				this.channelOptions.forEach((option, index) => {

					if (option.value === "ATTACH_EMAIL") {
						removeIndexes.push(index)
					}
					if (option.value === "LINK_EMAIL") {
						removeIndexes.push(index)
					}
				})
			}
			if (selectedChannels.includes("PRINT")) {
				this.channelOptions.forEach((option, index) => {
					if (option.value === "PRINT") {
						removeIndexes.push(index)
					}
				})
			}

			selectedChannels.forEach(channel => {
				let isEdiChannel = false
				let ediChannel = ''
				selectedChannels.forEach(channel => {
					if (this.ediChannels.includes(channel)) {
						isEdiChannel = true
						ediChannel = channel
					}
				})

				if (channel === "EDI" || isEdiChannel) {
					this.ediChannels.forEach((availableEdiChannel) => {
						this.channelOptions.forEach((option, index) => {
							if (option.value === availableEdiChannel) {
								removeIndexes.push(index)
							}
						})
					})
					if (isEdiChannel) {
						let identifierNumberTypeExistInList = false
						this.identifierNumberTypeMatch.forEach(item => {
							if (item.names.includes(ediChannel)) {
								identifierNumberTypeExistInList = item.identifierNumberTypes.includes(this.companyIdentifierNumberType.value)
							}
						})
						if (!identifierNumberTypeExistInList) {
							this.companyIdentifierNumberType = {value: '', label: ''}
						}
					}
				}
			})
			removeIndexes.sort((a, b) => {
				return a - b
			})
			let uniqueRemoveUIndexes = [...new Set(removeIndexes)].reverse()

			uniqueRemoveUIndexes.forEach(index => {
				this.channelOptions.splice(index, 1)
			})
		},

		setDisplayReceiverEmails(selectedChannels) {
			this.displayReceiverEmails = !!(selectedChannels.includes("LINK_EMAIL") || selectedChannels.includes("ATTACH_EMAIL"))
		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		closeAddReceiver() {
			this.formData = {
				receiverId: '',
				receiverName: '',
				supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
				legalEntityId: '',
				channelChoice: [],
				emails: {},
				holdRelease: false
			}
			this.legalEntityId = ''
			this.companyIdentifierNumberType = ''
			this.companyIdentifierNumber = ''
			this.selectedChannels = []
			this.showConfirmDialog = false
			this.confirm.alreadyConfirmed = false
			this.errors = {
				receiverId: false,
				legalEntity: false,
				companyIdentifierNumberType: false,
				companyIdentifierNumber: false,
				atLeastOneEmail: false,
				emails: false,
				receiverName: false,
				givenMailInput: []
			}
			this.displayReceiverEmails = false
			this.newReceiverEmails = []
			this.channelOptions = JSON.parse(JSON.stringify(this.channels))
			this.identifierNumberType = []
			this.$store.commit("setDisplayAddReceiver", false)
		},
		setLeOptions() {
			this.leData.forEach(le => {
				let name = le.name
				if (le.internalName) {
					name = le.internalName
				}
				let tempItem = {
					value: le.leId, label: name
				}
				this.leOptions.push(tempItem)
				this.leOptions = this.leOptions.sort((a,b) => {
					const textA = a.label.toUpperCase()
					const textB = b.label.toUpperCase()
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
				})
			})
		}
	}
}
</script>

<style scoped>
.one-item {
	display: grid;
	grid-template-columns: 40% 60%;
	grid-template-rows: 100%;
	align-items: center;
	margin-bottom: 20px;
}

h1 {
	font-weight: bolder;
	font-size: 14px;
	line-height: 1.5em;
}
</style>