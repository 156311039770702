<template>
    <div class="terms" v-if="this.$store.state.supplierGroup.isReady">
        <top-banner>
            <div class="w-9/12">
                <h1 class="text-3xl">{{ $t('termsConditions.title') }}</h1>
            </div>
        </top-banner>
        <div class="box terms-content">
            <div class="terms-info">
                <div class="divider">
                    <h1 class="header font-semibold title">{{$t('termsConditions.generalTermsAndConditions.title')}}</h1>
                    <h2 class="text ml-8">{{$t('termsConditions.generalTermsAndConditions.text') }}
                        <router-link :to=routerData.tos.link><span class="link">{{$t('general.link')}}</span></router-link>
                    </h2>
                </div>
                <div class="divider"  v-if="displaySgTos && locale">
                    <h1 class="header font-semibold  title">{{this.SgTosTitle}}</h1>
                    <div>
                        <div v-for="(sgTermItem, index) in SgTosData"
                             :key="index"
                        >
                            <div v-if="sgTermItem.location[locale]">
                                <h2 class="text ml-8">{{$t('termsConditions.sgTermsAndConditions.text') }}
                                    <span class="link">
                                    <a  class="text profile-link"
                                        target="_blank"

                                        :href="sgTermItem.location[locale].url">
                                        <i class="icon icon-file-text"></i>
                                        {{sgTermItem.location[locale].description}}
                                 </a>
                                </span>
                                </h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="divider"
                      v-if="displayLeTos && locale">
                    <h1 class="header font-semibold title">{{$t('termsConditions.leTermsAndConditions.title')}}</h1>
                    <div v-for="(leTermItem, index) in LeTosData"
                         :key="index"
                        class="ml-8">
                        <div v-if="leTermItem.location[this.$store.getters.getLocale.toUpperCase()]">
                            <div class="color-light">{{leTermItem.leName}}</div>
                            <h2 class="text ml-8 mb-4">
                                {{$t('termsConditions.sgTermsAndConditions.text') }}
                                <span  class="text link"
                                       @click="getLeTos({leName: leTermItem.leName,
                                                        index: leTermItem.index,
                                                        location : leTermItem.location[this.$store.getters.getLocale.toUpperCase()],
                                                        lang: this.$store.getters.getLocale.toUpperCase(),
                                                            legalEntityNumber: leTermItem.legalEntityNumber})"
                                >
                                <i class="icon icon-file-text"></i>
                                {{leTermItem.location[this.$store.getters.getLocale.toUpperCase()].description}}
                            </span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopBanner from "../components/TopBanner";
import routerData from "../routerData";
import textEN from "../locales/en.json";
import textNL from "../locales/nl.json";
import textFR from "../locales/fr.json";
import textDE from "../locales/de.json";

export default {
    name: "TermsPage",
    components: {TopBanner},
    mounted() {
        this.watchSupplierGroupId();
        this.checkDocType();

    },
    data() {
        return {
            supplierGroupId: false,
            routerData: routerData,
            SgTosData: [],
            LeTosData: [],
            SgTosTitleItems: {
                en: textEN.termsConditions.sgTermsAndConditions.title,
                fr: textFR.termsConditions.sgTermsAndConditions.title,
                nl: textNL.termsConditions.sgTermsAndConditions.title,
                de: textDE.termsConditions.sgTermsAndConditions.title,
            },
            SgTosTitle : '',
        }
    },
    computed:{
        locale(){
            return this.$store.state.locale.toUpperCase()
        },
        displaySgTos(){
            if ((this.$store.state.docType === "BILLING" && this.$store.state.supplierGroup.data.templates && this.$store.state.supplierGroup.data.templates.terms)){
                let displayTerms = false
                this.$store.state.supplierGroup.data.templates.terms.forEach(term =>{
                    if(term.display){
                        displayTerms = true
                        this.SgTosData.push(term)
                    }
                })
                if (displayTerms){
                    return true
                }
            }
           return false
        },
        displayLeTos(){

            if (this.$store.state.docType === "BILLING"){
                let legalEntities = this.$store.state.supplierGroup.data.supplierLegalEntities
                let displayLeTerms = false
                for (let le in legalEntities){
                    if(legalEntities[le].templates && legalEntities[le].templates.terms){
                        legalEntities[le].templates.terms.forEach((term, index) =>{
                            if (term.display){
                                displayLeTerms = true;
                                let tempItem = {
                                    location : term.location,
                                    leName: legalEntities[le].name,
                                    legalEntityNumber : legalEntities[le].entityNumber,
                                    index: index
                                }
                                this.LeTosData.push(tempItem);
                            }
                        })
                    }
                }
                if (displayLeTerms){
                    return true
                }
            }
            return false
        },
    },
    watch:{
        locale: function(newVal){
            this.locale = newVal;
            let SGName = this.$store.state.supplierGroup.data.name;
            this.SgTosTitle = this.SgTosTitleItems[this.$store.state.locale].replaceAll("INSERT_SG_NAME", SGName);
        }
    },

    methods: {
        checkDocType(){
            if (this.$store.state.docType === 'SALARY' || this.$store.state.docType === 'GENERAL' ){
                this.$router.push({ path: routerData.notFound.link })
            }
        },
        getLeTos(data){
            this.$store.dispatch('downloadLeTos', data);
        },
        watchSupplierGroupId() {
            if (!this.$store.state.supplierGroup.isReady) {
                setTimeout(() => {
                    this.watchSupplierGroupId()
                }, 100);
                return
            }
            if (this.$store.state.supplierGroup.data.sgType === "GENERAL") {
                this.docTypeGeneral = true;
            }
            this.supplierGroupId = this.$store.state.supplierGroup.data.supplierGroupId
        },
    }
}
</script>

<style scoped>
.terms {
    min-height: calc(100vh - var(--headerheight) - var(--footerHeight) - var(--generalPaddings));
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: var(--headerheight) calc(100% - var(--headerheight));
    grid-auto-flow: column;
}
.terms-content{
    margin: var(--generalPaddings) 0;
    padding:calc(2*var(--generalPaddings));
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    grid-auto-flow: column;
}
.terms-info{
}
.contact-items{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(10, auto);
    grid-auto-flow: column;
    row-gap: var(--generalPaddings);
    font-size: 1.3em;
    line-height: 1.5em;
}

.question-title{
    cursor: pointer;
}
.question-title:hover{
    color: var(--infoColor);
}
.title{
    margin-bottom: var(--generalPaddings);
}


.divider{

    margin-bottom: var(--generalPaddings);
    padding: var(--generalPaddings);
    border-bottom: 1px solid var(--theme-primary-100);

}
.user-guid-text{
    display: grid;
    grid-template-rows: auto 60px;
    grid-template-columns: 100%;
    grid-auto-flow: column;
}

.user-guid-text div button{
    margin: var(--generalPaddings) 0 ;
}

.portal-usage-text{
    text-align: justify;
    font-size: 1.2em;
    line-height: 1.4em;
}

.portal-usage-title{
    padding: var(--generalPaddings);
    text-align: justify;
}

.button-container{
    display: grid;
    grid-template-columns: 100%;
    grid-auto-flow: column;
    justify-items: center;
}
.header{
    font-size: 24px;
}
.text{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
@media only screen and (min-width: 651px) and (max-width: 950px){
    .text{
        font-size: 1.1rem;
        line-height: 1.75rem;
    }
    .header{
        font-size: 20px;
    }
    .terms-content{
        padding: calc(2*var(--generalPaddings));
    }
}
@media only screen and (min-width: 450px) and (max-width: 650px){
    .header{
        font-size: 18px;
    }
    .text{
        font-size: 1rem;
        line-height: 1.55rem;
    }
    .terms-content{
        padding: calc(1*var(--generalPaddings));
    }
}
@media only screen and (min-width: 0px) and (max-width: 451px){
    .header{
        font-size: 16px;
    }
    .text{
        font-size: 0.8rem;
        line-height: 1.25rem;
    }
    .terms-content{
        padding: calc(0.5*var(--generalPaddings));
    }
    .divider{
        padding: 10px;
    }
}
</style>
