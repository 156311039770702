
export default function getDocType(item, documentType) {
	let docType = ''

	if (item.documentType.toLowerCase() === "invoice" || item.documentType.toLowerCase() === 'credit_note'){
		docType = documentType
	}
	else if (documentType.toLowerCase() === 'general') {
		docType = item.generalDocumentData.generalType
	}
	else if (item.documentType.toLowerCase() === "salary") {
		if(item.salaryData.salaryType === "SALARY_SLIP"){
			//has to be camelCase, otherwise he won't understand it when he tries to find a translation
			return 'salarySlip'
		}
		else docType = item.salaryData.salaryType
	}
	return docType.toLowerCase().replace('_', '')

}