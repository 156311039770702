<template>
    <div>
        <error-notification>

        </error-notification>

        <!-- BEGIN: HTML Table Data -->
        <div class="intro-y box p-5 mt-5" id="tabulatorOuter">
            <div class="overflow-x-auto scrollbar-hidden">
                <div
                      id="tabulator"
                      ref="tableRef"
                      class="mt-5 table-report table-report--tabulator"
                ></div>
            </div>

        </div>
        <!-- END: HTML Table Data -->
    </div>
</template>

<script>
import moment from 'moment';
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import cash from "cash-dom";
import ErrorNotification from "../../UI/ErrorNotification.vue";
import i18n from "@/i18n";

export default defineComponent({
    components:{ErrorNotification},
    emits: ['update:modelValue'],
    props: {
        token: String,
        supplierGroupId: String,
        general: Boolean,
        salary: Boolean,
        billing: Boolean,
        requestUrl: String,
        modelValue: String,
        selectedLE: String,
        selectedReceivers: Array,
    },
    setup(props,) {
        const en = require('../../../locales/en.json')
        const nl = require('../../../locales/nl.json')
        const fr = require('../../../locales/fr.json')
        const de = require('../../../locales/de.json')
        const es = require('../../../locales/es.json')
        const sk = require('../../../locales/sk.json')
        const store = useStore();

        const selectedDocuments = ref([]);
        const requestUrl = ref(props.requestUrl);
        const token = ref(props.token);
        const supplierGroupId = ref(props.supplierGroupId);
        const selectedLE = ref(props.selectedLE);
        const selectedReceivers = ref(props.selectedReceivers);
        const totalDocuments = ref('');
        const searchTableValue = store.state.tableSearchValue;
        const tableRef = ref()
        const tabulator = ref()
		const tableIsLoading = ref(false);
        const filter = reactive({
            value: searchTableValue
        })
        const pageSize = ref(10);
        let displayButton = computed( () =>{
            if (store.state.docType === "SALARY" || store.state.docType === "GENERAL"){
                return false
            }
            return true
        })

        watch(() => props.token, (newVal) => {
            initTabulator(tabulator.value.getPage());
            token.value = newVal;
        });
        watch(() => props.selectedReceivers, (newVal) => {
            selectedReceivers.value = newVal;
            initTabulator();
        });
        watch(() => props.selectedLE, (newVal) => {
            selectedLE.value = newVal;
            initTabulator();
        });
        watch(()=>store.getters.getLocale, function() {
            tabulator.value.setLocale(store.getters.getLocale);
        });


        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: requestUrl.value,
                ajaxConfig: {
                    method: "POST",
                    headers: {
                        'Authorization': 'bearer ' + store.state.token,
                        'Accept': 'application/json',
                    },
                },
                ajaxParams: {
                    pageNo: 1,
                    pageSize: pageSize.value,
                    supplierGroupId: supplierGroupId.value,
                    legalEntityId: selectedLE.value,
                    receiverIds: selectedReceivers.value,
                },
                ajaxResponse: function (url, params, response) {
                    let data = [];
                    const res = response.data.users;
                    for (let item of res) {
                        let tempItem = {
                            supplierGroupId: item.supplierGroupId,
                            id : item.id,
                            userData: item,
                            email : item.email,
                            firstName : item.firstName ? item.firstName : "-",
                            language : item.language,
                            lastName : item.lastName ? item.lastName : "-",
                            legalEntities: item.legalEntities,
                            role:  item.group,
                            lastLogin : item.lastLogin ? moment(item.lastLogin).format('DD/MM/YYYY') : '-',
                            createdOn : moment(item.createdOn).format('DD/MM/YYYY')
                        }
                        data.push(tempItem);
                    }
                    let last_page = Math.ceil(response.data.numResults / pageSize.value);
					return {
						last_page: last_page,
						data: data,
					}
                },
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
					filter: "quick",
				},
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
                ajaxFiltering: true,
                printAsHtml: true,
                printStyled: true,
				pagination: true,
				paginationMode: 'remote',
                paginationSize: pageSize.value,
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: i18n.t('placeholders.noMatchingFound'),
                locale: true,
                langs: {
                    "en":  en,
                    "nl":  nl,
                    "de" : de,
                    "fr" : fr,
                    "sk" : sk,
                    "es" : es,
                },
                columns: [
                    {
                        title: 'E-MAIL',
                        field: 'email',
                        minWidth: 250,
                        headerSort: false,
                        formatter(cell) {
                            let email = cell.getData().email ? cell.getData().email : cell.getData().userData.username;
                            return `<div  class="font-medium whitespace-nowrap cell-font cell-style">
                          ${email}
                    </div>`
                        }
                    },
                    {
                        title: 'Roles',
                        field: 'role',
                        minWidth: 120,
                        headerSort: false,
                        cssClass: "text-centered",
                        hozAlign: "center",
                        formatter(cell) {
                            return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                             ${cell.getData().role}
                        </div>
                    </div>`
                        }
                    },
                    {
                        title: 'LANGUAGE',
                        field: 'language',
                        minWidth: 80,
                        headerSort: false,
                        cssClass: "text-centered",
                        hozAlign: "center",
                        formatter(cell) {
                            let date = (cell.getData().language);
                            const a = cash(
                                  `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          <div>
                          ${date}
                        </div>
                    </div>`
                            )
                            return a[0];
                        }
                    },
                    {
                        title: 'CREATED AT',
                        field: 'createdAt',
                        minWidth: 80,
                        headerSort: false,
                        cssClass: "text-centered",
                        hozAlign: "center",
                        formatter(cell) {
                            let date = (cell.getData().createdOn);
                            const a = cash(
                                  `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          <div>
                          ${date}
                        </div>
                    </div>`
                            )
                            return a[0];
                        }
                    },
                    {
                        title: 'LAST LOGIN',
                        field: 'lastLogin',
                        minWidth: 80,
                        headerSort: false,
                        cssClass: "text-centered",
                        hozAlign: "center",
                        formatter(cell) {
                            let date = (cell.getData().lastLogin);
                            const a = cash(
                                  `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          <div>
                          ${date}
                        </div>
                    </div>`
                            )
                            return a[0];
                        }
                    },
                ],
            })
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', ()=> {
				tableIsLoading.value = true
			})
        }
		watch(tableIsLoading, () => {
			if(tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
        const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
        }
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {

                tabulator.value.redraw();

                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }
        const onFilter = () => {
            selectedDocuments.value=[]
            document.getElementById('selectAllDocuments').checked = false; //to clean selectAll checkBox

            store.commit('tableSearchValue', filter.value); //to save search valeu on keyUp


            tabulator.value.setFilter(filter.value);

            tabulator.value.redraw();

        }
        const onResetFilter = () => {
            filter.value = ''
            onFilter();
            tabulator.value.redraw();
        }
        const getUsers = () =>{
            initTabulator();
        }
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }
        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }
        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }
        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {
            initTabulator();
            reInitOnResizeWindow();
            setLocale();
        })

        return {
            displayButton,
            tableRef,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            selectedDocuments,
            totalDocuments,
            tabulator,
            getUsers,
        }
    }
})
</script>

<style scoped>
.tabulator-arrow {
    display: none;
}
.tabulator-col-title {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}
.leg-entity-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-columns: 80px auto;
    grid-auto-flow: column;
}
.leg-entity-container img {
    padding-right: var(--generalPaddings);
}
.leg-entity-container .table-report img {
    border: none;
}
.leg-entity-container .table-report img, .table-image {
    box-shadow: none !important;
}
.legEntityColumnHeader {
    padding-left: 40px
}
#tabulator-html-filter-value {
    width: 400px;

}
.totalNumberDocuments{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: end;
    padding-top: var(--generalPaddings);
}
#selectAllDocuments{
    justify-self: center;
}
.buttons-container{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    grid-column-gap: var(--generalPaddings);
}
.search{
    display: none !important;
}
.tabulator-buttons-container{
    display: grid;
    grid-template-columns: 90% 10%;
    grid-auto-flow: column;
    align-items: center;
}

.tabulator-search-container{
    display: grid;
    grid-template-columns: 400px repeat(5, auto);
    grid-template-rows: 100%;
    grid-auto-flow: column;
    grid-column-gap: 5px;
}
.download{
    justify-self: end;
}

.buttons-container{
    align-self: center;
    justify-self: start;
}
.col-header{
    text-align: center;
}


.arrow-up:after{
    content: ' \2191';
}

.arrow-down:after{
    content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i{
    display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting{
    display: inline-block;
    color:var(--theme-primary-50);
    font-size: 16px;
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up{
    display: inline-block;
    color:var(--theme-primary-50);
    font-size: 16px;
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down{
    display: inline-block;
    color:var(--theme-primary-50);
    font-size: 16px;
}

.download-outer{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-auto-flow: column;
    justify-items: end;

}
.download-outer button{
    margin-left: 5px;
}
.icon-coin-euro{
    font-size: 24px;
}
.icon-coin-euro:hover{
    cursor: pointer;
    color:  var(--infoColor);

}
.download-buttons{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: auto auto;
}
.download-buttons .btn{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding: 0;
}
.button-text{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.download-buttons .btn span{
    grid-column: 1/2;
    grid-row: 1/2;
}
.download-buttons .btn:disabled:hover .tooltip{

    display: block;

}
.tooltip{
    display: none;
    position: relative;
    top: 20px;
    background-color: var(--theme-primary-500);
    color: white;
    border-radius: 5px;
    font-size: 12px;


}

.display-none{
    display: none;
}
@media only screen and (max-width: 800px) and (max-width: 670px){
    #tabulator-html-filter-value {
        width: 50vw;
    }
    .tabulator-search-container{
        grid-template-columns: 50vw repeat(5, auto);
    }

}

@media only screen and (max-width: 669px){
    #tabulator-html-filter-value {
        width: 40vw;
    }
    .tabulator-search-container{
        grid-template-columns: 40vw repeat(5, auto);
    }

}
</style>
