<template>
    <div id="app">
        <the-dialog :show="!this.$store.state.supplierGroup.isReady && displayLoader" title="Please wait..." fixed>
            <the-spinner></the-spinner>
        </the-dialog>
		<div v-if="this.$store.state.supplierGroup.isReady">
			<general-notification>
			</general-notification>
			<transition name='dialog'>
				<main-container>
				</main-container>
			</transition>
			<reset-own-password></reset-own-password>
			<extra-info></extra-info>
		</div>
    </div>
</template>
<script>

import TheSpinner from "./components/UI/TheSpinner";
import TheDialog from "./components/UI/TheDialog";
import MainContainer from "./components/MainContainer";
import i18n from './i18n'
import routerData from "./routerData";
import GeneralNotification from "./components/UI/GeneralNotification";
import store from "./store";
import ResetOwnPassword from "./components/UI/ResetOwnPassword";
import ExtraInfo from "./components/UI/ExtraInfo";

const newLoginPage = window.newLoginPage
const proxyToken = localStorage.getItem("proxyToken")

export default {
    name: 'App',
    components: {
        ExtraInfo,
        ResetOwnPassword,
        GeneralNotification,
        TheDialog,
        TheSpinner,
        MainContainer
    },
    created() {
        this.getToken();
        this.getSGInfo();
		localStorage.setItem("saml", "false")
    },
    data() {
        return {
            activeKeycloak: this.keycloak,
            primaryColor: this.$store.getters.getPrimaryColor,
            i18n: i18n,
            routerData: routerData,
        }
    },
    computed: {
        checkTokenICurrent(){
          return  this.$store.state.checkTokenICurrent;
        },
        tablePlaceHolder() {
            return this.$store.state.tableSearchPlaceHolder
        },
        displayLoader() {
            return !(this.$route.name === 'saml' || this.$route.name === undefined);
        },
        token() {
            return this.$store.state.token
        },
        refreshToken() {
            return this.$store.state.refreshToken
        },
        rootToken() {
            return this.$root.token;
        },

        currentUserLanguage() {
            return this.$store.state.currentUserData.data.language
        },
    },

    watch: {
        checkTokenICurrent(newVal){
            if (newVal === this.$store.state.checkTokenIMax){
                clearInterval(window.keepTokenAlive)
            }
        },
        currentUser(newVal) {
            this.$store.commit('setLocale', newVal.toLowerCase());
        },
        token() {
        },
        refreshToken(newVal) {
            localStorage.setItem("vue-refresh-token", newVal);
        },
        rootToken(newVal) {
            this.$store.state.token = newVal;
        },
    },
    methods: {
        getToken() {
            this.$store.state.token = this.$root.token;
        },
        getBrandingOrSgId() {
          if(this.$store.state.supplierGroup.data.brandingId) {
            return this.$store.state.supplierGroup.data.brandingId
          } else {
            return this.$store.state.supplierGroup.data.supplierGroupId
          }
        },
        async getSGInfo() {
            try {
                if ((!(newLoginPage || proxyToken) && this.$root.keycloak.authenticated)) {
                    store.commit('setUserAuth', true);
                    store.commit('setToken', this.$root.keycloak.token);
                    store.commit('setRefreshToken', this.$root.keycloak.refreshToken);
                }
                await this.$store.dispatch('fetchSupplierGroupData');
                await this.$store.dispatch('fetchSupplierGroupBranding', {
                    sgId: this.getBrandingOrSgId()
                });

                if (this.$store.state.userIsAuthenticated || (!(newLoginPage || proxyToken) && this.$root.keycloak.authenticated)) {
                    await this.$store.dispatch('users/fetchCurrentUserData', this.$store.state.token);
                    await this.$store.dispatch('fetchLegalEntitiesData', {
                        sgId: this.$store.state.supplierGroup.data.supplierGroupId
                    });
                    if (newLoginPage || proxyToken) {
                        if (this.$store.state.currentUserData.data.lastLogin) {
                            this.$store.dispatch('users/updateUserLogin', {
                                userId: this.$store.state.currentUserData.data.id
                            });
                        }
                    } else {
                        this.$store.dispatch('users/updateUserLogin', {
                            userId: this.$store.state.currentUserData.data.id
                        });
                    }
                }
                document.getElementById('app-title').innerHTML = this.$store.state.supplierGroup.branding.supplierGroupName
                if (this.$store.state.supplierGroup.branding.favicon) {
                    document.getElementById('favIcon').setAttribute('href', this.$store.state.supplierGroup.branding.favicon);
                } else {
                    document.getElementById('favIcon').setAttribute('href', process.env.BASE_URL + 'favicon.ico');
                }
                let defaultLocale = 'en';
                if (this.$route.name === 'public'
                      || this.$route.name === 'help'
                      || this.$route.name === 'terms'
                      || this.$route.name === 'tos'
                      || this.$route.name === 'onboarding'
                      || this.$route.name === 'saml'
                      || this.$route.name === 'login'
                      || this.$route.name === 'proxyLogin'
                      || this.$route.name === 'channelChoice'
                      || this.$route.name === 'channelChoiceAC'
                      || this.$route.name === 'onboardingAC'
                      || this.$route.name === 'cancelMandateSuccessful'
                      || this.$route.name === 'cancelMandateFail'
                      || this.$route.name === 'createMandateOk'
                      || this.$route.name === 'mandateOk'
                      || this.$route.name === 'onboardingSuccessful'
                      || this.$route.name === 'onboardingUnsuccessful'
                      || this.$route.name === 'cancelMandateCreate'
                      || this.$route.name === undefined
                      || this.$route.name === 'notFound'
                ) {
                    defaultLocale = this.$store.state.supplierGroup.data.languages[0].toLowerCase();

                    if (this.$route.name === 'notFound' && this.$store.state.currentUserData.data && this.$store.state.currentUserData.data.language) {
                        defaultLocale = this.$store.state.currentUserData.data.language.toLowerCase();
                    } else {
                        if (this.$store.state.currentUserData.data.language === undefined) {
                            this.$store.state.currentUserData.data.language = '';
                        }
                        this.$store.state.currentUserData.data.language = this.$store.state.supplierGroup.data.languages[0].toLowerCase();
                    }
                } else {
                    defaultLocale = this.$store.state.currentUserData.data.language.toLowerCase()
                }
                if (this.$store.state.locale === '') {
                    this.i18n.setLocale(defaultLocale);
                    this.$store.state.locale = defaultLocale;
                }
                this.$store.state.supplierGroup.isReady = true;
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>

<style>


#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}

.dialog-enter-active {
    transition: all 0.3s ease-out;
}

.dialog-leave-active {
    transition: all .3s ease-in;
}

.dialog-enter-from, .dialog-leave-to {
    transform: scale(0.8);
    opacity: 0;
}
</style>
