<template>
    <div class="min-height-1000">
    </div>
</template>

<script>
import Keycloak from 'keycloak-js';

const env = window.environment;
export default {
    mounted() {
        this.redirectToOverview()
    },
    methods: {
        redirectToOverview() {
            localStorage.setItem("saml", "true")

            let initOptions = {
                url: env.keycloakUrl,
                realm: env.keycloakRealm,
                clientId: env.keycloakClient,
                'ssl-required': 'external',
                'public-client': true,
                idpHint: 'saml',
            }
            let keycloak = new Keycloak(initOptions);
            keycloak.init({onLoad: initOptions.onLoad}).then(() => {
                keycloak.login({idpHint: 'saml'})
            })
            let loginResponse = this.$router.app.keycloak;
            if (this.$store.state.supplierGroup.data.supplierGroupId === 'modde') {
                if (loginResponse.token) {
                    this.$store.commit('setUserAuth', true)
                    this.$store.commit('setToken', loginResponse.token);
                    this.$store.commit('setRefreshToken', loginResponse.refreshToken);
                    this.$store.dispatch('users/fetchCurrentUserData', this.$store.state.token);
                    this.$store.dispatch('fetchLegalEntitiesData', {
                        token: this.$store.state.token,
                        sgId: this.$store.state.supplierGroup.data.supplierGroupId
                    });
                    this.$store.state.currentUserData.data.language = this.$store.state.supplierGroup.data.languages[0].toLowerCase();
                    this.$store.dispatch('users/updateUserLogin', {
                        token: this.$store.state.token,
                        userId: this.$store.state.currentUserData.data.id
                    });
                    keycloak.authenticated = true;
                    console.log("Saml_4")
                    this.$router.push({path: '/overview'})
                }
            } else {
                this.$router.push({path: '/notFound'})
            }
        }
    }
}
</script>

<style scoped>
.min-height-1000 {
    min-height: 1000px;
}

</style>
