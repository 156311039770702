<template>
    <pop-up-window :show="this.$store.state.postDrop.pendingTable.displayViewFiles"
                   :showClose=false
                   class="doc-statuses-popup"
                   additional-info=""
				   :position-fixed="true"
                   :stickToScrollPosition="true"
    >
        <template #title>
            <h1 class="text-2xl">
                {{ $t('postDrop.viewFiles.title') }}
            </h1>
        </template>
        <template #content>
            <h2 class="text-base text-center mb-2">
                {{ $t('postDrop.viewFiles.description') }}
            </h2>
            <div class="files-list-outer">
                <div class="files-list">
                    <div v-for="(item,index) in this.$store.state.postDrop.pendingTable.viewFilesData"
                         :key=index
                    >
                        {{ index + 1 }}. {{ item }}
                    </div>
                </div>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6 grey-line">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
                        {{ $t('buttons.close') }}
                    </button>
                </div>
            </div>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow";

export default {
    name: "ViewFiles",
    components: {PopUpWindow},

    methods: {
        close() {
            this.$store.commit('displayViewFiles', false);
            this.$store.commit("setViewFilesData", [])
        }
    }
}
</script>

<style scoped>
.files-list-outer{
    max-height: 300px;
    overflow: scroll;
}
.files-list {
    display: grid;
    grid-template-rows: repeat(10, auto);
    grid-template-columns: 50% 50%;
    grid-auto-flow: row;
}

</style>
