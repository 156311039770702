import IsErrorsAtDocument from "./calcIfErrorsAtDocument";

export default function setEnvelopIcon(item){
	let envelopIcon = null
	let errorsAtDocument = IsErrorsAtDocument(item.channels)
	let errorHandled = item.channels.archive.errorsHandled

	if (item.held === true){
		envelopIcon =  `<span class="material-icons" style="color: blue">edit_document</span>`
	}
	else {
		if(item.viewProperties){
			if(item.viewProperties.receiver.toLowerCase() === "anonymous"){
				envelopIcon =  `<i id="envelopIcon" class="icon icon-mail-envelope-open icon-color view-doc-icon"></i>`
			}
			else   {
				envelopIcon =`<i id="envelopIcon" class="icon icon-mail-envelope-open1 icon-color view-doc-icon"></i>`
			}
		}
		else envelopIcon = `<i id="envelopIcon" class="icon icon-mail-envelope icon-color view-doc-icon"></i>`

		if(errorHandled){
			envelopIcon += '<i id="envelopIcon" class="icon-checkmark"></i>'
		}
		else if(errorsAtDocument) {
			let tempEnvelopIcon = document.createElement('div')
			tempEnvelopIcon.innerHTML = envelopIcon.trim()
			tempEnvelopIcon.children[0].className = tempEnvelopIcon.children[0].className + ' warning'
			envelopIcon = tempEnvelopIcon.innerHTML
		}
	}

	return envelopIcon
}