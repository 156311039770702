<template>
  <transition name="dialog">
    <page-container class="page-content">
      <div>
        <top-banner>
            <div class="w-9/12">
              <h1 class="text-3xl text-center" v-if="displayErrors()">{{ $t('errors.incorrectProxyIdpLoginHeader') }}</h1>
            </div>
        </top-banner>
      </div>
      <div class="box page-content intro-y box p-5 mt-5">
        <div class="content-container">
          <h1 class="text-center" v-if="displayErrors()">{{ $t('errors.incorrectProxyIdpLoginText') }}</h1>
          <div class="button-container" v-if="displayErrors()">
            <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="goBack">
              {{ $t('buttons.return') }}
            </button>
          </div>
        </div>
      </div>
    </page-container>
  </transition>
</template>

<script>
import PageContainer from "../components/PageContainer";
import TopBanner from "../components/TopBanner";
import i18n from "../i18n";

export default {
    name: "ProxyLogin",
    components: { PageContainer, TopBanner},
    mounted() {

    },
    data(){
        return{
          i18n: i18n,
        }
    },
    watch: {

    },
    created() {
      this.exchangeToken();
    },
    computed:{

    },
    methods:{
      goBack(){
        if(this.$route.query.redirect === undefined) {
          window.location.href = "https://google.com"
        } else {
          window.location.href = this.$route.query.redirect
        }
      },

      displayErrors() {
        return this.$store.state.errorNotification.display
      },

      exchangeToken() {
        let lang = window.location.href.split('/')[3];
        this.i18n.setLocale(lang);
        localStorage.setItem("language", lang);
        this.$store.commit('setLocale', lang);
        this.$store.dispatch("proxyLogin", {
          proxyIdpToken: this.$route.query.jwt
        })
      }
    }

}
</script>

<style scoped>
.content-container{
  width: 40%;
  margin: 60px 30%;
}
.text-center{
  text-align: center;
}
.content-container h1{
  line-height: 2em;
  font-size: 20px;
}
@media only screen and (min-width: 600px) and (max-width: 1050px){
  .content-container{
    width: 80%;
    margin: 40px 10%;
  }
  .content-container h1{
    line-height: 1.8em;
    font-size: 18px;
  }
}
@media only screen and (min-width: 00px) and (max-width: 599px){
  .content-container{
    width: 90%;
    margin: 20px 5%;
  }
  .content-container h1{
    line-height: 1.6em;
    font-size: 16px;
  }
}

.button-container{
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-auto-flow: row;
  justify-items: center;
}

</style>
