export default function IsErrorsAtDocument(channels) {
	let errorsAtDocument = false

	//linkEmail error
	if ((channels.linkEmail && channels.linkEmail.channelState.state === "ERROR")) errorsAtDocument = true
	if (channels.linkEmail && channels.linkEmail.feedback && channels.linkEmail.feedback.bounces && channels.linkEmail.feedback.bounces.length > 0) errorsAtDocument = true
	if (channels.linkEmail && channels.linkEmail.feedback && channels.linkEmail.feedback.spamComplaints && channels.linkEmail.feedback.spamComplaints.length > 0) errorsAtDocument = true
	if (channels.linkEmail && channels.linkEmail.emailRecipients) {
		channels.linkEmail.emailRecipients.forEach((rec) => {
			if (rec.error) {
				errorsAtDocument = true;
			}
		})
	}

	//attachEmail Error
	if (channels.attachEmail && channels.attachEmail.emailRecipients) {
		channels.attachEmail.emailRecipients.forEach((rec) => {
			if (rec.error) {
				errorsAtDocument = true;
			}
		})
	}
	if (channels.attachEmail && channels.attachEmail.channelState.state === "ERROR") errorsAtDocument = true
	if (channels.attachEmail && channels.attachEmail.feedback && channels.attachEmail.feedback.bounces && channels.attachEmail.feedback.bounces.length > 0) errorsAtDocument = true
	if (channels.attachEmail && channels.attachEmail.feedback && channels.attachEmail.feedback.spamComplaints && channels.attachEmail.feedback.spamComplaints.length > 0) errorsAtDocument = true

	//print erorors
	if (channels.print && channels.print.channelState && channels.print.channelState.state === "ERROR") errorsAtDocument = true

	// ERROR_TRAN error
	if (channels.payment && channels.payment.paymentUpdates) {
		let paymentStatuses = [];
		channels.payment.paymentUpdates.forEach(update => {
			paymentStatuses.push(update.paymentStatus);
		});
		if (paymentStatuses.includes('ERROR_TRAN')) {
			errorsAtDocument = true;
		}
	}

	//EDI Errors

	if ((channels.edi && channels.edi.channelState.state === "ERROR")) errorsAtDocument = true
	if (channels.edi && channels.edi.updates) {
		channels.edi.updates.forEach(update => {
			if (update.status === 'NOT_SENT' || update.status === 'REJECTED') {
				errorsAtDocument = true;
			}
		})
	}
	return errorsAtDocument

}