<template>
	<div class="flex flex-row phone-screen" :class=" smallScreen ? 'mt-14' : 'mt-2'">
		<div v-for="(filterElement, index) in this.$store.state.extendedSearchValue.extendedSearch"
			 :key="index">
			<div
				v-if="showFilterElement(index, filterElement)"
				class="flex flex-row items-center mr-2"
				style="border:solid 1px #adacac; border-radius: 5px; padding: 2px 5px"
			>
				<div class="text-xs" v-html="translationField(index, filterElement)"></div>
				<span class="material-icons ml-1 text-sm" style="cursor: pointer"
					  @click="removeFilterElement(index)">close</span>
			</div>

		</div>
	</div>
</template>
<script>

import i18n from "@/i18n"
import moment from "moment/moment"
import en from "../../locales/en.json"

export default {
	emits: ['removeLabel'],
	props: {
		currentPage: {
			type: String,
			required: false,
		}
	},
	data() {
		return {
			smallScreen: window.innerWidth < 980
		}
	},
	mounted() {
		window.addEventListener('resize', () => this.smallScreen = 'change var')
	},
	watch: {
		smallScreen() {
			this.smallScreen = window.innerWidth < 980
		}
	},
	methods: {
		removeFilterElement(filterElement) {
			if (filterElement === "legalEntityNumber") {
				this.$store.state.extendedSearchValue.extendedSearch[filterElement] = {
					label: 'general.allLegalEntity',
					value: null
				}
			} else if (filterElement === "errorChannels") {
				delete this.$store.state.extendedSearchValue.extendedSearch[filterElement]
				delete this.$store.state.extendedSearchValue.extendedSearch.includeHandled
			} else if (filterElement === "peppolMessageType"){
				delete this.$store.state.extendedSearchValue.extendedSearch[filterElement]
				delete this.$store.state.extendedSearchValue.extendedSearch.rejectedImrOnly
			}
			else {
				delete this.$store.state.extendedSearchValue.extendedSearch[filterElement]
			}
			this.$emit("removeLabel")
		},
		showFilterElement(field, value) {
			switch (field) {
				case "period":
					return !!(value.from && value.to)
				case "legalEntityNumber":
				case "peppolMessageTypeOptions":
					if (value === undefined) return false
					return value.value !== null
				case "selectedChannels":
					if (value === undefined) return false
					else return value.value !== "NO_CHANNEL"
				case "includeHandled":
				case "released":
				case "clientDocsOnHold":
				case "rejectedImrOnly":
					return value
				case "documentNumber":
				case "clientId":
				case "role":
				case "transactionId":
				case "receiverId":
				case "mandateId":
				case "receiverName":
				case "senderId":
				case "senderName":
					return value !== undefined && value !== ''
				default:
					return value.value !== undefined && value.value !== null && value.value !== ''
			}
		},
		translationField(field, value) {
			switch (field) {
				case "period":
					if (this.currentPage !== undefined) {
						return `<p>${i18n.t('extendedSearch.label.' + field + '.' + this.currentPage)} <span>${i18n.t('extendedSearch.label.from').toLowerCase()}</span> ${moment(value.from).format("DD/MM/yyyy")} <span>${i18n.t('extendedSearch.label.to').toLowerCase()}</span>  ${moment(value.to).format("DD/MM/yyyy")}</p>`
					} else return `<p>${i18n.t('extendedSearch.documentDate')} <span>${i18n.t('extendedSearch.label.from').toLowerCase()}</span> ${moment(value.from).format("DD/MM/yyyy")} <span>${i18n.t('extendedSearch.label.to').toLowerCase()}</span>  ${moment(value.to).format("DD/MM/yyyy")}</p>`
				case "legalEntityNumber":
				case "errorCode":
				case "userId":
					return `<p>${i18n.t('extendedSearch.label.' + field)} <span>${i18n.t('extendedSearch.label.equals')}</span> ${value.label}</p>`
				case "errorChannels":
					return `<p>${i18n.t('extendedSearch.label.' + field)} <span>${i18n.t('extendedSearch.label.with')}</span> ${i18n.t(value.label).toLowerCase()}</p>`
				case "selectedChannels":
					return `<p>${i18n.t('extendedSearch.label.' + field)} <span>${i18n.t('extendedSearch.label.equalsPlural')}</span> ${this.displayChannels(value)} </p>`
				case "includeHandled":
				case "released":
				case "clientDocsOnHold":
				case "rejectedImrOnly":
					return `<p>${i18n.t('extendedSearch.label.' + field)}</p>`
				case "documentNumber":
				case "clientId":
				case "role":
				case "transactionId":
				case "receiverId":
				case "senderId":
				case "senderName":
				case "receiverName":
				case "mandateId":
					if(this.currentPage !== undefined &&  en.extendedSearch.label[field][this.currentPage] !== undefined){
						return `<p>${i18n.t('extendedSearch.label.' + field + '.' + this.currentPage)} <span>${i18n.t('extendedSearch.label.equals')}</span> ${value}</p>`
					} else return `<p>${i18n.t('extendedSearch.label.' + field)} <span>${i18n.t('extendedSearch.label.equals')}</span> ${value}</p>`
				default :
					return `<p>${i18n.t('extendedSearch.label.' + field)} <span>${i18n.t('extendedSearch.label.equals')}</span> ${i18n.t(value.label).toLowerCase()}</p>`
			}
		},
		displayChannels(value) {
			let text = ''
			value.forEach(channel => {
				text += channel.label + ', '
			})
			return text.substring(0, text.length -2)
		}
	}
}
</script>

<style scoped>
>>> p > span {
	color: #adacac;
}

@media only screen and (max-width: 1030px) {
	.phone-screen {
		margin-top: 55px;
	}
}

@media only screen and (max-width: 739px) {
	.phone-screen {
		margin-top: 85px;
	}
}
</style>