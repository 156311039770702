<template>
    <div>
        <!-- BEGIN: HTML Table Data -->
        <div class="" id="tabulatorOuter">
            <div class="buttons-container">
                <button
                      :disabled="!selectedDocuments.length"
                      class="btn custom-button shadow-md handle-error"
                      @click="startPrint">
                    <span class="button-text">{{ $t('buttons.startPrint') }}</span>
                    <span class="tooltip">{{ $t('tooltips.selectBatch') }}</span>
                </button>
            </div>
            <div class="filter-container">
                <div class="extended-search-check-box">
                    <span>{{ $t('extendedSearch.showExtendedSearch') }}</span>
                    <input type="checkbox"
                           class="customCheckbox mt-2"
                           v-model="showExtendedSearch"
                           @change="setExtendedSearchValues"
                    >
                </div>
                <div v-if="showExtendedSearch">
                    <div class="extended-search">
                        <v-select
                              v-model="departmentCode"
                              :options=departmentCodeOptions
                              :clearable="false"
                        >
                            <template #no-options="{search}">
                                {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                            </template>
                        </v-select>

                        <v-select
                              v-model="batch"
                              :options=this.$store.state.postDrop.pendingBatches.ids
                              :clearable="false"
                        >
                            <template #no-options="{search}">
                                {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                            </template>
                        </v-select>

                    </div>

                    <div class="search-button mt-4">
                        <div>
                            <button class="btn custom-button shadow-md mr-2 showStatuses text-popup"
                                    @click="cleanExtendedSearchValues">
                                {{ $t('buttons.reset') }}
                            </button>
                            <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="onFilter">
                                {{ $t('buttons.search') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="doc-info-margin">
                <div class="totalNumberDocuments">{{ $t('table.totalNumberPendingBatches') }}: {{
                        totalDocuments
                    }}
                </div>
                <div class="totalNumberDocuments">{{ $t('table.totalNumberPendingFiles') }}: {{
                        totalFiles
                    }}
                </div>
            </div>

            <div class="overflow-x-auto scrollbar-hidden">
                <div
                      id="tabulator"
                      ref="tableRef"
                      class="mt-5 table-report table-report--tabulator">

                </div>
            </div>
        </div>
        <!-- END: HTML Table Data -->
        <view-files></view-files>
        <delete-batch></delete-batch>
    </div>
</template>

<script>
import {defineComponent, ref, reactive, onMounted, watch, computed, onBeforeUnmount} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import i18n from "../../../i18n";
import cash from "cash-dom";
import en from "../../../locales/en.json";
import nl from "../../../locales/nl.json";
import de from "../../../locales/de.json";
import fr from "../../../locales/fr.json";
import sk from "../../../locales/sk.json";
import es from "../../../locales/es.json";
import userGroups from "../../../userGoups";
import ViewFiles from "./ViewFiles";
import DeleteBatch from "./DeleteBatch";

export default defineComponent({
    components: {DeleteBatch, ViewFiles},
    emits: ['update:modelValue'],
    props: {
        token: String,
        supplierGroupId: String,
        requestUrl: String,
        modelValue: String,
    },
    setup() {
        const store = useStore();

        const searchTableValue = ref(store.state.postDropTableSearchValue);
        const tableRef = ref()
        const tabulator = ref();
        const pageSize = ref(10);
        const filter = reactive({
            value: searchTableValue
        });
        const allBatchIds = ref(store.state.postDrop.pendingTable.allBatchIds);
        let selectedDocuments = ref([])
        let totalDocuments = ref(store.state.postDrop.pendingTable.data.length);
        let tableItems = ref(0);
        let showExtendedSearch = ref(store.state.postDrop.uploadedFiles.displayExtendedSearch);
        let departmentCode = ref(store.state.postDrop.uploadedFiles.departmentCode);
        let batch = ref(store.state.postDrop.uploadedFiles.batch);
        let options = ref([]);
		const tableIsLoading = ref(false);

        const totalFiles = computed (()=>{
            let fileNumber = 0;
            store.state.postDrop.pendingTable.data.forEach((item) =>{
                fileNumber = fileNumber + item.fileName.length
            })

            return fileNumber
        })
        let displayHandleErrors = computed(() => {
            if ((userGroups.optipostNames.includes(store.state.currentUserData.data.group) || userGroups.supplierNames.includes(store.state.currentUserData.data.group))) {
                return true
            }
            return false
        })
        let disableCheckBox = computed ((batchId)=>{
            if (batchId === '-'){
                return true
            }
            return false
        })
        let tableColumns = computed(() => {
            let columns = [];
            columns = [
                {
                    title: '<input class ="customCheckbox ml-4" type=checkbox id="selectAllDocuments">',
                    field: 'docId',
                    headerClick: function (e) {
                        selectAll(e)
                    },
                    width: 60,
                    headerSort: false,
                    formatter(cell) {
                        if (cell.getData().batchId !== '-'){
                            const a = cash(`<div class="">
                                          <input type=checkbox
                                            value=${cell.getData().batchId}
                                            class="downloadCheckbox common customCheckbox ml-4 checkBoxStart"
                                          >
                          </div>`);
                            cash(a).on('change', function () {
                                let selectAllEl = document.getElementById("selectAllDocuments");
                                selectAllEl.checked = false;
                                let index = selectedDocuments.value.indexOf(cell.getData().batchId);
                                if (index === -1) {
                                    selectedDocuments.value.push(cell.getData().batchId);
                                }
                                if (index > -1) {
                                    selectedDocuments.value.splice(index, 1);
                                }
                            })
                            return a[0]
                        } else {
                            const a = cash(`<div>
                                          <input type=checkbox
                                            value=${cell.getData().batchId}
                                            class="downloadCheckbox common customCheckbox ml-4 checkBoxStart"
                                            disabled = true
                                          >
                          </div>`);
                            return a[0]
                        }
                    }
                },
                {
                    title: 'DEPARTMENT NAME',
                    field: 'departmentName',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: '200',
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ">
                                     <div>${cell.getData().department}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'BATCH ID',
                    field: 'batchId',
                    cssClass: "text-centered",
                    headerSort: false,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     <div>${cell.getData().batchId}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'POSTAL TYPE',
                    field: 'postalType',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: '200',
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     <div>${cell.getData().postalType}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'PRINT MODE',
                    field: 'printMode',
                    cssClass: "text-centered",
                    headerSort: true,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     <div>${cell.getData().printMode}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'FILE NAME',
                    field: 'fileName',
                    cssClass: "text-centered",
                    headerSort: false,
                    minWidth: '150',
                    formatter(cell) {
                        if (cell.getData().fileName.length === 1){
                            const a = cash(
                                  `<div  class="font-medium whitespace-nowrap grid grid-rows-1 grid-columns-1 justify-items-center">
                                                    <div class="files-preview justify-items-end">${cell.getData().fileName} </div>
                                            </div>`
                            )
                            cash(a).on('click', function () {
                            })
                            return a[0];
                        } else {
                            const a = cash(
                                  `<div  class="font-medium whitespace-nowrap grid grid-rows-1 grid-columns-1 justify-items-center">
                                                    <div class="files-preview justify-items-end">${cell.getData().fileName[0]}, ... </div>
                                          </div>`
                            )
                            cash(a).on('click', function () {
                            })

                            return a[0];
                        }
                    }
                },
                {
                    title: 'VIEW FILES',
                    field: 'viewFiles',
                    width: "80",
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start ">
                                                 <div class="tooltip-outer">
                                                 <i class="icon-eye"></i>
                                                  <span class="tooltip-inner view-files">${cell.getData().viewFilesTooltip}</span>
                                            </div>
                                    </div> `);
                            cash(a).on('click', function () {
                                store.commit("setViewFilesData", cell.getData().fileName)
                                store.commit('displayViewFiles', true);
                            })
                            return a[0];
                    }
                },
                {
                    title: 'DELETE BATCH',
                    field: 'deleteBatch',
                    width: "80",
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                        if (cell.getData().batchId !== '-'){
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start ">

                                                 <div class="tooltip-outer">
                                                 <i class="icon-bin"></i>
                                                  <span class="tooltip-inner delete-batch">${cell.getData().deleteBatchTooltip}</span>
                                            </div>

                                    </div> `);
                            cash(a).on('click', function () {
                                store.dispatch("updateToken")
                                store.commit("setDeleteBatchData", cell.getData().batchId)
                                store.commit('displayDeleteBatch', true);
                            })
                            return a[0];
                        } else {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start ">
                                                 <div class="tooltip-outer">
                                                 <i class="icon-bin icon-color-disable"></i>
                                                  <span class="tooltip-inner disabled-delete-batch">${cell.getData().disabledDeleteBatchTooltip}</span>
                                            </div>
                                    </div> `);
                            return a[0];
                        }
                    }
                },
                {
                    title: 'START PRINT',
                    field: 'startPrint',
                    width: "80",
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                        if (cell.getData().batchId !== '-'){
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start ">
                                                 <div class="tooltip-outer">
                                                 <i class="icon-spinner11"></i>
                                                  <span class="tooltip-inner start-print">${cell.getData().startPrintTooltip}</span>
                                            </div>
                                    </div> `);
                            cash(a).on('click', function () {
                                store.dispatch("updateToken")
                                store.dispatch('startPrint', [cell.getData().batchId]);
                            })
                            return a[0];
                        } else {
                            const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start ">

                                                 <div class="tooltip-outer">
                                                 <i class="icon-spinner11 icon-color-disable"></i>
                                                  <span class="tooltip-inner disabled-start-print">${cell.getData().disabledStartPrintTooltip}</span>
                                            </div>
                                    </div> `);
                            return a[0];
                        }
                    }
                },
            ];

            return columns
        });
        const departmentCodeOptions = computed(() => {
            let options = [{label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''}]
            store.state.userDepartmentCodeList.forEach((department) => {
                options.push(department);
            })
            return options
        })

        watch(() => store.state.postDrop.departmentsUpdated, (newVal) => {
            if (newVal) {
                setTimeout(() => {
                    initTabulator();
                    store.commit("departmentsUpdated", false);
                }, 1000)
            }
        })
        watch(() => store.getters.getLocale, function () {
            if (departmentCode.value.value === '') {
                departmentCode.value.label = i18n.t('dropdowns.postDrop.departmentCode')
            }
            if (batch.value.value === '') {
                batch.value.label = i18n.t('dropdowns.postDrop.batch')
            }
            store.state.postDrop.pendingBatches.ids[0].label = i18n.t('dropdowns.postDrop.batch')
            tabulator.value.setLocale(store.getters.getLocale);
        });
        watch(() => store.state.postDrop.pendingTable.data, () => {
            if (store.state.postDrop.pendingTable.isReady) {
                totalDocuments.value = store.state.postDrop.pendingTable.data.length;
                initTabulator()
            }
        })

        const selectAll = (e) => {


            allBatchIds.value.forEach(() => {
                let cells = document.getElementsByClassName("checkBoxStart");

                for (var i = 0; i < cells.length; i++) {
                    if (cells[i].value !== '-'){
                        cells[i].checked = e.originalTarget.checked
                    }

                }

            })
            if (e.originalTarget.checked) {
                let selectedBatches = JSON.parse(JSON.stringify(allBatchIds.value))
                const index = selectedBatches.indexOf('-');
                if (index > -1) {
                    selectedBatches.splice(index, 1);
                }
                selectedDocuments.value = [...new Set(selectedBatches)]
            } else {
                selectedDocuments.value = []
            }

        }
        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value);
            tabulator.value = new Tabulator(tableRef.value, {
                data: store.state.postDrop.pendingTable.data,
                headerSortElement: "<span><i claas='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
                ajaxContentType: "json",
                ajaxFiltering: false,
                printAsHtml: false,
                printStyled: false,
                pagination: 'local',
                paginationSize: pageSize.value,
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: i18n.t('placeholders.noMatchingFound'),
                locale: true,
                langs: {
                    "en": en,
                    "nl": nl,
                    "de": de,
                    "fr": fr,
                    "sk": sk,
                    "es": es,
                },
                columns: tableColumns.value,
            })
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', ()=> {
				tableIsLoading.value = true
			})
        }
		watch(tableIsLoading, () => {
			if(tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
        const resetSearch = () => {
            onFilter();
        }
        const cleanQuickSearch = () => {
            departmentCode.value = {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''};
            filter.value = '';
            onFilter();
        }
        const startPrint = () => {
            store.dispatch('startPrint', selectedDocuments.value);
        }
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw();
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }
        const quickSearch = () => {
            onFilter();
        }
        const onFilter = () => {
            store.dispatch("updateToken");
            store.commit('setPostDropUploadedFilesExtendedSearch', {
                displayExtendedSearch: showExtendedSearch.value,
                departmentCode: departmentCode.value,
                batch: batch.value
            })
            if (showExtendedSearch.value && (departmentCode.value.value || batch.value.value)) {
                store.dispatch("setPendingTableData", {
                    batch: batch.value.value,
                    department: departmentCode.value.value
                })
            } else {
                store.dispatch("setPendingTableData")
            }
            tabulator.value.setLocale(store.getters.getLocale);
        }
        const onResetFilter = () => {
            filter.value = ''
            onFilter();
        }
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }
        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }
        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }
        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }
        const setExtendedSearchValues = () => {
            if (!showExtendedSearch.value) {
                departmentCode.value = {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''}
                cleanExtendedSearch();
                onFilter();

            }
        }
        const cleanExtendedSearch = () => {
            departmentCode.value = {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''};
            batch.value = {label: i18n.t('dropdowns.postDrop.batch'), value: ''};
        }
        const cleanExtendedSearchValues = () => {
            cleanExtendedSearch();
            onFilter();
        }

        onMounted(() => {
            if (store.state.postDrop.uploadedFiles.batch.value === '') {
                batch.value.label = i18n.t('dropdowns.postDrop.batch')
            }

            if (store.state.postDrop.uploadedFiles.departmentCode.value === '') {
                departmentCode.value.label = i18n.t('dropdowns.postDrop.departmentCode')
            }
            store.commit('setPostDropUploadedFilesExtendedSearch', {
                displayExtendedSearch: showExtendedSearch.value,
                departmentCode: departmentCode.value,
                batch: batch.value
            })
            initTabulator();
            reInitOnResizeWindow();

        })
        onBeforeUnmount(() => {
            store.commit('setPostDropUploadedFilesExtendedSearch', {
                displayExtendedSearch: showExtendedSearch.value,
                departmentCode: departmentCode.value,
                batch: batch.value
            })
        })


        return {
            tableRef,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            totalDocuments,
            totalFiles,
            quickSearch,
            tabulator,
            resetSearch,
            disableCheckBox,
            cleanQuickSearch,
            tableItems,
            showExtendedSearch,
            setExtendedSearchValues,
            cleanExtendedSearchValues,
            departmentCode,
            batch,
            options,
            departmentCodeOptions,
            selectedDocuments,
            displayHandleErrors,
            startPrint

        }
    }
})
</script>

<style scoped>


.tabulator-arrow {
    display: none;
}

.tabulator-col-title {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}

.leg-entity-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-columns: 80px auto;
    grid-auto-flow: column;
}

.leg-entity-container img {
    padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
    border: none;
}

.leg-entity-container .table-report img, .table-image {
    box-shadow: none !important;
}

.legEntityColumnHeader {
    padding-left: 40px
}

#tabulator-html-filter-value {
    width: 400px;

}

.totalNumberDocuments {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: end;
}
.doc-info-margin{
    padding-top: var(--generalPaddings);

}

#selectAllDocuments {
    justify-self: center;
}

.buttons-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    grid-column-gap: var(--generalPaddings);
}

.search {
    display: none !important;
}

.tabulator-buttons-container {
    display: grid;
    grid-template-columns: 90% 10%;
    grid-auto-flow: column;
    align-items: center;
}

.tabulator-search-container {
    display: grid;
    grid-template-columns: 400px repeat(5, auto);
    grid-template-rows: 100%;
    grid-auto-flow: column;
    grid-column-gap: 5px;
}

.download {
    justify-self: end;
}

.buttons-container {
    align-self: center;
    justify-self: start;
}

.col-header {
    text-align: center;
}


.arrow-up:after {
    content: ' \2191';
}

.arrow-down:after {
    content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
    display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
    display: inline-block;
    color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
    display: inline-block;
    color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
    display: inline-block;
    color: var(--theme-primary-100);
}

.download-outer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-auto-flow: column;
    justify-items: end;

}

.download-outer button {
    margin-left: 5px;
}

.icon-coin-euro {
    font-size: 24px;
}

.icon-coin-euro:hover {
    cursor: pointer;
    color: var(--infoColor);

}

.download-buttons {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: auto auto;
}

.download-buttons .btn {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding: 0;
}

.button-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.download-buttons .btn span {
    grid-column: 1/2;
    grid-row: 1/2;
}

.download-buttons .btn:disabled:hover .tooltip {

    display: block;

}

.tooltip {
    display: none;
    position: relative;
    top: 20px;
    background-color: var(--theme-primary-500);
    color: white;
    border-radius: 5px;
    font-size: 12px;


}

.extended-search {
    margin-top: 10px;
    width: 40%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 10px;

}

.extendedLe {
    align-self: start;
}

.extended-left {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    grid-row-gap: 10px;
}

.display-none {
    display: none;
}

.extended-search-check-box {
    margin-top: 10px;
    line-height: 1.5em;

}

.search-button {
    grid-column: 1/3;
    justify-items: center;
    display: grid;
}

.extended-search-check-box span {
    margin-right: 10px;
    position: relative;
    top: -5px;
}

.margin-right {
    margin-right: 10px;
}

@media only screen and (max-width: 800px) and (max-width: 670px) {
    #tabulator-html-filter-value {
        width: 50vw;
    }

    .tabulator-search-container {
        grid-template-columns: 50vw repeat(5, auto);
    }

}

@media only screen and (max-width: 669px) {
    #tabulator-html-filter-value {
        width: 40vw;
    }

    .tabulator-search-container {
        grid-template-columns: 40vw repeat(5, auto);
    }

}

@media only screen and (max-width: 850px) {
    .download {
        height: 38px;
    }
}

.findBy {
    display: grid;
    grid-template-columns: 240px 240px auto;
    grid-template-rows: 100%;
    grid-column-gap: 20px;
    grid-auto-flow: row;
    justify-items: start;
}

.findByStatus {
    width: 240px;
}

.button-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-row: 100%;
    align-items: center;
}

.quickSearch {
    display: grid;
    grid-template-columns: 400px 100px;
    grid-template-rows: 100%;
    grid-column-gap: 10px;
}

.filter-container {
    width: 660px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-flow: row;

}

.button-container {
    align-self: center;
}

.extended-search {
    margin-top: 10px;
    width: 640px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 10px;
}

.handle-error {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding: 0;
}

.button-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.handle-error span {
    grid-column: 1/2;
    grid-row: 1/2;
}

.handle-error:disabled:hover .tooltip {

    display: block;

}

.tooltip {
    display: none;
    position: relative;
    top: 20px;
    background-color: var(--theme-primary-500);
    color: white;
    border-radius: 5px;
    font-size: 12px;


}

.right-buttons {
    justify-self: end;
}

.buttons-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: end;
}

@media only screen and (max-width: 560px) {
    .quickSearch {

        grid-template-columns: 80% 20%;
    }
}

@media only screen and (max-width: 750px) {
    .filter-container {
        width: 90%;

    }

    .extended-search {
        width: 100%;
    }

}

@media only screen and (max-width: 520px) {
    .extended-search {
        grid-template-columns: 100%;
    }
}
</style>

