import {createRouter, createWebHistory} from 'vue-router'
import routerData from "../routerData";

import OverviewPage from "../pages/OverviewPage.vue";
import ProcessedDocument from "../pages/ProcessedDocument.vue";
import OnboardingPage from "../pages/OnboardingPage.vue";
import ChannelChoice from "../pages/ChannelChoice.vue";
import ChannelChoiceAC from "../pages/ChannelChoiceAC.vue";
import OnboardingAC from "../pages/OnboardingAC.vue";
import CreateMandateOk from "../pages/CreateMandateOk.vue";
import MandateOk from "../pages/MandateOk.vue";
import OnboardingSuccsseful from "../pages/OnboardingSuccessful.vue";
import OnboardingUnsuccsseful from "../pages/OnboardingUnsuccessful.vue";
import CancelMandateSuccessful from "../pages/CancelMandateSuccessful.vue";
import CancelMandateFail from "../pages/CancelMandateFail.vue";
import CancelMandateCreate from "../pages/CancelMandateCreate.vue"
import ManagementPage from "../pages/ManagementPage.vue";
import ReportsPage from "../pages/ReportsPage.vue";
import DirectDebetPage from "../pages/DirectDebetPage.vue";
import UblPage from "../pages/Ubl.vue";

import ResetPassword from "../pages/ResetPassword.vue";
import IngestionPage from "../pages/IngestionPage.vue";
import LoginPage from "../pages/LoginPage.vue";
import PostDrop from "../pages/PostDrop.vue";
import SamlLogin from "../pages/SamlLogin.vue";
import HelpPage from "../pages/HelpPage.vue";
import TermsAndConditions from "../pages/TermsAndConditions.vue";
import ProxyLogin from '../pages/ProxyLogin.vue';
import TermsPage from "../pages/TermsPage.vue"
import NotFound from "../pages/NotFound.vue";
import NoAccess from "../pages/NoAccess.vue";
import ErrorPage from "../pages/ErrorPage.vue"
import store from '../store/index'
import qs from "qs";
import axios from "axios";
import DocumentDetailPage from "../components/documentDetail/DocumentDetailPage.vue";
import userGroups from "@/userGoups";
const env = window.environment;
const newLoginPage = window.newLoginPage;
const proxyToken = localStorage.getItem("proxyToken")

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			redirect: routerData.overview.link,
			meta: {
				requiresAuth: true
			},
		},
		{
			path: '/auth',
			redirect: (newLoginPage || proxyToken) ? routerData.login.link : '/',
			meta: {
				requiresAuth: false
			},
		},
		{
			path: routerData.proxyLogin.link,
			component: ProxyLogin,
			name: "proxyLogin",
			meta: {
				requiresAuth: false
			}
		},
		{
			path: routerData.oldPortalMain.link,
			redirect: routerData.overview.link,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: routerData.home.link,
			redirect: routerData.overview.link,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: routerData.login.link,
			component: LoginPage,
			meta: {
				requiresAuth: false
			},
			name: 'login'
		},
		{
			path: routerData.overview.link,
			component: OverviewPage,
			meta: {
				requiresAuth: true
			},
			name: 'overview'
		},
		{
			path: routerData.documentDetail.link,
			component: DocumentDetailPage,
			meta: {
				requiresAuth: true
			},
			name: 'documentDetail'
		},
		{
			path: routerData.processedDocument.link,
			component: ProcessedDocument,
			meta: {
				requiresAuth: true
			},
			name: 'processedDocument'
		},
		{
			path: routerData.management.link,
			component: ManagementPage,
			meta: {
				requiresAuth: true
			},
			name: "management"
		},
		{
			path: routerData.resetPassword.link,
			component: ResetPassword,
			meta: {
				requiresAuth: true
			},
			name: "resetPassword"
		},
		{
			path: routerData.ingestion.link,
			component: IngestionPage ,
			meta: {
				requiresAuth: true
			},
			name: "Ingestion"
		},
		{
			path: routerData.reports.link,
			component: ReportsPage,
			meta: {
				requiresAuth: true
			},
			name: "reports"
		},
		{
			path: routerData.payments.link,
			component: DirectDebetPage,
			meta: {
				requiresAuth: true
			},
			name: "payments"
		},
		{
			path: routerData.ubl.link,
			component: UblPage,
			meta: {
				requiresAuth: true
			},
			name: "ubl"
		},
		{
			path: routerData.tos.link,
			component: TermsAndConditions,
			name: "tos",
			meta: {
				requiresAuth: false
			}
		},
		{
			path: routerData.documentDetailPublic.link,
			component: DocumentDetailPage,
			meta: {
				requiresAuth: false
			},
			name: 'public'
		},
		{
			path: routerData.onboarding.link,
			component: OnboardingPage,
			meta: {
				requiresAuth: false
			},
			name: 'onboarding'
		},
		{
			path: routerData.postDrop.link,
			component: PostDrop,
			meta: {
				requiresAuth: true
			},
			name: 'postDrop'
		},
		{
			path: routerData.saml.link,
			component: SamlLogin,
			meta: {
				requiresAuth: false
			},
			name: 'saml'
		},
		{
			path: routerData.channelChoice.link,
			component: ChannelChoice,
			meta: {
				requiresAuth: false
			},
			name: 'channelChoice'
		}, {
			path: routerData.channelChoiceAC.link,
			component: ChannelChoiceAC,
			meta: {
				requiresAuth: false
			},
			name: 'channelChoiceAC'
		},
		{
			path: routerData.onboardingAC.link,
			component: OnboardingAC,
			meta: {
				requiresAuth: false
			},
			name: 'onboardingAC'
		},
		{
			path: routerData.createMandateOk.link,
			component: CreateMandateOk,
			meta: {
				requiresAuth: false
			},
			name: 'createMandateOk'
		},
		{
			path: routerData.mandateOk.link,
			component: MandateOk,
			meta: {
				requiresAuth: false
			},
			name: 'mandateOk'
		},
		{
			path: routerData.cancelMandateFail.link,
			component: CancelMandateFail,
			meta: {
				requiresAuth: false
			},
			name: 'cancelMandateFail'
		},
		{
			path: routerData.cancelMandateSuccessful.link,
			component: CancelMandateSuccessful,
			meta: {
				requiresAuth: false
			},
			name: 'cancelMandateSuccessful'
		},
		{
			path: routerData.cancelMandateCreate.link,
			component: CancelMandateCreate,
			meta: {
				requiresAuth: false
			},
			name: 'cancelMandateCreate'
		},
		{
			path: routerData.onboardingSuccessful.link,
			component: OnboardingSuccsseful,
			meta: {
				requiresAuth: false
			},
			name: 'onboardingSuccessful'
		},
		{
			path: routerData.onboardingUnsuccessful.link,
			component: OnboardingUnsuccsseful,
			meta: {
				requiresAuth: false
			},
			name: 'onboardingUnsuccessful'
		},
		{
			path: routerData.helpLang.link,
			redirect: routerData.help.link,
			meta: {
				requiresAuth: false
			}
		},
		{
			path: routerData.help.link,
			component: HelpPage,
			name: "help",
			meta: {
				requiresAuth: false
			}
		},
		{
			path: routerData.errorPage.link,
			component: ErrorPage,
			name: "errorPage",
			meta: {
				requiresAuth: false
			}
		},
		{
			path: routerData.terms.link,
			component: TermsPage,
			name: "terms",
			meta: {
				requiresAuth: false
			}
		},
		{
			path: routerData.oldPortalTerms.link,
			redirect: routerData.terms.link,
			meta: {
				requiresAuth: false
			}
		},
		{
			path: routerData.noAccess.link,
			component: NoAccess,
			name: "noAccess",
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/:notFound(.*)',
			component: NotFound,
			name: 'notFound',
			meta: {
				requiresAuth: false
			}
		}
	]
});

const checkRoutes = (to, from, next) => {
	store.commit("fromToRoute", {from: from.name, to: to.name})
	if ((to.matched.some(record => record.meta.requiresAuth))) {
		if (store.state.userIsAuthenticated || (!(newLoginPage || proxyToken) && router.app.keycloak.authenticated)) {
			if ((from.name === 'tos' && to.name === 'overview')
				|| (from.name === 'tos' && to.name === 'management')
				|| (from.name === 'tos' && to.name === 'documentDetail')
				|| (from.name === 'tos' && to.name === 'reports')
				|| (from.name === 'tos' && to.name === 'payments')
				|| (from.name === 'tos' && to.name === 'postDrop')
			) {
				if(userGroups.receiverNames.includes(store.state.currentUserData.data.group)) {
					if (!(store.state.currentUserData.data.termsAndConditionsAcceptedOn)) {
						if (newLoginPage) {
							store.state.dispatch('logout')
							router.push('/login')
						} else {
							router.app.keycloak.logout()
						}
					} else {
						next()
					}
				} else next()
			}
			next()
		} else {
			if (newLoginPage) {
				if (to.name !== 'tos' || to.name !== 'resetPassword' || from.name !== 'resetPassword') {
					store.commit("redirectPageAfterLogin", to.path)
				}
				router.push('/login')
			} else {
				const loginUrl = router.app.keycloak.createLoginUrl();
				window.location.replace(loginUrl);
			}
		}
	} else {
		next()
	}
}

router.beforeEach((to, from, next) => {
	if (newLoginPage || proxyToken) {
		if (localStorage.getItem("vue-refresh-token")) {
			let dataToSend = {
				client_id: env.keycloakClient,
				grant_type: "refresh_token",
				refresh_token: localStorage.getItem("vue-refresh-token")
			}
			let body = qs.stringify(dataToSend);
			let url = store.state.API.keycloakToken;
			return axios.post(url, body, {
					headers: {
						'Content-Type': "application/x-www-form-urlencoded",
					}
				})
				.then((res) => {
					store.commit('setUserAuth', true)
					store.commit('setToken', res.data.access_token);
					store.commit('setRefreshToken', res.data.refresh_token);

					if (this.$store.state.currentUserData.isReady && store.state.supplierGroup.isReady) {
						checkRoutes(to, from, next)
					} else {
						let url = store.state.domainName + store.state.API.currentUser;
						return axios.get(url, {
								headers: {
									'Authorization': "bearer " + store.state.token,
								}
							})
							.then((response) => {
								let curerntUserData = response.data.data;
								store.commit('setCurrentUserData', curerntUserData);
								store.dispatch('fetchLegalEntitiesData', {
									sgId: store.state.supplierGroup.data.supplierGroupId
								});
								store.state.currentUserData.data.language = store.state.supplierGroup.data.languages[0].toLowerCase();

								checkRoutes(to, from, next)
							})
					}
				})
				.catch(() => {
					localStorage.removeItem("proxyToken")
					localStorage.removeItem("vue-refresh-token")
					checkRoutes(to, from, next)
				})
		} else {
			checkRoutes(to, from, next)
		}
	} else {
		checkRoutes(to, from, next)
	}
})

export default router;
