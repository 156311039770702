<template>
    <transition name="dialog">
        <page-container class="page-content" v-if="this.leDataIsReady">
            <tabulator
                  :supplierGroupId="this.$store.state.supplierGroup.data.supplierGroupId"
                  :general="this.docTypeGeneral"
                  :token="this.$root.token"
                  :requestUrl="this.requestUrl"
                  :leIds="this.leIds"
                  :leData="this.leData"
            >
            </tabulator>
        </page-container>
    </transition>
</template>

<script>
import PageContainer from "../../PageContainer"
import Tabulator from './ReceiverTable'

export default {
    name: "ManageReceiverContent",
    components: {PageContainer, Tabulator},
    created() {
      this.setLegalEntities();
    },
    mounted() {
        this.$store.dispatch("updateToken");
    },
    data() {
        return {
            docTypeGeneral: false,
            requestUrl: this.$store.state.domainName + this.$store.state.API.receivers,
            leIds: [],
            leData: [],
            leDataIsReady: false
        }
    },
    methods: {
        setLegalEntities() {
            let leIds = []
            let leData = []
            for (let le in this.$store.state.legalEntitiesData.data.legalEntities) {
                leIds.push(this.$store.state.legalEntitiesData.data.legalEntities[le].entityNumber);
                let tempItem = {
                    leId: this.$store.state.legalEntitiesData.data.legalEntities[le].entityNumber,
                    name: this.$store.state.legalEntitiesData.data.legalEntities[le].name,
                    logo: 'no-logo'
                }
                if (this.$store.state.legalEntitiesData.data.legalEntities[le].legalEntityConfig
                      && this.$store.state.legalEntitiesData.data.legalEntities[le].legalEntityConfig.logo) {
                    tempItem.logo = this.$store.state.legalEntitiesData.data.legalEntities[le].legalEntityConfig.logo;
                }
                if (this.$store.state.supplierGroup.data.supplierLegalEntities[le].internalName) {
                    tempItem.name = this.$store.state.legalEntitiesData.data.legalEntities[le].internalName
                }
                leData.push(tempItem);
            }
            this.leIds = leIds
            this.leData = leData
            this.leDataIsReady = true
        }
    }
}
</script>