<template>
	<div>
		<!-- BEGIN: HTML Table Data -->
		<div class="" id="tabulatorOuter">
			<div class="tabulator-buttons-container">
				<div class="quick-search-container">
					<input id='mandateId'
						   type='text'
						   v-model="filter.value"
						   @keyup="quickSearch"
						   :placeholder="$t('placeholders.search')"
						   class="form-control mr-1.5"
					>
					<div class="flex flex-row margin-small-screen">
						<extended-search-filter
							current-page="peppolOverview"
							@search="onFilter"
							@reset-filter="onFilter"
							:margin-left-small-screen="8"
							class="mr-1.5"
						/>
						<button class="btn custom-button shadow-md mr-2 showStatuses text-popup"
								@click="cleanQuickSearch">
							{{ $t('buttons.reset') }}
						</button>
					</div>

				</div>

			</div>
			<filter-label @remove-label="onFilter" current-page="peppolOverview"/>

			<div class="totalNumberDocuments">{{ $t('table.totalNumberDocuments') }}: {{ totalDocuments }}</div>
			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator">
				</div>
			</div>
		</div>
		<!-- END: HTML Table Data -->
		<view-ubl-detail></view-ubl-detail>
	</div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import i18n from "../../i18n";
import cash from "cash-dom";
import en from "../../locales/en.json";
import nl from "../../locales/nl.json";
import de from "../../locales/de.json";
import fr from "../../locales/fr.json";
import sk from "../../locales/sk.json";
import es from "../../locales/es.json";
import axios from 'axios'
import qs from "qs";
import moment from "moment";
import userGroups from "../../userGoups";
import ViewUblDetail from "./ViewUblDetail";
import ExtendedSearchFilter from "@/components/extendedSearch/ExtendedSearchFilter.vue";
import FilterLabel from "@/components/extendedSearch/FilterLabel.vue";

const env = window.environment;

export default defineComponent({
	components: {FilterLabel, ExtendedSearchFilter, ViewUblDetail},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		requestUrl: String,
		modelValue: String,
	},
	setup(props) {
		const store = useStore();

		const requestUrl = ref(props.requestUrl);
		const supplierGroupId = ref(props.supplierGroupId);
		const totalDocuments = ref('');
		const tableRef = ref()
		const tabulator = ref();
		const pageSize = ref(10);
		const tableIsLoading = ref(false);
		const filter = reactive({
			value: store.state.ublTableSearch.quick
		});

		let tableItems = ref(0);

		let tableColumns = computed(() => {
			let columns;
			columns = [
				{
					title: 'DIRECTION',
					field: 'direction',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '100',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                                 <div class="tooltip-outer">
                                                 ${cell.getData().direction}
                                                  <span class="tooltip-inner  ml-min-30 ${cell.getData().directionClass} ">${cell.getData().directionTooltip}</span>
                                               </div>
                                    </div> `);
						return a[0];
					}
				},
				{
					title: 'SENDER',
					field: 'sender',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <p> ${cell.getData().sender}</p>
                                                  <span class="tooltip-inner tooltip-ml-0"> ${cell.getData().senderId}</span>
                                            </div>
                                    </div> `);
						return a[0];
					}
				},
				{
					title: 'RECEIVER',
					field: 'receiver',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <p> ${cell.getData().receiver}</p>
                                               <span class="tooltip-inner tooltip-ml-0">${cell.getData().receiverId}</span>
                                            </div>
                                    </div> `);

						return a[0];
					}
				},

				{
					title: 'MESSAGE TYPE',
					field: 'messageType',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center capitalize">
                                                ${cell.getData().peppolMessageType}
                                    </div> `);
						return a[0];
					}
				},
				{
					title: 'ID',
					field: 'id',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                                 <p> ${cell.getData().id}</p>
                                    </div> `);

						return a[0];
					}
				},
				{
					title: 'ISSUED DATE',
					field: 'issueDate',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                                ${cell.getData().issueDate}
                                    </div> `);
						return a[0];
					}
				},
				{
					title: 'PROCESSING DATE',
					field: 'processingDate',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: '200',
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                                ${cell.getData().processingDate}
                                    </div> `);
						return a[0];
					}
				},
				{
					title: 'DOWNLOAD',
					field: 'download',
					width: "60",
					cssClass: "text-centered color-grey",
					headerSort: false,
					formatter(cell) {
						const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                               <div class="tooltip-outer">
                                                 <i class="icon-arrow-down"></i>
                                                  <span class="tooltip-inner download-xml tooltip-ml-min20">${cell.getData().downloadXmlTooltip}</span>
                                               </div>
                                    </div> `);
						cash(a).on('click', function () {
							store.dispatch('updateToken');
							window.open(cell.getData().xmlLink);
						})

						return a[0];
					},
					cellClick: function () {
						store.state.tabulator.isClickBlocked = true
					}
				},
			];
			return columns
		});


		watch(() => store.state.postDrop.departmentsUpdated, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					initTabulator();
					store.commit("departmentsUpdated", false);
				}, 1000)
			}
		})

		watch(() => store.getters.getLocale, function () {
			setLocale()
		});

		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value);
			let params = {
				supplierGroupId: supplierGroupId.value,
				pageNo: 1,
				pageSize: pageSize.value,
				quick: filter.value,
				extended: {}
			}

			if (store.state.extendedSearchValue.extendedSearch.documentNumber) params.extended.documentNumber = store.state.extendedSearchValue.extendedSearch.documentNumber
			if (store.state.extendedSearchValue.extendedSearch.senderId) params.extended.senderId = store.state.extendedSearchValue.extendedSearch.senderId
			if (store.state.extendedSearchValue.extendedSearch.receiverId) params.extended.receiverId = store.state.extendedSearchValue.extendedSearch.receiverId
			if (store.state.extendedSearchValue.extendedSearch.senderName) params.extended.senderName = store.state.extendedSearchValue.extendedSearch.senderName
			if (store.state.extendedSearchValue.extendedSearch.receiverName) params.extended.receiverName = store.state.extendedSearchValue.extendedSearch.receiverName

			if (store.state.extendedSearchValue.extendedSearch.peppolMessageType?.value) {
				let peppolMessageType = store.state.extendedSearchValue.extendedSearch.peppolMessageType
				if ((peppolMessageType.value === "INVOICE" || peppolMessageType.value === "CREDIT_NOTE")) {
					params.extended.billingDocumentType = peppolMessageType.value
				} else {
					params.extended.peppolMessageType = peppolMessageType.value
					if (peppolMessageType.value === 'InvoiceResponse') {
						params.extended.rejectedImrOnly = store.state.extendedSearchValue.extendedSearch.rejectedImrOnly
					}
				}
			}
			if (store.state.extendedSearchValue.extendedSearch.direction?.value) {
				params.extended.direction = store.state.extendedSearchValue.extendedSearch.direction.value
			}

			if (filter.value === '') {
				delete params.quick
			}
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "POST",
					headers: {
						'Authorization': 'bearer ' + store.state.token,
						'Accept': 'application/json',
					},
				},
				ajaxParams: params,
				ajaxResponse: function (url, params, response) {
					let data = [];
					const res = response.data.items;

					totalDocuments.value = response.data.numResults;
					tableItems.value = response.data.items.length;

					for (let item of res) {
						let tempItem = {
							peppolbisdocument: item.peppolbisdocument,
							docId: item.data.docId,
							legalEntity: item.data.legalEntity.name ? item.data.legalEntity.name : item.data.legalEntityentityNumber,
							id: item.data.id,
							receiver: '-',
							receiverId: '',
							issueDate: moment(item.data.issueDate).format("DD/MM/YYYY"),
							processingDate: moment(item.data.createdOn).format("DD/MM/YYYY"),
							sender: '-',
							senderId: '',
							peppolMessageType: item.data.peppolMessageType ? item.data.peppolMessageType : "-",
							direction: item.data.direction ? item.data.direction.toLowerCase() : "-",
							xmlLink: 'https://' + item.data.fileStructure.bucketName + '.storage.googleapis.com/' + item.data.fileStructure.originalFileName,
							downloadXmlTooltip: i18n.t('tooltips.downloadXml'),
							viewDetailsTooltip: i18n.t('tooltips.viewDetails'),
							directionTooltip: '',
							ublDetailData: {},
							directionClass: item.data.direction.toLowerCase(),
							allData: item,
						}

						if (tempItem.direction === "incoming") {
							tempItem.directionTooltip = i18n.t('tooltips.incoming')
						} else {
							tempItem.directionTooltip = i18n.t('tooltips.outgoing')
						}
						if (item.data.peppolMessageType === 'InvoiceResponse') {
							tempItem.id = item.data.documentReference;
						}
						tempItem.ublDetailData.createdOn = moment(item.data.createdOn).format("DD/MM/YYYY")

						tempItem.ublDetailData.ediProvider = item.data.edi.network;
						tempItem.ublDetailData.id = tempItem.id;
						let updates = [];
						if (item.data.direction === "OUTGOING") {
							const sent = item.data.edi.updates.find(update => update.status === "SENT")
							if (sent === undefined || sent === null) {
								let tempItem = {
									time: moment(item.data.createdOn),
									status: "SENT"
								}
								updates.push(tempItem)
							}
						}

						item.data.edi.updates.forEach(update => {
							let tempItem = {
								time: moment(update.time).format("DD/MM/YYYY hh:mm"),
								status: update.status
							}
							if (update.message) {
								tempItem.message = update.message
							}
							if (userGroups.optipostNames.includes(store.state.currentUserData.data.group)
								|| userGroups.supplierNames.includes(store.state.currentUserData.data.group)) {
								tempItem.time = moment(update.time).format("DD/MM/YYYY h:mm:ss a")
							}
							if (update.forwardedTo) {
								tempItem.forwardedTo = update.forwardedTo.toLowerCase().replaceAll("_", '')
							}
							if (update.email) {
								tempItem.email = update.email
							}
							updates.push(tempItem)
						})

						if (item.data.peppolMessageType === 'InvoiceResponse' && item.data.imrStatus === "RE") {
							updates.push({
								time: moment(item.data.updatedOn),
								status: "REJECTED",
								message: item.data.message
							})
						}

						tempItem.ublDetailData.edi = updates
						if (item.data.peppolMessageType === 'Billing') {
							tempItem.peppolMessageType = item.data.billingDocumentType.toLowerCase()
						}

						if (item.data.direction) {
							let direction = '';
							if (item.data.direction.toLowerCase() === "incoming") {
								direction = `<span class="material-icons">login</span>`
							}

							if (item.data.direction.toLowerCase() === "outgoing") {
								direction = `<span class="material-icons">logout</span>`
							}
							tempItem.direction = direction;
						}
						if (item.data.receiver) {
							if (item.data.receiver.receiverName) {
								tempItem.receiver = item.data.receiver.receiverName;
							}
							tempItem.receiverId = item.data.receiver.schemeId + ":" + item.data.receiver.receiverId;
						}
						if (item.data.sender) {
							if (item.data.sender.senderName) {
								tempItem.sender = item.data.sender.senderName;
							}
							tempItem.senderId = item.data.sender.schemeId + ':' + item.data.sender.senderId;
						}
						data.push(tempItem);
					}
					let last_page = Math.ceil(response.data.numResults / pageSize.value);
					return {
						last_page: last_page,
						data: data,
					};
				},
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
				},
				ajaxURLGenerator: function (url, config, params) {
					updateToken();
					if (params.sort?.length > 0) {
						let sortFields = {
							receiver: 'PEPPOL_RECEIVER_NAME',
							receiverId: 'PEPPOL_RECEIVER_ID',
							sender: 'PEPPOL_SENDER_NAME',
							senderId: 'PEPPOL_SENDER_ID',
							direction: 'PEPPOL_DIRECTION',
							issueDate: 'PEPPOL_ISSUE_DATE',
							customerName: 'CLIENT_NAME',
							messageType: "PEPPOL_MESSAGE_TYPE",
							id: "PEPPOL_DOC_NR",
							processingDate: "PEPPOL_CREATED_ON"
						};
						params.sortOn = {
							order: params.sort[0].dir.toUpperCase(),
							sortField: sortFields[params.sort[0].field]
						}
					}
					return url;
				},
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				sortMode: "remote",
				paginationMode: 'remote',
				paginationSize: pageSize.value,
				paginationInitialPage: store.state.tableInitialPage.mandates,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				langs: {
					"en-us": en,
					"nl-nl": nl,
					"de-de": de,
					"fr-fr": fr,
					"sk-sk": sk,
					"es-es": es,
				},
				columns: tableColumns.value,
			})

			tabulator.value.on('dataLoaded', () => {
				setLocale()
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
			tabulator.value.on('rowClick', (e, row) => {
				// Timeout so that the cell click event is before the row click event
				setTimeout(() => {
					if (store.state.tabulator.isClickBlocked === false) {
						store.dispatch('updateToken');
						store.commit('setPageYScrollPosition', window.pageYOffset)
						row._row.data.ublDetailData.docId = row._row.data.docId
						store.commit('setUblDocumentData', row._row.data.ublDetailData);
						store.commit('setViewUblDetail', true)
					} else store.state.tabulator.isClickBlocked = false
				}, 300)
			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const tokenIsExpired = () => {
			let exp;
			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
			}
			let now = parseInt(new Date().getTime() / 1000);

			return exp - now <= 0;
		}
		const updateToken = () => {
			if (tokenIsExpired()) {
				let payload = {
					client_id: env.keycloakClient,
					grant_type: "refresh_token",
					refresh_token: store.state.refreshToken
				}

				let body = qs.stringify(payload);
				axios.post(store.state.API.keycloakToken,
					body,
					{
						headers: {
							'Content-Type': "application/x-www-form-urlencoded",
						}
					}
				)
					.then(res => {
						store.commit('setToken', res.data.access_token);
						store.commit('setRefreshToken', res.data.refresh_token);
						initTabulator();
					})
					.catch(() => {
						store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
						store.commit('displayNotification', true);
					})
			}
		}
		const cleanQuickSearch = () => {
			store.state.extendedSearchValue.extendedSearch = {}
			filter.value = '';
			onFilter();
		}
		const setLocale = () => {
				if (store.getters.getLocale === 'en') tabulator.value.setLocale(store.getters.getLocale + '-us')
				else tabulator.value.setLocale(store.getters.getLocale + '-' + store.getters.getLocale)
		}
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw();
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}
		const quickSearch = () => {
			onFilter();
		}
		const onFilter = () => {
			initTabulator();
		}

		onMounted(async () => {
			await initTabulator();
			reInitOnResizeWindow();
		})

		return {
			tableRef,
			filter,
			onFilter,
			totalDocuments,
			quickSearch,
			tabulator,
			cleanQuickSearch,
			tableItems,
		}
	}
})
</script>

<style scoped>

.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
}

.leg-entity-container .table-report img {
	box-shadow: none !important;
}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	padding-top: var(--generalPaddings);
}

.tabulator-buttons-container {
	display: grid;
	grid-template-columns: 90% 10%;
	grid-auto-flow: column;
	align-items: center;
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.download-outer button {
	margin-left: 5px;
}

.download-buttons .btn span {
	grid-column: 1/2;
	grid-row: 1/2;
}

.extended-search-check-box span {
	margin-right: 10px;
	position: relative;
	top: -5px;
}

.quick-search-container {
	display: flex;
	flex-direction: row;
	width: 400px;
	height: 37px;
}

@media only screen and (max-width: 1030px) {
	.quick-search-container {
		display: flex;
		flex-direction: row;
		width: 400px;
		height: 37px
	}
}

@media only screen and (max-width: 739px) {
	.quick-search-container {
		display: flex;
		flex-direction: column;
		width: 400px;
		height: 37px
	}

	.margin-small-screen {
		margin-top: 5px;
	}
}
</style>
