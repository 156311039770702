<template>
	<div>
		<handle-error :docIds=selectedDocuments @unselect-documents="unselectDocuments">
		</handle-error>
		<error-notification>
		</error-notification>
		<resend-document
			:docsData=selectedDocsData
			@cancel='cancelResend'
			@unselect-documents="unselectDocuments"
		>
		</resend-document>

		<!-- BEGIN: HTML Table Data -->
		<div class="intro-y box pl-2 pr-2 pt-5 pb-5 mt-5">
			<div class="action-container">
				<div class="quick-search-container">
					<input
						style="margin-right: 10px"
						v-model.trim="this.$store.state.extendedSearch.overview.quickSearch"
						type="text"
						class="form-control"
						:placeholder="$t('placeholders.search')"
						@keyup="onFilter"
					/>
					<div class="flex flex-row margin-small-screen">
						<extended-search-filter
							current-page="overview"
							@reset-filter="resetFilters(false)"
							@search="setFilter"
							class="mr-1.5"
						/>
						<button
							style="flex-basis: 15%"
							type="button"
							class="btn custom-button w-full shadow-md"
							@click="resetFilters(true)"
						>
							{{ $t('buttons.reset') }}
						</button>
					</div>

				</div>
				<div class="buttons-container">
					<div class="flex flex-col show-tooltip">
						<button
							v-if="displayResendByButton"
							:disabled="isResendButtonDisabled"
							style="margin-right: 5px; height: 37px"
							class="btn custom-button shadow-md mr-2 no-break-text"
							@click="showResendDocumentWindow"
						>
							{{ $t('buttons.resendByEmail') }}
						</button>
						<span v-if="displayTooltipResendEmail" style="min-width: 150px; z-index: 1"
							  class="tooltip">
							{{ $t('buttons.downloadTooltip') }}
						</span>
						<span v-if="displayTooltipUnselectOnboardingResendEmail" style="z-index: 1"
							  class="tooltip">
							{{ $t('buttons.downloadTooltipOverdueOnboardingSelected') }}
						</span>
						<span v-if="displayTooltipUnreleasedDocuments" style="z-index: 1"
							  class="tooltip">
							{{ $t('buttons.disableResendBecauseOfUnreleasedDocument') }}
						</span>
						<span v-if="displayTooltipDocumentNotVisibleResendEmail" style="z-index: 1"
							  class="tooltip">
							{{ $t('buttons.displayTooltipDocumentNotVisibleResendEmail') }}
						</span>
					</div>

					<div class="flex flex-col show-tooltip">
						<button
							v-if="displayHandleErrors"
							:disabled="isHandleErrorsDisabled"
							class="btn custom-button shadow-md download"
							@click="handleErrors"
						>
							{{ $t('buttons.handleErrors') }}
						</button>
						<span v-if="!selectedDocuments.length"
							  class="tooltip">{{ $t('buttons.downloadTooltip') }}</span>
						<span v-else-if="isHandleErrorsDisabled"
							  class="tooltip">{{ $t('buttons.handleErrorTooltipDocNotInError') }}</span>
					</div>
					<div style="margin-left: 5px;">
						<drop-down-menu
							:main-button-name="$t('buttons.download')"
							:width-px="130"
							:is-disabled="!selectedDocuments.length"
							:tooltip="$t('buttons.downloadTooltip')"
							:width-tooltip="170"
							:show-arrow="selectedDocuments.length > 0"
						>
							<template v-slot:options>
								<button></button>
								<button
									style="padding: 5px"
									@click="download('pdf')">
									{{ $t('buttons.downloadPDF') }}
								</button>
								<button
									v-if="displayButton"
									style="padding: 5px"
									@click="download('ubl')">
									{{ $t('buttons.downloadSet') }}
								</button>
							</template>
						</drop-down-menu>
					</div>
				</div>
			</div>
			<filter-label @remove-label="refreshTable"/>
			<div class="totalNumberDocuments">{{ $t('table.totalNumberDocuments') }}: {{ totalDocuments }}</div>

			<div class="overflow-x-auto scrollbar-hidden margin-small-screen-table">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				>

				</div>
			</div>

		</div>
		<!-- END: HTML Table Data -->
	</div>
</template>

<script>
import {computed, defineComponent, onMounted, ref, watch} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import cash from "cash-dom";
import userGroups from "../../userGoups";
import {useRoute} from "vue-router";
import i18n from "../../i18n";
import ErrorNotification from "../UI/ErrorNotification.vue";
import ResendDocument from "../documentDetail/resend/ResendDocument.vue"
import axios from 'axios'
import qs from "qs";
import HandleError from "../documentDetail/actions/HandleError.vue";
import calcDueDate from "../../mixins/documentTable/calcDueDate";
import getAmount from "../../mixins/documentTable/calcAmount";
import getPaymentStatus from "../../mixins/documentTable/calcPayStatus";
import getDocType from "../../mixins/documentTable/calcDocType";
import setEnvelopIcon from "../../mixins/documentTable/CalcEnvelopIcon";
import getPaymentButton from "../../mixins/documentTable/calcPaymentButton";
import getAttachments from "../../mixins/documentTable/calcAttachments";
import dropDownMenu from "../UI/DropDownMenu.vue";
import ExtendedSearchFilter from "@/components/extendedSearch/ExtendedSearchFilter.vue"
import FilterLabel from "../extendedSearch/FilterLabel.vue"
import IsErrorsAtDocument from "@/mixins/documentTable/calcIfErrorsAtDocument";

const env = window.environment;

export default defineComponent({
	name: "MainOverview",
	components: {FilterLabel, ExtendedSearchFilter, dropDownMenu, ErrorNotification, ResendDocument, HandleError},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		general: Boolean,
		salary: Boolean,
		billing: Boolean,
		requestUrl: String,
		modelValue: String,
	},
	setup(props) {
		const store = useStore();
		const en = require('../../locales/en.json')
		const nl = require('../../locales/nl.json')
		const fr = require('../../locales/fr.json')
		const de = require('../../locales/de.json')
		const es = require('../../locales/es.json')
		const sk = require('../../locales/sk.json')

		const translation = {
			en: require('../../locales/en.json'),
			nl: require('../../locales/nl.json'),
			fr: require('../../locales/fr.json'),
			de: require('../../locales/de.json'),
			es: require('../../locales/es.json'),
			sk: require('../../locales/sk.json')
		}

		const pageSize = ref(20);
		const selectedDocuments = ref([]);
		const selectedDocsData = ref([]);
		const requestUrl = ref(props.requestUrl);
		const supplierGroupId = ref(props.supplierGroupId);
		const general = ref(props.general);
		const salary = ref(props.salary);
		const billing = ref(props.billing);

		const route = useRoute()
		const totalDocuments = ref('');
		const overviewColumns = store.state.columnsOverview  //defined at the moment od DocType is identified (saved in storage)
		const token = ref(props.token);
		const tableRef = ref();
		const tabulator = ref();
		const tableIsLoading = ref(false);

		const docIdsOnboardings = ref([])
		const unreleasedDocs = ref([])
		const showResendWindow = ref(false);
		const showResendDocument = ref(false);
		const errorChannelsOptions = ref(
			[
				{label: i18n.t("dropdowns.errors.edi"), value: 'EDI'},
				{label: i18n.t("dropdowns.errors.email"), value: 'EMAIL'},
				{label: i18n.t("dropdowns.errors.print"), value: 'PRINT'}
			]
		)
		if (store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus) {
			errorChannelsOptions.value.push({label: i18n.t("dropdowns.errors.payment"), value: 'PAYMENT'})
		}

		const displayTooltipResendEmail = ref(true)
		const displayTooltipUnselectOnboardingResendEmail = ref(false)
		const displayTooltipDocumentNotVisibleResendEmail = ref(false)
		let displayTooltipUnreleasedDocuments = ref(false)

		const format = (date) => {
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			return `${day}/${month}/${year}`;
		}

		let docTypeError = ref(false);
		let documentType = ref('');

		let displayButton = computed(() => {
			return !(store.state.docType === "SALARY" || store.state.docType === "GENERAL");

		})
		let displayHandleErrors = computed(() => {
			return !!((userGroups.optipostNames.includes(store.state.currentUserData.data.group))
				|| ((userGroups.supplierNames.includes(store.state.currentUserData.data.group)
					&& store.state.currentUserData.data.group !== 'SupplierLimitedSubUser')));

		})

		let displayForInvoice = computed(() => {
			return store.state.supplierGroup.data.sgType !== "SALARY";
		})
		let showExtendedSearchButton = computed(() => {
			if (general.value) return false
			else if (userGroups.optipostNames.includes(store.state.currentUserData.data.group) || userGroups.supplierNames.includes(store.state.currentUserData.data.group)) {
				return true
			}
			return false
		})

		let isResendButtonDisabled = computed(() => {
			let documentIsOverDue = false
			let isDocumentUnReleased = false
			let documentIsNotVisible = false

			// is there no selected document?
			if (!selectedDocuments.value.length === true) {
				showTooltip("noneSelected")
				return !selectedDocuments.value.length
			}

			// is document released?
			selectedDocsData.value.forEach(selectedDoc => {
				unreleasedDocs.value.forEach(id => {
					if (id === selectedDoc.docId) {
						showTooltip("unreleased")
						isDocumentUnReleased = true
					}
				})

			})
			if (isDocumentUnReleased === true) return true

			// is it an onboarding?
			selectedDocsData.value.forEach(selectedDoc => {
				docIdsOnboardings.value.forEach(doc => {
					if (doc.docId === selectedDoc.docId) {
						showTooltip("onboarding")
						documentIsOverDue = true
					}
				})
			})
			// is it overdue?
			if (documentIsOverDue === true) return documentIsOverDue

			// is document visible?
			let data = tabulator.value.getData()
			selectedDocuments.value.forEach(docId => {
				let document = data.find(doc => doc.documentData.data.docId === docId)
				if (document.visibility.includes("icon-eye-blocked")) {
					showTooltip("notVisible")
					documentIsNotVisible = true
				}
			})
			if (documentIsNotVisible) return documentIsNotVisible


			showTooltip("selected")
			return false
		})
		let isHandleErrorsDisabled = computed(() => {
			if (selectedDocuments.value.length > 0){
				let showHandleErrors = true
				let data = tabulator.value.getData()

				selectedDocuments.value.forEach(docId => {
					let doc = data.find(doc => doc.documentData.data.docId === docId)
					let errors = IsErrorsAtDocument(doc.documentData.data.channels)

					if (!errors) showHandleErrors = false
					if (doc.documentData.data.channels.archive.errorsHandled) showHandleErrors = false
				})
				return !showHandleErrors
			} else return true
		})
		let showTooltip = (displayedTooltip) => {
			displayTooltipResendEmail.value = false
			displayTooltipUnselectOnboardingResendEmail.value = false
			displayTooltipDocumentNotVisibleResendEmail.value = false

			displayTooltipUnreleasedDocuments.value = displayedTooltip === 'unreleased';
			if (displayedTooltip === 'onboarding') {
				displayTooltipUnselectOnboardingResendEmail.value = true
			}
			if (displayedTooltip === "noneSelected") {
				displayTooltipResendEmail.value = true
			}
			if (displayedTooltip === "notVisible"){
				displayTooltipDocumentNotVisibleResendEmail.value = true
			}
		}
		let tableColumns = computed(() => {
			let columns = overviewColumns;
// First and last column checkbox + detail
			let firstColumn = {
				title: `<input class ="common displayColumn customCheckbox marginleft-5" type=checkbox id="selectAllDocuments">`,
				field: 'docId',
				width: 40,
				hozAlign: "center",
				cssClass: 'text-centered',
				headerSort: false,
				cellClick: function () {
					store.state.tabulator.isClickBlocked = true
				},
				formatter(cell) {
					const a = cash(`<div>
                                          		<input 	type=checkbox
                                            			value=${cell.getData().docId} class="downloadCheckbox common customCheckbox"
                                            			data-leId=${cell.getData().legalEntityId}
                                            			data-noVat=${cell.getData().noVat}>
                          					</div>`);
					cash(a).on('change', function () {
						let index = selectedDocuments.value.indexOf(cell.getData().docId);

						if (index === -1) {
							selectedDocuments.value.push(cell.getData().docId);
							selectedDocsData.value.push({
								docId: cell.getData().docId,
								legalEntityId: cell.getData().legalEntityId,
								noVat: true
							})
						} else if (index > -1) {
							selectedDocuments.value.splice(index, 1);
							selectedDocsData.value.splice(index, 1);
						}
					})
					return a[0]
				},
			}

			let firstColumnItem = Object.values(Object.assign({}, columns[0]));

			if ((firstColumnItem[0] !== firstColumn.title)) {
				columns.unshift(firstColumn);
			} else {
				columns.splice(0, 1);
				columns.unshift(firstColumn);
			}
			if (store.state.docType === "BILLING") {

//Visibility column

				if ((userGroups.supplierNames.includes(store.state.currentUserData.data.group)
						|| userGroups.optipostNames.includes(store.state.currentUserData.data.group))
					&& columns.find(col => col.title === 'VISIBILITY') === undefined) {
					if (store.state.overviewTable.billing.displayVisibilityColumn) {

						let visibilityColumn = {
							title: 'VISIBILITY',
							field: 'visibility',
							hozAlign: "center",
							cssClass: 'text-centered',
							headerSort: false,
							width: 100,
							formatter(cell) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                            				<p> ${cell.getData().visibility} </p>
                                    					</div> `)
								return a[0]
							}
						}
						columns.splice(columns.length - 3, 0, visibilityColumn)
					}
				}

//payment column
				if (store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus && overviewColumns.find(pay => pay.title === "PAYMENT") === undefined) {
					if (store.state.overviewTable.billing.displayPaymentColumn) {
						let paymentColumn = {
							title: 'PAYMENT',
							field: 'payment',
							hozAlign: "center",
							cssClass: 'text-centered',
							width: 60,
							formatter(cell) {
								let status = cell.getData().paymentStatusTooltip;
								let toolTip = '';
								if (!(translation[store.state.locale].paymentStatusesTooltips === undefined && status === '')) {
									toolTip = translation[store.state.locale].paymentStatusesTooltips[status];
								}

								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                    						<div class="customer-name-outer payment-statuses">
                                            					${cell.getData().payment}
                                            					<span class="tooltip tooltip-billing payment-status ${status}">${toolTip}</span>
                                     						</div>
                                    					</div> `);
								return a[0];
							}
						}
						columns.splice(columns.length - 1, 0, paymentColumn);
					}
				}

//paymentButton
				if (store.state.supplierGroup.data.channelsConfig.payment
					&& userGroups.receiverNames.includes(store.state.currentUserData.data.group)
					&& columns.find(col => col.title === 'PAY NOW') === undefined) {
					if (store.state.overviewTable.billing.displayPaymentButtonColumn) {
						let paymentButtonColumn = {
							title: 'PAY NOW',
							field: 'paymentButton',
							cssClass: 'text-centered',
							width: 80,
							hozAlign: "center",
							headerSort: false,
							formatter(cell) {
								if (cell.getData().paymentButton !== null && cell.getData().paymentButton !== undefined) {
									const a = cash(`<div class="grid grid-rows-1 justify-items-center" style="position:relative; margin-top: -7px;">
																<div style="border: 1px solid lightgray; border-radius: 5px; width: 31px; margin-left: 5px">
																	<span class="material-icons" style="padding-top: 2px" >credit_card</span>
																</div>
                                            				</span>
                                            				<span class="tooltip" style="position:absolute; margin-top: 18px">${cell.getData().paymentButtonTooltip}</span>
                                            				</div>
                                    					 `);
									cash(a).on('click', function () {
										window.open(cell.getData().paymentButton)
									})
									return a[0];

								} else return ''
							},
							cellClick: function () {
								store.state.tabulator.isClickBlocked = true
							}
						}
						columns.splice(columns.length - 1, 0, paymentButtonColumn);
					}
				}
//viewProperties columns
				if (store.state.overviewTable.billing.displayViewPropertiesColumn && columns.find(col => col.field === "viewProperties") === undefined) {
					let viewPropertiesColumn = {
						title: '',
						field: 'viewProperties',
						width: 60,
						headerSort: false,
						formatter(cell) {
							return `<div  class="font-medium whitespace-nowrap salary billing">
                                    ${cell.getData().viewPropertiesColumns}
                              </div>`
						}
					}
					columns.splice(1, 0, viewPropertiesColumn);
				}
			}

			if (store.state.docType === "SALARY") {
				if (columns.find(col => col.title === "AMOUNT") === undefined && route.name === 'public'
					|| (route.name === 'overview' && !(userGroups.optipostNames.includes(store.state.currentUserData.data.group)))) {
					if (store.state.overviewTable.salary.displayAmountColumn) {
						let amountColumn = {
							title: 'AMOUNT',
							field: 'amount',
							cssClass: 'text-centered',
							width: 140,
							headerSort: false,
							formatter(cell) {
								return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell">
                          					${cell.getData().amount}
                    					</div>`
							}
						}
						columns.splice(columns.length - 1, 0, amountColumn);
					}
				}
			}
			return columns
		})
		let documentTypes = computed(() => {
			let docTypes = [
				{
					label: i18n.t("general.noDocType"),
					value: ''
				}
			]
			if (store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.searchableDocTypes) {
				store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.searchableDocTypes.forEach(type => {
					if (type === "INVOICE") {
						if (store.state.docType === "BILLING") {
							docTypes.push({
								label: i18n.t("billing.invoice"),
								value: type
							})
						}
						if (store.state.docType === "SALARY") {
							docTypes.push({
								label: i18n.t("salary.salarySlip"),
								value: type
							})
						}
					}
					if (type === "CREDIT_NOTE") {
						docTypes.push({
							label: i18n.t("billing.creditnote"),
							value: type
						})
					}
					if (type === "DUNNING") {

						docTypes.push({
							label: i18n.t("billing.dunning"),
							value: type
						})
					}
					if (type === "YEARLY") {
						docTypes.push({
							label: i18n.t("salary.yearly"),
							value: type
						})
					}
					if (type === "FISCAL") {
						docTypes.push({
							label: i18n.t("salary.fiscal"),
							value: type
						})
					}
					if (type === "CAR_INSURANCE") {
						docTypes.push({
							label: i18n.t("billing.carinsurance"),
							value: type
						})
					}
					if (type === "MANDATE_ONBOARDING") {

						docTypes.push({
							label: i18n.t("billing.mandateonboarding"),
							value: type
						})
					}
					if (type === "GENERAL") {
						docTypes.push({
							label: i18n.t("general.general"),
							value: type
						})
					}
					if (type === "GENERIC") {
						docTypes.push({
							label: i18n.t("billing.generic"),
							value: type
						})
					}

				})
			}
			docTypes.sort(function (a, b) {
				if (a.label === i18n.t("general.noDocType")) {
					return -1
				} else if (b.label === i18n.t("general.noDocType")) {
					return 1
				}
				const textA = a.label.toUpperCase()
				const textB = b.label.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
			return docTypes
		})
		let legalEntitiesNames = computed(() => {
			let leNames = []
			let legalEntities = store.state.legalEntitiesData.data.legalEntities;

			for (const le in legalEntities) {
				let name = '';
				if (userGroups.optipostNames.includes(store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(store.state.currentUserData.data.group)) {
					name = legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				} else {
					name = +legalEntities[le].name
				}

				let tempItem = {
					label: name,
					value: legalEntities[le].entityNumber
				}
				leNames.push(tempItem);
			}

			leNames.sort(function (a, b) {
				const textA = a.label.toUpperCase()
				const textB = b.label.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
			leNames.unshift({
				label: i18n.t('general.allLegalEntity'),
				value: null
			})

			return leNames
		})
		let displayResendByButton = computed(() => {
			return (userGroups.supplierNames.includes(store.state.currentUserData.data.group)
				|| userGroups.optipostNames.includes(store.state.currentUserData.data.group)) && store.state.currentUserData.data.group !== "SupplierLimitedSubUser";
		})

		watch(() => selectedDocuments.value, (newVal) => {
			selectedDocuments.value = newVal
		})
		watch(() => store.state.documentErrorHandled, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					tabulator.value.destroy()
					initTabulator();

				}, 2000)
			}
		})
		watch(() => store.state.documentDeleted, (newVal) => {
			if (newVal === true) {
				setTimeout(() => {
					refreshTable();
				}, 2000)
				refreshTable();
			}
			store.state.documentDeleted = false;
		})
		watch(() => props.token, (newVal) => {
			token.value = newVal
		})
		const cancelResend = () => showResendWindow.value = false
		const showResendDocumentWindow = () => {
			store.dispatch("updateToken")
			store.commit('displayResendDocument', true)
			showResendDocument.value = true
		}

		const refreshTable = () => {
			tabulator.value.destroy()
			selectedDocuments.value = []
			initTabulator()
			tabulator.value.on('dataLoaded', () => {
				setLocale()
			})
		}
		const setFilter = async () => {
			store.state.extendedSearch.overview.quickSearch = null
			refreshTable()
		}
		const resetFilters = () => {
			delete store.state.extendedSearchValue.extendedSearch
			store.state.extendedSearchValue.extendedSearch = {
				legalEntityNumber: {label: 'general.allLegalEntity', value: null}
			}
			store.state.extendedSearch.overview.quickSearch = null
			refreshTable()
		}

		const unselectDocuments = () => {
			selectedDocuments.value = []
			selectedDocsData.value = []
			let checkboxes = document.getElementsByClassName("customCheckbox")
			for (let test in checkboxes) {
				if (checkboxes[test]?.checked) {
					checkboxes[test].checked = false
				}
			}
		}

		const initTabulator = async () => {
			let extendedSearch = JSON.parse(JSON.stringify(store.state.extendedSearchValue.extendedSearch))
			const searchLegalEntityNumber = store.state.extendedSearchValue.extendedSearch.legalEntityNumber
			const released = store.state.extendedSearchValue.extendedSearch.released
			// error channels
			if (extendedSearch.errorChannels) {
				if (extendedSearch.errorChannels.value === "ALL") {
					let all = []
					errorChannelsOptions.value.forEach(channel => {
						all.push(channel.value)
					})
					extendedSearch.errorChannels = all
				} else {
					extendedSearch.errorChannels = [extendedSearch.errorChannels.value]
				}
			}
			// document type
			if (extendedSearch.documentType) {
				if (extendedSearch.documentType.value === "MANDATE_ONBOARDING" || extendedSearch.documentType.value === "DUNNING") {
					extendedSearch.generalDocumentType = extendedSearch.documentType.value
					delete extendedSearch.documentType
				} else extendedSearch.documentType = extendedSearch.documentType.value
			}
			// paymentStatus
			if (extendedSearch.paymentStatus) {
				extendedSearch.paymentStatus = extendedSearch.paymentStatus.value
			}

			delete extendedSearch.legalEntityNumber
			delete extendedSearch.released
			if (extendedSearch.includeHandled === false) delete extendedSearch.includeHandled

			tabulator.value = new Tabulator(tableRef.value,
				{
					ajaxURL: requestUrl.value,
					ajaxConfig: {
						method: "POST",
						headers: {
							'Authorization': 'bearer ' + store.state.token,
							'Accept': 'application/json',
						},
					},
					ajaxParams: {
						extended: extendedSearch,
						filters: {
							clientIds: null,
							legalEntityIds: null,
							published: true,
							visible: null,
							held: released
						},
						legalEntityNumber: searchLegalEntityNumber?.value !== undefined ? searchLegalEntityNumber.value : null,
						pageNo: 1,
						pageSize: pageSize.value,
						quick: store.state.extendedSearch.overview.quickSearch,
						sortOn: null,
						supplierGroupId: supplierGroupId.value,
						general: general.value,
						salary: salary.value,
						billing: billing.value,
					},
					ajaxResponse: function (url, params, response) {
						let data = [];
						const res = response.data.items;

						totalDocuments.value = response.data.numResults;

						for (let item of res) {
							// for the resend button
							const payment = getPaymentStatus(item.data.channels.payment, calcDueDate(item.data, item.opTableInfo), getDocType(item.data, item.docType))

							let logoPath = store.state.legalEntitiesData.data.legalEntities[item.data.legalEntity.entityNumber]?.legalEntityConfig

							let legalEntityName = item.data.legalEntity.name
							if (userGroups.optipostNames.includes(store.state.currentUserData.data.group)
								|| userGroups.supplierNames.includes(store.state.currentUserData.data.group)){
								let le = store.state.legalEntitiesData.data.legalEntities[item.data.legalEntity.entityNumber]
								if (le.internalName) legalEntityName = le.internalName
							}
							let tempItem = {
								viewPropertiesColumns: setEnvelopIcon(item.data),
								logo: logoPath ? (logoPath.logo ? logoPath.logo : 'no-logo') : 'no-logo',
								legalEntityName: legalEntityName,
								docType: getDocType(item.data, item.docType),
								customerName: item.opTableInfo.receiver.receiverName,
								clientId: item.data.billingData && item.data.billingData.clientId ? item.data.billingData.clientId : item.opTableInfo.receiver.receiverId,
								docNumber: item.data.documentNumber,
								visibility: item.data.visible ? `<i class="icon icon-eye1 icon-color visibility-doc-icon"></i>` : `<i class="icon icon-eye-blocked icon-color visibility-doc-icon"></i>`,
								docDate: item.data.salaryData ? item.data.salaryData.documentDate : item.opTableInfo.documentDates.documentDate,
								dueDate: calcDueDate(item.data, item.opTableInfo),
								amount: getAmount(item.data),
								payment: payment.payment,
								paymentStatusTooltip: payment.paymentStatusTooltip,
								documentData: item,
								attachmentFileIcon: getAttachments(item.data.fileStructure.attachmentsFileNames, store.state.docType),

								startDate: item.data.generalDocumentData && item.data.generalDocumentData.startDate ? item.data.generalDocumentData.startDate : '',
								endDate: item.data.generalDocumentData && item.data.generalDocumentData.endDate ? item.data.generalDocumentData.endDate : '',
								licensePlate: item.data.carInsuranceData ? item.data.carInsuranceData.licensePlate : '',

								// salary
								period: item.data.salaryData ? item.data.salaryData.period : '',
								employee: item.opTableInfo.receiver && item.opTableInfo.receiver.receiverName ? item.opTableInfo.receiver.receiverName : '',

								// resend doc
								docId: item.data.docId,
								legalEntityId: item.opTableInfo ? item.opTableInfo.legalEntity.legalEntityId : item.data.legalEntity.entityNumber,

								// peppol
								noVat: true,

								// receiverView
								paymentButton: getPaymentButton(item.data.channels.payment, item.data.documentType),
								paymentButtonTooltip: i18n.t('tooltips.payNow'),
							}
							data.push(tempItem)

							// to know if send button has to be active
							if (getDocType(item.data, item.docType) === "mandateonboarding") {
								if (new Date(tempItem.dueDate).getTime() < Date.now()) {
									docIdsOnboardings.value.push({docId: tempItem.docId, dueDate: tempItem.dueDate})
								}
							}
							if (item.data.held === true) unreleasedDocs.value.push(item.data.docId)

						}

						let last_page = Math.ceil(response.data.numResults / pageSize.value);
						let formatedData = {
							last_page: last_page,
							data: data,
						}

						tabulator.value.on('tableLoaded', () => {
							store.commit("setTablePage", {field: "overview", pageNo: tabulator.value.getPage()})
						})
						return formatedData; //return the tableData property of a response json object
					},
					dataSendParams: {
						page: "pageNo",

						filter: "quick"
					},
					ajaxURLGenerator: function (url, config, params) {
						updateToken();
						store.commit("setOverviewPageNumber", params.pageNo);
						store.commit('overviewTableSort', true)

						if (store.state.tableSorters.length > 0 && store.state.tableSorters[0].order && params.sort?.length === 0
							&& store.state.tableSorters[0].place === 'overview' || store.state.tableSorters[0].place === '') {
							params.sortOn = {
								order: store.state.tableSorters[0].order.toUpperCase(),
								sortField: store.state.tableSorters[0].sortField
							}
						} else if (params.sort?.length > 0) {
							let sortFields = {
								licensePlate: 'LICENSE_PLATE',
								startDate: 'GENERAL_START_DATE',
								endDate: 'GENERAL_END_DATE',
								customerName: 'CLIENT',
								docType: 'DOCUMENT_TYPE',
								docNumber: 'DOCUMENT_NUMBER',
								dueDate: 'DUE_DATE',
								payment: 'PAY_STATUS',
								amount: 'AMOUNT',
								customerId: 'CLIENT',
								period: 'PERIOD',
								docDate: 'DOCUMENT_DATE',
								employee: 'EMPLOYEE'
							}

							store.commit('setTableSorters',
								{
									place: 'overview',
									order: params.sort[0].dir.toUpperCase(),
									sortField: sortFields[params.sort[0].field].toUpperCase()
								})
							params.sortOn = {
								order: params.sort[0].dir.toUpperCase(),
								sortField: sortFields[params.sort[0].field]
							}
						}

						return url;
					},
					dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
					headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
					ajaxContentType: "json",
					ajaxFiltering: true,
					printAsHtml: true,
					printStyled: true,
					pagination: true,
					sortMode: "remote",
					paginationMode: 'remote',
					paginationSize: pageSize.value,
					paginationInitialPage: store.state.tableInitialPage.overview,
					layout: 'fitColumns',
					responsiveLayout: 'collapse',
					placeholder: i18n.t('placeholders.noMatchingFound'),
					locale: true,

					langs: {
						"en-us": en,
						"nl-nl": nl,
						"de-de": de,
						"fr-fr": fr,
						"sk-sk": sk,
						"es-es": es,
					},
					columns: tableColumns.value,
				})

			tabulator.value.on('rowClick', (e, row) => {
				// e.detail tests how many times you clicked. Whe only want to do the call once and not twice when you would do a double click
				// otherwise it gave errors when you double-clicked
				if (e.detail === 1) {
					// Timeout so that the cell click event is before the row click event
					setTimeout(async () => {
						if (store.state.tabulator.isClickBlocked === false) {
							await store.dispatch('documents/displayDetailPage', {
								docData: row._row.data.documentData,
								mouseEvent: 'left'
							})
						} else store.state.tabulator.isClickBlocked = false
					}, 300)
				}
			})

			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
			tabulator.value.on('dataLoaded', () => {
				tableIsLoading.value = false
				store.commit("setOverviewTableRefreshed", true)
				selectAllDocuments();
			})
			tabulator.value.on("headerClick", function (e, column) {
				if (column._column.field === 'docId') {
					selectAllDocuments();
				}
			});
			tabulator.value.on("tableBuilt", async function () {
				store.state.extendedSearchValue.extendedSearch.legalEntityNumber = searchLegalEntityNumber
			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		watch(() => store.state.locale, () => {
			setLocale()
		})
		const tokenIsExpired = () => {
			let exp;
			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp);
			}
			let now = parseInt(new Date().getTime() / 1000);

			return exp - now <= 0;
		}
		const updateToken = () => {
			if (tokenIsExpired()) {
				let payload = {
					client_id: env.keycloakClient,
					grant_type: "refresh_token",
					refresh_token: store.state.refreshToken
				}

				let body = qs.stringify(payload);
				axios.post(store.state.API.keycloakToken, body, {
						headers: {
							'Content-Type': "application/x-www-form-urlencoded"
						}
					}
				).then(res => {
					store.commit('setToken', res.data.access_token)
					store.commit('setRefreshToken', res.data.refresh_token)
					initTabulator()
					selectAllDocuments()
				})
					.catch(() => {
						store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
						store.commit('displayNotification', true);
					})

				// keycloak.updateToken(30)
				//       .then(function (refreshed) {
				//           if (refreshed) {
				//               store.commit('setToken', keycloak.token)
				//               initTabulator();
				//               selectAllDocuments();
				//           }
				//       })
				//       .catch(function () {
				//
				//           store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
				//           store.commit('displayNotification', true);
				//       })
			}

		}
		const setLocale = () => {
			if (store.getters.getLocale === 'en') tabulator.value.setLocale(store.getters.getLocale + '-us')
			else tabulator.value.setLocale(store.getters.getLocale + '-' + store.getters.getLocale)
		}
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw();
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}
// Filter function
		const onFilter = async () => {
			store.commit("setTablePage", {field: "overview", pageNo: 1})
			await initTabulator()
			tabulator.value.on('dataLoaded', () => {
				setLocale()
			})
		}
		const selectAllDocuments = () => {
			selectedDocuments.value = [];
			selectedDocsData.value = [];
			let downloadCheckboxes = document.getElementsByClassName('downloadCheckbox');
			if (document.getElementById('selectAllDocuments').checked === true) {
				for (let checkbox of downloadCheckboxes) {
					selectedDocuments.value.push(checkbox.value);
					let tempItem = {
						docId: checkbox.value,
						legalEntityId: checkbox.getAttribute('data-leid'),
						noVat: checkbox.getAttribute('data-noVat'),
					}
					selectedDocsData.value.push(tempItem)
					checkbox.checked = true;
				}
			} else {
				for (let checkbox of downloadCheckboxes) {
					checkbox.checked = false;
				}
			}
		}
		const download = (type) => {
			store.dispatch("updateToken");
			store.dispatch("documents/downloadZip", {ids: selectedDocuments.value, type: type});
			let downloadCheckboxes = document.getElementsByClassName('downloadCheckbox');
			for (let checkbox of downloadCheckboxes) {
				checkbox.checked = false;
			}
			selectedDocuments.value = [];
			document.getElementById('selectAllDocuments').checked = false;
			unselectDocuments()
		}
		const handleErrors = () => {
			store.dispatch("updateToken");
			store.commit("setDocumentErrorHandled", false);
			store.commit("setDisplayHandleError", true);
		}

		onMounted(async () => {
			await initTabulator();
			// setExtendedSearchValues();

			reInitOnResizeWindow();
			tabulator.value.on('dataLoaded', () => {
				setLocale();
				selectAllDocuments();
			})
		})

		return {
			displayButton,
			displayTooltipResendEmail,
			displayTooltipUnselectOnboardingResendEmail,
			displayTooltipDocumentNotVisibleResendEmail,
			displayTooltipUnreleasedDocuments,
			tableRef,
			onFilter,
			resetFilters,
			format,
			selectedDocuments,
			displayHandleErrors,
			totalDocuments,
			download,
			handleErrors,
			tabulator,
			refreshTable,
			documentTypes,
			legalEntitiesNames,
			isResendButtonDisabled,
			isHandleErrorsDisabled,
			setFilter,
			docTypeError,
			documentType,
			displayForInvoice,
			showExtendedSearchButton,
			unselectDocuments,
			selectedDocsData,
			showResendWindow,
			cancelResend,
			showResendDocumentWindow,
			displayResendByButton,
			showResendDocument,
		}
	}

})
</script>

<style scoped>

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	margin-bottom: -20px;
}

#selectAllDocuments {
	justify-self: center;
}

.action-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 37px
}

.quick-search-container {
	display: flex;
	flex-direction: row;
	flex-basis: 400px;
}

.buttons-container {
	display: flex;
	flex-direction: row;
}

.download {
	justify-self: end;
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.download-outer button {
	margin-left: 5px;
}

.button-text {
	padding: 0.5rem 0.75rem;
}

.btn span {
	grid-column: 1/2;
	grid-row: 1/2;
}


.extended-search {
	margin-top: 10px;
	width: 740px;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto auto;
	grid-gap: 10px;
}

.extended-search-check-box {
	margin-top: 10px;
	line-height: 1.5em;
}

.search-button {
	grid-column: 1/3;
	justify-items: center;
	display: grid;
}

.extended-search-check-box span {
	position: relative;
	top: -5px;
}

.margin-right {
	margin-right: 10px;
}

.date-picker-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
}

.date-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.icon-calendar {
	z-index: 2;
	position: absolute;
	margin-top: 15px;
	margin-left: 10px;
}

.resend-button {
	min-width: 140px;
}

.includeHandled {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1030px) {
	.action-container {
		display: flex;
		flex-direction: column;
		height: 37px;
		position: relative;
	}

	.quick-search-container {
		display: flex;
		flex-direction: row;
		width: 400px;
		height: 37px
	}

	.buttons-container {
		display: flex;
		flex-direction: row;
		margin-top: 10px;
		height: 37px;
	}

	.extended-search-check-box {
		margin-top: 55px !important;
		line-height: 1.5em;
	}

}

@media only screen and (max-width: 980px) {
	.margin-small-screen-table {
		margin-top: 10px;
	}
}

@media only screen and (max-width: 840px) {
	.extended-search > div, .extended-search > input {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 739px) {
	.quick-search-container {
		display: flex;
		flex-direction: column;
		width: 400px;
		height: 37px
	}

	.margin-small-screen {
		margin-top: 5px;
	}

	.buttons-container {
		margin-top: 45px;
	}
}

@media only screen and (max-width: 430px) {
	.resend-button {
		min-width: 120px;
	}

	.totalNumberDocuments, .extended-search-check-box {
		font-size: 12px;
	}

}

@media only screen and (max-width: 420px) {
	.extended-search {
		width: 95%;
	}

}

.tabulator-alert {
	opacity: 0;
}

#tabulator > .tabulator-alert {
	background: none !important;
}

.current-element::before {
	content: '';
	background-color: lightblue; /* Example style to mimic container change */
	z-index: -1; /* Ensure it doesn't cover actual content */
}

.show-tooltip {
	display: flex;
	align-items: center;
}


>>> .show-tooltip:hover > .tooltip {
	display: block;
}

.tooltip {
	position: absolute;
	display: none;
	margin-top: 30px;
	padding: 1px;
	top: 20px;
	background-color: var(--theme-primary-500);
	color: white;
	border-radius: 5px;
	text-align: center;
	opacity: 0.7;
}

</style>