<template>
    <pop-up-window :show="this.$store.state.postDrop.pendingTable.displayDeleteBatch"
                   :showClose=false
                   class="doc-statuses-popup"
                   additional-info=""
                   :positionFixed="true"
                   :top0="true"
                   :stickToScrollPosition="true"
                   :bottomDiv="false"
    >
        <template #title>
            <h1 class="text-2xl">
                {{ $t('postDrop.deleteBatch.title') }}
            </h1>
        </template>
        <template #content>
            <h2 class="text-base text-center mb-2">
                {{ $t('postDrop.deleteBatch.description') }} {{batchId}}
            </h2>
            <div class="grid grid-rows-1 justify-items-center my-6 grey-line">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
                        {{ $t('buttons.cancel') }}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="deleteBatch">
                        {{ $t('buttons.delete') }}
                    </button>
                </div>
            </div>
            </template>
    </pop-up-window>

</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow";
export default {
    name: "DeleteBatch",
    components: {PopUpWindow},
    data(){
        return{
        }
    },

    computed:{
        batchId(){
          return this.$store.state.postDrop.pendingTable.deleteBatchData
        }
    },

    methods:{
        deleteBatch(){
            this.$store.dispatch('deleteBatch', this.batchId);
            this.close();
        },
        close(){
            this.$store.commit("setDeleteBatchData", '')
            this.$store.commit('displayDeleteBatch', false);
        }
    }
}
</script>

<style scoped>

</style>
