<template>
	<div>
		<pop-up-window :show="this.$store.state.displayHandleError"
					   :showClose=false
					   :positionFixed="true"
					   :top20=true
		>
			<template #title>
				<h1 data-test="title" class="">{{ $t('documentDetail.handleError.title') }}</h1>
			</template>
			<template #content>
				<h2 class="mb-2 text-base text-center" data-test="description">
					{{ $t('documentDetail.handleError.description') }}
				</h2>
				<div class="outer">
					<div class="inner">
                    <textarea
						data-test="inputFieldReason"
						@click="setErrors('reason', false)"
						v-model="reason"
						class="form-control text-popup text-bigger"
						:placeholder="$t('placeholders.reason')"></textarea>
						<p data-test="errorRequiredField" v-if="errors.reason" class="error mt-2">
							{{ $t('errors.requiredField') }}
						</p>
					</div>
				</div>

				<div class="grid grid-rows-1 justify-items-center my-6 grey-top-border">
					<div>
						<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" data-test="btncancel"
								@click="closeHandleError">
							{{ $t('buttons.cancel') }}
						</button>
						<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="handleError" data-test="btnHandleError">
							{{ $t('buttons.handleError') }}
						</button>
					</div>
				</div>
			</template>
		</pop-up-window>
	</div>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue";

export default {
	name: "HandleError",
	components: {PopUpWindow},
	emits: ["unselectDocuments"],
	props: {
		docIds: {
			type: Array,
			required: false,
			default: null
		}
	},
	data() {
		return {
			reason: '',
			errors: {
				reason: false
			},
		}
	},
	methods: {
		async handleError() {
			if (this.reason === '') {
				this.setErrors("reason", true);
				return
			}

			this.$store.state.displayHandleError = false
			this.$store.state.errorsFixedByUser = true
			this.$store.commit("setDocumentErrorHandled", false);
			let docIds = [];

			if (this.docIds) {
				docIds = this.docIds
			} else {
				docIds = [this.$store.state.documentDetailData.docId]
			}
			await this.$store.dispatch("documents/handleErrors", {
				formData: {
					docIds: docIds,
					reason: this.reason
				},
				routeName: this.$route.name
			});

			this.$store.state.displayHandleError = true
			this.closeHandleError();
			this.$emit("unselectDocuments")
		},

		setErrors(field, value) {
			this.errors[field] = value;
		},

		closeHandleError() {
			this.reason = '';
			this.setErrors("reason", false);
			this.$store.commit("setDisplayHandleError", false)
		}
	}
}
</script>

<style scoped>
.text-bigger {
	height: 100px;
}

.outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: center;
}

.inner {
	width: 60%;
}

</style>
