<template>
	<transition name="dialog">
		<page-container class="page-content">
			<top-banner>
				<div class="w-9/12">
					<h1 class="text-3xl text-center">{{ $t('ubl.header') }}</h1>
				</div>
			</top-banner>
			<div class="box page-content intro-y box p-5 mt-5" v-if="checkResetPassword">
				<div class="button-container">
					<div class="button-container-inner">
						<button
							class="btn custom-button"
							:class="{ inactive: activeTab !== 'UblTable' }"
							@click="setActiveTab('UblTable')"
						>
							{{ $t('postDrop.tabs.statuses') }}
						</button>
						<button
							class="btn custom-button"
							:class="{ inactive: activeTab !== 'validateUBL' }"
							@click="setActiveTab('validateUBL')"
						>
							{{ $t('ubl.ublValidateButton') }}
						</button>
						<button
							class="btn custom-button"
							:class="{ inactive: activeTab !== 'peppolParticipants' }"
							@click="setActiveTab('peppolParticipants')"
						>
							{{ $t('ubl.peppolParticipants.buttonPeppolTab') }}
						</button>
					</div>
				</div>
				<div class="component-container">
					<ubl-table v-if="activeTab === 'UblTable' "
							   :supplierGroupId="this.$store.state.supplierGroup.data.supplierGroupId"
							   :requestUrl="this.requestUrl"
					></ubl-table>
					<validate-u-b-l
						v-if="activeTab === 'validateUBL'"
						:dropdownOptions="dropdownOptions"
					></validate-u-b-l>
					<find-peppol-participants v-if="activeTab === 'peppolParticipants'"/>
				</div>
			</div>
		</page-container>
	</transition>
</template>

<script>
import ValidateUBL from '../components/ubl/ValidateUBL.vue'
import TopBanner from "../components/TopBanner"
import PageContainer from "../components/PageContainer"
import routerData from "../routerData"
import userGroups from "../userGoups"
import store from "../store"

const newLoginPage = window.newLoginPage
import UblTable from '@/components/ubl/UblTable'
import i18n from "../i18n"
import FindPeppolParticipants from "@/components/ubl/findPeppolParticipants.vue"

export default {
	name: "UblPage",
	components: {
		FindPeppolParticipants,
		PageContainer, TopBanner, UblTable, ValidateUBL
	},
	mounted() {
		this.$store.dispatch("updateToken")
	},
	created() {
		this.$store.dispatch("checkAcceptedTos", {requiresAuth: this.$route.meta.requiresAuth})
		this.passwordReset()
		this.checkUser()
	},
	data() {
		return {
			activeTab: 'UblTable',
			requestUrl: this.$store.state.domainName + this.$store.state.API.searchUbl,
			dropdownOptions: [
				"BIS_BILLING_INVOICE_V3",
				"BIS_BILLING_CREDIT_NOTE_V3",
				"INVOICE_RESPONSE_3_0",
				"ORDER_3_0",
				"ORDER_AGREEMENT_3_0",
				"ORDER_RESPONSE_3_0",
				"CATALOGUE_3_0",
				"CATALOGUE_RESPONSE_3_0",
				"PUNCH_OUT_3_0",
				"DESPATCH_ADVICE_3_0",
				"MESSAGE_LEVEL_RESPONSE_3_0",
				"ORDER_CHANGE",
				"ORDER_CANCELLATION",
				"ORDER_RESPONSE_ADVANCED",
			]
		}
	},
	computed: {
		checkResetPassword() {
			return !(newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)))

		},
		locale() {
			return this.$store.state.locale
		}
	},
	watch: {
		locale() {
			let tooltipsData = [
				{cellName: "view-details", translationField: 'viewDetails'},
				{cellName: "download-xml", translationField: 'downloadXml'},
				{cellName: "incoming", translationField: 'incoming'},
				{cellName: "outgoing", translationField: 'outgoing'},
			];
			tooltipsData.forEach(tooltip => {
				this.changeTooltipsTranslation(tooltip.cellName, tooltip.translationField)
			})
		}
	},
	methods: {
		setActiveTab(tab) {
			this.activeTab = tab
		},
		changeTooltipsTranslation(cellName, cellTranslationField) {
			let cellClass = '.' + cellName
			let cells = document.querySelectorAll(cellClass)
			let key = 'tooltips.' + cellTranslationField
			if ((cells.length) !== 0) {
				cells.forEach((cell) => {
					cell.innerHTML = i18n.t(key)
				})
			}
		},
		checkUser() {
			if (this.$store.state.currentUserData.data.id) {
				if (!(this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.peppol)) {
					this.$router.push({path: routerData.notFound.link})
				}

				if (!(userGroups.supplierNames.includes(store.state.currentUserData.data.group)
					|| userGroups.optipostNames.includes(store.state.currentUserData.data.group)
					|| this.$store.state.currentUserData.data.departments)) {
					this.$router.push({path: routerData.noAccess.link})
				}
			}
		},
		passwordReset() {
			if (newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))
			) {
				this.$store.commit("setDisplayResetOwnPassword", true);
			}
		}
	}
}
</script>

<style scoped>
.inactive {
	background-color: var(--theme-primary-100);
	border-color: rgba(85, 85, 85, var(--theme-primary-100));
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.button-container-inner {
	display: grid;
	grid-template-columns: repeat(20, auto);
	grid-template-rows: 100%;
}

.custom-button {
	margin-left: 10px;
}

.component-container {
	margin-top: var(--generalPaddings);
	padding-top: var(--generalPaddings);
	border-top: 1px solid var(--theme-primary-50);
}
</style>