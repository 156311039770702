<template>
    <div class="edit-profile box"
         v-if="this.$store.state.supplierGroup.isReady && this.$store.state.currentUserData.isReady"
    >
        <div class="edit-profile-header pop-up-text-styles" :style="{'background-color': brandSettings.mainColor}">
            <h3>{{ $t('profile.editProfile') }}</h3>
        </div>
        <div class="edit-content ">
            <div class="form-container ">
                <h1 class="form-control">{{ this.$store.state.currentUserData.data.email }}</h1>
                <v-select
                      v-model="selectedLang"
                      :options='options'
                      :clearable = 'false'
                >
                    <template #no-options="{search}">
                        {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                    </template>
                </v-select>
                <h1 class="form-control"><span
                      class="color-grey">{{ $t('general.accessType') }}: </span>{{ accessType }}</h1>
                <h1 class="form-control"><span
                      class="color-grey">{{ $t('general.accessRight') }}: </span>{{ accessRight }}</h1>
            </div>
            <div class="btn-content">
                <div>
                    <button button class="btn custom-button shadow-md mr-2" @click="closeEditProfile">
                        {{ $t('buttons.cancel') }}
                    </button>
                    <button button class="btn custom-button shadow-md mr-2" @click="changeUserData">
                        {{ $t('buttons.save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userGroups from "../userGoups";


export default {
    name: "EditProfile",

    data() {
        return {
            userGroups: userGroups,
            selectedLang: { label : this.$store.state.currentUserData.data.language.toUpperCase() , value: this.$store.state.currentUserData.data.language.toLowerCase()},
            legalEntities: this.$store.state.currentUserData.data.legalEntities,
            email: this.$store.state.currentUserData.data.email,
            brandSettings: this.$store.state.supplierGroup.branding
        }
    },
    watch:{
        currentUser(newVal){
            if (newVal && newVal.language){
                this.selectedLang= { label : newVal.language , value: newVal.language.toLowerCase()}
            }
        }
    },
    computed: {
        currentUser(){
            return this.$store.state.currentUserData.data;
        },
       options(){
           let options = []
           this.$store.state.supplierGroup.data.languages.forEach(lang =>{
               options.push ({  label: lang, value: lang.toLowerCase()})
           })
           return options
       },
        group() {
            let group = '';
            if (this.$store.state.currentUserData.data.group){
                group = this.$store.state.currentUserData.data.group
            } else {
                group = this.$store.state.currentUserData.data.roles[this.$store.state.supplierGroup.data.supplierGroupId][0]
            }
            return group
        },
        accessRight() {
            if (this.$store.state.userIsAuthenticated) {
                return this.userGroups[this.group].accessRight;
            }
            return null
        },
        accessType() {
            if (this.$store.state.userIsAuthenticated) {
                return this.userGroups[this.group].accessType
            }
            return null
        },
    },
    methods: {
        changeUserData() {
            let formData = {};
            if (this.$store.state.currentUserData.data.group === "OpAdmin") {
                formData = {
                    group: this.group,
                    language: this.selectedLang.value.toUpperCase(),
                    legalEntities: [],
                }
            } else {
                if(!this.$store.state.currentUserData.data.legalEntities){
                    this.$store.state.currentUserData.data.legalEntities = []
                }
                let le = this.$store.state.currentUserData.data.legalEntities;
                le.forEach((item) => {
                    item.add = [];
                    item.remove = [];
                })
                formData = {
                    group: this.group,
                    language: this.selectedLang.value.toUpperCase(),
                    legalEntities: this.legalEntities,
                }
            }
            let data = {
                formData: formData,
                userId: this.$store.state.currentUserData.data.id,
            }
            this.$store.dispatch('users/updateUserProfile', data);
            this.$emit('close');
        },
        changeUserLang() {
            this.selectedLang = document.querySelector("#userLang").value.toLowerCase();
        },

        closeEditProfile() {
            this.$emit('close');
            this.selectedLang = { label : this.$store.state.currentUserData.data.language , value: this.$store.state.currentUserData.data.language.toLowerCase()};
        }
    }
}
</script>

<style scoped>
.edit-profile {
    position: absolute;
    top: 30%;
    left: calc(50% - 20rem);
    width: 40rem;
    z-index: 100;
}

.edit-profile-header {
    height: 100px;
    display: grid;
    grid-template-rows:100%;
    grid-template-columns: 100%;
    grid-auto-flow: row;
    color: white;
    border-radius: 0.375rem 0.375rem 0 0;

    padding: calc(0.5 * var(--generalPaddings)) 30px;
}

.edit-profile-header h3 {
    align-self: center;
    justify-self: center;
}

.edit-profile-header span {
    cursor: pointer;
}

.edit-content {
    padding: 2rem;
    border: 1px solid #d9d9d9;
}

.btn-content {
    margin-top: var(--generalPaddings);
}

.form-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    grid-auto-flow: row;
    border-bottom: 1px solid var(--theme-primary-50);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding-bottom: var(--generalPaddings);
}

@media only screen and (max-width: 600px) {
    .form-container {
        grid-template-columns: 100%;
        grid-template-rows: repeat(4, 25%);
    }


}

@media only screen and (max-width: 520px) {
    .edit-profile-header {
        height: auto;
    }
}

@media only screen and (max-width: 700px) {
    .edit-profile {
        left: 10vw;
        width: 80vw;
    }

}
</style>
