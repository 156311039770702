<template>
	<div >
		<pop-up-window
			:show="showStatuses && showInPopUp"
			:showClose="false"
			:positionFixed=true
			:top10=true
			:stickToScrollPosition=true
		>
			<template #title>
				<h1 class="text-2xl"><i class="icon-bell"></i>
					{{ $t('documentDetail.documentStatusesInfo.documentStatuses') }}
				</h1>
			</template>
			<template #content>
					<document-statuses
						:docStatusData="docStatusData"
						:noHeader=true
					>
					</document-statuses>
				<div class="button-container-doc-statuses ml-5">
					<button class="btn custom-button shadow-md text-popup" @click="closeDocStatuses">
						{{ $t('buttons.close') }}
					</button>
				</div>
			</template>
		</pop-up-window>

		<document-statuses v-if="!showInPopUp" :docStatusData="docStatusData"/>
	</div>
</template>

<script>
import DocumentStatuses from "../statuses/DocumentStatuses.vue";
import PopUpWindow from "../../UI/PopUpWindow.vue";

export default {
	name: "DocumentStatusViewer",
	components: {PopUpWindow, DocumentStatuses},
	props: {
		docStatusData: Object,
		showStatuses: Boolean,
		showInPopUp: Boolean
	},
	emits: ['closeDocStatuses'],
	methods: {
		closeDocStatuses() {
			this.$emit('closeDocStatuses')
		}
	}
}

</script>
