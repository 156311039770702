<template>
	<div class="mt-3.5">
		<div class="one-line mb-2.5">
			<input
				type='text'
				v-model="extendedSearch.transactionId"
				:placeholder="$t('placeholders.transactionId')"
				class="form-control"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
			>
			<input
				type='text'
				v-model="extendedSearch.receiverId"
				:placeholder="$t('placeholders.receiverId')"
				class="form-control"
				:class="showInOneColumn ? '' : 'ml-2.5'"
			>
		</div>
		<div class="one-line mb-2.5">
			<input
				type='text'
				v-model="extendedSearch.documentNumber"
				:placeholder="$t('placeholders.documentNumber')"
				class="form-control"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
			>
			<input
				type='text'
				v-model="extendedSearch.mandateId"
				:placeholder="$t('placeholders.mandateId')"
				class="form-control"
				:class="showInOneColumn ? '' : 'ml-2.5'"
			>
		</div>
		<!--Legal entity & docType-->
		<div class="one-line w-full mb-2.5">
			<vue-select
				v-model="extendedSearch.errorCode"
				:options='optionsErrorCode'
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:class="showInOneColumn ? (errorExplanation !== '' ? 'mb-2.5' : '') : 'mr-2.5'"
				:clearable="false"
			>
				<template #option="option">
					{{ option.label }}
				</template>
				<template #selected-option="option">
					{{ option.label }}
				</template>
			</vue-select>
			<div class="error-explanation" :style="showInOneColumn ? {width: '100%'} : {width: '50%'}" :class="showInOneColumn ? '' :'ml-2.5'">
				<h2>{{ errorExplanation }}</h2>
			</div>
		</div>
		<div class="one-line w-full mb-2.5">
			<vue-select
				v-model="extendedSearch.handled"
				:options='optionsHandled'
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:clearable="false"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
				<template #option="option">
					{{ $t(option.label) }}
				</template>
				<template #selected-option="option">
					{{$t(option.label) }}
				</template>
			</vue-select>
			<div class="ml-2.5" style="width: 50%"/>
		</div>
	</div>
</template>

<script>

import VueSelect from "vue3-select";
import i18n from "@/i18n";

export default {
	components: {VueSelect},
	data() {
		return {
			extendedSearch: {},
			showInOneColumn: window.innerWidth < 980,
		}
	},
	created() {
		this.extendedSearch = {
			transactionId: '',
			receiverId: '',
			documentNumber: '',
			mandateId: '',
			errorCode: {label: 'Error code', value: ''},
			handled: {label: 'dropdowns.failedTransactions.allTransactions', value: ''}
		}
	},
	mounted(){
		for (let filterElement in this.$store.state.extendedSearchValue.extendedSearch) {
			if (this.$store.state.extendedSearchValue.extendedSearch[filterElement] !== undefined) {
				this.extendedSearch[filterElement] = this.$store.state.extendedSearchValue.extendedSearch[filterElement]
			}
		}

		window.addEventListener('resize', () => this.showInOneColumn = 'change var')
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler() {
				this.extendedSearch = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch))
				if (!this.extendedSearch.errorCode) this.extendedSearch.errorCode = {label: 'Error code', value: ''}
				if (!this.extendedSearch.handled) this.extendedSearch.handled = {label: 'dropdowns.failedTransactions.allTransactions', value: ''}
				if (!this.extendedSearch.transactionId) this.extendedSearch.transactionId = ''
				if (!this.extendedSearch.receiverId) this.extendedSearch.receiverId = ''
				if (!this.extendedSearch.documentNumber) this.extendedSearch.documentNumber = ''
				if (!this.extendedSearch.mandateId) this.extendedSearch.mandateId = ''
			},
			deep: true
		},
		showInOneColumn() {
			this.showInOneColumn = window.innerWidth < 980
		}
	},
	computed: {
		optionsHandled() {
			return [
				{label: 'dropdowns.failedTransactions.allTransactions', value: ''},
				{label: 'dropdowns.failedTransactions.handled', value: true},
				{label: 'dropdowns.failedTransactions.unHandled', value: false},
			]
		},
		errorExplanation() {
			let errorTextExplanation = '';
			if (this.extendedSearch.errorCode.value !== '') {
				let key = 'failedTransactions.errorTypes.' + this.extendedSearch.errorCode.value;
				errorTextExplanation = i18n.t(key);
			}

			return errorTextExplanation
		},
		optionsErrorCode() {
			return [
				{label: 'Error code', value: ''},
				{label: 'AC01', value: 'AC01'},
				{label: 'AC04', value: 'AC04'},
				{label: 'AC06', value: 'AC06'},
				{label: 'AC13', value: 'AC13'},
				{label: 'AG01', value: 'AG01'},
				{label: 'AM04', value: 'AM04'},
				{label: 'FF01', value: 'FF01'},
				{label: 'MD01', value: 'MD01'},
				{label: 'MD02', value: 'MD02'},
				{label: 'MD06', value: 'MD06'},
				{label: 'MD07', value: 'MD07'},
				{label: 'MS02', value: 'MS02'},
				{label: 'MS03', value: 'MS03'},
				{label: 'PY01', value: 'PY01'},
				{label: 'RC01', value: 'RC01'},
				{label: 'SL01', value: 'SL01'},
			]
		}
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 980px) {
	.one-line {
		flex-direction: column;
	}
}
</style>