<template>
    <pop-up-window :show="this.$store.state.displayResetOwnPassword"
                   :showClose=false
                   class="doc-statuses-popup"
                   additional-info=""
                   fixed>
        <template #title>
            <h1 class="text-2xl">
                {{ $t('general.resetOwnPassword') }}
            </h1>
        </template>
        <template #content>
            <div class="input-container-outer">
                <div class="input-container">
                    <div>
                        <input v-model="password"
                               type="password"
                               class="form-control text-popup"
                               :placeholder="$t('placeholders.password')"
                               @click='cleanAllErrors'
                               @keyup='checkPassword'
                        >
                    </div>
                    <div>
                        <input v-model="passwordConfirmation"
                               class="form-control text-popup"
                               type="password"
                               :placeholder="$t('placeholders.passwordConfirmation')"
                               @click='cleanAllErrors'
                        >
                    </div>
                    <div>
                        <p :class="{
                            'weakPassword' : passwordCheckStyle.weakPassword,
                            'mediumPassword' : passwordCheckStyle.mediumPassword,
                            'strongPassword' : passwordCheckStyle.strongPassword,
                        }"
                        >
                            {{ passwordCheckText }}
                        </p>
                        <p class="error mt-2"
                           v-if="errors.passwordsAreNotTheSame"
                        >
                            {{ $t('errors.passwordsAreNotTheSame') }}
                        </p>
                        <p class="error mt-2"
                           v-if="errors.passwordsAreEmpty"
                        >
                            {{ $t('errors.passwordsAreEmpty') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="reset">
                        {{ $t('buttons.reset') }}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup"
                            @click="cancel">
                        {{ $t('buttons.close') }}
                    </button>
                </div>
            </div>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUpWindow";
import i18n from "../../i18n";
import store from "../../store";
const newLoginPage = window.newLoginPage

export default {
    name: "ResetOwnPassword",
    components: {PopUpWindow},
    data() {
        return {
            i18n: i18n,
            passwordCheckText: '',
            passwordCheckStyle: {
                weakPassword: false,
                mediumPassword: false,
                strongPassword: false
            },
            errors: {
                passwordsAreNotTheSame: false,
                passwordsAreEmpty: false,
            },
            password: '',
            passwordConfirmation: ''
        }
    },
    computed:{
        displayClose(){
           if (this.$store.state.currentUserData.data.resetPassword || this.$store.state.currentUserData.data.lastLogin === undefined) {
               return false
           }
           return true
        }
    },
    methods: {
        checkPassword() {
            if (this.password.length === 0) {
                this.setPasswordCheckText('');
                this.setPasswordCheckStyle(false, false, false)
            }
            if (this.password.length > 0 && this.password.length <= 6) {
                this.setPasswordCheckText(i18n.t('password.weak'));
                this.setPasswordCheckStyle(true, false, false)
            }
            if (this.password.length > 6 && this.password.length <= 10) {
                this.setPasswordCheckText(i18n.t('password.medium'));
                this.setPasswordCheckStyle(false, true, false)
            }
            if (this.password.length > 10) {
                let containNumber = /\d/.test(this.password);
                let containUpperCase = /[A-Z]/.test(this.password);
                let containSpecialCharacter = /[!@#$%^&*()_-]/.test(this.password);
                if (this.password.includes('+') || this.password.includes('-') || this.password.includes('|')) {
                    containSpecialCharacter = true
                }
                if (containNumber && containUpperCase && containSpecialCharacter) {
                    this.setPasswordCheckText(i18n.t('password.strong'));
                    this.setPasswordCheckStyle(false, false, true)
                } else {
                    this.setPasswordCheckText(i18n.t('password.medium'));
                    this.setPasswordCheckStyle(false, true, false)
                }
            }
        },
        setPasswordCheckStyle(weak, medium, strong) {
            this.passwordCheckStyle = {
                weakPassword: weak,
                mediumPassword: medium,
                strongPassword: strong
            }
        },
        setPasswordCheckText(text) {
            this.passwordCheckText = text
        },
        reset() {
            if (this.password === '' && this.passwordConfirmation === '') {
                this.setError('passwordsAreEmpty', true);
                return
            }
            if (this.password !== this.passwordConfirmation) {
                this.setError('passwordsAreNotTheSame', true);
                return
            }
            let formData = {
                password: this.password,
                passwordConfirmation: this.passwordConfirmation
            }
            this.$store.dispatch("changePassword", formData);
            this.close();
        },
        cleanAllErrors() {
            this.setError('passwordsAreNotTheSame', false);
            this.setError('passwordsAreEmpty', false);
        },
        setError(field, value) {
            this.errors[field] = value;
        },
        cancel(){
            this.close();
            if ((newLoginPage &&
                  (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
                  || (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))
            ){
                this.logOut();
            }
        },
        close() {
            this.setPasswordCheckText('');
            this.setPasswordCheckStyle(false, false, false)
            this.passwordConfirmation = '';
            this.password = '';
            this.setError('passwordsAreNotTheSame', false);
            this.setError('passwordsAreEmpty', false);
            this.$store.state.displayResetOwnPassword = false;
        },
        logOut() {
            this.$emit('closeProfile');
            if (newLoginPage) {
                store.commit("redirectPageAfterLogin", this.$route.path);
                this.$router.push('/login');
                this.$root.keycloak.logout()
                this.$store.dispatch('logout')
            } else {
                this.$router.push('/')
                setTimeout(() => {
                    this.$root.keycloak.logout();
                }, 10)
            }
        },
    }
}
</script>

<style scoped>
.input-container {
    display: grid;
    grid-template-columns: 400px;
    grid-template-rows: auto auto;
    grid-row-gap: 10px;
}

@media only screen and (max-width: 580px) {
    .input-container {
        grid-template-columns: 70vw;
    }
}

.input-container-outer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
}

.weakPassword {
    color: var(--errorColor)
}

.mediumPassword {
    color: var(--warningColor)
}

.strongPassword {
    color: var(--successeColor)
}
</style>
