<template>

        <transition name="dialog" v-if="dataIsReady">
            <page-container class="page-content onboarding">
                <div>
                    <top-banner>
                        <div class="w-9/12">
                            <h1 class="text-3xl text-center">{{ $t('onboarding.header.title') }}</h1>
                        </div>
                    </top-banner>
                    <div class="box page-content intro-y box p-5 mt-5">
                        <div class="content-container">
                            <h1 class="text-center">{{ $t('onboarding.onboardingWithAccessCode.description') }}</h1>
                            <div class="access-code">
                                <input
                                       v-model = "accessCode"
                                       @click = "clearErrors"
                                       class="form-control text-popup access-code-input"
                                       :placeholder="$t('placeholders.accessCode')"
                                       @keydown="goToOnboardingPageOnEnter($event)"
                                >
                                <p v-if="accessCodeInvalid" class="error mt-10">{{ $t('onboarding.unsuccessful.description')}}</p>
                                <p v-if="accessCodeError" class="error mt-10">{{ $t('errors.accessCodeEmpty')}}</p>
                            </div>
                            <div class="button-container">
                                <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="goToOnboadringPage">
                                    {{$t('buttons.goToOnboardingPage')}}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </page-container>
        </transition>
</template>

<script>
import TopBanner from "../components/TopBanner";
import PageContainer from "../components/PageContainer";
import i18n from '../i18n'
export default {
    name: "OnboardingAC",
    components: {TopBanner, PageContainer},
    created() {
        this.setLang();
    },
    data(){
        return{
            dataIsReady: false,
            i18n : i18n,
            accessCode : '',
            accessCodeError : false,
            accessCodeInvalid : false,
        }
    },

    computed:{
        accessCodeReplyLength(){
            return this.$store.state.onboarding.accessCodeReplyLength
        }
    },
    watch:{
        accessCodeReplyLength(newVal){

            if (newVal === 0){
                this.accessCodeInvalid = true;
            }
            if (newVal === 1){
                let route = '/' + this.$store.state.locale + '/onboarding/' + this.accessCode
                this.$router.push({path: route})
            }
        }
    },
    methods:{
        goToOnboardingPageOnEnter(data){
            if (data.key === "Enter"){
                this.goToOnboadringPage();
            } else {
                this.clearErrors();
            }
        },
         goToOnboadringPage(){
             this.$store.commit('accessCodeReplyLength', -1);
            if (this.accessCode === ''){
                this.accessCodeError = true;
                return
            }
            this.$store.dispatch('checkMandateOnboardingData', {
                mandateOnboardingAccessCode: this.accessCode,
                supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId
            });
        },
        clearErrors(){
            this.accessCodeError = false;
            this.accessCodeInvalid = false;
        },
        setLang(){
            let lang = window.location.href.split('/')[3];
            this.$store.commit('setLocale', lang);
            this.dataIsReady = true;
        }
    }
}
</script>

<style scoped>
.content-container{
    width: 40%;
    margin: 60px 30%;
}
.text-center{
    text-align: center;
}
.content-container h1{
    line-height: 2em;
    font-size: 20px;
}
@media only screen and (min-width: 600px) and (max-width: 1050px){
    .content-container{
        width: 80%;
        margin: 40px 10%;
    }
    .content-container h1{
        line-height: 1.8em;
        font-size: 18px;
    }
}
@media only screen and (min-width: 00px) and (max-width: 599px){
    .content-container{
        width: 90%;
        margin: 20px 5%;
    }
    .content-container h1{
        line-height: 1.6em;
        font-size: 16px;
    }
}
.mt-10{
    margin-top: 10px;
}

.access-code{

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    justify-items: center;

    margin-bottom: 20px;
}
.button-container{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-auto-flow: row;
    justify-items: center;
}
.access-code-input{
    max-width: 250px;
}
</style>
