<template>
    <div>
        <div v-if="show" @click="tryClose" class="backdrop"
             :style="{'background-color': brandSettings.mainColor}"
             :class="{'bg-transparent' : bgTransparent}"
        >
        </div>
        <transition name='dialog'>
            <dialog open v-if="show">
                <header :style="{'background-color': brandSettings.mainColor}">
                    <slot name="header">
                        <h2 class="header">{{ title }}</h2>
                    </slot>
                </header>
                <section>
                    <slot></slot>
                </section>
                <menu v-if="!fixed">
                    <slot name="actions">
                        <button @click.native="tryClose">Close</button>
                    </slot>
                </menu>
            </dialog>
        </transition>

    </div>
</template>
<script>
export default {
    computed: {
        brandSettings() {
            return this.$store.state.supplierGroup.branding;
        },
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false,
        },
        bgTransparent:{
            type: Boolean,
            required: false,
            default: false,
        }
    },
    emits: ['close'],
    methods: {
        tryClose() {
            if (this.fixed) {
                return;
            }
            this.$emit('close');
        },

    },
};
</script>

<style scoped>
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 10;
}

dialog {
    position: fixed;
    top: 20vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: white;
}

header {
    color: white;
    width: 100%;
    padding: 1rem;
}

header h2 {
    margin: 0;
}

section {
    padding: 1rem;
}

menu {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
}


.dialog-enter-active {
    transition: all 0.3s ease-out;
}

.dialog-leave-active {
    transition: all .3s ease-in;
}

.dialog-enter-from, .dialog-leave-to {
    transform: scale(0.8);
    opacity: 0;
}
.header{
    text-align: center;
}
.bg-transparent{
    background-color: transparent !important;
}

@media (min-width: 768px) {
    dialog {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}
</style>
