const userGroups = {
	OpAdmin : {
		name: "OpAdmin",
		accessType : 'OPTIPOST',
		accessRight : 'ADMINISTRATOR'
	},
	OpHelpdesk : {
		name: "OpHelpdesk",
		accessType : 'OPTIPOST',
		accessRight : 'HELPDESK'
	},
	ReceiverAdmin : {
		name: "ReceiverAdmin",
		accessType : 'RECEIVER',
		accessRight : 'ADMINISTRATOR'
	},

	ReceiverUser : {
		name: "ReceiverUser",
		accessType : 'RECEIVER',
		accessRight : 'USER'
	},
	SupplierGroupSuperAdmin : {

		name: "SupplierGroupSuperAdmin",
		accessType : 'SUPPLIER',
		accessRight : 'SUPER_ADMINISTRATOR'
	},
	SupplierSubAdmin:
		{
			name: "SupplierSubAdmin",
			accessType : 'SUPPLIER',
			accessRight : 'SUB_ADMINISTRATOR'
		},

	SupplierSubUser : {
		name: "SupplierSubUser",
		accessType : 'SUPPLIER',
		accessRight : 'SUB_USER'
	},
	SupplierLimitedSubUser : {
		name: "SupplierLimitedSubUser",
		accessType : 'SUPPLIER',
		accessRight : 'LIMITED_SUB_USER'
	},
	receiverUserNames: ['ReceiverUser'],

	receiverNames : ['ReceiverUser', 'ReceiverAdmin'],

	supplierNames : ['SupplierGroupSuperAdmin', 'SupplierSubAdmin', 'SupplierLimitedSubUser' , 'SupplierSubUser'],

	optipostNames: ['OpAdmin', 'OpHelpdesk'],

	allUsersNames: ['ReceiverUser', 'SupplierSubUser', 'SupplierLimitedSubUser']


}
export default userGroups;
