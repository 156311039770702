import axios from "axios"
import userGroups from "@/userGoups"
import router from "@/router";

export default {
	fetchReceivers(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.receivers
		payload.formData.pageSize = 500
		payload.pageNo = 1

		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
				context.commit('setReceiversOption', [], {root: true})

				let tempOptions = []
				res.data.data.items.forEach((item) => {
					let receiverName = ''
					if (item.info.firstName && item.info.lastName) {
						receiverName = item.info.firstName + " " + item.info.lastName + " - "
					}

					let label
					if (userGroups.optipostNames.includes(context.rootState.currentUserData.data.group)
						|| userGroups.supplierNames.includes(context.rootState.currentUserData.data.group)) {
						let leName = context.rootState.supplierGroup.data.supplierLegalEntities[item.legalEntityId].internalName ? context.rootState.supplierGroup.data.supplierLegalEntities[item.legalEntityId].internalName : context.rootState.supplierGroup.data.supplierLegalEntities[item.legalEntityId].name
						label = receiverName + item.info.companyName + ' - ' + item.receiverId + ' - ' + leName
					} else {
						label = receiverName + item.info.companyName + ' - ' + item.receiverId + ' - ' + context.rootState.supplierGroup.data.supplierLegalEntities[item.legalEntityId].name
					}

					let tempItem = {
						label: label,
						value: {
							receiverId: item.receiverId,
							legalEntityId: item.legalEntityId,
							companyName: item.info.companyName,
							legalEntityName: item.legalEntityName,
							receiverName: receiverName
						}
					}
					tempOptions.push(tempItem)
				})
				context.commit('setReceiversOption', tempOptions, {root: true})
			}
		).catch((err) => console.error(err))
	},
	fetchUserReceiversData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.searchReceiver
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => context.commit('setUserReceivers', res.data.data.items, {root: true}))
			.catch((err) => console.error(err))
	},
	addReceiver(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.addReceiver
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit('receiverDataUpdated', true, {root: true}))
			.catch((error) => {
				if (error.response.status === 409) {
					context.commit('setCreateReceiverId', payload.formData.receiverId, {root: true})
					context.rootState.errorNotification.type = "MANAGEMENTRCEIVEREXISTS"
				}
				context.rootState.errorNotification.display = true
			})
	},
	updateReceiver(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.updateReceiver
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit('receiverDataUpdated', true, {root: true}))
			.catch(() => context.rootState.errorNotification.display = true)
	},
	deleteReceiver(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.deleteReceiver
		return axios.delete(url, {
				data: {
					supplierGroupId: payload.formData.supplierGroupId,
					receiverId: payload.formData.receiverId,
					legalEntityId: payload.formData.legalEntityId,
				},
				headers: {
					'Content-Type': "application/json",
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
				context.commit('setReceiverData', {}, {root: true})
				context.commit('receiverDataUpdated', true, {root: true})
			}
		)
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},

	fetchSupplierUsers(context) {
		let url = context.rootState.domainName + context.rootState.API.supplierUsers
		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => context.commit("setSupplierUsers", res.data.data.items, {root: true}))
			.catch((err) => console.error(err))
	},
	fetchUsers(context, payload) {
		return axios.get(payload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => context.commit("setUsersDataForPostDrop", res.data.data.users, {root: true}))
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	fetchCurrentUserData(context) {
		let url = context.rootState.domainName + context.rootState.API.currentUser
		return axios.get(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then((response) => {
				let curerntUserData = response.data.data
				context.commit('setCurrentUserData', {currentUserData: curerntUserData, isReady: true}, {root: true})
				if (localStorage.getItem("language") == null) {
					context.commit('setLocale', curerntUserData.language.toLowerCase(), {root: true})
					localStorage.setItem("language", curerntUserData.language.toLowerCase())
				}
				if (context.rootState.currentUserData.data.lastLogin) {
					context.dispatch('updateUserLogin', {
						token: context.rootState.token,
						userId: context.rootState.currentUserData.data.id
					})
				}
			})
			.catch((err) => console.error(err))
	},
	exportUsers(context) {
		let url = context.rootState.domainName + context.rootState.API.exportUsers
		return axios.get(url, {
			responseType: 'blob',
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(res => {
			let url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'users.csv')
			document.body.appendChild(link)
			link.click()
		})
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	inviteUser(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.inviteUser

		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.rootState.userDataUpdated = true)
			.catch((error) => {
				console.error(error)
				context.rootState.errorNotification.type = "INVITE"
				context.rootState.errorNotification.display = true
				context.rootState.errorNotification.errorStatus = error.response.status
				context.rootState.errorNotification.errorText = error.response.data
			})
	},
	manageUser(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.updateUser + payload.id + '/update'

		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.rootState.userDataUpdated = true)
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	updateUser(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.updateUser + payload.userId + '/update'
		return axios.post(url, payload.userData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.rootState.userDataUpdated = true)
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	updateUserProfile(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.updateUserProfile + payload.userId + '/update'
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
				context.commit('setLocale', payload.formData.language.toLowerCase(), {root: true})
				localStorage.setItem("language", payload.formData.language.toLowerCase())
				context.commit('setCurrentUserData', {currentUserData: res.data.data.user, isReady: true}, {root: true})
			}
		).catch((err) => {
			console.error(err)
			context.rootState.errorNotification.display = true
		})
	},
	updateUserLogin(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.updateUserLogin + payload.userId + '/updateLogin'

		return axios.post(url, '', {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
			if (context.rootState.currentUserData.data.lastLogin === undefined) {
				context.rootState.currentUserData.data.lastLogin = 'ok'
			}
		}).catch((err) => console.error(err))
	},
	deleteUser(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.deleteUser + payload.id + '/delete'

		return axios.delete(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		}).then(() => context.rootState.userDataUpdated = true)
			.catch((err) => {
				console.error(err)
			})
	},
	reactivateEmail(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.deleteSuppression.replace(':email', payload.email)

		return axios.delete(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
				context.commit("emailReactivated", true)
				if (payload.column === "reactivateEmail") {
					context.commit("setGeneralNotificationText", {field: "emailReactivated", text: "Ok"}, {root: true})
				} else {
					context.commit("setGeneralNotificationText", {field: "emailCorrected", text: "Ok"}, {root: true})
				}

				context.commit('displayNotification', true, {root: true})
			}
		).catch(() => {
			context.rootState.errorNotification.display = true
		})
	},
	getChannelChoiceData(context, payload) {
		let url = context.state.domainName + context.state.API.channelChoice

		return axios.post(url, payload)
			.then((res) => {
				if (res.data.data.numResults > 0) {
					context.commit("setChannelChoiceData", res.data.data.items[0], {root: true})
					context.commit("displayChannelChoice", true, {root: true})
				} else {
					router.push('/onboardingUnsuccessful')
				}
			})
			.catch(() => router.push('/pageNotFound'))
	}
}