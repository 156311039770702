<template>
    <div>
        <error-notification>
        </error-notification>

        <!-- BEGIN: HTML Table Data -->
        <div class="" id="tabulatorOuter">
            <div class="tabulator-buttons-container">
                <div class="quickSearch">
                    <input
                           type='text'
                           v-model="filter.value"
                           @keyup="quickSearch"
                           :placeholder="$t('placeholders.search')"
                           class="form-control text-popup"
                    >
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="cleanQuickSearch">
                        {{ $t('buttons.reset') }}
                    </button>

                </div>
                <div class="refreshButton">
                <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="cleanQuickSearch">
                        {{ $t('buttons.refresh') }}
                    </button>
                <div class="right-buttons">
                </div>

                </div>
                <div class="flex mt-5 sm:mt-0">

                </div>
            </div>
            <div class="filter-container">
                <div class="extended-search-check-box">
                    <span>{{ $t('extendedSearch.showExtendedSearch') }}</span>
                    <input type="checkbox"
                           class="customCheckbox mt-2"
                           v-model="showExtendedSearch"
                           @change="setExtendedSearchValues"
                    >
                </div>
                <div v-if="showExtendedSearch">
                    <div class="extended-search">
                        <v-select
                              v-model="departmentCode"
                              :options=departmentCodeOptions
                              :clearable="false"
                        >
                            <template #no-options="{search}">
                                {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                            </template>


                        </v-select>
                        <v-select
                              v-model="printStatus"
                              :options=printStatusOptions
                              :clearable="false"
                        >
                            <template #no-options="{search}">
                                {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                            </template>
                        </v-select>
                    </div>

                    <div class="search-button mt-4">
                        <div>
                            <button class="btn custom-button shadow-md mr-2 showStatuses text-popup"
                                    @click="cleanExtendedSearchValues">
                                {{ $t('buttons.reset') }}
                            </button>
                            <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="onFilter">
                                {{ $t('buttons.search') }}
                            </button>

                        </div>

                    </div>

                </div>
            </div>

            <div class="totalNumberDocuments">{{ $t('table.totalNumberDocuments') }}: {{
                    totalDocuments
                }}
            </div>


            <div class="overflow-x-auto scrollbar-hidden">
                <div
                      id="tabulator"
                      ref="tableRef"
                      class="mt-5 table-report table-report--tabulator"

                ></div>
            </div>

        </div>
        <!-- END: HTML Table Data -->
    </div>
</template>

<script>
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import i18n from "../../../i18n";
import ErrorNotification from "../../UI/ErrorNotification";
import cash from "cash-dom";
import en from "../../../locales/en.json";
import nl from "../../../locales/nl.json";
import de from "../../../locales/de.json";
import fr from "../../../locales/fr.json";
import sk from "../../../locales/sk.json";
import es from "../../../locales/es.json";
import axios from 'axios'
import qs from "qs";
import moment from "moment";
import userGroups from "../../../userGoups";
import getStatusesHtml from "@/mixins/nymdropTable/calcStatusesHtml";
import getStatusBar from "@/mixins/nymdropTable/calcStatusBar";

const env = window.environment;
export default defineComponent({
    components: {ErrorNotification},
    emits: ['update:modelValue'],
    props: {
        token: String,
        supplierGroupId: String,
        requestUrl: String,
        modelValue: String,
    },
    setup(props) {
        const store = useStore();
        const requestUrl = ref(props.requestUrl);
        const supplierGroupId = ref(props.supplierGroupId);
        const totalDocuments = ref('');
        const searchTableValue = ref(store.state.postDropTableSearchValue);
        const tableRef = ref()
        const tabulator = ref();
        const pageSize = ref(10);
		const tableIsLoading = ref(false);
        const filter = reactive({
            value: searchTableValue
        });
        const allDocIds = ref([]);
        const printStatusOptions = ref([
            {label: i18n.t('dropdowns.printStatuses.allStatuses'), value: ''},
            {label: i18n.t('dropdowns.printStatuses.printererror'), value: 'PRINTER_ERROR'},
            {label: i18n.t('dropdowns.printStatuses.printerreturned'), value: 'PRINTER_RETURNED'},

        ])
        let options = ref([]);
        let selectedDocuments = ref([])
        let tableItems = ref(0);
        let showExtendedSearch = ref(store.state.postDrop.extendedSearch.displayExtendedSearch);
        let departmentCode = ref(store.state.postDrop.extendedSearch.departmentCode);
        let printStatus = ref(store.state.postDrop.extendedSearch.printStatus);

        let displayHandleErrors = computed(() => {
            if ((userGroups.optipostNames.includes(store.state.currentUserData.data.group) || userGroups.supplierNames.includes(store.state.currentUserData.data.group))) {
                return true
            }
            return false
        })
        let tableColumns = computed(() => {
            let columns = [];

            columns = [
                {
                    title: 'DEPARTMENT NAME',
                    field: 'departmentName',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 220,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     <div>${cell.getData().department}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'POSTDROP NAME',
                    field: 'postDropName',
                    cssClass: "text-centered",
                    headerSort: true,
                    minWidth: 220,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     <div>${cell.getData().postDropName}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'FILE NAME',
                    field: 'fileName',
                    cssClass: "text-centered",
                    headerSort: false,
                    minWidth: 220,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     <div>${cell.getData().fileName}</div>
                                </div>`
                        )

                        return a[0];
                    }
                },
                {
                    title: 'BATCH ID',
                    field: 'batchId',
                    cssClass: "text-centered",
                    headerSort: false,
					minWidth: 220,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     <div>${cell.getData().batchId}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'CREATED AT',
                    field: 'createdAt',
                    cssClass: "text-centered",
                    headerSort: true,
					minWidth: 220,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     <div>${cell.getData().createdOn}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'PRINTER STATUSES',
                    field: 'printerStatuses',
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    width: 250,
                    formatter(cell) {
                        const a = cash(
                              `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                    <div >${cell.getData().statusBar}</div>
                                </div>`
                        )
                        return a[0];
                    }
                },
                {
                    title: 'VIEW PROCESSED DOCUMENT',
                    field: 'viewProcessedDocument',
                    width: 60,
                    cssClass: "text-centered color-grey",
                    headerSort: false,
                    formatter(cell) {
                        const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start ">
                                                 <div> >> </div>
                                    </div> `);
                        cash(a).on('click', function () {
                            store.commit('setProcessedDocumentData', cell.getData().allData);
                        })
                        return a[0];
                    }
                },
            ];
            return columns
        });
        const departmentCodeOptions = computed(() => {
            let options = [{label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''}]
            store.state.userDepartmentCodeList.forEach((department) => {
                options.push(department);
            })
            return options
        })


        watch(() => store.state.postDrop.departmentsUpdated, (newVal) => {
            if (newVal) {
                setTimeout(() => {
                    initTabulator();
                    store.commit("departmentsUpdated", false);
                }, 1000)
            }
        })
        watch(() => store.state.documentErrorHandled, (newVal) => {
            if (newVal) {
                setTimeout(() => {
                    initTabulator()
                }, 1500)
            }
        })

        watch(() => store.getters.getLocale, function () {
            if (departmentCode.value.value === '') {
                departmentCode.value.label = i18n.t('dropdowns.postDrop.departmentCode')
            }
            departmentCodeOptions.value[0].label = i18n.t('dropdowns.postDrop.departmentCode');

            if (printStatus.value.value === '') {
                printStatus.value.label = i18n.t('dropdowns.printStatuses.allStatuses')
            } else {
                let key = 'dropdowns.printStatuses.' + printStatus.value.value.toLowerCase();
                printStatus.value.label = i18n.t(key);
            }
            printStatusOptions.value.forEach(opt => {
                if (opt.value === '') {

                    opt.label = i18n.t('dropdowns.printStatuses.allStatuses');
                } else {
                    let key = 'dropdowns.printStatuses.' + opt.value.replaceAll('_', '').toLowerCase();
                    opt.label = i18n.t(key);
                }
            })
            tabulator.value.setLocale(store.getters.getLocale);
        });

        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value);
            let params = {
                supplierGroupId: supplierGroupId.value,
                pageNo: 1,
                pageSize: pageSize.value,
                quick: filter.value,
                extended: {}
            }

            if (showExtendedSearch.value) {
                if (departmentCode.value.value !== '') {
                    params.extended.departmentCode = departmentCode.value.value;
                }
                if (printStatus.value.value !== '') {
                    params.extended.printStatus = printStatus.value.value;
                }

                if ((departmentCode.value.value === '') && (printStatus.value.value === '')) {
                    delete params.extended
                }

            } else {
                delete params.extended
            }
            if (store.state.tablePostdropSorters[0].order) {
                params.sortOn = store.state.tableSorters[0];
            }
            if (filter.value === '') {
                delete params.quick
            }
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: requestUrl.value,
                ajaxConfig: {
                    method: "POST",
                    headers: {
                        'Authorization': 'bearer ' + store.state.token,
                        'Accept': 'application/json',
                    },
                },
                ajaxParams: params,
                ajaxResponse: function (url, params, response) {
                    let data = [];
                    const res = response.data.items;

                    totalDocuments.value = response.data.numResults;
                    tableItems.value = response.data.items.length;

                    for (let item of res) {
                        let tempItem = {
                            docId: item.docId,
                            createdOn: moment(item.createdOn).format("DD/MM/YYYY"),
                            department: item.documentData.department,
                            dropDate: item.documentData.dropDate ? moment(item.documentData.dropDate).format("DD/MM/YYYY") : '-',
                            postDropName: '-',
                            departmentIsDeletedClass: '',
                            fileName: '',
                            statusesHTML: '',
                            statusbar: '',
                            allData: item,
                            errorsHandled: false,
                            errorsHandledClass: 'display-none',
                            batchId : '-'
                        }
                        if(item.documentData && item.documentData.batch){
                            tempItem.batchId = item.documentData.batch;
                        }
                        allDocIds.value.push(item.docId);

						let printStatuses = [];
						tempItem.statusesHTML = getStatusesHtml(printStatuses, item.print)
						tempItem.statusBar = getStatusBar(printStatuses, item.documentData.errorsHandled)

                        if (item.documentData.errorsHandled && printStatuses.includes('printerreturned')) {
                            tempItem.errorsHandled = true;
                            tempItem.errorsHandledClass = ""
                        }

                        if (item.fileStructure) {
                            let nameArray = item.fileStructure.opDocumentOriginalFileName.split('/');
                            tempItem.fileName = nameArray[nameArray.length - 1]
                        }

                        let name = '';
                        store.state.userDepartmentCodeList.forEach(department => {
                            if (department.value === item.documentData.department) {
                                name = department.label
                            }
                        })

                        if (name === '') {
                            name = item.documentData.department + " (Department DELETED)"
                            tempItem.departmentIsDeletedClass = 'deleted-department'
                        }
                        tempItem.department = name;

                        if (item.print && item.print.postalAddress && item.print.postalAddress.companyName) {
                            tempItem.postDropName = item.print.postalAddress.companyName;
                        }
                        data.push(tempItem);
                    }

                    let last_page = Math.ceil(response.data.numResults / pageSize.value);
					return {
						last_page: last_page,
						data: data,
					}
                },
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
				},
                ajaxURLGenerator: function (url, config, params) {
                    updateToken();
                    if (params.sort?.length > 0) {
                        let sortFields = {
                            dropDate: 'POST_DROP_DATE',
                            createdAt: 'POST_DROP_CREATED_ON',
                            departmentName: 'POST_DROP_DEPARTMENT',
                            postDropName: 'POST_DROP_NAME',
                        };

                        store.commit('setTablePostdropSorters',
                              {
                                  order: params.sort[0].dir.toUpperCase(),
                                  sortField: sortFields[params.sort[0].field].toUpperCase()
                              });
                        params.sortOn = {
                            order: params.sort[0].dir.toUpperCase(),
                            sortField: sortFields[params.sort[0].field]
                        }
                    } else {
                        delete params.sortOn;
                    }
                    return url;
                },
                headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
                ajaxContentType: "json",
                ajaxFiltering: true,
                printAsHtml: true,
                printStyled: true,
				pagination: true,
				sortMode:"remote",
				paginationMode: 'remote',
                paginationSize: pageSize.value,
                paginationInitialPage: store.state.tableInitialPage.mandates,
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: i18n.t('placeholders.noMatchingFound'),
				rowClick: function (_, row) {
					store.commit('setProcessedDocumentData',  row._row.data.allData)
				},
                locale: true,
                langs: {
                    "en": en,
                    "nl": nl,
                    "de": de,
                    "fr": fr,
                    "sk": sk,
                    "es": es,
                },
                columns: tableColumns.value,
            })
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', ()=> {
				tableIsLoading.value = true
			})
        }
		watch(tableIsLoading, () => {
			if(tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
        const tokenIsExpired = () => {
            let exp;
            if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
                exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
            }
            let now = parseInt(new Date().getTime() / 1000);
            if (exp - now <= 0) {
                return true
            } else {
                return false
            }
        }
        const updateToken = () => {
            if (tokenIsExpired()) {
                let payload = {
                    client_id: env.keycloakClient,
                    grant_type: "refresh_token",
                    refresh_token: store.state.refreshToken
                }

                let body = qs.stringify(payload);
                axios.post(store.state.API.keycloakToken,
                      body,
                      {
                          headers: {
                              'Content-Type': "application/x-www-form-urlencoded",
                          }
                      }
                )
                      .then(res => {
                          store.commit('setToken', res.data.access_token);
                          store.commit('setRefreshToken', res.data.refresh_token);
                          initTabulator();

                      })
                      .catch(() => {
                          store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
                          store.commit('displayNotification', true);
                      })
            }
        }
        const handleErrors = () => {
            store.commit("setDocumentErrorHandled", false);
            store.dispatch("documents/handleErrors", {
                formData: {
                    docIds: selectedDocuments.value,
                    postDropDocuments: true
                }
            });
        }
        const resetSearch = () => {
            onFilter();
        }
        const cleanQuickSearch = () => {
            departmentCode.value = {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''};
            printStatus.value = {label: i18n.t('dropdowns.printStatuses.allStatuses'), value: ''};
            filter.value = '';
            store.commit("setPostDropTableSearch", filter.value)
            onFilter();
        }
        const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
        }
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw();
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }
        const quickSearch = () => {
            store.commit("setPostDropTableSearch", filter.value)
            store.commit("setTablePage", {field: "postDrop", pageNo: 1})
            onFilter();
        }
        const onFilter = () => {
            initTabulator();
        }
        const onResetFilter = () => {
            filter.value = ''
            onFilter();
        }
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }
        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }
        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'data.xlsx', {
                sheetName: 'Products'
            })
        }
        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }
        const setExtendedSearchValues = () => {

            if (!showExtendedSearch.value) {
                departmentCode.value = {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''}
                printStatus.value = {label: i18n.t('dropdowns.printStatuses.allStatuses'), value: ''}
                cleanExtendedSearch();
                onFilter();
            }
        }
        const cleanExtendedSearch = () => {
            departmentCode.value = {label: i18n.t('dropdowns.postDrop.departmentCode'), value: ''};
            printStatus.value = {label: i18n.t('dropdowns.printStatuses.allStatuses'), value: ''};
        }
        const cleanExtendedSearchValues = () => {
            cleanExtendedSearch();
            onFilter();
        }
        const fetchUsers = (search) => {
            let url = store.state.domainName + store.state.API.getUsers + "?size=500" + "&from=0" + "&filter=" + search;
            store.dispatch("users/fetchUsers", url);
        }

        onMounted(() => {
            if (store.state.postDrop.extendedSearch.departmentCode.value === '') {
                departmentCode.value.label = i18n.t('dropdowns.postDrop.departmentCode')
                store.commit('setPostDropExtendedSearch', {
                    displayExtendedSearch: showExtendedSearch.value,
                    departmentCode: departmentCode.value,
                    printStatus: printStatus.value
                })
            }

            if (store.state.postDrop.extendedSearch.printStatus.value === '') {
                printStatus.value.label = i18n.t('dropdowns.printStatuses.allStatuses')
                store.commit('setPostDropExtendedSearch', {
                    displayExtendedSearch: showExtendedSearch.value,
                    departmentCode: departmentCode.value,
                    printStatus: printStatus.value
                })
            }
            store.commit('setTablePostdropSorters', [{}])
            initTabulator();
            reInitOnResizeWindow();
            setLocale();
        })

        onBeforeUnmount(() => {
            store.commit("setDocumentErrorHandled", false);
            store.commit("setTablePage", {field: "postDrop", pageNo: tabulator.value.getPage()})
            store.commit('setPostDropExtendedSearch', {
                displayExtendedSearch: showExtendedSearch.value,
                departmentCode: departmentCode.value,
                printStatus: printStatus.value
            })
        })

        return {
            tableRef,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            totalDocuments,
            quickSearch,
            tabulator,
            resetSearch,
            cleanQuickSearch,
            tableItems,
            showExtendedSearch,
            setExtendedSearchValues,
            cleanExtendedSearchValues,
            fetchUsers,
            departmentCode,
            printStatus,
            options,
            departmentCodeOptions,
            printStatusOptions,
            selectedDocuments,
            handleErrors,
            displayHandleErrors
        }
    }
})
</script>

<style scoped>


.tabulator-arrow {
    display: none;
}

.tabulator-col-title {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
}

.leg-entity-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 80px auto;
    grid-auto-flow: column;
}

.leg-entity-container img {
    padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
    border: none;
}

.leg-entity-container .table-report img, .table-image {
    box-shadow: none !important;
}

.legEntityColumnHeader {
    padding-left: 40px
}

#tabulator-html-filter-value {
    width: 400px;

}

.totalNumberDocuments {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: end;
    padding-top: var(--generalPaddings);
}

#selectAllDocuments {
    justify-self: center;
}

.buttons-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: repeat(2, auto);
    grid-auto-flow: column;
    grid-column-gap: var(--generalPaddings);
}

.search {
    display: none !important;
}

.tabulator-buttons-container {
    display: grid;
    grid-template-columns: 90% 10%;
    grid-auto-flow: column;
    align-items: center;
}

.tabulator-search-container {
    display: grid;
    grid-template-columns: 400px repeat(5, auto);
    grid-template-rows: 100%;
    grid-auto-flow: column;
    grid-column-gap: 5px;
}

.download {
    justify-self: end;
}

.buttons-container {
    align-self: center;
    justify-self: start;
}

.col-header {
    text-align: center;
}


.arrow-up:after {
    content: ' \2191';
}

.arrow-down:after {
    content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
    display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
    display: inline-block;
    color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
    display: inline-block;
    color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
    display: inline-block;
    color: var(--theme-primary-100);
}

.download-outer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-auto-flow: column;
    justify-items: end;

}

.download-outer button {
    margin-left: 5px;
}

.icon-coin-euro {
    font-size: 24px;
}

.icon-coin-euro:hover {
    cursor: pointer;
    color: var(--infoColor);

}

.download-buttons {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: auto auto;
}

.download-buttons .btn {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding: 0;
}
.refreshButton{
    width: auto;
    text-align: right;
}

.button-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.download-buttons .btn span {
    grid-column: 1/2;
    grid-row: 1/2;
}

.download-buttons .btn:disabled:hover .tooltip {

    display: block;

}

.tooltip {
    display: none;
    position: relative;
    top: 20px;
    background-color: var(--theme-primary-500);
    color: white;
    border-radius: 5px;
    font-size: 12px;


}

.extended-search {
    margin-top: 10px;
    width: 40%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 10px;

}

.extendedLe {
    align-self: start;
}

.extended-left {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 100%;
    grid-row-gap: 10px;
}

.display-none {
    display: none;
}

.extended-search-check-box {
    margin-top: 10px;
    line-height: 1.5em;

}

.search-button {
    grid-column: 1/3;
    justify-items: center;
    display: grid;
}

.extended-search-check-box span {
    margin-right: 10px;
    position: relative;
    top: -5px;
}

.margin-right {
    margin-right: 10px;
}

@media only screen and (max-width: 800px) and (max-width: 670px) {
    #tabulator-html-filter-value {
        width: 50vw;
    }

    .tabulator-search-container {
        grid-template-columns: 50vw repeat(5, auto);
    }

}

@media only screen and (max-width: 669px) {
    #tabulator-html-filter-value {
        width: 40vw;
    }

    .tabulator-search-container {
        grid-template-columns: 40vw repeat(5, auto);
    }

}

@media only screen and (max-width: 850px) {
    .download {
        height: 38px;
    }
}

.findBy {
    display: grid;
    grid-template-columns: 240px 240px auto;
    grid-template-rows: 100%;
    grid-column-gap: 20px;
    grid-auto-flow: row;
    justify-items: start;
}

.findByStatus {
    width: 240px;
}

.button-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-row: 100%;
    align-items: center;
}

.quickSearch {
    display: grid;
    grid-template-columns: 400px 100px;
    grid-template-rows: 100%;
    grid-column-gap: 10px;
}

.filter-container {
    width: 660px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-auto-flow: row;

}

.button-container {
    align-self: center;
}

.extended-search {
    margin-top: 10px;
    width: 640px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-gap: 10px;
}

.handle-error {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    padding: 0;
}

.button-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.handle-error span {
    grid-column: 1/2;
    grid-row: 1/2;
}

.handle-error:disabled:hover .tooltip {

    display: block;

}

.tooltip {
    display: none;
    position: relative;
    top: 20px;
    background-color: var(--theme-primary-500);
    color: white;
    border-radius: 5px;
    font-size: 12px;


}

.right-buttons {
    justify-self: end;
}

@media only screen and (max-width: 560px) {
    .quickSearch {

        grid-template-columns: 80% 20%;
    }
}

@media only screen and (max-width: 750px) {
    .filter-container {
        width: 90%;

    }

    .extended-search {
        width: 100%;
    }

}

@media only screen and (max-width: 520px) {
    .extended-search {
        grid-template-columns: 100%;
    }
}
</style>

