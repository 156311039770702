<template>
    <div>
        <error-notification>

        </error-notification>
        <general-notification>

        </general-notification>
        <top-banner>
            <div class="w-9/12">
                <h1 class="text-3xl text-center">PDF reading demo</h1>

            </div>

        </top-banner>
        <div class="box page-content intro-y box p-5 mt-5">

            <div class="ingestion-container">

                <h1 class="mb-40">

                </h1>
                <div class="mb-40">
                    <input id='file'
                           type='file'
                           @change="uploadFile($event.target.files, $event.target.value)">
                </div>

            </div>


        </div>

    </div>

</template>

<script>
import TopBanner from "../components/TopBanner";
import ErrorNotification from "../components/UI/ErrorNotification";
import GeneralNotification from "../components/UI/GeneralNotification";

export default {
    components: {TopBanner, ErrorNotification, GeneralNotification},
    mounted() {
        this.emptyTempFile();
    },
    data() {
        return {
            filename: '',
            error: false,
        }
    },
    methods: {

        emptyTempFile() {
            this.$store.commit("emptyTempFile", '');
        },
        uploadFile(files, value) {
            this.emptyTempFile();
            this.error = false;

            let file = new FormData();
            let payload = {
                token: this.$store.state.token,
                file: file,
                value: value.replace(/C:\\fakepath\\/, ""),
            };

            file.append("bucketName", "op-stag-extraction-intake");
            file.append("bucketFolder", "demofr1/ATALIAN");
            file.append("file", files[0]);


            this.$store.dispatch("uploadFile", payload);

            this.emptyTempFile();
        },

    }
}
</script>

<style scoped>
.page-content {
    min-height: calc(100vh - var(--headerheight) - var(--footerHeight) - 10 * var(--generalPaddings));

}

.mb-40 {
    margin-bottom: 40px;
}
.ingestion-container{
    width: 60vw;
    margin: 0 20vw;
    margin-top: 60px;
}
.button-container{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: center;
}

</style>
