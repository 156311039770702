import axios from "axios"

export default {
	getDepartmentsData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.departments
		return axios.post(url, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(res => context.commit("setUserDepartmentCodeList", res.data.data.items, {root: true}))
			.catch((err) => {
				console.error(err)
				context.commit('displayErrorNotification', true, {root: true})
			})
	},
	editDepartment(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.editDepartment.replace(":departmentId", payload.departmentId)

		return axios.post(url, {name: payload.name}, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit("departmentsUpdated", true, {root: true}))
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	deleteDepartment(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.editDepartment.replace(":departmentId", payload)

		return axios.delete(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit("departmentsUpdated", true, {root: true}))
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	addDepartmentUser(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.addDepartmentUser.replace(":departmentId", payload.departmentId).replace(':userId', payload.userId)

		return axios.post(url, {}, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit("departmentsUpdated", true, {root: true}))
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	deleteDepartmentUser(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.addDepartmentUser.replace(":departmentId", payload.departmentId).replace(':userId', payload.userId)

		return axios.delete(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit("departmentsUpdated", true, {root: true}))
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	createDepartment(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.createDepartment

		return axios.post(url, {name: payload.name, code: payload.code}, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit("departmentsUpdated", true, {root: true}))
			.catch((error) => {
				if (error.response.data === "Invalid input: Department already exists." && error.response.status === 500) {
					context.commit("setGeneralNotificationText", {field: "departmentExists", text: "Ok"}, {root: true})
					context.commit('displayNotification', true, {root: true})
				} else context.rootState.errorNotification.display = true
			})
	}
}