<template>
	<div style="position: relative">
		<div class="table-container">
			<div class="overflow-x-auto scrollbar-hidden">
				<div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, defineComponent, onMounted, ref, watch} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router'
import routerData from "../../routerData";
import cash from "cash-dom";
import userGroups from "../../userGoups";
import getPaymentStatus from "../../mixins/documentTable/calcPayStatus";
import calcDueDate from "../../mixins/documentTable/calcDueDate";
import getDocType from "../../mixins/documentTable/calcDocType";

export default defineComponent({
	name: 'DocumentTable',
	data() {
		return {
			routerData: routerData,
			documentData: this.$store.getters.getDocumentDetailData,
		}
	},
	emits: ['update:modelValue'],
	props: {
		modelValue: String,
		tableData: Object,
		accessCode: {
			type: String,
			required: false,
			default: ''
		},
	},
	setup(props) {
		const en = require('../../locales/en.json')
		const nl = require('../../locales/nl.json')
		const fr = require('../../locales/fr.json')
		const de = require('../../locales/de.json')
		const es = require('../../locales/es.json')
		const sk = require('../../locales/sk.json')

		const selectedDocuments = ref([]);
		const tableData = ref(props.tableData);
		const store = useStore();
		const route = useRoute()
		const tableRef = ref()
		const tabulator = ref()
		const docDetailColumns = store.state.columnsDocDetail

		let tableColumns = computed(() => {
			let columns = docDetailColumns;
			if (store.state.docType === "BILLING" && columns.find(pay => pay.title === "PAYMENT") === undefined) {
//payment status column
				if (store.state.docDetailTable.billing.displayPaymentColumn
					&& store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.showPaymentStatus) {
					let paymentColumn = {
						title: 'PAYMENT',
						field: 'payment',
						cssClass: 'text-centered',
						width: 120,
						headerSort: false,
						formatter(cell) {
							let status = cell.getData().paymentStatusTooltip;
							let toolTip = '';
							if (status !== '') {
								toolTip = translation[store.state.locale].paymentStatusesTooltips[status];
							}
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                        <div class="customer-name-outer payment-statuses">
                                            ${cell.getData().payment}
                                             <span class="tooltip tooltip-billing payment-status ${status}">${toolTip}</span>
                                             </div>
                                    </div> `);
							return a[0];
						}
					}
					columns.splice(columns.length - 1, 0, paymentColumn);
				}
//payment Button

				if ((route.name !== 'public' && columns.find(pay => pay.title === "PAY NOW") === undefined
						&& store.state.supplierGroup.data.channelsConfig.payment
						&& store.state.docDetailTable.billing.displayPaymentButtonColumn
						&& userGroups.receiverNames.includes(store.state.currentUserData.data.group))
					|| (route.name === 'public' && store.state.supplierGroup.data.channelsConfig.payment && tableData.value.paymentButton !== '')
				) {

					let paymentButtonColumn = {
						title: 'PAY NOW',
						field: 'paymentButton',
						cssClass: 'text-centered',
						width: 80,
						hozAlign: "center",
						headerSort: false,
						formatter(cell) {
							if (cell.getData().paymentButton !== null && cell.getData().paymentButton !== undefined) {
								const a = cash(`<div class="grid grid-rows-1 justify-items-center" style="position:relative; margin-top: -7px;">
																<div class="" style="border: 1px solid lightgray; border-radius: 5px; width: 31px; margin-left: 5px">
																	<span class="material-icons" style="padding-top: 2px" >credit_card</span>
																</div>
                                            				</span>
                                            				<span class="tooltip" style="position:absolute; margin-top: 18px">${cell.getData().paymentButtonTooltip}</span>
                                            				</div>
                                    					 `);
								cash(a).on('click', function () {
									window.open(cell.getData().paymentButton)
								})
								return a[0];
							} else return ''
						},
					}

					columns.splice(columns.length - 1, 0, paymentButtonColumn);
				}

//viewProperties column
				if ((route.name !== 'public') && columns.find(pay => pay.field === "viewPropertiesColumns") === undefined) {
					if (userGroups.supplierNames.includes(store.state.currentUserData.data.group)
						|| userGroups.optipostNames.includes(store.state.currentUserData.data.group)) { //check if user.group is in supplierNames or in optipostName
						if (store.state.docDetailTable.billing.displayViewPropertiesColumn) {
							let viewPropertiesColumn = {
								title: '',
								field: 'viewPropertiesColumns',
								width: 60,
								widthGrow: 1,
								headerSort: false,
								hozAlign: "center",
								cssClass: 'text-centered',
								formatter(cell) {
									return `<div  class="font-medium whitespace-nowrap salary billing">
 												${cell.getData().viewPropertiesColumns}
                              				</div>`
								}
							}
							columns.splice(0, 0, viewPropertiesColumn);
						}
					}
				}
			}
			// visibility
			if (store.state.docType === "BILLING" && route.name !== 'public') {
				if ((userGroups.supplierNames.includes(store.state.currentUserData.data.group)
						|| userGroups.optipostNames.includes(store.state.currentUserData.data.group))
					&& columns.find(col => col.title === 'VISIBILITY') === undefined) {
					if (store.state.overviewTable.billing.displayVisibilityColumn) {
						let visibilityColumn = {
							title: 'VISIBILITY',
							field: 'visibility',
							hozAlign: "center",
							cssClass: 'text-centered',
							headerSort: false,
							width: 100,
							formatter(cell) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common">
                                            				<p> ${cell.getData().visibility} </p>
                                    					</div> `)
								return a[0]
							}
						}
						columns.splice(columns.length - 4, 0, visibilityColumn)
					}
				}
			}
			if (store.state.docType === "SALARY") {
				if (columns.find(col => col.field === "viewPropertiesColumns") === undefined && route.name !== 'public'
					|| (route.name === 'documentDetail' && !(userGroups.optipostNames.includes(store.state.currentUserData.data.group)))) {
					if (store.state.docDetailTable.salary.displayViewPropertiesColumn) {
						let viewPropertiesColumn =
							{
								title: '',
								field: 'viewPropertiesColumns',
								width: 60,
								headerSort: false,

								formatter(cell) {
									return `<div  class="font-medium whitespace-nowrap salary billing">
												${cell.getData().viewPropertiesColumns}
                              				</div>`
								}
							}
						columns.splice(0, 0, viewPropertiesColumn)
					}
				}
				if (userGroups.supplierNames.includes(store.state.currentUserData.data.group)
					|| userGroups.optipostNames.includes(store.state.currentUserData.data.group)
					&& columns.find(col => col.title === "AMOUNT") === undefined) {
					if (store.state.docDetailTable.salary.displayAmountColumn) {
						let amountColumn = {
							title: 'AMOUNT',
							field: 'amount',
							cssClass: 'text-centered',
							width: 160,
							headerSort: false,
							formatter(cell) {
								return `<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center salary billing tab-cell">
											<div >${cell.getData().amount}</div>
                    					</div>`
							}
						}
						columns.splice(columns.length - 1, 0, amountColumn)
					}
				}
			}
			if (store.state.docType === "GENERAL") {
				if (columns.find(col => col.field === "viewPropertiesColumns") === undefined && route.name !== 'public'
					|| (route.name === 'documentDetail' && !(userGroups.optipostNames.includes(store.state.currentUserData.data.group)))) {
					if (store.state.docDetailTable.general.displayViewPropertiesColumn) {
						let viewPropertiesColumn =
							{
								title: '',
								field: 'viewPropertiesColumns',
								width: 60,
								headerSort: false,
								formatter(cell) {
									return `<div class="font-medium whitespace-nowrap salary billing">
												${cell.getData().viewPropertiesColumns}
                              				</div>`
								}
							}
						columns.splice(0, 0, viewPropertiesColumn);
					}
				}
			}
			return columns
		})

		const docId = ref(tableData.value.docId) //we should have a string to delete document

		watch(store.state.documentDetailData, () => {
				const originalData = store.state.documentDetailData
				const payment = store.state.documentDetailData.channels.payment?.paymentUpdates?.find(payment => payment.paymentStatus === "PAYMENT_CONFIRMED")
				if (payment) {
					tableData.value.payment = '<span class="payment-icon payment-icon-green"></span>'
				} else {

					const payment = getPaymentStatus(originalData.channels.payment, calcDueDate(originalData, originalData.opTableInfo), getDocType(originalData, originalData.documentType))

					tableData.value.payment = payment.payment
				}
				if (store.state.documentDetailData.visble) {
					tableData.value.visibility = this.documentData.visible ? `<i class="icon icon-eye1 icon-color visibility-doc-icon"></i>` : `<i class="icon icon-eye-blocked icon-color visibility-doc-icon"></i>`
				}
			},
			{deep: true}
		)

		const translation = {
			en: require('../../locales/en.json'),
			nl: require('../../locales/nl.json'),
			fr: require('../../locales/fr.json'),
			de: require('../../locales/de.json'),
			es: require('../../locales/es.json'),
			sk: require('../../locales/sk.json')
		}
		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value, {
				layout: 'fitColumns',
				data: [tableData.value],
				reactiveData: true,
				headerSort: false,
				rowHeight: 50,
				responsiveLayout: 'collapse',
				locale: true,
				langs: {
					"en-us": en,
					"nl-nl": nl,
					"de-de": de,
					"fr-fr": fr,
					"sk-sk": sk,
					"es-es": es,
				},
				columns: tableColumns.value,
			})
		}

		const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				if (store.getters.getLocale === 'en') tabulator.value.setLocale(store.getters.getLocale + '-us')
				else tabulator.value.setLocale(store.getters.getLocale + '-' + store.getters.getLocale)
			})
		}

		watch(() => store.getters.getLocale, function () {
			tabulator.value.setLocale(store.getters.getLocale)
		})
		watch(() => props.tableData, (value) => {
				tabulator.value.replaceData([value])
			},
			{deep: true}
		)
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw()
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}

		onMounted(() => {
			initTabulator()
			reInitOnResizeWindow()
			setLocale()
		})

		return {
			tableRef,
			selectedDocuments,
			docId
		}
	}
})
</script>

<!--IMPORTANT! Do NOT delete the unused 'button-container' and 'button-container-without-dropdown' because they ARE used -->
<style scoped>
#tabulator {
	padding-bottom: 0;
	margin-top: 15px;
}

.table-report img {
	box-shadow: none !important;
}

.btn:disabled:hover .tooltip {
	display: block;
}

.tooltip {
	position: absolute;
	display: none;
	margin-top: 30px;
	padding: 1px;
	top: 20px;
	background-color: var(--theme-primary-500);
	color: white;
	border-radius: 5px;
	font-size: 12px;
}
</style>
