<template>
	<div class="profile-inner box" v-if="displayProfile">
		<div class="profile-header text-xl" :style="{'background-color': brandSettings.mainColor}">
			<h3>{{ this.$store.state.currentUserData.data.email }}</h3>
		</div>
		<div class="profile-content">
			<div class="divider">
				<h2 class="profile-link" @click="editProfile">
					<i class="icon-user1 icon-color"> </i>
					{{ $t('profile.title') }}
				</h2>
				<h2 class="profile-link"
					v-if="displayResetPassword"
					@click="resetOwnPassword">
					<i class="icon-key icon-color"> </i>
					{{ $t('general.resetPassword') }}
				</h2>
				<div class="profile-link"
					 v-if="displayTos">
					<h2 class="text-lg color-grey">{{ $t('termsConditions.generalTermsAndConditions.title') }}</h2>
					<i class="icon icon-file-text ml-4 info-color"></i>
					<router-link :to=routerData.tos.link @click="closeProfile">
						{{ $t('termsConditions.title') }}
					</router-link>
				</div>
				<div v-if="displaySgTos">
					<h1 class="text-lg color-grey">{{ SgTosTermsTitle }}</h1>
					<div v-for="(sgTermItem, index) in SgTosData"
						 :key="index"
						 class="ml-4">
						<a class="profile-link"
						   target="_blank"
						   @click="closeProfile"
						   v-if="sgTermItem.location[this.$store.getters.getLocale.toUpperCase()]"
						   :href="sgTermItem.location[this.$store.getters.getLocale.toUpperCase()].url">
							<i class="icon icon-file-text info-color"></i>
							{{ sgTermItem.location[this.$store.getters.getLocale.toUpperCase()].description }}
						</a>
					</div>
				</div>
				<div v-if="displayLeTos">
					<h1 class="text-lg color-grey">{{ $t('termsConditions.leTermsAndConditions.title') }}</h1>
					<div v-for="(leTermItem, index) in LeTosData"
						 :key="index"
						 class="ml-4">
						<div class="color-light">{{ leTermItem.leName }}</div>
						<div class="profile-link"
							 v-if="leTermItem.location[this.$store.getters.getLocale.toUpperCase()]"
							 target="_blank"
							 @click="getLeTos({leName: leTermItem.leName,
                                                        index: leTermItem.index,
                                                        location : leTermItem.location[this.$store.getters.getLocale.toUpperCase()],
                                                        lang: this.$store.getters.getLocale.toUpperCase(),
                                                        legalEntityNumber: leTermItem.legalEntityNumber})"
						>
							<i class="icon icon-file-text info-color"></i>
							{{ leTermItem.location[this.$store.getters.getLocale.toUpperCase()].description }}
						</div>
					</div>

				</div>
			</div>
			<div class="button-container">
				<div class="button-container-inner">
					<button
						v-if="displayCleanUpButton"
						class="btn custom-button shadow-md text-popup" @click="cleanUp">
						{{ $t('buttons.cleanUp') }}
					</button>
					<button class="btn custom-button shadow-md text-popup" @click="closeProfile">
						{{ $t('buttons.cancel') }}
					</button>
					<button class="btn custom-button shadow-md text-popup logout" @click="logOut">
						{{ $t('buttons.logout') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import routerData from "../routerData"
import textEN from "../locales/en.json"
import textNL from "../locales/nl.json"
import textFR from "../locales/fr.json"
import textDE from "../locales/de.json"
import store from "../store"
import userGroups from "../userGoups"

const newLoginPage = window.newLoginPage

export default {
	emits: ['closeProfile', 'editProfile'],
	data() {
		return {
			routerData: routerData,
			SgTosData: [],
			LeTosData: [],
			SgTosTitleItems: {
				en: textEN.termsConditions.sgTermsAndConditions.title,
				fr: textFR.termsConditions.sgTermsAndConditions.title,
				nl: textNL.termsConditions.sgTermsAndConditions.title,
				de: textDE.termsConditions.sgTermsAndConditions.title,
			},
			SgTosTitle: textEN.termsConditions.sgTermsAndConditions.title
		}
	},
	computed: {
		displayCleanUpButton() {
			if ((this.$store.state.supplierGroup.data.supplierGroupId === "demo")
				&& (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group))
			) {
				return true
			}
			return false
		},
		displayResetPassword() {
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset === undefined) {
				return false
			} else {
				return !this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.hidePasswordReset
			}
		},
		SgTosTermsTitle() {
			let SGName = this.$store.state.supplierGroup.data.name
			return this.SgTosTitleItems[this.$store.state.locale].replaceAll("INSERT_SG_NAME", SGName)
		},
		locale() {
			return this.$store.state.locale
		},
		brandSettings() {
			return this.$store.state.supplierGroup.branding
		},
		displayTos() {
			if (this.$store.state.docType === "BILLING") {
				return !!this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.termsOfServiceEmail
			}
			return false
		},
		displaySgTos() {
			if ((this.$store.state.docType === "BILLING" && this.$store.state.supplierGroup.data.templates && this.$store.state.supplierGroup.data.templates.terms)) {
				let displayTerms = false
				this.$store.state.supplierGroup.data.templates.terms.forEach(term => {
					if (term.display) {
						displayTerms = true
						this.SgTosData.push(term)
					}
				})
				if (displayTerms) {
					return true
				}
			}
			return false
		},
		displayLeTos() {
			if (this.$store.state.docType === "BILLING") {
				let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities
				let displayLeTerms = false
				for (let le in legalEntities) {
					if (legalEntities[le].templates && legalEntities[le].templates.terms) {
						legalEntities[le].templates.terms.forEach((term, index) => {
							if (term.display) {
								displayLeTerms = true
								let tempItem = {
									location: term.location,
									leName: legalEntities[le].name,
									legalEntityNumber: legalEntities[le].entityNumber,
									index: index
								}
								this.LeTosData.push(tempItem)
							}
						})
					}
				}
				if (displayLeTerms) {
					return true
				}
			}
			return false
		},
		displayProfile() {
			if (this.$route.name === 'public'
				|| this.$route.name === 'onboarding'
				|| this.$route.name === 'saml'
				|| this.$route.name === 'login'
				|| this.$route.name === 'channelChoice'
				|| this.$route.name === 'channelChoiceAC'
				|| this.$route.name === 'onboardingAC'
				|| this.$route.name === 'cancelMandateSuccessful'
				|| this.$route.name === 'cancelMandateFail'
				|| this.$route.name === 'createMandateOk'
				|| this.$route.name === 'mandateOk'
				|| this.$route.name === 'onboardingSuccessful'
				|| this.$route.name === 'onboardingUnsuccessful'
				|| this.$route.name === 'cancelMandateCreate'
				|| this.$route.name === 'terms'
				|| (this.$route.name === 'help'
					&& !(this.$store.state.userIsAuthenticated))) {
				return false
			}
			return true
		}
	},
	methods: {
		cleanUp() {
			this.$store.dispatch('cleanUp')
			this.closeProfile()
		},
		resetOwnPassword() {
			this.closeProfile()
			this.$store.commit("setDisplayResetOwnPassword", true)
		},
		logOut() {
			this.$emit('closeProfile')
			if (newLoginPage) {
				store.commit("redirectPageAfterLogin", this.$route.path)
				this.$root.keycloak.logout()
				this.$store.dispatch('logout')
			} else {
				this.$router.push('/')
				setTimeout(() => {
					this.$root.keycloak.logout()
				}, 10)
			}
		},
		getLeTos(data) {
			this.$store.dispatch('downloadLeTos', data)
		},
		getSGTos() {
			this.$store.dispatch('downloadBillingTerms', this.$root.token)
		},
		closeProfile() {
			this.$emit('closeProfile')
		},
		editProfile() {
			this.$emit('editProfile')
		},
	},

}
</script>

<style scoped>
.profile-inner {
	z-index: 50;
	position: absolute;
	right: 0px;
	top: calc(0.5 * var(--headerheight));
	margin-right: var(--generalPaddings);
	background-color: var(--primaryBgColor);
}

.profile-header, .profile-content {
	padding: calc(0.5 * var(--generalPaddings)) 30px;
}

.profile-content {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto auto;
	grid-auto-flow: row;
	border: 1px solid #d9d9d9;
	font-size: 1.125rem;
	line-height: 1.75rem;

}

.logout {
	justify-self: end;
	font-size: 14px;
}

.profile-header {
	display: grid;
	grid-template-rows: calc(100% - 30px) 30px;
	grid-template-columns: calc(100% - 0px) 0;
	grid-auto-flow: row;
	color: white;
	border-radius: 0.375rem 0.375rem 0 0;
	padding: 20px 0;
}

.profile-header span {
	grid-column: 2/3;
	grid-row: 1/2;
	cursor: pointer;
	justify-self: end;
	font-size: 14px;
}

.profile-header h3 {
	grid-column: 1/2;
	grid-row: 2/3;
	justify-self: center;
}

.dialog-enter-active {
	transition: all 0.3s ease-out;
}

.dialog-leave-active {
	transition: all .3s ease-in;
}

.dialog-enter-from, .dialog-leave-to {
	transform: scale(0.8);
	opacity: 0;
}

.profile-content i {
	font-size: 18px;
	margin-right: 5px;
}

.divider {
	margin-bottom: calc(0.5 * var(--generalPaddings));
	padding-bottom: calc(0.5 * var(--generalPaddings));
	border-bottom: 1px solid var(--theme-primary-50);
}

.profile-link {
	cursor: pointer;
	margin-bottom: var(--generalPaddings);
}

.color-light {
	color: var(--theme-primary-200);
}

.divider {
	margin-bottom: var(--generalPaddings);
	border-bottom: 1px solid var(--theme-primary-50);
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.button-container-inner > .btn {
	margin-left: 6px;
}


@media only screen and (max-width: 520px) {
	.profile-content {
		font-size: 1rem;
		line-height: 1.4rem;
	}

	.profile-inner {
		margin-right: 10px;
	}

	.profile-link {
		margin-bottom: 10px;
	}
}

</style>