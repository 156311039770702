<template>
    <transition name="dialog" v-if="dataIsReady && this.$store.state.onboarding.isReady">
        <page-container class="page-content">
            <div>
                <top-banner>
                    <div class="w-9/12">
                        <h1 class="text-3xl text-center">{{ $t('onboarding.header.title') }}</h1>
                    </div>
                </top-banner>
                <div class="box page-content intro-y box p-5 mt-5">
                    <div class="content-container">
                        <h1 class="text-center">{{ $t('onboarding.mandateCreated.description') }}</h1>
                    </div>

                </div>
            </div>
        </page-container>
    </transition>
</template>

<script>
import TopBanner from "../components/TopBanner";
import PageContainer from "../components/PageContainer";
import i18n from '../i18n'


export default {
    name: "CreateMandateOk",
    components: {TopBanner, PageContainer},
    data() {
        return {
            i18n : i18n,
            dataIsReady: false,
            accessCode : '',
        }
    },
    mounted(){
        this.setLang();
        this.getAccessCode();
    },
    computed:{
        onboardingData(){
            return this.$store.state.onboarding.isReady
        }
    },
    watch:{
        onboardingData(newVal){
            if (newVal){
               this.sendFlowComplete();
            }
        }
    },
    methods:{
        getOnboardingData(){
            this.$store.dispatch('fetchGetOnboardingData', {
                mandateOnboardingAccessCode: this.accessCode,
                supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId
            });
        },
        setLang(){
            let lang = window.location.href.split('/')[3];
            this.$store.commit('setLocale', lang);
            this.dataIsReady = true;
        },
        getAccessCode(){
            let accessCode = window.location.href.split('/')[6];
            this.accessCode = accessCode.split("#")[0]
            if (this.accessCode){
                this.getOnboardingData();
            }
        },
        sendFlowComplete(){
            let payload = {
                supplierGroupId : this.$store.state.supplierGroup.data.supplierGroupId,
                legalEntityId : this.$store.state.onboarding.data.legalEntityId,
                receiverId : this.$store.state.onboarding.data.receiverId,
                mandateOnboardingAccessCode : this.accessCode
            }
            this.$store.dispatch('sendFlowCompleted', payload);
        }
    }
}
</script>

<style scoped>
.content-container{
    width: 60%;
    margin: 60px 20%;
}
.text-center{
    text-align: center;
}
.content-container h1{
    line-height: 2em;
    font-size:20px;
}
@media only screen and (min-width: 600px) and (max-width: 900px){
    .content-container{
        width: 80%;
        margin: 40px 10%;
    }
    .content-container h1{
        line-height: 1.8em;
        font-size: 18px;
    }
}
@media only screen and (min-width: 00px) and (max-width: 599px){
    .content-container{
        width: 90%;
        margin: 20px 5%;
    }
    .content-container h1{
        line-height: 1.6em;
        font-size: 16px;
    }
}

.mandate-data{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
    margin-top: 10px;
}
.mandate-data>div{
    width: 400px;
}
</style>
