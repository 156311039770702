<template>
	<div class="mb-2">
		<h2 class="text-base font-bold mb-1">{{ title }}</h2>
		<div class="margin-left container_title" style="margin-top: -8px">
			<label-with-icon
				:margin-left="10"
				icon-name="warning_amber"
				:label="description"
				icon-severity-color="warning"
				:font-size-label="14"
				:font-size-icon="20"
			/>
		</div>
		<div class="ml-9">
			<p v-if="informationReceiverText !== ''" style="font-size: 14px">{{ informationReceiverText }}</p>
			<div v-if="hasReceiversData && receiversData.length">
				<li
					v-for="(item, index) in receiversData"
					:key="index"
					style="font-size: 14px"
				>
					{{ item.email ? item.email : item }}
				</li>
			</div>
		</div>
	</div>
</template>
<script>
import LabelWithIcon from "../../UI/LabelWithIcon.vue";

export default {
	components: {LabelWithIcon},
	props: {
		title: String,
		description: {
			type: String,
			required: false,
			default: ''
		},
		informationReceiverText: {
			type: String,
			required: false,
			default: ''
		},
		receiversData : Array,
		hasReceiversData: {
			type: Boolean,
			required: false,
			default: true
		}
	},
}

</script>

<style scoped>
.container_title {
	display: flex;
	flex-direction: row;
}
.margin {
	margin-top: calc(1 * var(--generalPaddings));
}
</style>