<template>
	<div class="mb-2.5">
		<p>{{ $t('general.selectDate') }}</p>
		<div class="one-line items-center">
			<div class="flex flex-row items-center" :style="showInOneColumn ? {width: '100%'} : {width: '50%'} ">
				<vue-date-picker
					style="padding: 0 !important;"
					:class="showInOneColumn ? '' : 'mr-1.5'"
					class="mb-2.5 w-full"
					v-model="extendedSearch.period.from"
					auto-apply
					format="dd/MM/yyyy"
					:enableTimePicker=false
				>
					<template #input-icon>
						<span class="material-icons calendar-icon">calendar_month</span>
					</template>
					<template #clear-icon="{clear}">
						<span class="material-icons clear-icon" @click="clear">close</span>
					</template>

				</vue-date-picker>

			</div>
			<p v-if="!showInOneColumn" style="font-size: 30px">/</p>
			<div class="flex flex-row items-center" :style="showInOneColumn ? {width: '100%'} : {width: '50%'}">
				<vue-date-picker
					:class="showInOneColumn ? '' : 'ml-1.5'"
					class="mb-2.5 w-full"
					v-model="extendedSearch.period.to"
					auto-apply
					format="dd/MM/yyyy"
					:enableTimePicker=false
				>
					<template #input-icon>
						<span class="material-icons calendar-icon">calendar_month</span>
					</template>
					<template #clear-icon="{clear}">
						<span class="material-icons clear-icon" @click="clear">close</span>
					</template>
				</vue-date-picker>
			</div>
		</div>
		<h2>{{ $t('general.selectReason') }}</h2>
		<div class="one-line items-center">
			<div class="flex flex-row items-center" :class="showInOneColumn ? '' : 'mr-2.5'" :style="showInOneColumn ? {width: '100%'} : {width: '50%'} ">
				<vue-select
					v-model="extendedSearch.reason"
					:options='optionsReasons'
					:clearable=false
					class="w-full"
				>
					<template #option="option">
						{{ $t(option.label) }}
					</template>
					<template #selected-option="option">
						{{ $t(option.label) }}
					</template>
				</vue-select>
			</div>
			<div class="ml-2.5" style="width: 50%"/>

		</div>
	</div>
</template>
<script>

import VueDatePicker from "@vuepic/vue-datepicker";
import VueSelect from "vue3-select";

export default {
	components: {VueDatePicker, VueSelect},
	data() {
		return {
			showInOneColumn: window.innerWidth < 980,
			extendedSearch: {}
		}
	},
	created() {
		this.extendedSearch = this.extendedSearchStartValue()
		if (this.$store.state.extendedSearchValue.extendedSearch.period)this.extendedSearch.period = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch.period))
		if (this.$store.state.extendedSearchValue.extendedSearch.reason) this.extendedSearch.reason = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch.reason))

		window.addEventListener('resize', () => this.showInOneColumn = 'change var')
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler() {
				this.extendedSearch = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch))
			},
			deep: true
		},
		showInOneColumn() {
			this.showInOneColumn = window.innerWidth < 980
		}
	},
	computed: {
		// showInOneColumn() {
		// 	return window.innerWidth < 980
		// },
		optionsReasons(){
			return [
				{label: 'dropdowns.mailReason.all', value: null},
				{label: 'dropdowns.mailReason.hardBounce', value: "HardBounce"},
				{label: 'dropdowns.mailReason.spamComplaint', value: "SpamComplaint"},
			]
		}
	},
	methods: {
		extendedSearchStartValue(){
			return {
				period: {
					from: null,
					to: null
				},
				reason: {label: 'dropdowns.mailReason.all', value: null}
			}
		}
	}
}
</script>

<style scoped>
.one-line {
	display: flex;
	flex-direction: row;
}
.calendar-icon {
	padding-top:5px;
	font-size: 20px;
	padding-left: 6px;
	padding-right: 12px;
	color: #355983;
}
.clear-icon {
	position: absolute;
	font-size: 20px;
	top: 12px;
	right: 15px;
	color: #82858a;
	cursor: pointer;
}

@media only screen and (max-width: 980px) {
	.one-line {
		flex-direction: column;
	}
}
</style>