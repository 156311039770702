<template>
	<div :class="{'pointer-events-none opacity-50':disabled, 'flex items-center justify-center w-full':centerItem }">
		<label :for="id" class="flex items-center cursor-pointer">
			<!-- toggle -->
			<div class="relative">
				<!-- input -->
				<input @click="change" :id="id" type="checkbox" class="sr-only"/>
				<!-- line -->
				<div class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
				<!-- dot -->
				<div
					ref="bool"
					class="false absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
				></div>
			</div>
		</label>
	</div>
</template>
<script>
export default {
	name: 'ToggleSlide',
	props: {
		id: String,
		bool: Boolean,
		disabled: Boolean,
		renewBool: {
			type: Boolean,
			default: false,
		},
		centerItem: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	watch: {
		bool(newVal) {
			if (this.renewBool) {
				this.curBool = newVal;
			}

			this.boolState()
		}
	},
	data() {
		return {
			curBool: this.bool
		}
	},
	emits: ['update-value'],
	methods: {
		change() {
			this.curBool = !this.curBool
			this.$emit("update-value", this.curBool);
			this.boolState()
		},
		boolState() {
			if (this.curBool) {
				this.$refs.bool.classList.remove("false")
				this.$refs.bool.classList.add("true")
			} else {
				this.$refs.bool.classList.remove("true")
				this.$refs.bool.classList.add("false")
			}
		}
	},
	mounted() {
		this.boolState()
	}
}
</script>
<style scoped>
.true {
	transform: translateX(100%);
	background-color: #291f8d;
}

.false {
	background-color: grey
}

</style>
