<template>
    <div class="intro-y "
         v-if="this.$store.state.userDepartmentCodeList.length">
        <the-dialog
              :show="this.$store.state.displayUploadFileLoader"
              :title="fileProcessing"
              :bgTransparent="true"
              fixed>
            <file-uploader :batches="this.batchesUploaded">
            </file-uploader>
        </the-dialog>

        <div class="upload-container mt-6">
            <div class="description">
                <div v-for="(item, index) in description"
                     :key="index"
                >
                    <h2 class="text-lg">
                        <i class="icon-checkmark info-color text-xs"></i> {{ item }}
                    </h2>

                </div>
            </div>
            <h1 v-if="disabledUpload">{{ $t('postDrop.forbiddenToUploadFiles') }}</h1>
            <div class="upload-container-inner">
                <div class="one-line">
                    <p class="font-semibold">{{ $t('postDrop.addAttachment') }}</p>
                    <div>
                        <input type="file"
                               v-if="renderComponent"
                               multiple="multiple"
                               ref="file"
                               class="form-control text-popup "
                               @change="uploadFile"
                               @click="setFileErrors"
                               accept=".pdf"
                               :disabled="disabledUpload"
                        >
                    </div>
                </div>

                <div class="mt-2">
                    <p class="error"
                       v-if="errors.files">
                        {{ $t('errors.attachFileIsEmpty') }}
                    </p>
                    <p class="error"
                       v-if="errors.fileType">
                        {{ $t('errors.fileType') }}
                    </p>
                </div>
                <div>
                    <div class="one-line">
                        <p class="font-semibold">{{ $t('postDrop.selectDepartment') }}:</p>
                        <v-select
                              @click="setErrors('departmentCode' , false)"
                              v-model="departmentCode"
                              :options=this.$store.state.userDepartmentCodeList
                              :clearable="false"
                              :disabled="disabledUpload"
                        >
                            <template #no-options="{search}">
                                {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                            </template>
                        </v-select>
                    </div>
                    <div class="mt-2">
                        <p class="error"
                           v-if="errors.departmentCode">
                            {{ $t('errors.departmentCode') }}
                        </p>
                    </div>
                </div>

                <div class="one-line" v-show="displayPrintJob">
                    <p class="font-semibold">{{ $t('postDrop.selectPrintJob') }}:</p>
                    <v-select
                          @click="setErrors('printJob' , false)"
                          v-model="printJob"
                          :options=this.printJobOptions
                          :clearable="false"
                          :disabled="disabledUpload"
                    >
                        <template #no-options="{search}">
                            {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                        </template>


                    </v-select>
                </div>
                <div class="mt-2">
                    <p class="error"
                       v-if="errors.printJob">
                        {{ $t('errors.printJob') }}
                    </p>
                </div>
                <div class="one-line">
                    <p class="font-semibold">{{ $t('postDrop.postDropType') }}</p>
                    <v-select
                          v-model="postalType"
                          :options='options'
                          :clearable='false'
                          :disabled="disabledUpload"
                    >
                        <template #no-options="{search}">
                            {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                        </template>
                    </v-select>
                </div>
                <div class="one-line recto-verso">
                    <p class="font-semibold">{{ $t('postDrop.rectoVerso') }}</p>
                    <input type="checkbox"
                           class="customCheckbox"
                           v-model="attachmentsRectoVerso"
                    >
                </div>
                <div class="grid grid-rows-1 justify-items-center my-6 grey-top-border">
                    <div>
                        <div class="upload">
                            <button class="btn custom-button shadow-md mr-2 showStatuses text-popup "
                                    @click="updateToken"
                                    :disabled="disabledUpload"
                            >
                                {{ $t('buttons.upload') }}
                            </button>
                            <div>
                                <div class="tooltip forbidden-to-upload"
                                     :class="{'display-none' : !disabledUpload}"
                                >
                                    {{ $t('tooltips.forbiddenToUpload') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "../../i18n"
import FileUploader from "../UI/FileUploader";
import TheDialog from "../UI/TheDialog";
import userGroups from "../../userGoups";
import textEN from "../../locales/en.json";
import textNL from "../../locales/nl.json";
import textFR from "../../locales/fr.json";
import textDE from "../../locales/de.json";

export default {
    name: "UploadFiles",
    components: {
        TheDialog,
        FileUploader,

    },
    mounted() {
        this.$store.dispatch("updateToken");
        this.$store.commit("clearUploadedFiles");
        this.$store.commit("setUploadBatchId", '')


    },
    beforeUnmount() {
        this.$store.commit('setPrintMode', '')
        this.$store.commit("setUploadedBatches", 0);
        this.$store.commit("setUploadBatchId", '')
    },
    data() {
        return {
            descriptionTranslation: {
                en: textEN.postDrop.uploadFilesDescriptionItems,
                nl: textNL.postDrop.uploadFilesDescriptionItems,
                fr: textFR.postDrop.uploadFilesDescriptionItems,
                de: textDE.postDrop.uploadFilesDescriptionItems
            },
            i18n: i18n,
            departmentId: '',
            name: "",
            departmentCode: {label: "", value: ""},
            errors: {
                printJob: false,
                files: false,
                departmentCode: false,
                fileTypeError: false,
            },
            files: [],
            printJob: {label: "", value: ""},
            postalType: {label: i18n.t('postDrop.postDropTypeList.regular'), value: 'REGULAR'},
            options: [
                {label: i18n.t('postDrop.postDropTypeList.regular'), value: 'REGULAR'},
                {label: i18n.t('postDrop.postDropTypeList.prior'), value: 'PRIOR'},
                {label: i18n.t('postDrop.postDropTypeList.registered'), value: 'REGISTERED'},
                {label: i18n.t('postDrop.postDropTypeList.registeredack'), value: 'REGISTERED_ACK'},
            ],
            batchesUploaded: 0,
            displayPrintJob: false,
            renderComponent: true,
            fileBatches: [],
            attachmentsRectoVerso: true,
            checkFiles: false,
        }
    },
    watch: {
        locale() {
            let selectedMailType = this.postalType.value.toLowerCase()
            let key = 'postDrop.postDropTypeList.' + selectedMailType;
            this.postalType.label = i18n.t(key);
            this.setOptions();
        },
        uploadedBathes(newVal) {
            if (newVal !== 0) {
                if (newVal === this.batchesUploaded) {
                    if (this.$store.state.uploadedFileErrorOccurred) {
                        this.$store.commit("setGeneralNotificationText", {field: "fileUploadedError", text: "Ok"});
                        this.$store.commit('displayNotification', true);
                    } else {
                        this.$store.commit("setGeneralNotificationText", {field: "fileUploaded", text: "Ok"})
                        this.$store.commit('displayNotification', true);
                    }
                    this.$store.commit('setDisplayUploadFileLoader', false);
                    this.forceRerender();
                    this.checkFiles = false;
                } else {
                    this.$store.dispatch('uploadFiles', {
                        fileNames: this.fileNames,
                        fileBatches: this.fileBatches,
                        departmentCode: this.departmentCode.value,
                        printJob: this.printJob.value,
                        postalType: this.postalType.value
                    })
                }
            }
        },
        uploadFileErrorOccurred(newVal) {
            if (newVal) {
                this.forceRerender();
                this.$store.commit('setUploadFileErrorOccurred', false)
            }
        },
        token() {
            if (this.checkFiles) {
                this.uploadFiles()
            }
        },
        resendErrorUploadedFiles() {
            if (this.$store.state.displayResendErrorFilesButton) {
                this.uploadFiles();
                this.$store.commit("setDisplayResendErrorFilesButton", false);
            } else {
                this.$store.commit("setDisplayResendErrorFilesButton", false);
            }
        }
    },
    computed: {
        description() {
            return this.descriptionTranslation[this.$store.state.locale]
        },
        resendErrorUploadedFiles() {
            return this.$store.state.resendErrorUploadedFiles
        },
        token() {
            return this.$store.state.token
        },
        uploadFileErrorOccurred() {
            return this.$store.state.uploadFileErrorOccurred
        },
        locale() {
            return this.$store.state.locale;
        },
        uploadedBathes() {
            return this.$store.state.uploadedBathes
        },
        fileProcessing() {
            return i18n.t('general.fileProcessing')
        },
        disabledUpload() {
            if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group) || this.$store.state.currentUserData.data.group === "SupplierGroupSuperAdmin") {
                return false
            }
            if (this.$store.state.currentUserData.data.departments
                  && this.$store.state.userDepartmentCodeList.length === 1
                  && this.$store.state.userDepartmentCodeList[0].value !== '') {
                this.setDepartmentCode()
                return false
            }
            if (this.$store.state.currentUserData.data.departments
                  && this.$store.state.userDepartmentCodeList.length > 1
            ) {
                return false
            }
            return true
        },


        printJobOptions() {
            let printJobOptions = [];
            if (this.$store.state.supplierGroup.data.channelsConfig
                  && this.$store.state.supplierGroup.data.channelsConfig.print
                  && this.$store.state.supplierGroup.data.channelsConfig.print.printJobs
            ) {
                this.$store.state.supplierGroup.data.channelsConfig.print.printJobs.forEach(job => {

                    let name = job.name
                    if (job.additional && job.additional.displayName) {
                        name = job.additional.displayName
                    }
                    let tempItem = {
                        label: name,
                        value: job.name
                    }
                    printJobOptions.push(tempItem)
                })
            } else {
                printJobOptions.push({
                    label: i18n.t('general.noPrintJob'),
                    value: ''
                })
            }
            this.setPrintJob(printJobOptions);
            return printJobOptions
        },
    },

    methods: {
        setOptions() {
            this.options.forEach(option => {
                let type = option.value.toLowerCase().replace("_", '')
                let key = 'postDrop.postDropTypeList.' + type
                option.label = i18n.t(key)
            })

        },
        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        setDepartmentCode() {
            this.departmentCode = this.$store.state.userDepartmentCodeList[0];
        },
        setPrintJob(jobsList) {
            if (jobsList.length === 1) {
                this.printJob = jobsList[0];
                this.displayPrintJob = false
            } else {
                this.displayPrintJob = true
            }

        },
        validateFile() {
            this.$store.commit("displayValidate", true);
        },
        uploadFile() {
            this.files = this.$refs.file.files;
        },
        setFileErrors() {
            this.setErrors('files', false);
            this.setErrors('fileType', false);
        },
        setErrors(field, value) {

            this.errors[field] = value
        },
        updateToken() {
            this.checkFiles = true;
            if (this.files.length) {
                this.$store.dispatch('updateToken');
            } else {
                this.setErrors('files', true);
            }
        },
        uploadFiles() {
            this.$store.commit("clearUploadedFiles");
            this.$store.commit("setUploadedBatches", 0);
            this.$store.commit('setUploadFileErrorOccurred', false);
            let fileBatches = [];
            if (this.$store.state.resendErrorUploadedFiles) {
                this.$store.commit('setResendErrorFiles', true);
                fileBatches = this.$store.state.errorUploadedFiles;
                this.fileBatches = fileBatches;
                this.batchesUploaded = fileBatches.length;
                let totalFilesNumber = 0;
                fileBatches.forEach(batch => {
                    totalFilesNumber = totalFilesNumber + Array.from(batch.keys()).length
                })
                this.$store.commit("setTotalFilesNumber", totalFilesNumber);
                this.$store.state.errorUploadedFiles = [];
                this.$store.state.uploadedFileNames = [];
                this.$store.state.uploadedFileNamesError = [];
            } else {
                this.$store.state.errorUploadedFiles = [];
                this.$store.state.uploadedFileNames = [];
                this.$store.state.uploadedFileNamesError = [];
                if (this.files.length === 0) {
                    this.setErrors('files', true);
                    return
                }
                this.$store.commit("setTotalFilesNumber", this.files.length)
                let fileTypeError = false;
                for (let file in this.files) {
                    if (this.files[file].type) {
                        if (this.files[file].type !== "application/pdf") {
                            fileTypeError = true
                        }
                    }
                }
                if (fileTypeError) {
                    this.setErrors('fileType', true);
                    return
                }
                if (this.departmentCode.value === '') {
                    this.setErrors('departmentCode', true);
                    return
                }
                if (this.printJob.value === "") {
                    this.setErrors('printJob', true);
                    return
                }
                let filesInBatch = 2;
                let batchNumbers = Math.ceil(this.files.length / filesInBatch);
                this.batchesUploaded = batchNumbers;

                for (let j = 0; j < batchNumbers; j++) {
                    const formData = new FormData();
                    let i = j * filesInBatch;
                    for (i; i < (j * filesInBatch + filesInBatch); i++) {
                        if (this.files[i]) {
                            formData.append('file', this.files[i]);
                        }
                    }
                    fileBatches.push(formData)
                }
                this.fileBatches = fileBatches;
            }
            let printMode = ""
            if (this.attachmentsRectoVerso === true) {
                printMode = "RECTO_VERSO"
            } else {
                printMode = "RECTO"
            }
            if (printMode) {
                this.$store.commit('setPrintMode', printMode)
            }
            let batchId = ''
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < 6) {
                batchId += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            this.$store.commit('setBatchId', batchId);
            this.$store.commit('setDisplayUploadFileLoader', true);
            this.$store.dispatch('uploadFiles', {
                fileBatches: fileBatches,
                departmentCode: this.departmentCode.value,
                printJob: this.printJob.value,
                postalType: this.postalType.value,
                printMode: this.attachmentsRectoVerso
            });
            this.files = [];
        },
        setDepartmentData(data) {
            if (data.departmentId) {
                this.departmentId = data.departmentId;
                this.name = data.departmentName;
                this.departmentCode = data.code
            }
        },
    }
}
</script>

<style scoped>
.upload-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    justify-items: center;
}

.upload-container-inner {
    width: 600px;
}

.one-line {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 140px auto;
    align-items: center;
    margin-top: 10px;

}

.upload {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
}

.upload button, .forbidden-to-upload {
    grid-column: 1/2;
    grid-row: 1/2;
}

.forbidden-to-upload {
    top: 0;
}

.upload:hover .tooltip {
    display: grid;

}

.display-none {
    display: none !important;
}

.button-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: end;
}

.description {
    width: 600px;
    margin-bottom: 20px;
    line-height: 1.8rem;
}
@media only screen and (max-width: 700px) {
    .description {
        width: 90%;
        margin: 5% 0 5% 20px;
        line-height: 1.8rem;
        font-size: 1rem;
    }
    .description h2{
        font-size: 1rem;
    }

}
@media only screen and (max-width: 450px) {
    .description h2{
        font-size: 12px;
    }
}
@media only screen and (max-width: 750px) {
    .upload-container-inner {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .one-line {
        grid-template-rows: auto auto;
        grid-template-columns: 100%;
    }

    .recto-verso {
        grid-template-rows: 100%;
        grid-template-columns: 100px auto;
    }
}
</style>
