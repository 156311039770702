<template>
    <pop-up-window :show="this.$store.state.userManagement.displayResetPassword"
                   :showClose = false
                   @close="closeResetPassword"
                   class="doc-statuses-popup"
                   additional-info=""
                   :positionFixed=true
                   fixed>
        <template #title>
            <h1 class="text-2xl">
               {{$t('management.resetPassword.title')}}
            </h1>
        </template>
        <template #content>
            <div class="user-info divider">
                <h2 class="text-center">
                    <i class="icon-notification info-color"></i> {{resetText}}
                </h2>
                <h2 class="text-center color-grey">
                    {{email}}
                </h2>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeResetPassword">
                        {{$t('buttons.cancel')}}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="resetPassword">
                        {{$t('buttons.reset')}}
                    </button>
                </div>
            </div>
        </template>
    </pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue";
import i18n from '../../../i18n'
export default {
    name: "ResetPassword",
    components: {PopUpWindow},
    emits:[ 'resetPassword'],
    data(){
        return {
            i18n: i18n,
        }
    },
    computed:{
        email(){
            if(this.$store.state.resetLoggedInUserPassword){
                return this.$store.state.currentUserData.data.email;
            }
            return this.$store.state.userManagement.userData.email
        },
        resetText(){
            if(this.$store.state.resetLoggedInUserPassword){
                return i18n.t('management.resetPassword.resetLoggedInUserPassword');
            }
            return i18n.t('management.resetPassword.text');
        }
    },
    methods:{
        closeResetPassword(){
            this.$store.commit('displayResetPassword', false);
        },
        resetPassword(){
            if(!this.$store.state.resetLoggedInUserPassword){
                this.$store.dispatch('resetPassword', {formData : {
                        language: this.$store.state.userManagement.userData.language,
                        userEmail: this.$store.state.userManagement.userData.email,
                    }});
            } else {
                this.$store.dispatch('resetPassword', {formData : {
                        language: this.$store.state.currentUserData.data.language,
                        userEmail: this.$store.state.currentUserData.data.email,
                    }});
            }
            this.$store.commit('resetLoggedInUserPassword', false);
            this.closeResetPassword();
            this.$emit("passwordIsReset");
        }
    }
}
</script>

<style scoped>
.divider{
    padding-bottom: calc(0.5 * var(--generalPaddings));
    border-bottom: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}
</style>
