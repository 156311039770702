<template>
    <div>

        <div class="loader-container-outer">
            <div class="loader-container">
                <div class="status-percents"
                     :style = "{'width' : status}"
                >
                    <div class="status-bar" >
                        <div class="status-bar-img" >
                        </div>
                    </div>
                </div>
                <div class="current">
                    <div class="status-text">
                        {{status}}
                    </div>
                </div>
                <div class="total">
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "FileUploader",
    props: {
        batches: {
            Type: Number,
            Required: true
        }
    },
    data() {
        return {
            total: this.batches,
            current: 0,
        }
    },
    watch:{
        uploadedBathes(newVal){
            this.setCurrent(newVal)
        }
    },
    computed:{
        uploadedBathes(){
            return this.$store.state.uploadedBathes
        },
        status(){
            let width = Math.round((this.$store.state.uploadedBathes/this.total)*100);
            let status =  width + "%";
            return status;
        }
    },
    methods:{
        setCurrent(value){
            this.current = value;
        }
    }
}
</script>

<style scoped>

.loader-container-outer{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;

}
.loader-container{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    align-items: center;
    width: 80%
}

.current, .total, .status-percents{
    height :30px;
    grid-row: 1/2;
    grid-column: 1/2;
}
.status-percents{


}
.current{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    z-index: 20;
}
.status-text{
    grid-column: 1/2;
    grid-row: 1/2;;
    justify-self: center;
    align-self: center;
}

.total{
    width: 100%;
}

.status-bar{
    height :30px;
}
@keyframes loading {
    0%   { top: 0px}
    25% { top: 50px}
    50% { top: 0px}
    75% { top: -50px}
    100% { top: 0px}
}
.status-bar-img{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../assets/images/fileloader/fileloader.png");

    animation-duration: 2s;
    animation-iteration-count: infinite;
    position: relative;
}

</style>


