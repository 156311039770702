<template>
    <pop-up-window :show="this.$store.state.userManagement.displayInviteUser"
                   :showClose=false
                   :width60=true
                   @close="closeInviteUser"
                   class="doc-statuses-popup"
                   additional-info=""
                   positionAbsolute=true
                   :top10 = true
                   fixed>
        <template #title>
            <h1 class="text-2xl">
                {{ $t('management.inviteUser.title') }}
            </h1>

        </template>
        <template #content>
            <div>
                <h2 class="base-text header-text">
                    {{ $t('management.inviteUser.text') }}
                </h2>
                <h2 class="text-center"></h2>
                <div class="form-container">
                    <div>
                        <input class="form-control text-popup"
                               v-model="inviteFormData.email"
                               @focus="hideError('email')"
                               :placeholder="$t('placeholders.email')">
                        <p class="error" v-if="errors.email">{{ $t('errors.correctEmail') }}</p>
                    </div>
                    <div>
                        <v-select
                              :options="langOptions"
                              v-model="inviteFormData.language"
                              :clearable = false
                              @click="hideError('language')"
                        >
                        </v-select>
                        <p v-if="errors.language" class="error">{{ $t('errors.choseLang') }}</p>
                    </div>
                    <div>
                        <v-select
                              v-if="displayRolesSection()"
                              @click="handleGroup('accessType')"
                              :options="this.accessTypes"
                              v-model="chosenAccessType"
                              :clearable = false
                        >
                        </v-select>
                        <p class="error" v-if="errors.accessType">{{ $t('errors.choseRole') }}</p>
                    </div>

                    <div>
                        <v-select
                              v-if="displayRolesSection()"
                              v-model="inviteFormData.group"
                              @click="handleGroup('group')"
                              :options="this.accessRights"
                              :clearable = false

                        >
                        </v-select>
                        <p class="error" v-if="errors.group">{{ $t('errors.choseGroup') }}</p>
                    </div>

                </div>
                <div v-if="checkGeneralSettingToDisplayLE">
                    <div v-if="displayLegalEntities()"
                         class="legal-entities-outer divider">
                        <h1 class="base-text header-text">{{ $t('general.legalEntities') }}</h1>
                        <div class="legal-entity-container">
                            <div v-for="legEntity in legalEntities"
                                 :key="legEntity.entityNumber"
                            >
                                <div class="input-container">
                                    <input type="checkbox"
                                           class="customCheckbox"
                                           @change="setSelectedLegalEntities"
                                           :id=legEntity.entityNumber
                                    >
                                    <label class="base-text"
                                           :for="legEntity.entityNumber">{{ legEntity.name }}</label>
                                </div>

                            </div>

                        </div>
                        <p class="error" v-if="displayLeError">{{ $t('errors.choseLegalEntity') }}</p>
                        <div class="find-receiver-container"
                             v-if="displayReceivers">
                            <h2 class="base-text header-text">
                                {{findReceiver}}
                            </h2>
                            <v-select
                                  @search="fetchOptions"
                                  v-model="selectedReceiver"
                                  :options=this.$store.state.receivers.options
                                  @click="hideError('receivers')"
                            >
                                <template #no-options="{search}">
                                    {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                                </template>
                            </v-select>
                        </div>
                        <div class="receiversList"
                             v-if="selectedReceiversArray.length">
                            <div
                                  class="receiversListItem box"
                                  v-for="receiver in selectedReceiversArray"
                                  :key=receiver.receiverId>

                                <h2 class="base-text">
                                    <span>{{ receiver.value.receiverName }}</span>{{ receiver.value.companyName }} -
                                    {{ receiver.value.receiverId }}
                                </h2>
                                <h3 class="base-text color-grey">{{ $t('general.legalEntity') }}:
                                    <strong>{{legalEntityName(receiver.value.legalEntityId)}}</strong></h3>
                                <i class="icon-bin"
                                   @click="removeReceiver(receiver.value)"></i>
                            </div>
                        </div>

                        <p class="error" v-if="displayReceiverError">{{ chooseReceiver }}</p>
                    </div>
                </div>
            </div>
            <div class="grid grid-rows-1 justify-items-center my-6">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeInviteUser">
                        {{ $t('buttons.cancel') }}
                    </button>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="inviteUser">
                        {{ $t('buttons.invite') }}
                    </button>
                </div>

            </div>
            <confirmation-pop-up
                  @approveAction="sendInvitationWithoutLe"
                  type = "addUser"
            >
            </confirmation-pop-up>
        </template>
    </pop-up-window>

</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue";
import ConfirmationPopUp from "../../UI/ConfirmationPopUp.vue"
import userGroups from "../../../userGoups";
import i18n from '../../../i18n'

export default {
    name: "InviteUser",
    components: {PopUpWindow, ConfirmationPopUp},
    beforeMounted() {
        this.setPortalData()
    },
    mounted() {
        this.setPortalData();
        this.createAccessTypeList();
    },
    data() {
        return {
            i18n: i18n,
            selectedLegalEntities: [],
            selectedReceiver: null,
            selectedReceiversArray: [],
            chosenAccessType: {label: i18n.t('select.choseAccessType'), value : ''},
            userGroups: userGroups,
            inviteFormData: {
                email: '',
                language: {label : i18n.t('select.choseLang') , value : ''},
                group: {label : i18n.t('select.choseAccessRights'), value: ""},
                supplierLegalEntities: []
            },
            userGroup: this.$store.state.currentUserData.data.group,
            accessTypes: [],
            accessRights: [{label : i18n.t('select.choseAccessRights'), value: ""}],
            errors: {
                email: false,
                language: false,
                accessType: false,
                group: false,
                supplierLegalEntities: false,
                receivers: false,
            },
            postDrop: false,
            billing: false,
            peppol: false,
        }
    },
    computed: {
        checkGeneralSettingToDisplayLE(){
            if (!this.billing && this.postDrop && !this.peppol){
                return false
            }
          return true
        },

        langOptions(){
            let options = [];
            let langs = this.$store.state.supplierGroup.data.languages;
            langs.sort().forEach(lang=>{
                let tempItem = {
                    label: lang,
                    value : lang
                }
                options.push(tempItem)
            })
            return options
        },
        findReceiver(){
            if (this.$store.state.supplierGroup.data.sgType === "SALARY"){
                return i18n.t('general.findEmployee');
            }
            return i18n.t('general.findReceiver');
        },
        chooseReceiver() {
            if (this.$store.state.docType === "SALARY") {
                return i18n.t('errors.choseEmployee');
            }
            return i18n.t('errors.choseReceiver');
        },
        displayReceivers() {
            if (this.selectedLegalEntities.length) {
                if ((this.inviteFormData.group.value === 'ReceiverUser') || (this.inviteFormData.group.value === 'ReceiverAdmin')) {
                    return true
                }
            }
            return false
        },
        displayLeError() {
            return this.errors.supplierLegalEntities
        },
        displayReceiverError() {
            return this.errors.receivers
        },

        showSelectedReceivers() {
            if (this.selectedReceiversArray.length === 0) {
                return false
            }
            if (this.selectedReceiversArray.length > 0 && this.inviteFormData.group !== 'SupplierGroupSuperAdmin') {
                return true
            }
            return true
        },
        legalEntities() {
            let legalEntitiesArray = [];
            let legalEntitiesObject = this.$store.state.legalEntitiesData.data.legalEntities
            for (let legEntity in legalEntitiesObject) {
                let name = '';
                if(userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
                      || userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
                    name = legalEntitiesObject[legEntity].internalName ? legalEntitiesObject[legEntity].internalName : legalEntitiesObject[legEntity].name
                } else {
                    name = legalEntitiesObject[legEntity].name
                }

                let tempItem = {
                    entityNumber: legalEntitiesObject [legEntity].entityNumber,
                    name: name
                };
                legalEntitiesArray.push(tempItem);
            }

            legalEntitiesArray.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
            return legalEntitiesArray
        }
    },

    watch: {
        selectedReceiver(newVal) {
            if (newVal !== null) {
                this.selectedReceiversArray.push(newVal);
            }
        },


        chosenAccessType(newVal) {
            if (newVal.value === "SUPPLIER") {
                this.inviteFormData.group = { label: i18n.t('select.choseAccessRights'), value: "" };
                this.selectedLegalEntities = [];
                this.selectedReceiver = null;
                this.selectedReceiversArray = [];
                } else if (newVal.value === "RECEIVER") {
                this.inviteFormData.group = { label: i18n.t('select.choseAccessRights'), value: "" };
                this.selectedLegalEntities = [];
                this.selectedReceiver = null;
                this.selectedReceiversArray = [];
                this.$nextTick(() => {
                    const checkboxes = document.querySelectorAll('.customCheckbox');
                    checkboxes.forEach((checkbox) => {
                    checkbox.checked = false;
                    });
                });
                }
// userGroup: 'OpAdmin'
            if (newVal.value === "OPTIPOST") {

                if (this.$store.state.currentUserData.data.group === 'OpAdmin') {
                    this.accessRights = [
                        {label : i18n.t('select.choseAccessRights'), value: ""},
                        {label: this.userGroups["OpAdmin"].accessRight, value: this.userGroups["OpAdmin"].name},
                        {label: this.userGroups["OpHelpdesk"].accessRight, value: this.userGroups["OpHelpdesk"].name}
                    ]
                } else {
                    this.accessRights = [
                        {label: "User Can Not Be Created".accessRight, value: ""}
                    ]
                }
            }

            if (newVal.value  === "SUPPLIER") {
                if (this.userGroup === 'OpAdmin') {
                    if ((this.$store.state.supplierGroup.data.supplierGroupId === 'g4sbelgium') || (this.$store.state.supplierGroup.data.supplierGroupId === 'g4sluxemburg')) {

                        this.accessRights = [
                            {label : i18n.t('select.choseAccessRights'), value: ""},
                        ];
                        if(
                              (this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                        ){
                            this.accessRights.push({
                                label: this.userGroups["SupplierLimitedSubUser"].accessRight,
                                value: this.userGroups["SupplierLimitedSubUser"].name
                            })
                        }

                        if ((this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                        ) {
                            this.accessRights.push(
                                  {
                                      label: this.userGroups["SupplierSubAdmin"].accessRight,
                                      value: this.userGroups["SupplierSubAdmin"].name
                                  }
                            );

                        }
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierSubUser"].accessRight,
                                  value: this.userGroups["SupplierSubUser"].name
                              }
                        );
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
                                  value: this.userGroups["SupplierGroupSuperAdmin"].name
                              }
                        )

                    } else {     //to exclude SupplierLimitedSubUser for SG: g4sbelgium orr g4sluxemburg

                        this.accessRights = [
                            {label : i18n.t('select.choseAccessRights'), value: ""},
                        ];

                        if ((this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                        ) {
                            this.accessRights.push(
                                  {
                                      label: this.userGroups["SupplierSubAdmin"].accessRight,
                                      value: this.userGroups["SupplierSubAdmin"].name
                                  }
                            );
                        }
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierSubUser"].accessRight,
                                  value: this.userGroups["SupplierSubUser"].name
                              }
                        )
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
                                  value: this.userGroups["SupplierGroupSuperAdmin"].name
                              }
                        )
                    }
                }
                if (this.userGroup === 'OpHelpdesk' || this.userGroup === 'SupplierGroupSuperAdmin') {
                    if ((this.$store.state.supplierGroup.data.supplierGroupId === 'g4sbelgium') || (this.$store.state.supplierGroup.data.supplierGroupId === 'g4sluxemburg')) {

                        this.accessRights = [
                            {label : i18n.t('select.choseAccessRights'), value: ""},
                        ];
                        if(
                              (this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)

                        ){
                            this.accessRights.push({
                                label: this.userGroups["SupplierLimitedSubUser"].accessRight,
                                value: this.userGroups["SupplierLimitedSubUser"].name
                            })
                        }

                        if ((this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                        ) {
                            this.accessRights.push(
                                  {
                                      label: this.userGroups["SupplierSubAdmin"].accessRight,
                                      value: this.userGroups["SupplierSubAdmin"].name
                                  }
                            )
                        }
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierSubUser"].accessRight,
                                  value: this.userGroups["SupplierSubUser"].name
                              },
                        )
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
                                   value: this.userGroups["SupplierGroupSuperAdmin"].name
                              },
                        )
                    } else {       //to exclude SupplierLimitedSubUser for SG: g4sbelgium orr g4sluxemburg


                        this.accessRights = [
                            {label : i18n.t('select.choseAccessRights'), value: ""},
                        ];
                        if ((this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                        ) {
                            this.accessRights.push(
                                  {
                                      label: this.userGroups["SupplierSubAdmin"].accessRight,
                                      value: this.userGroups["SupplierSubAdmin"].name
                                  }
                            )
                        }
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierSubUser"].accessRight,
                                  value: this.userGroups["SupplierSubUser"].name
                              }
                        )
                        this.accessRights.push(
                              {
                                  label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
                                 value: this.userGroups["SupplierGroupSuperAdmin"].name
                              }
                        )
                    }

                }
                if (this.userGroup === 'SupplierSubAdmin'
                      || this.userGroup === 'SupplierSubUser'
                      || this.userGroup === 'SupplierLimitedSubUser'
                      || this.userGroup === 'ReceiverAdmin'
                      || this.userGroup === 'ReceiverUser') {
                    this.accessRights = [
                        {label: "User Can Not Be Created".accessRight, value: ""}
                    ]
                }
            }
            if (newVal.value  === "RECEIVER") {
                if (this.$store.state.currentUserData.data.group === 'OpAdmin'
                      || this.$store.state.currentUserData.data.group === 'OpHelpdesk'
                      || this.$store.state.currentUserData.data.group === 'SupplierGroupSuperAdmin'
                      || this.$store.state.currentUserData.data.group === 'SupplierSubAdmin'
                      || this.$store.state.currentUserData.data.group === 'SupplierSubUser'
                      || this.$store.state.currentUserData.data.group === 'SupplierLimitedSubUser'
                ) {
                    if (this.$store.state.docType === "GENERAL" || this.$store.state.docType === "SALARY") {
                        this.accessRights = [
                            {label : i18n.t('select.choseAccessRights'), value: ""}
                        ];
                        if (
                              (this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                        ){

                            this.accessRights.push(
                                  {
                                      label: this.userGroups["ReceiverUser"].accessRight,
                                      value: this.userGroups["ReceiverUser"].name
                                  }
                            )
                        }
                    } else {
                        this.accessRights = [
                            {label : i18n.t('select.choseAccessRights'), value: ""}
                        ];
                        if (
                              (this.billing && this.postDrop && this.peppol)
                              || (this.billing && this.postDrop && !this.peppol)
                              || (this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                        ){
                            this.accessRights.push({
                                label: this.userGroups["ReceiverAdmin"].accessRight,
                                value: this.userGroups["ReceiverAdmin"].name
                            })
                            this.accessRights.push(
                                  {
                                      label: this.userGroups["ReceiverUser"].accessRight,
                                      value: this.userGroups["ReceiverUser"].name
                                  }
                            )
                        }
                    }
                }
                if (this.$store.state.currentUserData.data.group === 'ReceiverAdmin') {
                    this.accessRights = [
                        {label : i18n.t('select.choseAccessRights'), value: ""},
                    ];
                    if (
                          (this.billing && this.postDrop && this.peppol)
                          || (this.billing && this.postDrop && !this.peppol)
                          || (this.billing && !this.postDrop && this.peppol)
                          || (this.billing && !this.postDrop && !this.peppol)
                    ){
                        this.accessRights.push({
                            label: this.userGroups["ReceiverAdmin"].accessRight,
                            value: this.userGroups["ReceiverAdmin"].name
                        })
                        this.accessRights.push(
                              {
                                  label: this.userGroups["ReceiverUser"].accessRight,
                                  value: this.userGroups["ReceiverUser"].name
                              }
                        )
                    }
                }
                if (this.$store.state.currentUserData.data.group === 'ReceiverUser') {
                    this.accessRights = [
                        {label: "User can not be created".accessRight, value: ""}
                    ]
                }
            }
        }
    },
    methods: {
        setPortalData() {
            if (
                  this.$store.state.supplierGroup.data.channelsConfig.docImage &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
            ) {
                this.postDrop = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
            }
            if (
                  this.$store.state.supplierGroup.data.channelsConfig.docImage &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
            ) {
                this.billing = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
            }
            if (
                  this.$store.state.supplierGroup.data.channelsConfig.docImage &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig &&
                  this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
            ) {
                this.peppol = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
            }
        },
        sendInvitationWithoutLe(){
            this.selectedReceiver = null;
            this.sendInvitation()
        },
        legalEntityName(leId){
            let leName = ''
            if(userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
                  || userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
                leName = this.$store.state.supplierGroup.data.supplierLegalEntities[leId].internalName ?
                      this.$store.state.supplierGroup.data.supplierLegalEntities[leId].internalName:
                      this.$store.state.supplierGroup.data.supplierLegalEntities[leId].name
            } else {
                leName = this.$store.state.supplierGroup.data.supplierLegalEntities[leId].name
            }
            return leName
        },

        handleGroup(errorType) {
            this.hideError(errorType);
            if (this.chosenAccessType === 'SUPPLIER') {
                if (this.inviteFormData.group === 'SupplierGroupSuperAdmin') {
                    this.selectedLegalEntities = [];
                    this.selectedReceiver = null;
                    this.selectedReceiversArray = [];
                } else {
                    this.selectedReceiver = null;
                    this.selectedReceiversArray = [];
                }
            }
        },
        removeReceiver(item) {
            this.selectedReceiversArray.forEach((receiver) => {
                let removeIndex;
                if ((receiver.value.receiverId === item.receiverId) && (receiver.value.legalEntityId === item.legalEntityId)) {
                    removeIndex = this.selectedReceiversArray.indexOf(receiver);
                    this.selectedReceiversArray.splice(removeIndex, 1);
                }
            })
        },
        fetchOptions(search) {
            this.$store.dispatch('users/fetchReceivers', {
                formData: {
                    supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
                    legalEntityIds: this.selectedLegalEntities,
                    quick: search
                }
            })
        },
        displayRolesSection() {
            if ((this.userGroup === "SupplierSubUser") || (this.userGroup === "SupplierLimitedSubUser") || (this.userGroup === "ReceiverUser")) {
                return false
            }

            return true
        },
        displayLegalEntities() {
            if (this.userGroup === 'OpAdmin'
                  || this.userGroup === 'OpHelpdesk'
                  || this.userGroup === 'SupplierGroupSuperAdmin'
                  || this.userGroup === 'ReceiverAdmin'
                  || this.userGroup === 'SupplierSubAdmin') {
                if (this.chosenAccessType.value === "RECEIVER" && (this.inviteFormData.group.value === 'ReceiverUser' || this.inviteFormData.group.value === 'ReceiverAdmin')) {
                    return true
                }
                if (this.chosenAccessType.value === "SUPPLIER" && this.inviteFormData.group.value === '') {
                    return false
                }
                if (this.chosenAccessType.value === "SUPPLIER" && this.inviteFormData.group.value !== 'SupplierGroupSuperAdmin') {
                    return true
                }
            }
            return false
        },
        clearInviteUserForm() {
            this.selectedReceiver = null;
            this.chosenAccessType = {label: i18n.t('select.choseAccessType'), value : ''};
            this.selectedLegalEntities = [];
            this.selectedReceiversArray = [];
            this.errors = {
                email: false,
                language: false,
                group: false,
                supplierLegalEntities: false
            }
            this.inviteFormData = {
                email: '',
                language: {label :  i18n.t('select.choseLang'), value : ''},
                group: {label : i18n.t('select.choseAccessRights'), value: ""},
                supplierLegalEntities: []
            }
        },

        closeInviteUser() {
            this.clearInviteUserForm();
            this.accessRights = [];
            this.$store.commit('displayInviteUser', false);
        },
        createAccessTypeList() {
            if (this.userGroup === userGroups.OpAdmin.name) {
                this.accessTypes = [
                    {label: "NYMUS", value: "OPTIPOST"}
                ]
                if(
                      (this.billing && this.postDrop && this.peppol)
                      || (this.billing && this.postDrop && !this.peppol)
                      || (this.billing && !this.postDrop && this.peppol)
                      || (this.billing && !this.postDrop && !this.peppol)
                ){
                    this.accessTypes.push(
                          {label: "RECEIVER", value: "RECEIVER"},
                    )
                }
                this.accessTypes.push(
                      {label: "SUPPLIER", value: "SUPPLIER"},
                )
            }

            if ((this.userGroup === userGroups.OpHelpdesk.name) || (this.userGroup === userGroups.SupplierGroupSuperAdmin.name)) {
                this.accessTypes = [];
                if(
                      (this.billing && this.postDrop && this.peppol)
                      || (this.billing && this.postDrop && !this.peppol)
                      || (this.billing && !this.postDrop && this.peppol)
                      || (this.billing && !this.postDrop && !this.peppol)
                ){
                    this.accessTypes.push(
                          {label: "RECEIVER", value: "RECEIVER"},
                    )
                }

                this.accessTypes.push(
                      {label: "SUPPLIER", value: "SUPPLIER"},
                )
            }

            if ((this.userGroup === userGroups.SupplierSubAdmin.name) || (this.userGroup === userGroups.ReceiverAdmin.name)) {

                this.accessTypes = []
                if(
                      (this.billing && this.postDrop && this.peppol)
                      || (this.billing && this.postDrop && !this.peppol)
                      || (this.billing && !this.postDrop && this.peppol)
                      || (this.billing && !this.postDrop && !this.peppol)
                ){
                    this.accessTypes.push(
                          {label: "RECEIVER", value: "RECEIVER"},
                    )
                }
            }
        },
        setSelectedLegalEntities(event) {
            this.errors.supplierLegalEntities = false;
            const index1 = this.selectedLegalEntities.indexOf('');
            if (index1 > -1) {
                this.selectedLegalEntities.splice(index1, 1);
            }
            if (event.target.checked) {
                this.selectedLegalEntities.push(event.target.id)
            } else {
                const index = this.selectedLegalEntities.indexOf(event.target.id);
                if (index > -1) {
                    this.selectedLegalEntities.splice(index, 1);
                }
            }
            if (this.$store.state.legalEntitiesData.data.legalEntities) {
                this.$store.dispatch('users/fetchReceivers', {
                     formData: {
                        supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
                        legalEntityIds: this.selectedLegalEntities,
                        quick: ''
                    }
                })
            }
        },

        validateEmail(email) {
            const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return re.test(email);
        },
        hideError(errorOn) {
            this.errors[errorOn] = false;
        },
        inviteUser() {
            this.inviteFormData.email = this.inviteFormData.email.trim();
            if (!(this.validateEmail(this.inviteFormData.email))) {
                this.errors.email = true;
                return
            }
            if (this.inviteFormData.language.value === '') {
                this.errors.language = true;
                return
            }
            if (this.chosenAccessType.value === '') {
                this.errors.accessType = true;
                return
            }
            if (this.inviteFormData.group.value === '') {
                this.errors.group = true;
                return
            }
            if ((this.chosenAccessType.value === "RECEIVER" && this.inviteFormData.group.value === 'ReceiverUser')
                  || (this.chosenAccessType.value === "RECEIVER" && this.inviteFormData.group.value === 'ReceiverAdmin')) {
                if (this.displayLegalEntities) {
                    if (this.selectedLegalEntities.length === 0) {
                        this.errors.supplierLegalEntities = true;
                        return
                    }
                    if (this.selectedReceiversArray.length === 0) {
                        this.selectedReceiver = null;
                        this.errors.receivers = true;
                        return
                    }
                    let selectedLegalEntities = []
                    this.selectedReceiversArray.forEach((receiver) => {
                        selectedLegalEntities.push(receiver.value.legalEntityId);
                    })
                    let uniqueLegalEntities = [...new Set(selectedLegalEntities)]

                    uniqueLegalEntities.forEach((value) => {
                        let tempItem = {
                            legalEntityId: value,
                            receiverIds: []
                        }
                        this.inviteFormData.supplierLegalEntities.push(tempItem)
                    })

                    this.inviteFormData.supplierLegalEntities.forEach((item) => {
                        this.selectedReceiversArray.forEach(receiver => {
                            if (receiver.value.legalEntityId === item.legalEntityId) {

                                item.receiverIds.push(receiver.value.receiverId);
                            }
                        })
                    })
                }
            }
            if ((this.chosenAccessType.value === "SUPPLIER" && this.inviteFormData.group.value == 'SupplierGroupSuperAdmin')) {
                this.inviteFormData.supplierLegalEntities = [];
            }


            if ((this.chosenAccessType.value === "SUPPLIER" && this.inviteFormData.group.value !== 'SupplierGroupSuperAdmin')) {
                this.selectedLegalEntities.forEach((value) => {
                    let tempItem = {
                        legalEntityId: value,
                        receiverIds: []
                    }
                    this.inviteFormData.supplierLegalEntities.push(tempItem);
                })
            }
            if ((this.chosenAccessType.value === "SUPPLIER"
                  && (this.inviteFormData.group.value !== 'SupplierGroupSuperAdmin' ))) {
                if (this.selectedLegalEntities.length === 0) {
                    if (this.inviteFormData.group.value === 'SupplierSubAdmin' || this.inviteFormData.group.value === 'SupplierLimitedSubUser' ){
                        this.errors.supplierLegalEntities = true;
                        return
                    }
                    if (this.inviteFormData.group.value === 'SupplierSubUser'){

                        if ((this.billing && !this.postDrop && this.peppol)
                              || (this.billing && !this.postDrop && !this.peppol)
                              || (!this.billing && !this.postDrop && this.peppol)
                        ){
                            this.errors.supplierLegalEntities = true;
                            return
                        }
                        this.$store.commit('setDisplayConfirmation', true);
                    }
                } else {
                    this.sendInvitation();
                }
            } else {
                this.sendInvitation();
            }
        },

        sendInvitation(){
            this.inviteFormData.language = this.inviteFormData.language.value;
            this.inviteFormData.group = this.inviteFormData.group.value;
            this.$store.dispatch('users/inviteUser', {
                formData: this.inviteFormData
            });
            this.clearInviteUserForm();
            this.$store.commit('displayInviteUser', false);
            this.$emit('userInvited');
        }
    }


}
</script>

<style scoped>
.legal-entities-outer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 2rem auto  auto auto auto auto;
    grid-auto-flow: column;
    margin-top: var(--generalPaddings)
}


.receiversListItem i {
    cursor: pointer;
}

.legal-entities-checkboxes {
    padding-top: var(--generalPaddings);
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(10, auto);
    grid-auto-flow: row;
    padding-bottom: var(--generalPaddings);
    border-bottom: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}

.input-container {
    display: grid;
    grid-template-columns: 20px auto;
    grid-template-rows: 100%;
    grid-auto-flow: column;
    align-items: center;
}

.input-container label {
    padding-top: 5px;
}

.legal-entities-checkbox label {
    padding-left: 10px;
}

.legal-entities-checkbox input {
    height: 20px;
}


.legal-entity-container div {
    font-size: 20px;
    margin-bottom: 5px;

}

.legal-entity-container label {
    padding-left: 5px;
}

.divider {
    padding-bottom: calc(0.5 * var(--generalPaddings));
    border-bottom: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}

.find-receiver-container {
    padding-top: calc(0.5 * var(--generalPaddings));
    border-top: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}


select {
    color: var(--theme-primary-100);
}
.form-container{
    grid-column-gap: 0px;
}
.form-container>div:nth-child(odd){
    margin-right: 10px;
}
.form-container>div:nth-child(even){
    margin-right: 10px;
}
</style>
