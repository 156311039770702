const isCookieEnabled = navigator.cookieEnabled;

if (isCookieEnabled) {

    if (window.location.host.includes("localhost")) {
        //WARNING!! You can only log in through localhost:8080 (defined in keycloak -> client -> supliergroupname -> Valid redirect URIs
        // If you want to test through another port, you will have to change keycloak for a moment
        // If you want to map local with PRODUCTION --> you have to add this path every time (security)
        fetch(process.env.BASE_URL + 'env/envAgilitas.json') // billing
            .then(res => res.json())
            .then(config => {
                window.environment = config;
                fetch(config.bffUrl + '/api/supplier-group/current')
                    .then(res => res.json())
                    .then(sgData => {
                        if (sgData.data.channelsConfig.docImage.configPortal.additionalPortalConfig
                            && sgData.data.channelsConfig.docImage.configPortal.additionalPortalConfig.nymusLoginPage
                        ) {
                            window.newLoginPage = sgData.data.channelsConfig.docImage.configPortal.additionalPortalConfig.nymusLoginPage
                        } else {
                            window.newLoginPage = false
                        }
                        require("./app");
                    })
            });
    } else {
        fetch('/environment')
              .then(res => {
                  return res.json()
              })
              .then(config => {
                  config.data.bffUrl = '';
                  window.environment = config.data;

                  fetch('/api/supplier-group/current')
                        .then(res => res.json())
                        .then(sgData => {
                            if (sgData.data.channelsConfig.docImage.configPortal.additionalPortalConfig
                                  && sgData.data.channelsConfig.docImage.configPortal.additionalPortalConfig.nymusLoginPage
                            ) {
                                window.newLoginPage = sgData.data.channelsConfig.docImage.configPortal.additionalPortalConfig.nymusLoginPage
                            } else {
                                window.newLoginPage = false
                            }
                            require("./app");
                        })

              });
    }

} else {
    alert('Er is een fout opgetreden. Gelieve uw browser te refreshen. \n' +
          'Moest u nog steeds problemen ondervinden, raden wij u aan de instelling te wijzigen naar \'allow third party cookies\'.\n' +
          '\n' +
          'An error has occurred. Please refresh your browser. \n' +
          'Should you still encounter problems, we recommend changing the setting to \'allow third party cookies\' . \n' +
          '\n' +
          'Une erreur s\'est produite. Veuillez rafraîchir votre navigateur. \n' +
          'Si vous rencontrez toujours des problèmes, nous vous recommandons de modifier le paramètre pour "autoriser les cookies tiers".'
    );
}
