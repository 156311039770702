<template>
    <transition name="dialog" v-if="this.$store.state.onboarding.isReady && this.$store.state.locale">

        <page-container class="page-content onboarding">
            <error-notification></error-notification>
            <general-notification></general-notification>
            <top-banner>
                <div class="w-9/12">
                    <h1 class="text-3xl text-center">{{ $t('onboarding.header.title') }}</h1>
                </div>
            </top-banner>
            <div class="box page-content intro-y box p-5 mt-5">
                <div class="content-container">
                    <div class="content-container-inner"
                         v-if="displayUserData"
                    >
                        <h1 class="mb-20 title">{{ $t('onboarding.description') }}</h1>
                        <div v-if="!validityEnd">
                            <div>
                                <div v-if="!$store.state.onboarding.data.mandateOnboardingInfo.choice">
                                    <h2 class="message-style">
                                        <i class="icon-notification error margin-right-10"></i>
                                        {{ $t('onboarding.texts.userMadeNoChoice') }}
                                    </h2>
                                </div>
                                <h2 v-if="$store.state.onboarding.data.mandateOnboardingInfo.choice
                                         && $store.state.onboarding.data.mandateOnboardingInfo.flowCompleted
                                         && $store.state.onboarding.data.mandateOnboardingInfo.choice  === 'MONTHLY'"
                                    class="message-style">
                                    <i class="icon-notification info margin-right-10"></i>
                                    {{ $t('onboarding.texts.linkExpiredHasChoiceMonthly') }}
                                </h2>
                                <h2 v-if="$store.state.onboarding.data.mandateOnboardingInfo.choice
                                          && !$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted
                                          && $store.state.onboarding.data.mandateOnboardingInfo.choice  === 'MONTHLY'"
                                    class="message-style">
                                    <i class="icon-notification info margin-right-10"></i>
                                    {{ $t('onboarding.texts.userMadeNoChoice') }}
                                </h2>
                                <h2 v-if="$store.state.onboarding.data.mandateOnboardingInfo.choice
                                          && $store.state.onboarding.data.mandateOnboardingInfo.flowCompleted
                                          && $store.state.onboarding.data.mandateOnboardingInfo.choice  === 'YEARLY'"
                                    class="message-style">
                                    <i class="icon-notification info margin-right-10"></i>
                                    {{ $t('onboarding.texts.linkExpiredHasChoiceYearly') }}
                                </h2>
                                <h2 v-if="$store.state.onboarding.data.mandateOnboardingInfo.choice
                                          && !$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted
                                          && $store.state.onboarding.data.mandateOnboardingInfo.choice  === 'YEARLY'"
                                    class="message-style">
                                    <i class="icon-notification info margin-right-10"></i>
                                    {{ $t('onboarding.texts.userMadeNoChoice') }}
                                </h2>
                            </div>
                        </div>

                        <div v-if="validityEnd && !flowCompleted">
                            <!-- user made previously choice  Start-->
                            <div v-if="userMadeChoice">
                                <!--user can NOT modify his choice Start -->
                                <h1 v-if="!userCanModifyChoice && !flowCompletedNotCompltedMonthlyChoice"
                                    class="message-style">
                                    <h2><i class="icon-notification  info margin-right-10"></i>{{
                                            textCanNotModifyChoice
                                        }}</h2>
                                </h1>
                                <!--user can NOT modify his choice End -->

                                <!--user can modify his choice Start -->
                                <div v-if="userCanModifyChoice">
                                    <div>
                                        <h2 class="message-style">
                                            <i class="icon-notification  info margin-right-10"></i>{{
                                                textUserCanModifyChoice
                                            }}
                                        </h2>
                                        <div class="label">{{ $t('onboarding.labels.makeChoice') }}</div>
                                        <v-select
                                              :options="choiceTypeOptions"
                                              v-model="choiceType"
                                              class="dropdown-style"
                                        >
                                            <template #no-options="{search}">
                                                {{ $t('placeholders.noResultFound') }} <span class="info">{{
                                                    search
                                                }}</span>
                                            </template>
                                        </v-select>
                                        <!--  choice change from monthly to yearly start -->
                                        <div v-if="choiceChangeDirection">
                                            <h2 class="message-style">
                                                <i class="icon-notification  info margin-right-10"
                                                   v-show="displayIcon4"></i>{{ textFromMonthlyToYearly }}
                                            </h2>
                                            <div
                                                  v-if="!userHasMandate && showMandateAvailabilityHasPreviousChoice">
                                                <div class="btn-content">
                                                    <div>
                                                        <button class="btn custom-button shadow-md mr-2 text-popup"
                                                                @click="saveHasPreviousChoiceFromMonthlyToYearlyHasNoMandate">
                                                            {{ $t('buttons.saveChoice') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="userHasMandate">
                                                <div v-if="showMandateAvailabilityHasPreviousChoice">
                                                    <div class="label">{{ $t('onboarding.labels.withWithoutMandate') }}
                                                    </div>
                                                    <v-select
                                                          :options="mandateAvailabilityOptions"
                                                          v-model="mandateAvailability"
                                                          class="dropdown-style"
                                                    >
                                                        <template #no-options="{search}">
                                                            {{ $t('placeholders.noResultFound') }} <span class="info">{{search }}</span>
                                                        </template>
                                                    </v-select>
                                                    <h2 class="message-style">
                                                        <i class="icon-notification  info margin-right-10"
                                                           v-show="displayIcon5"></i> {{  textYearlyMandateAvailability }}
                                                    </h2>
                                                    <div v-if="displayCancelReason">
                                                        <div class="label">{{
                                                                $t('onboarding.labels.cancelReason')
                                                            }}
                                                        </div>
                                                        <input
                                                              type='text'
                                                              class="form-control text-popup mb-20"
                                                              v-model="cancelReason"
                                                              @click=cleanErrors()
                                                        >
                                                        <p v-if="errors.cancelReason" class="error">
                                                            {{ $t('errors.provideCancelReason') }}
                                                        </p>
                                                    </div>
                                                    <div class="btn-content">

                                                        <div v-if="mandateAvailability.value">
                                                            <button class="btn custom-button shadow-md mr-2 text-popup"
                                                                    @click="saveHasPreviousChoiceFromMonthlyToYearly">
                                                                {{ buttonTextFromMonthlyToYearlyWithMandate }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  choice change from monthly to yearly end -->

                                        <!--  choice change from yearly to monthly start -->

                                        <div v-if="!choiceChangeDirection">
                                            <h2 class="message-style">
                                                <i class="icon-notification  info margin-right-10"
                                                   v-show="displayIcon3"></i>{{ textFromYearlyToMonthly }}
                                            </h2>

                                            <div v-if="showButtonsHasPreviousChoice">
                                                <h2 class="message-style">
                                                    <i class="icon-notification  info margin-right-10"></i>{{
                                                        textMonthlyMandateAvailability
                                                    }}
                                                </h2>
                                                <div class="btn-content">
                                                    <div>
                                                        <button v-if="userHasMandate"
                                                                class="btn custom-button shadow-md mr-2 text-popup"
                                                                @click="saveHasPreviousChoiceFromYearlyToMonthlyHasMandate">
                                                            {{ $t('buttons.saveChoice') }}
                                                        </button>
                                                        <button v-if="!userHasMandate"
                                                                class="btn custom-button shadow-md mr-2 text-popup"
                                                                @click="saveHasPreviousChoiceFromYearlyToMonthlyHasNoMandate">
                                                            {{ $t('buttons.startNewMandate') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  choice change from monthly to yearly end -->
                                    </div>
                                </div>

                                <!--user can modify his choice END -->
                            </div>
                            <!-- user made previously choice  end-->

                            <!-- user made previously NO choice  start-->
                            <div v-if="!userMadeChoice">
                                <!-- user has no mandate start -->
                                <div v-if=!userHasMandate>
                                    <v-select
                                          :options="choiceTypeOptions"
                                          v-model="choiceType"
                                          class="dropdown-style"
                                    >
                                        <template #no-options="{search}">
                                            {{ $t('placeholders.noResultFound') }} <span class="info">{{
                                                search
                                            }}</span>
                                        </template>
                                    </v-select>

                                    <div class="btn-content">
                                        <div v-if="choiceType.value">
                                            <button
                                                  class="btn custom-button shadow-md mr-2 text-popup"
                                                  @click="saveNoPreviousChoiceAndNoMandate">
                                                {{ buttonTitleNoMandate }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- user has no mandate end -->

                                <!-- user has  mandate start -->
                                <div v-if=userHasMandate>
                                    <h2 class="message-style">
                                        <i class="icon-notification  info margin-right-10"></i>
                                        {{ $t('onboarding.texts.userHasMandate') }}
                                    </h2>
                                    <v-select
                                          :options="choiceTypeOptions"
                                          v-model="choiceType"
                                          class="dropdown-style"
                                    >
                                        <template #no-options="{search}">
                                            {{ $t('placeholders.noResultFound') }} <span
                                              class="info">{{ search }}</span>
                                        </template>
                                    </v-select>

                                    <!-- Button with mandate  -->
                                    <div class="btn-content">
                                        <div v-if="displayButton">
                                            <button
                                                  class="btn custom-button shadow-md mr-2 text-popup"
                                                  @click="saveNoPreviousChoiceAndHasMandate">
                                                {{ buttonTitle }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- user has  mandate start -->
                            </div>
                            <!-- user made previously no choice  end-->
                        </div>
                        <div v-if="validityEnd && flowCompletedNotCompltedMonthlyChoice">
                            <h2><i class="icon-notification  info margin-right-10"></i>
                                {{ descriptionText }}
                            </h2>
                            <v-select
                                  :options="choiceTypeOptions"
                                  v-model="choiceType"
                                  class="dropdown-style"
                            >
                                <template #no-options="{search}">
                                    {{ $t('placeholders.noResultFound') }} <span class="info">{{
                                        search
                                    }}</span>
                                </template>
                            </v-select>
                            <div class="btn-content">
                                <button v-if="this.choiceType.value === 'MONTHLY'"
                                        class="btn custom-button shadow-md mr-2 text-popup"
                                        @click="createMandateHasPreviosChoiceMontly">
                                    {{ $t('buttons.startNewMandate') }}
                                </button>
                                <button v-if="this.choiceType.value === 'YEARLY'"
                                        class="btn custom-button shadow-md mr-2 text-popup"
                                        @click="saveChoiceHasPreviosChoiceMontly">
                                    {{ $t('buttons.saveChoice') }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="content-container-inner" v-if="!displayUserData">
                        <div class="invalid-access-code">
                            {{ $t('onboarding.invalidAccessCode') }}
                        </div>
                    </div>
                </div>


            </div>
        </page-container>
    </transition>

</template>

<script>
// component has to be rewritten due to bad structure
import TopBanner from "../components/TopBanner";
import PageContainer from "../components/PageContainer";
import i18n from '../i18n'
import GeneralNotification from "../components/UI/GeneralNotification";
import textEN from "../locales/en.json";
import textNL from "../locales/nl.json";
import textFR from "../locales/fr.json";
import textDE from "../locales/de.json";
import moment from "moment";
import ErrorNotification from "../components/UI/ErrorNotification";

export default {
    components: {ErrorNotification, GeneralNotification, PageContainer, TopBanner},
    created() {
        this.getMandateOnboardingData();
    },
    mounted() {
        this.setChoiceType();
    },
    data() {
        return {
            i18n: i18n,
            today: new Date().getTime(), // miliseconds
            choiceType: {label: i18n.t('onboarding.labels.makeChoice'), value: ''},
            mandateAvailability: {label: '', value: ''},
            notificationText: '',
            textFromMonthlyToYearly: '',
            textFromYearlyToMonthly: '',
            textUserSelection: '',
            textYearlyMandateAvailability: '',
            textMonthlyMandateAvailability: '',
            showMandateAvailabilityHasPreviousChoice: false,
            showButtonsHasPreviousChoice: false,
            buttonTitle: '',
            displayButton: false,
            buttonTitleNoMandate: '',
            displayIcon: false,
            displayIcon2: false,
            displayIcon3: false,
            displayIcon4: false,
            displayIcon5: false,
            description: {
                en: textEN.onboarding.description,
                fr: textFR.onboarding.description,
                nl: textNL.onboarding.description,
                de: textDE.onboarding.description,
            },
            allTexts: {
                en: textEN.onboarding.texts,
                fr: textFR.onboarding.texts,
                nl: textNL.onboarding.texts,
                de: textDE.onboarding.texts,
            },
            choiceTypeList: {
                en: textEN.onboarding.choiceTypeTranslation,
                fr: textFR.onboarding.choiceTypeTranslation,
                nl: textNL.onboarding.choiceTypeTranslation,
                de: textDE.onboarding.choiceTypeTranslation,
            },
            placeholder: {
                en: textEN.onboarding.labels,
                fr: textFR.onboarding.labels,
                nl: textNL.onboarding.labels,
                de: textDE.onboarding.labels,
            },
            canselReasonPlaceholder: '',
            descriptionText: '',
            moment: moment,
            accessCode: '',
            cancelReason: '',
            errors: {
                emptyCancelReason: false,
            },
            textCanNotModifyChoice: '',
            withMandate: true,
            userMadeNoChoiceDateExpired: ''
        }
    },
    watch: {
        withMandate(newVal) {
            this.cleanErrors();
            if (this.choiceType.value === "YEARLY") {
                if (newVal) {
                    this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithMandate');
                    this.buttonTitle = i18n.t('buttons.saveChoice');
                }
                if (!newVal) {
                    this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithoutMandate');
                    this.buttonTitle = i18n.t('buttons.cancelMandate');
                }
                if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice == undefined) {
                    this.displayButton = true;
                }
            }
        },
        mandateAvailability(newVal) {
            this.displayIcon2 = true;
            if (this.mandateAvailability.value !== '') {
                this.displayIcon5 = true;
            }
            this.textYearlyMandateAvailability = ''
            if (newVal.value === "withMandate") {
                this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithMandate');
                this.buttonTitle = i18n.t('buttons.saveChoice');
            }
            if (newVal.value === "withoutMandate") {
                this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithoutMandate');
                this.buttonTitle = i18n.t('buttons.cancelMandate');
            }
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice == undefined) {
                this.displayButton = true;
            }
        },
        choiceType(newVal, oldVal) {
            this.displayIcon = true;
            this.cleanErrors();
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice == undefined) {
                if (newVal.value === 'MONTHLY') {
                    this.buttonTitle = i18n.t('buttons.saveChoice');
                    this.displayButton = true;
                    this.buttonTitleNoMandate = i18n.t('buttons.startNewMandate');
                }
                if (newVal.value === 'YEARLY') {
                    this.displayIcon2 = false;
                    if (this.withMandate) {
                        this.buttonTitle = i18n.t('buttons.saveChoice');
                    } else {
                        this.buttonTitle = i18n.t('buttons.cancelMandate');
                    }
                    this.textYearlyMandateAvailability = ''
                    this.buttonTitleNoMandate = i18n.t('buttons.saveChoice');
                    this.displayButton = true;
                }
            }
            if (!this.userHasMandate) {
                if (newVal.value === 'YEARLY') {
                    this.textUserSelection = ' ' + this.$store.state.onboarding.data.mandateOnboardingInfo.mandateOnboardingData.yearlyAmount + '. ' + i18n.t('onboarding.texts.userHasNoPreviousChoiceSelectTypeYearly');
                }
                if (newVal.value === 'MONTHLY') {
                    this.textUserSelection = ' ' + this.$store.state.onboarding.data.mandateOnboardingInfo.mandateOnboardingData.monthlyAmount + '. ' + i18n.t('onboarding.texts.userHasNoPreviousChoiceSelectNoMandateSelectChoiceTypeMonthly');
                    this.withMandate = false;
                    this.mandateAvailability = {
                        label: '',
                        value: ''
                    };
                }
            }
            if (this.userHasMandate) {
                if (newVal.value === 'YEARLY') {
                    this.displayIcon3 = false;
                    this.displayIcon4 = true;
                    if (this.mandateAvailability.value !== '') {
                        this.displayIcon5 = true;
                    }
                    this.textUserSelection = i18n.t('onboarding.texts.userSelectChoiceTypeMonthly');
                }
                if (newVal.value === 'MONTHLY') {
                    this.displayIcon3 = true;
                    this.displayIcon4 = false;
                    this.displayIcon5 = false;
                    this.textUserSelection = i18n.t('onboarding.texts.userHasMandateSelectChoiceTypeMonthly');
                    this.mandateAvailability = {
                        label: '',
                        value: ''
                    };
                    this.buttonTitle = i18n.t('buttons.saveChoice');
                }
            }
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "MONTHLY") {
                if (oldVal.value === "MONTHLY" && newVal.value === 'YEARLY') {
                    this.textFromMonthlyToYearly = i18n.t('onboarding.texts.choiceChangeFromMonthlyToYearly');
                    this.showMandateAvailabilityHasPreviousChoice = true;
                    if (this.mandateAvailability.value === "withoutMandate") {
                        this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithoutMandate');
                    }
                    if (this.mandateAvailability.value === "withMandate") {
                        this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithMandate');
                    }
                    if (!this.withMandate) {
                        this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithoutMandate');
                    }
                    if (this.withMandate) {
                        this.textYearlyMandateAvailability = i18n.t('onboarding.texts.userSelectChoiceTypeYearlyWithMandate');
                    }
                } else {
                    this.textFromMonthlyToYearly = '';
                    this.showMandateAvailabilityHasPreviousChoice = false;
                }
            }
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "YEARLY") {
                if (oldVal.value === "YEARLY" && newVal.value === 'MONTHLY') {
                    this.textFromYearlyToMonthly = i18n.t('onboarding.texts.choiceChangeFromYearlyToMonthly');
                    this.showButtonsHasPreviousChoice = true;
                    if (this.$store.state.onboarding.data.mandates) {
                        let userHasMandate = false
                        this.$store.state.onboarding.data.mandates.forEach(mandate => {
                            if (mandate.status === "SIGNED") {
                                userHasMandate = true
                            }
                        });
                        if (userHasMandate) {
                            this.textMonthlyMandateAvailability = i18n.t('onboarding.texts.userHasMandateSelectChoiceTypeMonthly');
                        } else {
                            this.textMonthlyMandateAvailability = i18n.t('onboarding.texts.userHasNoPreviousChoiceSelectNoMandateSelectChoiceTypeMonthly');
                        }
                    }
                } else {
                    this.textFromYearlyToMonthly = '';
                    this.showButtonsHasPreviousChoice = false;
                }
            }
        },
        locale(newVal) {
            this.setDescriptionText(newVal);
            this.setPlaceholder(newVal);
            this.setButtonsTitle(newVal);
        },
        ondoardingData() {
            this.setChoiceType();
            if (this.$store.state.locale) {
                this.setDescriptionText(this.$store.state.locale);
            }
        },
    },
    computed: {
        flowCompletedDisplay() {
            return this.$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted
        },
        flowCompleted() {
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted === false && this.$store.state.onboarding.data.mandateOnboardingInfo.choice == undefined) {
                return false
            }
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted === true && this.$store.state.onboarding.data.mandateOnboardingInfo.choice) {
                return false
            }
            if (!this.$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted) {
                return false
            }
            return false
        },
        flowCompletedNotCompltedMonthlyChoice() {
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted === false
                  && this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "MONTHLY") {
                return true
            }
            return false
        },
        showSaveYerlyWithMandateButton() {
            return true
        },
        userMandates() {
            return this.$store.state.onboarding.data.mandates
        },
        displayCancelReason() {
            if (this.withMandate) {
                return true
            }
            return false
        },
        buttonTextFromMonthlyToYearlyWithMandate() {
            if (this.mandateAvailability.value === 'withoutMandate') {
                return i18n.t('buttons.cancelMandate')
            }
            return i18n.t('buttons.save')
        },
        ondoardingData() {
            return this.$store.state.onboarding.data;
        },
        locale() {
            return this.$store.state.locale
        },
        displayUserData() {
            if (this.$store.state.onboarding.data !== undefined) {
                return true;
            }
            if (Object.keys(this.$store.state.onboarding.data).length === 0) {
                return false;
            }
            return false
        },
        textUserCanModifyChoice() {
            if (this.$store.state.onboarding.data.mandateOnboardingInfo !== undefined
                  && this.$store.state.onboarding.data.choiceModify === undefined
                  && this.$store.state.onboarding.data.mandateOnboardingInfo.choice !== undefined
                  && this.$store.state.onboarding.data.mandateOnboardingInfo.choice === 'MONTHLY'
            ) {
                return i18n.t('onboarding.texts.userPreviousChoiceMonthlyCanModify')
            }
            if (this.$store.state.onboarding.data.mandateOnboardingInfo !== undefined
                  && this.$store.state.onboarding.data.choiceModify === undefined
                  && this.$store.state.onboarding.data.mandateOnboardingInfo.choice !== undefined
                  && this.$store.state.onboarding.data.mandateOnboardingInfo.choice === 'YEARLY'
            ) {
                let text = ''
                if (this.$store.state.onboarding.data.mandates) {
                    let userHasMandate = false
                    this.$store.state.onboarding.data.mandates.forEach(mandate => {
                        if (mandate.status === "SIGNED") {
                            userHasMandate = true
                        }
                    });
                    if (userHasMandate) {
                        text = i18n.t('onboarding.texts.userPreviousChoiceYearlyCanModifyHasMandate')
                    } else {
                        text = i18n.t('onboarding.texts.userPreviousChoiceYearlyCanModifyHasNoMandate');
                    }
                } else {
                    text = i18n.t('onboarding.texts.userPreviousChoiceYearlyCanModifyHasNoMandate');
                }
                return text
            }
            return ''
        },
        choiceTypePreviousChoice() {
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "MONTHLY") {
                return {label: i18n.t('onboarding.choiceType.monthly'), value: 'MONTHLY'};
            }
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "YEARLY") {
                return {label: i18n.t('onboarding.choiceType.yearly'), value: 'YEARLY'};
            }
            return {label: i18n.t('onboarding.choiceType.selectType'), value: ''};
        },
        choiceTypeOptions() {
            return [
                {label: i18n.t('onboarding.choiceType.yearly'), value: 'YEARLY'},
                {label: i18n.t('onboarding.choiceType.monthly'), value: 'MONTHLY'},
            ]
        },
        mandateAvailabilityOptions() {
            return [
                {label: i18n.t('onboarding.mandateAvailability.withMandate'), value: 'withMandate'},
                {label: i18n.t('onboarding.mandateAvailability.withoutMandate'), value: 'withoutMandate'},
            ]
        },
        validityEnd() {
            let endDate = new Date(this.$store.state.onboarding.data.mandateOnboardingInfo.mandateOnboardingData.validityEnd);
            endDate.setHours(23, 59, 59, 999);
            let validityEnd = new Date(endDate).getTime();  //miliseconds
            if (validityEnd >= this.today) {
                return true
            }
            return false;
        },
        textDateInvalid() {
            if (this.userMadeChoice) {
                return i18n.t('onboarding.texts.userMadeChoice');
            }
            return i18n.t('onboarding.texts.userMadeNoChoice');
        },
        textValidityEndIsValidNoChoice() {
            if (this.userHasMandate) {
                return i18n.t('onboarding.texts.userHasMandate')
            }
            return i18n.t('onboarding.texts.userHasNoMandate');
        },
        textChoiceType() {
            if (this.choiceType.value === "YEARLY") {
                return i18n.t('onboarding.texts.choiceTypeYearly');
            }
            if (this.choiceType.value === "MONTHLY") {
                return i18n.t('onboarding.texts.choiceTypeMonthly');
            }
            return ''
        },
        userMadeChoice() {
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice) {
                return true
            }
            return false
        },
        userHasMandate() {
            if (this.$store.state.onboarding.data.mandates) {
                let userHasMandate = false;
                this.$store.state.onboarding.data.mandates.forEach(mandate => {
                    if (mandate.status === "SIGNED") {
                        userHasMandate = true
                    }
                });
                return userHasMandate;
            }
            return false;
        },
        showMandateAvailability() {
            if (this.choiceType.value === 'YEARLY') {
                return true
            }
            return false
        },
        userCanModifyChoice() {
            if (!this.$store.state.onboarding.data.choiceModify) {
                return false
            }
            return this.$store.state.onboarding.data.choiceModify;
        },
        choiceChangeDirection() {
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "MONTHLY") {
                return true
            }
            if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "YEARLY") {
                return false
            }
            return ''
        }
    },
    methods: {
        setButtonsTitle(lang) {
            if (lang !== '' && this.$store.state.onboarding.data.mandateOnboardingInfo !== undefined) {
                if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === undefined) {
                    if (this.choiceType.value === "MONTHLY") {
                        this.buttonTitleNoMandate = i18n.t('buttons.startNewMandate');
                        this.buttonTitle = i18n.t('buttons.saveChoice');
                    }
                    if (this.choiceType.value === 'YEARLY') {
                        if (this.withMandate) {
                            this.buttonTitle = i18n.t('buttons.saveChoice');
                        } else {
                            this.buttonTitle = i18n.t('buttons.cancelMandate');
                        }
                        this.textYearlyMandateAvailability = ''
                        this.buttonTitleNoMandate = i18n.t('buttons.saveChoice');
                    }
                }
            }
        },
        createMandateHasPreviosChoiceMontly() {
            this.createMandate();
        },
        saveChoiceHasPreviosChoiceMontly() {
            this.$store.commit("setOnboardingSuccssefulText", 'userHasNoPreviousChoiceSelectTypeYearly');
            this.saveChoice(true);
        },
        setPlaceholder(locale) {
            if (this.choiceType.value === '') {
                this.choiceType.label = this.placeholder[locale].makeChoice
            } else {
                if (this.choiceType.value === "YEARLY") {
                    this.choiceType.label = i18n.t('onboarding.choiceType.yearly')
                } else {
                    this.choiceType.label = i18n.t('onboarding.choiceType.monthly')
                }
            }
            this.canselReasonPlaceholder = this.placeholder[locale].cancelReason
        },
        saveChoice(sendFlowCompleted) {
            let payload = {
                supplierGroupId: this.$store.state.onboarding.data.supplierGroupId,
                legalEntityId: this.$store.state.onboarding.data.legalEntityId,
                receiverId: this.$store.state.onboarding.data.receiverId,
                mandateOnboardingAccessCode: this.accessCode,
                mandateOnboardingChoice: this.choiceType.value,
                sendFlowCompleted: sendFlowCompleted
            }
            this.$store.dispatch('mandateSaveChoice', payload);
        },
        createMandate() {
            let payload = {
                legalEntityId: this.$store.state.onboarding.data.legalEntityId,
                clientId: this.$store.state.onboarding.data.receiverId,
                data: {
                    paymentMethodId: "mandate",
                    l: this.$store.state.locale,
                    mandateOnboardingAccessCode: this.accessCode,
                }
            }
            this.$store.dispatch('mandateCreate', payload);
        },
        cancelMandate() {
            let mandateId = '';
            this.$store.state.onboarding.data.mandates.forEach(mandate => {
                if (mandate.status === "SIGNED") {
                    mandateId = mandate.mandateId;
                }
            })
            let payload = {
                entityId: this.$store.state.onboarding.data.legalEntityId,
                clientId: this.$store.state.onboarding.data.receiverId,
                mandateId: mandateId,
                reason: this.cancelReason,
                mandateOnboardingAccessCode: this.accessCode,
                supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId
            }
            this.$store.dispatch('mandateCancelPublic', payload);
        },
        cleanErrors() {
            this.errors.emptyCancelReason = false;
        },
        setChoiceType() {
            if (this.$store.state.onboarding.data.mandateOnboardingInfo && this.$store.state.onboarding.data.mandateOnboardingInfo.choice) {
                if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice !== undefined
                      && this.$store.state.onboarding.data.mandateOnboardingInfo.choice === 'YEARLY') {
                    if (this.$store.state.onboarding.data.mandates) {
                        let userHasMandate = false;
                        this.$store.state.onboarding.data.mandates.forEach(mandate => {
                            if (mandate.status === "SIGNED") {
                                userHasMandate = true
                            }
                        })
                        if (userHasMandate) {
                            this.mandateAvailability = {
                                label: i18n.t('onboarding.mandateAvailability.withMandate'),
                                value: 'withMandate'
                            };
                        } else {
                            this.mandateAvailability = {
                                label: i18n.t('onboarding.mandateAvailability.withoutMandate'),
                                value: 'withoutMandate'
                            };
                        }
                    }
                }
                if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice) {
                    if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "MONTHLY") {
                        this.choiceType = {label: i18n.t('onboarding.choiceType.monthly'), value: 'MONTHLY'};
                        return
                    }
                    if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "YEARLY") {
                        this.choiceType = {label: i18n.t('onboarding.choiceType.yearly'), value: 'YEARLY'};
                        return
                    }
                    this.choiceType = {label: i18n.t('onboarding.choiceType.selectType'), value: ''};
                }
            }
        },
        displayNotification() {
            this.$store.commit('setNotificationText', this.notificationText);
            this.$store.commit('displayNotification', true);
        },
        async saveNoPreviousChoiceAndNoMandate() {
            if (this.choiceType.value === "YEARLY") {
                this.$store.commit("setOnboardingSuccssefulText", 'userHasNoPreviousChoiceSelectTypeYearly');
                this.saveChoice(true);
            }
            if (this.choiceType.value === "MONTHLY") {
                this.saveChoice(false);
                this.createMandate();
            }
        },
        async saveNoPreviousChoiceAndHasMandate() {
            if (this.choiceType.value === "YEARLY") {
                if (this.withMandate) {
                    this.$store.commit("setOnboardingSuccssefulText", 'userSelectChoiceTypeYearlyWithMandate');
                    this.saveChoice(true);
                    this.$router.push('/onboardingSuccessful');
                }
                if (!this.withMandate) {
                    if (this.cancelReason === '') {
                        this.errors.emptyCancelReason = true;
                        return
                    }
                    this.saveChoice(false);
                    this.cancelMandate();
                    this.$router.push('/cancelMandateSuccessful');
                }
            }
            if (this.choiceType.value === "MONTHLY") {
                this.$store.commit("setOnboardingSuccssefulText", 'userHasMandateSelectChoiceTypeMonthly');
                this.saveChoice(true);
                this.$router.push('/onboardingSuccessful');
            }
        },
        async saveHasPreviousChoiceFromMonthlyToYearlyHasNoMandate() {
            this.saveChoice(true);
        },
        async saveHasPreviousChoiceFromMonthlyToYearly() {
            if (this.mandateAvailability.value === "withMandate") {
                this.saveChoice(true);
            }
            if (this.mandateAvailability.value === "withoutMandate") {
                if (this.cancelReason === '') {
                    this.errors.cancelReason = true;
                    return
                }
                this.saveChoice(false);
                this.cancelMandate();
            }
        },
        async saveHasPreviousChoiceFromYearlyToMonthlyHasMandate() {
            this.saveChoice();
        },
        async saveHasPreviousChoiceFromYearlyToMonthlyHasNoMandate() {
            this.saveChoice(false);
            this.createMandate();
        },
        getMandateOnboardingData() {
            this.setLocale();
            let accessCode = this.getAccessCode();
            this.getOnboardingData(accessCode);
        },

        setDescriptionText(locale) {
            if (Object.keys(this.$store.state.onboarding.data).length) {
                if (this.$store.state.onboarding.data.mandateOnboardingInfo && this.$store.state.onboarding.data.mandateOnboardingInfo.choice) {
                    let text = ''
                    if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice === "YEARLY") {
                        text = ''

                    } else {
                        if (this.$store.state.onboarding.data.mandateOnboardingInfo.flowCompleted === true) {
                            text = '';

                        } else {
                            text = this.allTexts[locale].userMadeChoiceMonthlyFlowNotCompleted
                        }
                    }
                    text = text.replaceAll('INSERT_USER_NAME', this.$store.state.onboarding.data.info.companyName);
                    let validityEnd = moment(this.$store.state.onboarding.data.mandateOnboardingInfo.mandateOnboardingData.validityEnd).format("DD/MM/YYYY");
                    text = text.replaceAll('INSERT_VALIDITY_END', validityEnd);
                    this.descriptionText = text;
                    let userCantModifyChoice = this.allTexts[locale];
                    let choice = '';
                    if (this.$store.state.onboarding.data.mandateOnboardingInfo && this.$store.state.onboarding.data.mandateOnboardingInfo.choice) {
                        choice = this.$store.state.onboarding.data.mandateOnboardingInfo.choice;
                        if (choice === "MONTHLY") {
                            choice = this.choiceTypeList[locale].monthly
                        }
                        if (choice === "YEARLY") {
                            choice = this.choiceTypeList[locale].yearly;
                        }
                    }
                    this.textCanNotModifyChoice = userCantModifyChoice.userMadeChoice.replaceAll('INSERT_CHOICE', choice);
                }
                let endDate = new Date(this.$store.state.onboarding.data.mandateOnboardingInfo.mandateOnboardingData.validityEnd)
                endDate.setHours(23, 59, 59, 999);
                let validityEnd = new Date(endDate).getTime();   //miliseconds
                if (validityEnd <= this.today) {
                    let userMadeNoChoiceDateExpiredText = '';
                    if (this.$store.state.onboarding.data.mandateOnboardingInfo.choice) {
                        let choice = this.$store.state.onboarding.data.mandateOnboardingInfo.choice;
                        if (choice === "MONTHLY") {
                            choice = this.choiceTypeList[locale].monthly
                        }
                        if (choice === "YEARLY") {
                            choice = this.choiceTypeList[locale].yearly;
                        }
                        userMadeNoChoiceDateExpiredText = this.allTexts[locale].userMadeChoice.replaceAll('INSERT_CHOICE', choice)
                    } else {
                        userMadeNoChoiceDateExpiredText = this.allTexts[locale].userMadeNoChoice
                    }
                    this.userMadeNoChoiceDateExpired = userMadeNoChoiceDateExpiredText;
                }
            }
        },
        setLocale() {
            let lang = window.location.href.split('/')[3];
            this.$store.commit('setLocale', lang);
        },
        getAccessCode() {
            let accessCodeTemp = window.location.href.split('/')[5];
            let accessCode = accessCodeTemp.split('#')[0];
            this.accessCode = accessCode;
            return accessCode
        },
        getOnboardingData(accessCode) {
            this.$store.dispatch('fetchGetOnboardingData', {
                mandateOnboardingAccessCode: accessCode,
                supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId
            });
        }
    }
}
</script>

<style scoped>
.content-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-auto-flow: row;
    justify-items: center;
}

.content-container-inner {
    width: 600px;
}

.content-container h1.title {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: justify;
    margin-top: 40px;
    line-height: 2em;
}

.dropdown-style {
    width: 600px;
    margin-bottom: 20px;
}

.message-style {
    margin-bottom: 20px;
}

.content-container h1 {
    font-size: 18px;
    line-height: 2em;
}

h2 {
    font-weight: bolder;
    font-size: 16px;
    margin-bottom: calc(0.5 * var(--generalPaddings));
    line-height: 2em;
    text-align: justify;
}

.label {
    padding-bottom: 10px;
}

.button-margin-top {
    margin-top: 40px;
}

.margin-right-10 {
    margin-right: 10px;
}

@media only screen and (max-width: 750px) {
    .content-container-inner {
        width: 90%;
    }

    .dropdown-style {
        width: 100%;
    }
}

@media (max-width: 520px) {
    .title {
        font-size: 14px;
    }
}

.invalid-access-code {
    margin-top: 40px;
}

.mb-20 {
    margin-bottom: 20px;
}

.font-20 {
    font-size: 20px;
}
</style>
