import axios from "axios"

export default {
	uploadFiles(context, payload) {
		let printMode = context.rootState.postDrop.uploadFilePrintMode
		let bacthId = context.rootState.postDrop.uploadBatchId

		let url = context.rootState.domainName + context.rootState.API.departmentUploadFiles.replace(':departmentCode', payload.departmentCode).replace(':printJob', payload.printJob).replace(':postalType', payload.postalType).replace(':printMode', printMode).replace(":batch", bacthId)
		let filesToSend = payload.fileBatches
		return axios.post(url, filesToSend[context.rootState.uploadedBathes], {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
					'Content-Type': 'multipart/form-data',
				}
			}
		).then(() => {
				payload.fileBatches[context.rootState.uploadedBathes].forEach(file => {
					context.commit("addUploadedFile", file.name, {root: true})
				})
				context.commit("setUploadedBatches", context.rootState.uploadedBathes + 1, {root: true})
			}
		).catch(() => {
			if (context.rootState.uploadedBathes <= filesToSend.length - 1) {
				payload.fileBatches[context.rootState.uploadedBathes].forEach(file => {
					context.commit("setUploadedFileNamesError", file.name, {root: true})
				})
				if (!context.rootState.resendErrorUploadedFiles) {
					context.commit("setErrorUploadedFiles", payload.fileBatches[context.rootState.uploadedBathes], {root: true})

				}

				context.commit("setUploadedBatches", context.rootState.uploadedBathes + 1, {root: true})
				context.commit('setUploadFileErrorOccurred', true, {root: true})
			}
		})
	},
	uploadFile(context, payload) {
		let url = context.rootState.domainName + "/api/admin/supplier-groups/upload/files"
		return axios.post(url, payload.file, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(() => {
				let data = {
					token: context.rootState.token,
					body: {
						bucketName: "op-stag-extraction-intake",
						bucketFolder: "/demofr1/ATALIAN",
						fileName: payload.value,
					},
				}
				context.dispatch("startIngestionProcess", data, {root: true})
			})
			.catch((err) => console.error(err))
	},
	startIngestionProcess(context) {
		let url = 'https://staging-admin.optipost.eu' + '/api/ingestion/process/' + context.rootState.supplierGroup.data.supplierGroupId
		return axios.get(url, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(() => {
				context.rootState.generalNotification.startIngestion = "ok"
				context.commit('displayNotification', true, {root: true})
			})
			.catch(() => {
				context.rootState.errorNotification.display = true
			})

	},
	setPendingTableData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.pendingUploadedFiles
		if (payload) {
			url = url + "?batch=" + payload.batch + '&department=' + payload.department
		} else {
			if (context.rootState.postDrop.uploadedFiles.displayExtendedSearch && (context.rootState.postDrop.uploadedFiles.batch.value || context.rootState.postDrop.uploadedFiles.departmentCode.value)) {
				url = url + "?batch=" + context.rootState.postDrop.uploadedFiles.batch.value + '&department=' + context.rootState.postDrop.uploadedFiles.departmentCode.value
			}
		}

		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
			context.commit('setPendingTableData', res.data.data.documents, {root: true})
		}).catch(err => console.error(err))
	},

	getPendingBatches(context) {
		let url = context.rootState.domainName + context.rootState.API.pendingUploadedFiles
		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => context.commit("setPendingBatchesData", res.data.data.documents, {root: true}))
			.catch(() => context.rootState.errorNotification.display = true)
	},
	startPrint(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.startPrint
		
		return axios.post(url, {batches: payload}, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
				context.commit("setGeneralNotificationText", {field: "startPrint", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})

				if (context.rootState.activeComponent === "uploadedFiles") {
					setTimeout(() => {
						context.dispatch("getPendingBatches", {root: true})
						context.dispatch("setPendingTableData", {root: true})
					}, 2000)
				}
			})
			.catch(() => {
				context.rootState.errorNotification.display = true
			})
	},

	deleteBatch(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.deleteBatch.replace(":batchId", payload)

		return axios.delete(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => {
				context.commit("setGeneralNotificationText", {field: "deleteBatch", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})

				if (context.rootState.activeComponent === "uploadedFiles") {
					setTimeout(() => {
						context.dispatch("getPendingBatches", {root: true})
						context.dispatch("setPendingTableData", {root: true})

					}, 2000)
				}
			})
			.catch(() => {
				context.rootState.errorNotification.display = true
			})
	},

	validateFilePostDrop(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.uploadFilePostDrop.replace(':mailType', payload.mailType)
		return axios.post(url, payload.file, {
				responseType: 'blob',
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})
			.then(res => {
				let url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement('a')
				link.href = url

				let name = payload.fileName.toLowerCase().replace('.pdf', '_validated.pdf')

				link.setAttribute('download', name)
				document.body.appendChild(link)
				link.click()
				context.commit("displayLoader", false)
			})
			.catch(() => {
				context.commit("displayLoader", false, {root: true})
				context.commit('displayErrorNotification', true, {root: true})
			})
	}
}