<template>
    <div class="not-found">
        <top-banner>

            <div class="w-9/12 text-center">
                <div class="error-message">
                    <h1 class="text-3xl">{{ $t('noAccess.message.part1') }}</h1>

                </div>

            </div>

        </top-banner>
        <div class="not-found-container box p-5 mt-5">
            <div class="text-3xl">{{ $t('noAccess.message.part2') }}
                <router-link class="link text-center" :to=routerData.overview.link>
                    {{ $t('notFound.message.part3') }}
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
import routerData from "../routerData";
import TopBanner from "../components/TopBanner";
export default {
    name: "NoAccess",
    components: {TopBanner},
    mounted(){
    },

    data() {
        return {
            routerData: routerData,
        }
    }
}
</script>

<style scoped>
.error-message{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
}
.link{
    text-decoration: underline;

}
.not-found{
    min-height: calc(100vh - var(--headerheight) - var(--footerHeight) - var(--generalPaddings));
    display: grid;
    grid-template-rows: 100px 300px;
    grid-template-columns: 100%;

}
.not-found-container{
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    justify-items: center;
    grid-auto-flow: row;
    margin-top: var(--generalPaddings);
    height: 100%;
}

.not-found-container div{
    align-self: center;
}
</style>
