<template>
	<div
		class="padding-left document-extended"
		:class="{
			'paymentInfo' : paymentInfo,
        	'errorsReason': errorsReason,
        	'notDelivered' : notDelivered,
        	'printCSS' : printCSS,
        	'paymentStatusCSS' : paymentStatusCSS,
        	'ediCSS' : ediCSS
         }"
	>
			<i v-if="name" class="icon-user1">
				<span class="description">{{ name }}</span>
			</i>

			<i v-if="printInfo" class="icon-printer">
				<span class="description">{{ print }}</span>
			</i>
		<slot name="print"></slot>

		<div class="flex items-center">
			<i v-if="email" class="icon-envelop">
				<span class="description">{{ email }}</span>
			</i>
			<div v-if="info && error === false" class="align-icon">
				<span class="material-icons" style="padding-right: 0;color: #215893">error_outline</span>
				<span class="description">{{ info }}</span>
			</div>
			<div v-if="info && error" class="align-icon">
				<span class="material-icons" style="padding-right: 0; color: darkred">error_outline</span>
				<span  class="description">{{ info }}</span>
			</div>
			<slot name="paymentInfo"></slot>
			<i v-if="time" class="icon-calendar">
				<span class="description">{{ time }}</span>
			</i>

		</div>
		<div v-if="errorInfo" class="padding-left">
			<span>{{ errorName }}: {{ errorDescription }}</span>
		</div>
		<slot name="ediError"></slot>
		<slot></slot>
	</div>

</template>

<script>
export default {
	name: "DocumentInfoExtended",
	props: {
		name: {
			type: String
		},
		info: {
			type: String
		},
		print: {
			type: String
		},
		email: {
			type: String,
		},
		time: {
			type: String,
		},
		errorName: {
			type: String,
		},
		errorDescription: {
			type: String,
		},
		printInfo: {
			type: String,
		},

		paymentStatusCSS: {
			type: Boolean,
			default: false,
		},

		ediCSS: {
			type: Boolean,
			default: false,
		},
		printCSS: {
			type: Boolean,
			default: false,
		},
		paymentInfo: {
			type: Boolean,
			default: false,
		},
		errorInfo: {
			type: Boolean,
			default: false,
		},
		notDelivered: {
			type: Boolean,
			default: false,
		},

		errorsReason: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style scoped>
i {
	color: var(--infoColor);
	padding-bottom: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
}

span, i {
	padding-right: calc(0.5 * var(--generalPaddings));
}

.padding-left {
	margin-left: var(--generalPaddings);
}

.description {
	color: var(--theme-primary-500);
	padding-left: 5px;
}

.document-extended {
	display: grid;
	grid-template-rows: auto;
	grid-auto-flow: column;
	font-size: 14px;
	justify-content: start;
}

.errorsReason {
	display: block;
}

i span, .description {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	font-size: 14px;
}

.paymentInfo {
	grid-template-columns: 20px auto  120px;
	font-size: 14px;
}

.notDelivered {
	display: block;
}

.printCSS {
	display: block;
	margin-left: 0;
}

.paymentStatusCSS, .ediCSS {
	display: block;
}

.align-icon {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.material-icons {
	font-size: 20px;
}

@media (min-width: 1001px) and  (max-width: 1300px) {
	.document-extended {
		grid-template-rows: auto auto;
		grid-template-columns: 100%;
	}

	.paymentInfo {
		grid-template-columns: 20px calc(100% - 140px)  120px;
		grid-template-rows: auto auto;
		grid-auto-flow: row;
	}

	.errorsReason {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: auto auto auto;
		grid-auto-flow: row;
	}

	.errorsReason .icon-envelop {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	.errorsReason .icon-calendar {
		grid-column: 1/2;
		grid-row: 2/3;
	}

	.errorsReason div {
		grid-column: 1/2;
	}
}

@media (min-width: 741px) and (max-width: 1000px) {
	.errorsReason {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: auto auto auto;
	}

	.errorsReason div {
		grid-column: 1/2;
	}
}

@media (max-width: 740px) {
	.document-extended {
		grid-template-rows: auto auto;
		grid-template-columns: 100%;
	}

	.paymentInfo {
		grid-template-columns: 20px calc(100% - 140px) 120px;
		grid-template-rows: auto;
		font-size: 14px;
	}

	.paymentInfo > .description {
		grid-column: 1/3;
		grid-row: 2/3;
	}

	.errorsReason {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: auto auto auto;
	}

	.errorsReason div {
		grid-column: 1/2;
	}
}

@media (max-width: 500px) {
	.padding-left {
		padding-left: 0;
	}
}
</style>