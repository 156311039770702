<template>
	<div class="mt-3.5">
		<!--Legal entity & docType-->
		<div class="one-line w-full mb-2.5">
			<v-select
				v-model="extendedSearch.selectedChannels"
				:options='channelOptions'
				multiple=true
				@option:deselected="deselectChannel"
				@option:selected="updatedChanel"
				:class="showInOneColumn ? 'mb-2.5' : 'mr-2.5'"
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>

			</v-select>

			<vue-select
				v-model="extendedSearch.legalEntityNumber"
				:options='legalEntitiesNames'
				:clearable='false'
				:style="showInOneColumn ? {width: '100%'} : {width: '50%'}"
				:class="showInOneColumn ? '' : 'ml-2.5'"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
				<template #option="option">
					{{ option.value === null ? $t(option.label) : option.label }}
				</template>
				<template #selected-option="option">
					{{ option.value === null ? $t(option.label) : option.label }}
				</template>
			</vue-select>
		</div>
		<div class="one-line mb-2.5">
			<p style="flex-basis: auto; font-size: 14px">{{
					$t('extendedSearch.customerMasterData.unreleasedDocuments')
				}}</p>
			<toggle-slide
				style="margin-left: 10px; flex-basis: auto"
				:class="showInOneColumn ? 'mt-2': ''"
				@update-value="extendedSearch.clientDocsOnHold = !extendedSearch.clientDocsOnHold"
				:center-item="false"
				:bool="extendedSearch.clientDocsOnHold"
				:renewBool="true"
			/>
		</div>

	</div>
</template>
<script>

import ToggleSlide from "@/components/UI/ToggleSlider.vue";
import userGroups from "@/userGoups";
import i18n from "@/i18n";
import VueSelect from "vue3-select";


export default {
	components: {ToggleSlide, VueSelect},
	data() {
		return {
			showInOneColumn: window.innerWidth < 980,
			extendedSearch: {}
		}
	},
	created() {
		this.extendedSearch = this.extendedSearchStartValue()
	},
	mounted() {
		// We are doing it like this, so it doesn't update the main class without needing to press search
		for (let filterElement in this.$store.state.extendedSearchValue.extendedSearch) {
			if (this.$store.state.extendedSearchValue.extendedSearch[filterElement] !== undefined) {
				this.extendedSearch[filterElement] = this.$store.state.extendedSearchValue.extendedSearch[filterElement]
			}
		}

		window.addEventListener('resize', () => this.showInOneColumn = 'change var')
	},
	watch: {
		"$store.state.extendedSearchValue.extendedSearch": {
			handler() {
				this.extendedSearch = JSON.parse(JSON.stringify(this.$store.state.extendedSearchValue.extendedSearch))
				if (!this.extendedSearch.legalEntityNumber) this.extendedSearch.legalEntityNumber = this.legalEntitiesNames[0]
				if (!this.extendedSearch.selectedChannels) this.extendedSearch.selectedChannels = {label: i18n.t('management.channelChoice.selectchannel'), value: 'NO_CHANNEL'}
				if (!this.extendedSearch.clientDocsOnHold) this.extendedSearch.clientDocsOnHold = false
			},
			deep: true
		},
		showInOneColumn() {
			this.showInOneColumn = window.innerWidth < 980
		}
	},
	computed: {
		legalEntitiesNames() {
			let leNames = []
			let legalEntities = this.$store.state.legalEntitiesData.data.legalEntities;

			for (const le in legalEntities) {
				let name = '';
				if (userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
					|| userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)) {
					name = legalEntities[le].internalName ? legalEntities[le].internalName : legalEntities[le].name
				} else {
					name = +legalEntities[le].name
				}

				let tempItem = {
					label: name,
					value: legalEntities[le].entityNumber
				}
				leNames.push(tempItem);
			}

			leNames.sort(function (a, b) {
				const textA = a.label.toUpperCase()
				const textB = b.label.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})

			leNames.unshift({label: 'general.allLegalEntity', value: null})
			return leNames
		},
		channelOptions() {
			let options = [];
			let channelConfig = this.$store.state.supplierGroup.data.channelsConfig;
			if (channelConfig.edi) {
				options.push({label: i18n.t('management.channelChoice.edi'), value: 'EDI'})
			}
			if (channelConfig.attachEmail) {
				options.push({label: i18n.t('management.channelChoice.attachemail'), value: 'ATTACH_EMAIL'})
			}
			if (channelConfig.linkEmail) {
				options.push({label: i18n.t('management.channelChoice.linkemail'), value: 'LINK_EMAIL'})
			}
			if (channelConfig.print) {
				options.push({label: i18n.t('management.channelChoice.print'), value: 'PRINT'})
			}
			return options;
		}
	},
	methods: {
		extendedSearchStartValue() {
			return {
				legalEntityNumber: this.legalEntitiesNames[0],
				selectedChannels: {label: i18n.t('management.channelChoice.selectchannel'), value: 'NO_CHANNEL'}
			}
		},
		deselectChannel() {
			if (this.extendedSearch.selectedChannels.length === 0) {
				this.extendedSearch.selectedChannels = [{
					label: i18n.t('management.channelChoice.selectchannel'),
					value: 'NO_CHANNEL'
				}];
			}
		},
		updatedChanel() {
			if (this.extendedSearch.selectedChannels.length >= 2) {
				this.extendedSearch.selectedChannels.forEach((channel, index) => {
					if (channel.value === 'NO_CHANNEL') {
						this.extendedSearch.selectedChannels.splice(index, 1);
					}
				})
			}
		}
	}
}
</script>


<style scoped>
.one-line {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 980px) {
	.one-line {
		flex-direction: column;
	}
}
</style>