<template>
	<div
		:style="{top: '-' + positionHigher + 'px', display: displayAlert}"
		class="info-alert-container"
	>
		<div
			:class="messageType"
			style="padding: 10px"
			role="alert"
		>
			<div class="flex">
				<div class="py-1" id="icon" style="margin-right: 5px">
					<span class="material-icons">{{ Icon }}</span>
				</div>
				<div>
					<p class="font-bold">{{ title }}</p>
					<p class="text-sm" id="text"> {{ text }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		text: {
			type: String,
			required: true
		},
		positionHigher: {
			type: Number,
			required: false,
			default: 0
		},
		isVisible: {
			type: Boolean,
			required: true
		},
		messageType: {
			type: String,
			required: false,
			default: 'info'
		}
	},
	computed: {
		displayAlert(){
			if (this.isVisible){
				return 'block'
			} else return 'none'
		},
		Icon(){
			if (this.messageType === 'info'){
				return 'info_outline'
			}
			else if (this.messageType === 'error'){
				return 'error_outline'
			}
			else if (this.messageType === 'warning'){
				return 'warning'
			}
			else if (this.messageType === 'success'){
				return 'task_alt'
			}
			return ''
		},
	}
}
</script>

<!--IMPORTANT! Leave the unused (info, error, warning, success). It is used dynamically-->
<style scoped>
.info-alert-container {
	position: absolute;
	top: 0;
	left: 50%; /* Adjust left position as needed */
	transform: translateX(-50%);
	z-index: 9999;
}

.info {
	background-color: #e5f6fd;
}
.error {
	background-color: #fdeded;
}
.warning {
	background-color: #fff4e5;
}
.success {
	background-color: #edf7ed;
}

</style>