<template>
    <div>
        <the-header></the-header>
        <loading-notification></loading-notification>
        <router-view></router-view>
        <the-footer></the-footer>
    </div>
</template>
<script>
import TheHeader from "./TheHeader"
import TheFooter from "./TheFooter"
import LoadingNotification from "./UI/LoadingNotification"

export default {
    name: "MainContainer",
    components: {LoadingNotification, TheFooter, TheHeader},
}
</script>
