<template>
    <pop-up-window :show="this.$store.state.extraInfo.display"
                   :showClose=false
                   class="doc-statuses-popup"
                   additional-info=""
                   :top40=true
                   fixed
                   :zIndexTop="true"
    >
        <template #title>
            <h1 class="text-2xl">
                {{ $t('generalNotification.title') }}
            </h1>
        </template>
        <template #content>
            <div class="text-popup-header text-center">
                <i class="icon-notification info-color"></i>
                {{ $t('generalNotification.uploadedBatchesExtraInfo') }}
            </div>
            <div
                 class="grid grid-rows-1 justify-items-center my-6 grey-line">
                <div>
                    <button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeExtraInfo">
                        {{ $t('buttons.close') }}
                    </button>
                </div>
            </div>
        </template>
    </pop-up-window>

</template>

<script>
import PopUpWindow from "./PopUpWindow";
import i18n from '../../i18n'
export default {
    name: "ExtraInfo",
    components: {PopUpWindow},
    data() {
        return {
            i18n: i18n,
        }
    },
    methods:{
        closeExtraInfo(){
            this.$store.commit("setDisplayExtraInfo", false);
            this.$store.commit("setPrintBatchAfterUpload", false)
        }
    }
}
</script>

<style scoped>

</style>
