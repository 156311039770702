<template>
	<transition name="dialog" v-if="currentUserDataIsReady">
		<page-container class="page-content">
			<top-banner>
				<div class="w-9/12">
					<h1 class="text-3xl text-center">{{ $t('reports.header.title') }}</h1>
				</div>
			</top-banner>
			<div class="box page-content intro-y box p-5 mt-5" v-if="checkResetPassword">
				<div class="button-container-outer">
					<div class="button-container-inner">
						<button class="btn custom-button"
								:class="{'inactive' : !tabs.reports.isActive}"
								@click="makeActive('reports')">
							{{ $t('buttons.reports') }}
						</button>
						<button class="btn custom-button"
								v-show="displayDataStudio"
								:class="{'inactive' : !tabs.dataStudio.isActive}"
								@click="makeActive('dataStudio')">
							{{ $t('buttons.dataStudio') }}
						</button>
					</div>
				</div>
				<div class="component-container">
					<ReportsContainer v-if="tabs.reports.isActive"></ReportsContainer>
					<DataStudioContainer v-if="tabs.dataStudio.isActive"></DataStudioContainer>
				</div>
			</div>
		</page-container>
	</transition>
</template>

<script>
import routerData from "../routerData";
import userGroups from "../userGoups"
import TopBanner from "../components/TopBanner"
import PageContainer from "../components/PageContainer";
import ReportsContainer from "../components/reports/ReportsContainer"
import DataStudioContainer from "../components/reports/DataStudioContainer";

const newLoginPage = window.newLoginPage
export default {
	components: {DataStudioContainer, TopBanner, PageContainer, ReportsContainer},
	mounted() {
		this.$store.dispatch("updateToken");
		this.$store.dispatch("checkAcceptedTos", {requiresAuth: this.$route.meta.requiresAuth})
		this.checkUser();
		this.passwordReset();
	},
	data() {
		return {
			currentUserDataIsReady: this.$store.state.currentUserData.data.id,
			userGroups: userGroups,
			tabs: {
				reports: {
					isActive: true
				},
				dataStudio: {
					isActive: false
				}
			},
			activeTab: "reports"
		}
	},
	watch: {
		currentUserData() {
			this.$store.dispatch("checkAcceptedTos", {requiresAuth: this.$route.meta.requiresAuth})
			this.checkUser();
			this.currentUserDataIsReady = true;
			this.passwordReset();
		},
	},
	computed: {
		checkResetPassword() {
			return !(newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)));
		},
		currentUserData() {
			return this.$store.state.currentUserData.data
		},
		displayDataStudio() {
			return this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.reportUrl;
		},
	},
	methods: {
		passwordReset() {
			if ((newLoginPage &&
				(this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
				|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))
			) this.$store.commit("setDisplayResetOwnPassword", true)
		},
		makeActive(tabName) {
			this.$store.dispatch("updateToken");
			for (let tab in this.tabs) {
				this.tabs[tab].isActive = false;
			}
			this.tabs[tabName].isActive = true;
			this.activeTab = tabName;
		},
		checkUser() {
			if (this.$store.state.currentUserData.data.id) {
				if (!(this.$store.state.currentUserData.data.group === this.userGroups.SupplierGroupSuperAdmin.name
					|| this.$store.state.currentUserData.data.group === this.userGroups.SupplierSubAdmin.name
					|| this.$store.state.currentUserData.data.group === this.userGroups.OpHelpdesk.name
					|| this.$store.state.currentUserData.data.group === this.userGroups.OpAdmin.name
				)) this.$router.push({path: routerData.noAccess.link})
			}
		}
	}
}
</script>

<style scoped>
.button-container-outer {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-content: end;
}

.button-container-inner {
	grid-column: 2/3;
	grid-row: 1/2;
	display: grid;
	grid-template-columns: repeat(10, auto);
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-self: end;
}

.button-container-inner button {
	margin-left: 10px;
}

.inactive {
	background-color: var(--theme-primary-100);
	border-color: rgba(85, 85, 85, var(--theme-primary-100));
}

.page-content {
	min-height: calc(100vh - var(--headerheight) - var(--footerHeight) - 10 * var(--generalPaddings));
}

.component-container {
	margin-top: var(--generalPaddings);
	padding-top: var(--generalPaddings);
	border-top: 1px solid var(--theme-primary-50);
}
@media only screen and (max-width: 730px) {
	.button-container-outer {
		grid-template-columns: 100%;
		grid-template-rows: 50% 50%;
	}

	.button-container-inner {
		grid-column: 1/2;
		grid-row: 1/2;
	}
}
</style>